<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark dense color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >My Courses</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-col cols="10" sm="6" md="4">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchCourse"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="showSearchField = !showSearchField"
              >mdi-magnify</v-icon
            >
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
    </v-toolbar>
    <v-container fluid>
      <v-row class="ma-1">
        <v-col cols="4" v-for="(course, index) in teacherCourses" :key="index">
          <v-card min-height="320" width="100%" class="ma-1">
            <v-img
              width="100%"
              max-height="170"
              :src="course.thumbnail"
              @click="courseDialogOpen(course)"
            ></v-img>
            <v-row class="ma-1 pa-0">
              <v-col cols="10" class="pa-0">
                <v-card-title
                  class="pa-0"
                  style="font-size: 22px; font-weight: 400"
                >
                  <span v-if="course.name.length <= 20">{{ course.name }}</span>
                  <v-tooltip bottom v-if="course.name.length > 20">
                    <template v-slot:activator="{ on }">
                      <span v-on="on">{{
                        course.name.substring(0, 20) + "..."
                      }}</span>
                    </template>
                    <span>{{ course.name }}</span>
                  </v-tooltip>
                </v-card-title>
              </v-col>
              <v-col cols="2" class="pa-0">
                <!-- <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE && course.buyable
                  " src="../assets/premium-quality 1.svg" class="ml-10" max-height="30" max-width="30"></v-img>
                <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE && !course.buyable
                  " src="../assets/school.png" class="ml-2" max-height="30" max-width="30"></v-img> -->
                <v-img
                  v-if="
                    course.courseType == COURSE_TYPE_PRIVATE && course.buyable
                  "
                  src="../assets/premium-quality 1.svg"
                  max-height="30"
                  max-width="30"
                ></v-img>
                <v-img
                  v-if="
                    course.courseType == COURSE_TYPE_PRIVATE &&
                    !course.buyable &&
                    !course.isForUK
                  "
                  src="../assets/school.png"
                  max-height="30"
                  max-width="30"
                ></v-img>
                <v-img
                  v-if="
                    course.courseType == COURSE_TYPE_PRIVATE &&
                    !course.buyable &&
                    course.isForUK == true
                  "
                  class="mt-1 ml-1"
                  src="../assets/alpha-u-circle-outline.svg"
                  max-height="30"
                  max-width="30"
                ></v-img>
              </v-col>
              <!-- <v-col cols="2" class="pa-0">
                <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE && !course.buyable && course.isForUK == true
                  " src="../assets/alpha-u-circle-outline.svg" class="ml-n4 mt-1" max-height="30"
                  max-width="30"></v-img> -->
            </v-row>
            <v-card-text class="pa-1">
              <v-col class="pa-0">
                <p
                  class="pa-0"
                  style="font-size: 20px; font-weight: 400; color: black"
                >
                  Grades :
                  <span
                    style="color: #faaf19; font-size: 20px; font-weight: 500"
                    v-for="(grade, index) in course.grades"
                    :key="index"
                  >
                    <span v-if="course.grades.length <= 4"
                      >{{ grade
                      }}{{
                        index != course.grades.length - 1 ? ", " : ""
                      }}</span
                    >
                  </span>
                  <v-tooltip bottom v-if="course.grades.length > 4">
                    <template v-slot:activator="{ on }">
                      <span
                        v-on="on"
                        style="
                          color: #faaf19;
                          font-size: 20px;
                          font-weight: 500;
                        "
                        >{{
                          course.grades.slice(0, 4).join(", ") + "..."
                        }}</span
                      >
                    </template>
                    <span v-for="(grade, index) in course.grades" :key="index"
                      >{{ grade
                      }}{{
                        index != course.grades.length - 1 ? ", " : ""
                      }}</span
                    >
                  </v-tooltip>
                </p>
              </v-col>
              <div
                style="font-size: 14px; font-weight: 400"
                class="pa-0"
                v-if="course.description && course.description.length < 80"
              >
                <span v-html="course.description"> </span>
              </div>
              <div
                style="font-size: 14px; font-weight: 400"
                class="pa-0"
                v-if="
                  course.description &&
                  course.description.length >= 80 &&
                  showDescArr.length > 0 &&
                  !showDescArr[index].showFullDesc
                "
              >
                <span v-html="course.description.substring(0, 80) + '...'">
                </span>
                <v-row justify="center" class="mb-1">
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="showDescArr[index].showFullDesc = true"
                  >
                    Read more
                  </v-btn>
                </v-row>
              </div>
              <div
                class="pa-0 ma-1"
                v-if="
                  course.description &&
                  course.description.length >= 80 &&
                  showDescArr.length > 0 &&
                  showDescArr[index].showFullDesc
                "
              >
                <span v-html="course.description"> </span>
                <v-row justify="center" class="mb-1">
                  <v-btn
                    color="primary"
                    small
                    text
                    @click="showDescArr[index].showFullDesc = false"
                  >
                    Read less
                  </v-btn>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-dialog
        v-model="courseDialog"
        eager
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-menu
          v-model="fab"
          offset-x
          top
          offset-y
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              v-model="fab"
              color="primary"
              dark
              fab
              v-on="on"
              class="fixed-btn"
            >
              <v-icon v-if="fab"> mdi-close </v-icon>
              <v-img
                v-else
                max-height="40"
                max-width="40"
                src="../assets/Icons/Group_227.svg"
              ></v-img>
            </v-btn>
          </template>
          <ChatBox></ChatBox>
        </v-menu>
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="closeCourseDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
              courseData.name
            }}</v-toolbar-title>

            <v-spacer></v-spacer>
            <v-toolbar-title style="font-weight: 500; font-size: 20px"
              >Full Screen</v-toolbar-title
            >
            <v-switch
              v-model="fullScreen"
              label=""
              class="mt-6 pa-2"
              @change="makeFullScreen()"
            ></v-switch>
          </v-toolbar>
          <v-row class="mr-1 pa-0">
            <v-col cols="3" v-if="!collapse" style="background-color: #f0fff0">
              <v-list
                class="overflow-y-auto"
                height="90dvh"
                style="background-color: #f0fff0"
              >
                <v-list-group
                  :value="true"
                  v-for="chapter in courseData.chapters"
                  :key="chapter.chapterNo"
                  prepend-icon="$expand"
                  sub-group
                  class="ml-n6"
                >
                  <template v-slot:activator>
                    <v-list-item>
                      <v-list-item-content class="ml-n7">
                        <v-list-item-title
                          style="font-size: 20px; font-weight: 500"
                          >{{ chapter.chapterName }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-group
                    :value="true"
                    v-for="lesson in chapter.lessons"
                    :key="lesson.lessonNo"
                    no-action
                    sub-group
                  >
                    <template v-slot:activator>
                      <v-list-item-content class="ml-n4">
                        <v-list-item-title
                          style="font-size: 18px; color: #ff6666"
                          class="text-wrap"
                          >{{ lesson.lessonName }}</v-list-item-title
                        >
                      </v-list-item-content>
                    </template>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            style="
                              font-size: 16px;
                              font-weight: 500;
                              color: #00308f;
                            "
                            >Content</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="content in lesson.content"
                          :key="content.contentNo"
                          link
                          class="ml-4"
                          @click="
                            showContent(
                              content,
                              chapter.chapterNo,
                              lesson.lessonNo
                            )
                          "
                          :input-value="
                            highLightCurrentContent(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              content.contentNo
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <!-- :class="{
                            active: activeContentElement === content.source,
                          }" -->
                          <v-list-item-icon>
                            <v-icon size="20" color="black">
                              {{ `mdi-${getIcon(content)}` }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title
                              style="font-size: 14px"
                              class="text-wrap"
                            >
                              {{ content.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.notes.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            style="
                              font-size: 16px;
                              font-weight: 500;
                              color: #00308f;
                            "
                            >Notes</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="note in lesson.notes"
                          :key="note.noteNo"
                          link
                          class="ml-4"
                          @click="
                            showNotes(note, chapter.chapterNo, lesson.lessonNo)
                          "
                          :input-value="
                            highLightCurrentNote(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              note.noteNo
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon size="20" color="black">
                              {{ `mdi-${getIcon(note)}` }}
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title
                              style="font-size: 14px"
                              class="text-wrap"
                              >{{ note.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.codeAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            style="
                              font-size: 16px;
                              font-weight: 500;
                              color: #00308f;
                            "
                            >Code Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.codeAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showCode(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentCode(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon size="20" style="color: black">
                              mdi-code-array
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title
                              style="font-size: 14px"
                              class="text-wrap"
                              >{{ assignment.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.quizAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            style="
                              font-size: 16px;
                              font-weight: 500;
                              color: #00308f;
                            "
                            >Quiz Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.quizAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showQuiz(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentQuiz(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon size="20" style="color: black">
                              mdi-lightbulb-on-50
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title
                              style="font-size: 14px"
                              class="text-wrap"
                              >{{ assignment.quizName }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.textAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            style="
                              font-size: 16px;
                              font-weight: 500;
                              color: #00308f;
                            "
                            >Text Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.textAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showText(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentText(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon size="20" style="color: black">
                              mdi-text-box-edit-outline
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title
                              style="font-size: 14px"
                              class="text-wrap"
                              >{{ assignment.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>

                    <v-list-group
                      :value="true"
                      no-action
                      sub-group
                      class="ml-3"
                      v-if="
                        lesson.codelyAssignments.length != 0 &&
                        courseData.courseType == COURSE_TYPE_PRIVATE
                      "
                    >
                      <template v-slot:activator>
                        <v-list-item-content class="ml-n4">
                          <v-list-item-title
                            style="
                              font-size: 16px;
                              font-weight: 500;
                              color: #00308f;
                            "
                            >Codely Assignments</v-list-item-title
                          >
                        </v-list-item-content>
                      </template>
                      <v-list-item-group :active-class="'border'">
                        <v-list-item
                          v-for="assignment in lesson.codelyAssignments"
                          :key="assignment.assignmentNumber"
                          link
                          class="ml-4"
                          @click="
                            showCodely(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment
                            )
                          "
                          :input-value="
                            highLightCurrentCodely(
                              chapter.chapterNo,
                              lesson.lessonNo,
                              assignment,
                              assignment.assignmentNumber
                            )
                              ? 'border'
                              : ''
                          "
                        >
                          <v-list-item-icon>
                            <v-icon size="20" style="color: black">
                              mdi-code-block-brackets
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="ml-n3">
                            <v-list-item-title
                              style="font-size: 14px"
                              class="text-wrap"
                              >{{ assignment.title }}</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list-group>
                  </v-list-group>
                </v-list-group>
              </v-list>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col :cols="contentViewCol">
              <div class="right-portion">
                <!-- <p v-if="content.type === CONTENT_TYPE_TEXT || (note.source != 'Notes locked' && note.type === CONTENT_TYPE_TEXT)"
                                class="ma-2">{{ content.source || note.source }}</p> -->
                <p
                  v-if="
                    content.type === CONTENT_TYPE_TEXT ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_TEXT)
                  "
                  class="ma-8"
                  style="font-size: 20px"
                  v-html="content.source || note.source"
                ></p>
                <p
                  v-if="
                    (content.source != 'Content locked' &&
                      content.type === CONTENT_TYPE_MEDIA &&
                      content.resource?.status === 'BLOCKED') ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_MEDIA &&
                      note.resource?.status === 'BLOCKED')
                  "
                  class="ma-8"
                  style="font-size: 20px"
                  v-html="content.source || note.source"
                ></p>
                <!-- Video player will be shown if content.resource.mimeType contains 'video' string -->
                <VideoPlayer
                  class="pa-8"
                  v-if="
                    (content.source != 'Content locked' &&
                      content.type === CONTENT_TYPE_MEDIA &&
                      !imagesArray &&
                      content.resource?.status != 'BLOCKED' &&
                      content.resource?.mimeType?.includes('video')) ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_MEDIA &&
                      !imagesArray &&
                      note.resource?.status != 'BLOCKED' &&
                      note.resource?.mimeType?.includes('video'))
                  "
                  :options="{
                    autoplay: true,
                    controls: true,
                    fluid: true,
                    sources: [
                      {
                        src: `${content.source || note.source}`,
                        type: 'video/mp4',
                      },
                    ],
                  }"
                ></VideoPlayer>
                <!-- Vue friendly Iframe will be shown if content.resource.mimeType  does not contain 'video' string -->
                <vue-friendly-iframe
                  style="height: 92dvh"
                  v-if="
                    (content.type === CONTENT_TYPE_MEDIA &&
                      !imagesArray &&
                      content.resource?.status != 'BLOCKED' &&
                      !content.resource?.mimeType?.includes('video')) ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_MEDIA &&
                      !imagesArray &&
                      note.resource?.status != 'BLOCKED' &&
                      !note.resource?.mimeType?.includes('video'))
                  "
                  class="iframe"
                  :src="
                    (content.resource?.mimeType?.includes('pdf')
                      ? content.source + '#toolbar=0'
                      : content.source) ||
                    (note.resource?.mimeType?.includes('pdf')
                      ? note.source + '#toolbar=0'
                      : note.source)
                  "
                ></vue-friendly-iframe>
                <ImageArray
                  v-if="
                    imagesArray &&
                    content.type === CONTENT_TYPE_MEDIA &&
                    content.resource?.status != 'BLOCKED'
                  "
                  :title="content.title"
                  :images="content.images"
                />
                <vue-friendly-iframe
                  style="height: 92dvh"
                  v-if="
                    (content.source != 'Content locked' &&
                      content.type === CONTENT_TYPE_CODELY) ||
                    (note.source != 'Notes locked' &&
                      note.type === CONTENT_TYPE_CODELY)
                  "
                  :src="content.source || note.source"
                  class="iframe"
                  allow="clipboard-read; clipboard-write"
                ></vue-friendly-iframe>
                <v-dialog v-model="imgDialog" width="500">
                  <v-card>
                    <v-img :src="quizQuestionMedia"></v-img>
                  </v-card>
                </v-dialog>
                <v-row
                  justify="center"
                  class="ma-4"
                  v-if="quizContent && quizContent.questions.length != 0"
                >
                  <v-card class="ma-4" width="70%">
                    <v-col class="ma-2">
                      <span
                        style="font-size: 20px"
                        v-html="
                          formatQuestionTitle(
                            quizContent.questions[index].questionNo,
                            quizContent.questions[index].title,
                            quizContent.questions.length
                          )
                        "
                      ></span>
                      <div
                        class="mt-4"
                        v-if="quizContent.questions[index].media"
                      >
                        <v-img
                          max-height="300"
                          max-width="300"
                          @click="viewImage(quizContent.questions[index])"
                          :src="quizContent.questions[index].media"
                        ></v-img>
                        <span
                          v-if="quizContent.questions[index].media"
                          style="font-size: 14px"
                          >If image is not clear , click on image to see full
                          image</span
                        >
                      </div>
                      <v-col
                        class="pl-0"
                        v-for="option in quizContent.questions[index].options"
                        :key="option.number"
                      >
                        <label :class="option.isAnswer ? 'success--text' : ''">
                          <input
                            type="radio"
                            name="option.value"
                            :value="option.number"
                            disabled
                            :checked="option.isAnswer"
                          />
                          {{ option.value }}</label
                        >
                      </v-col>
                    </v-col>
                    <v-row class="ma-4">
                      <span style="font-weight: 500; font-size: 18px">
                        Explanation :
                      </span>
                      <span
                        class="purple--text"
                        style="font-weight: 400; font-size: 16px"
                        v-html="quizContent.questions[index].explaination"
                      >
                      </span>
                    </v-row>
                    <v-row class="ma-2">
                      <v-spacer></v-spacer>
                      <v-btn
                        class="ma-2"
                        medium
                        :disabled="index == 0"
                        color="primary"
                        @click="backpage()"
                        >Back</v-btn
                      >
                      <v-btn
                        class="ma-2"
                        medium
                        :disabled="index == quizContent.questions.length - 1"
                        color="primary"
                        @click="nextPage()"
                        >Next</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-card>
                </v-row>
                <div class="ma-4" v-if="showCodeAssignment == true">
                  <v-col>
                    <v-row class="mt-1 pl-3">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Question:
                      </span>
                      <span
                        class="ml-1"
                        style="font-size: 20px"
                        v-html="codeAssignment.question"
                      ></span>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="mt-1 pl-3">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Solution:
                      </span>
                      <span
                        class="ml-1"
                        style="font-size: 20px"
                        v-html="codeAssignment.solution"
                      ></span>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="mt-1 pl-3">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Expected Output:
                      </span>
                      <span
                        class="ml-1"
                        style="font-size: 20px"
                        v-html="codeAssignment.expectedOutput"
                      ></span>
                    </v-row>
                  </v-col>
                </div>
                <div class="ma-4" v-if="showTextAssignment == true">
                  <v-col>
                    <v-row class="mt-1 pl-3">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Question:
                      </span>
                      <span
                        class="ml-1"
                        style="font-size: 20px"
                        v-html="textAssignment.question"
                      ></span>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row class="mt-1 pl-3">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Solution:
                      </span>
                      <span
                        class="ml-1"
                        style="font-size: 20px"
                        v-html="textAssignment.answer"
                      ></span>
                    </v-row>
                  </v-col>
                </div>

                <div class="ma-4" v-if="showCodelyAssignment">
                  <v-row class="ma-3 pa-0">
                    <v-col cols="12" class="ma-3 pa-0">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Question Link:</span
                      >
                      <v-text-field
                        v-model="codelyAssignment.codelyLink"
                        prepend-inner-icon="mdi-link"
                        readonly
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                @click="
                                  copyToClipboard(codelyAssignment.codelyLink)
                                "
                                color="primary"
                                >mdi-content-copy</v-icon
                              >
                            </template>
                            <span>Copy</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" class="ma-3 pa-0">
                      <span
                        style="font-weight: 500; font-size: 20px"
                        class="error--text"
                        >Solution Link:</span
                      >
                      <v-text-field
                        v-model="codelyAssignment.solutionLink"
                        prepend-inner-icon="mdi-link"
                        readonly
                        outlined
                        dense
                      >
                        <template v-slot:append>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                class="ml-2"
                                @click="
                                  copyToClipboard(codelyAssignment.solutionLink)
                                "
                                color="primary"
                                >mdi-content-copy</v-icon
                              >
                            </template>
                            <span>Copy</span>
                          </v-tooltip>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-snackbar
                    v-model="snackbar"
                    :timeout="2000"
                    :color="snackbarColor"
                    top
                  >
                    {{ snackbarText }}
                  </v-snackbar>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>
<script>
import {
  GET_TEACHER_COURSES,
  GET_COURSE_BY_ID,
  BASE_URL,
  GET_QUIZ_BY_ID,
} from "@/common/apiEndpoints.js";
import {
  COURSE_TYPE_PRIVATE,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
} from "@/common/constants";

import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatQuestionTitle } from "@/common/utility";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import StudentQuizes from "@/components/StudentQuizes.vue";
import ChatBox from "../components/ChatBox.vue";
import VideoPlayer from "./VideoPlayer.vue";
import ImageArray from "./ImageArray2.vue";
export default {
  data() {
    return {
      imagesArray: false,
      index: 0,
      model: null,
      multiple: false,
      mandatory: false,
      fullScreen: false,
      collapse: false,
      contentViewCol: "9",
      imgDialog: false,
      quizQuestionMedia: "",
      showArrows: true,
      prevIcon: true,
      nextIcon: true,
      centerActive: false,
      showSearchField: false,
      searchText: "",
      name: "",
      courses: [],
      courseTypeFilter: [],
      menu: false,
      teacherCourses: [],
      enrolledCourses: [],
      freeCourses: [],
      completedCourses: [],
      showDescArr: [],
      courseDialog: false,
      courseData: [],
      content: {},
      note: {},
      textAssignment: {},
      codeAssignment: {},
      codelyAssignment: {},
      showCodelyAssignment: false,
      showTextAssignment: false,
      showCodeAssignment: false,
      showSelectedContent: false,
      showSelectedNote: false,
      selected: -1,
      explaination: "",
      quizContent: { questions: [] },
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      fab: false,
      top: false,
      left: false,
      currentActiveElement: null,

      // activeContentElement: null,
      // activeNoteElement: null,
      // activeQuizAssignmentElement: null,
      // activeTextAssignmentElement: null,
      // activeCodeAssignmentElement: null,
      // activeCodelyAssignmentElement: null,

      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
    };
  },
  components: { StudentQuizes, VideoPlayer, ChatBox, ImageArray },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },

  mounted: function () {
    this.fetchCourses();
    this.teacherCourses = this.courses;
  },

  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.showSnackbar("Link copied to clipboard", "success");
        },
        () => {
          this.showSnackbar("Failed to copy link", "error");
        }
      );
    },
    showSnackbar(message, type) {
      this.snackbarText = message;
      this.snackbarColor = type === "success" ? "green" : "red";
      this.snackbar = true;
    },

    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getIcon(content, note) {
      if (content) {
        if (content.type == CONTENT_TYPE_QUIZ) {
          return "lightbulb-on-10";
        } else if (content.type == CONTENT_TYPE_TEXT) {
          return "text-box-outline";
        } else if (content.type == CONTENT_TYPE_MEDIA) {
          if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("video")
          ) {
            return "video";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("image")
          ) {
            return "image";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("pdf")
          ) {
            return "file-pdf-box";
          } else {
            return "table-of-contents";
          }
        } else {
          return "code-block-braces";
        }
      } else {
        if (note.type == CONTENT_TYPE_MEDIA) {
          if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("video")
          ) {
            return "video";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("image")
          ) {
            return "image";
          } else if (
            content.resource.mimeType &&
            content.resource.mimeType.includes("pdf")
          ) {
            return "file-pdf-box";
          } else {
            return "table-of-contents";
          }
        } else {
          return "text-box-outline";
        }
      }
    },
    fetchCourses() {
      this.setOverlayValue(true);
      let url = GET_TEACHER_COURSES;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teacherCourses = res.data.courses;
          this.showDescArr = [];
          for (let i = 0; i < this.teacherCourses.length; i++) {
            let showDescProp = {};
            showDescProp.courseId = this.teacherCourses[i].courseId;
            showDescProp.showFullDesc = false;
            this.showDescArr.push(showDescProp);
          }
          for (let i = 0; i < this.teacherCourses.length; i++) {
            const grade = this.teacherCourses[i].grades;
            grade.sort((a, b) => {
              if (Number(a) < Number(b)) return -1;
              if (Number(a) > Number(b)) return 1;
              return 0;
            });
          }
          this.courses = res.data.courses;
          this.teacherCourses = this.courses;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    viewImage(quizQuestions) {
      this.imgDialog = true;
      this.quizQuestionMedia = quizQuestions.media;
    },
    makeFullScreen() {
      this.collapse = !this.collapse;
      this.contentViewCol == "9"
        ? (this.contentViewCol = "12")
        : (this.contentViewCol = "9");
    },
    getCourseData(course) {
      let url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courseData = res.data;
          this.content = this.courseData.chapters[0].lessons[0].content[0];

          const initialContent =
            this.courseData.chapters[0].lessons[0].content[0];
          this.content = initialContent;
          if (initialContent.type === CONTENT_TYPE_QUIZ) {
            this.getQuizBYQuizId(initialContent.source);
          } else {
            this.showContent(initialContent);
          }

          // if (this.content.type == CONTENT_TYPE_QUIZ) {
          //   this.getQuizBYQuizId(this.content.source);
          // }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    searchCourse() {
      if (this.searchText) {
        this.teacherCourses = this.courses.filter((course) => {
          return course.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.teacherCourses = this.courses;
      }
    },
    courseDialogOpen(course) {
      this.getCourseData(course);
      this.courseDialog = true;
    },
    closeCourseDialog() {
      this.courseData = [];
      this.chapters = [];
      this.content = {};
      this.note = {};
      this.textAssignment = {};
      this.codeAssignment = {};
      this.codelyAssignment = {};
      this.showSelectedNote = false;
      this.showCodeAssignment = false;
      this.showTextAssignment = false;
      this.showCodelyAssignment = false;
      (this.quizContent = { questions: [] }), (this.courseDialog = false);
    },

    showContent(content, chapterNo, lessonNo) {
      // this.currentActiveElement = null;
      this.currentActiveElement = content;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;

      if (
        content.type === CONTENT_TYPE_TEXT ||
        content.type === CONTENT_TYPE_MEDIA ||
        content.type === CONTENT_TYPE_CODELY
      ) {
        if (content.images && content.images.length > 0) {
          this.imagesArray = true;
        } else {
          this.imagesArray = false;
        }
        this.note = {};
        this.quizContent = {};
        this.quizContent.questions = [];
        this.textAssignment = {};
        this.codeAssignment = {};
        this.codelyAssignment = {};
        this.showSelectedNote = false;
        this.showCodeAssignment = false;
        this.showTextAssignment = false;
        this.showCodelyAssignment = false;
        this.content = content;
        content.value = true;
        this.showSelectedContent = true;
      }
      if (content.type === CONTENT_TYPE_QUIZ) {
        this.getQuizBYQuizId(content.source);
        this.content = content;
        content.value = true;
      }
    },

    showNotes(note, chapterNo, lessonNo) {
      // this.currentActiveElement = null;
      this.currentActiveElement = note;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      if (
        note.source === "Notes locked" ||
        note.type === CONTENT_TYPE_TEXT ||
        note.type === CONTENT_TYPE_MEDIA ||
        note.type === CONTENT_TYPE_CODELY
      ) {
        this.content = {};
        this.quizContent = {};
        this.quizContent.questions = [];
        this.textAssignment = {};
        this.codeAssignment = {};
        this.codelyAssignment = {};
        this.showSelectedContent = false;
        this.showCodeAssignment = false;
        this.showTextAssignment = false;
        this.showCodelyAssignment = false;
        this.note = note;
        note.value = true;
        this.showSelectedNote = true;
        if (note.type === CONTENT_TYPE_CODELY) {
          window.open(note.source, "_blank");
        }
      }
    },

    showCode(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showCodeAssignment = true;
      this.codeAssignment = assignment;
    },

    showQuiz(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.getQuizBYQuizId(assignment.quizId);
    },

    showText(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showTextAssignment = true;
      this.textAssignment = assignment;
    },

    showCodely(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showCodelyAssignment = true;
      this.codelyAssignment = assignment;
    },

    highLightCurrentContent(chapterNo, lessonNo, contentNo) {
      if (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo
      )
        return contentNo == this.currentActiveElement.contentNo;
    },
    highLightCurrentNote(chapterNo, lessonNo, noteNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        noteNo == this.currentActiveElement.noteNo
      );
    },
    highLightCurrentCode(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentQuiz(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentText(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentCodely(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },

    getQuizBYQuizId(quizId) {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = quizId;
      this.index = 0;
      // this.selected = false;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizContent = res.data;
          this.content = {};
          this.note = {};
          this.codelyAssignment = {};
          this.codeAssignment = {};
          this.textAssignment = {};
          this.showCodelyAssignment = false;
          this.showTextAssignment = false;
          this.showCodeAssignment = false;
          // this.selected = true;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    nextPage() {
      if (this.index < this.quizContent.questions.length) {
        this.index++;
      }
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
      }
    },
    formatQuestionTitle(questionNo, questionTitle, totalQuestions) {
      return formatQuestionTitle(questionNo, questionTitle, totalQuestions);
    },
  },
};
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 80px;
  right: 20px;
}

.border {
  border: 1px dashed red;
}

.right-portion {
  flex: 2;
  overflow-y: auto;
  overflow-x: auto;
  height: 90vh;
}

.active {
  background-color: #f0f0f0;
  /* Highlight the active content */
}
</style>
