<template>
  <v-card class="ma-4 mx-4 pa-1" flat>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Register Yourself</v-toolbar-title
      >
    </v-toolbar>
    <v-card-title>
      Please fill below details to complete your on-boarding
    </v-card-title>
    <v-card-text>
      <v-card-text style="font-size: 18px; color: #ff6666; font-weight: 600"
        >Enter Your Basic Details</v-card-text
      >
      <v-form ref="form" v-model="studentDetailsValidation.valid">
        <v-row class="ma-2">
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.firstName"
              label="First Name"
              :rules="studentDetailsValidation.nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.lastName"
              label="Last Name"
              :rules="studentDetailsValidation.nameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.parentName"
              label="Parent/Guardian Name"
              :rules="studentDetailsValidation.nameRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.schoolName"
              label="School Name"
              :rules="studentDetailsValidation.schoolnameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4" class="mt-1">
            <v-combobox
              v-model="studentDetails.grade"
              :items="gradesArray"
              label="Your Grade"
              dense
              deletable-chips
              chips
              :rules="studentDetailsValidation.gradeRules"
              item-text="desc"
              item-value="id"
            >
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.age"
              label="Your Age"
              :rules="studentDetailsValidation.ageRules"
              type="number"
              required
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="4">
            <span style="font-size: 16px">Select Your Gender</span>
            <v-row>
              <v-col class="pt-0">
                <v-radio-group
                  v-model="studentDetails.gender"
                  row
                  :rules="studentDetailsValidation.nameRules"
                >
                  <v-radio label="Male" color="primary" value="MALE"></v-radio>
                  <v-radio
                    label="Female"
                    color="primary"
                    value="FEMALE"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.address.addressLine1"
              label="Primary Address"
              :rules="addressValidation.addressRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.address.addressLine2"
              label="Secondry Address"
              :rules="addressValidation.addressRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              @input="getAddressDetails()"
              v-model="studentDetails.address.pincode"
              label="Pin Number"
              :rules="studentDetailsValidation.pinRules"
              type="number"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.address.city"
              label="City"
              :rules="addressValidation.cityRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="studentDetails.address.state"
              label="State"
              :rules="studentDetailsValidation.stateRules"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="ma-2">
        <v-spacer></v-spacer>
        <v-col cols="8">
          <v-btn
            color="primary"
            @click="onBoard()"
            class="ma-2"
            width="200"
            height="40"
            style="font-size: 20px"
          >
            Register Now
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import {
  BASE_URL,
  GET_ADDRESS_DETAILS,
  STUDENT_ONBOARD,
} from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import axios from "axios";
import { GET_TOKEN } from "@/store/getters";
import { grades } from "@/common/constants";
import { FETCH_USER_PROFILE, SAVE_TOKEN } from "@/store/actions";
export default {
  data() {
    return {
      onboardingStep: 1,
      selectAllChecked: false,
      allowedMinutes: [0, 30],
      studentDetailsValidation: {
        valid: false,
        nameRules: [
          (v) => !!v || "Name is required",
          (v) =>
            /^[A-Za-z\s]+$/.test(v) ||
            "Please enter alphabetic characters only.",
        ],
        ageRules: [
          (v) => !!v || "Age is required",
          (v) => /^\d+$/.test(v) || "Age must be a number",
          (v) => Number(2) <= v || "Age must be greater than 2",
          (v) => Number(99) >= v || "Age must be less than 99",
        ],
        gradeRules: [(v) => !!v || "Grade is required"],
        pinRules: [
          (v) => !!v || "Pin is required",
          (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
        ],
        stateRules: [(v) => !!v || "State is required"],
        schoolnameRules: [(v) => !!v || "School Name is required"],
      },

      qualificationValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },

      addressValidation: {
        valid: false,
        cityRules: [(v) => !!v || "City is required"],
        addressRules: [(v) => !!v || "Address is required"],
      },
      scheduleValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      timing: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      studentDetails: {
        grade: "",

        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      dateMenu: false,
      gradesArray: grades,
    };
  },
  mounted: function () {},
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    ...mapActions({
      saveToken: `schoolModule/${SAVE_TOKEN}`,
      fetchProfile: `schoolModule/${FETCH_USER_PROFILE}`,
    }),
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.studentDetails.address.pincode.length == 6) {
        url += this.studentDetails.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.district == null) {
              this.studentDetails.address.city = "";
              this.studentDetails.address.state = "";
            } else {
              this.studentDetails.address.city = res.data.district;
              this.studentDetails.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    onBoard() {
      this.validate();
      if (this.studentDetailsValidation.valid) {
        //&& this.scheduleValidation.valid
        // console.log(this.studentDetails);
        const payload = {};
        payload.firstName = this.studentDetails.firstName;
        payload.lastName = this.studentDetails.lastName;
        payload.schoolName = this.studentDetails.schoolName;
        payload.parentName = this.studentDetails.parentName;
        payload.gender = this.studentDetails.gender;
        payload.grade = this.studentDetails.grade;
        payload.age = this.studentDetails.age;
        payload.state = this.studentDetails.address.state;
        payload.pincode = this.studentDetails.address.pincode;
        payload.city = this.studentDetails.address.city;
        payload.addressLine1 = this.studentDetails.address.addressLine1;
        payload.addressLine2 = this.studentDetails.address.addressLine2;

        let url = STUDENT_ONBOARD;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentDetailsValidation.valid = false;
            const storePayload = {};
            let oThis = this;
            storePayload.token = res.data.newToken;
            this.saveToken(storePayload).then(() => {
              oThis.fetchProfile();
              oThis.$router.push({ path: "/home/student-courses" });
              this.setApiSuccess("Student onboarded successfully");
            });
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
