<template v-bind="$props">
  <v-card flat class="pt-8" width="90%">
    <v-col cols="12">
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Question:
          </span>
        </v-col>
        <v-col>
          <span style="font-size: 20px" v-html="codeAssignment.question"></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Submission:
          </span>
        </v-col>
        <v-col>
          <v-textarea
            style="font-size: 18px"
            v-model="codeAssignment.submissions[0].submission"
            auto-grow
            outlined
            :readonly="true"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Solution:
          </span>
        </v-col>
        <v-col>
          <span style="font-size: 20px" v-html="codeAssignment.solution"></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="2" class="pa-0 ma-1">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Expected Output:
          </span>
        </v-col>
        <v-col>
          <span
            style="font-size: 20px"
            v-html="codeAssignment.expectedOutput"
          ></span>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      codeAssignment: this.assignment,
    };
  },
 
  props: ["assignment"],
};
</script>
