<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Competitions</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchComp"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="showSearchField = !showSearchField"
              >mdi-magnify</v-icon
            >
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="compStatusFilter"
              label="All"
              color="primary"
              value="ALL"
              @click="getAllCompetitions()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="compStatusFilter"
              label="Draft"
              color="primary"
              @click="getAllCompetitions()"
              value="DRAFT"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="compStatusFilter"
              label="Published"
              color="primary"
              @click="getAllCompetitions()"
              value="PUBLISHED"
              hide-details
            ></v-checkbox>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-dialog persistent v-model="dialog" max-width="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 16px">
            New Competition
          </v-btn>
        </template>
        <v-card>
          <v-card>
            <v-card-title class="primary--text">New Competition</v-card-title>
            <v-form ref="form" v-model="createCompValidation.valid">
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="8">
                      <v-text-field
                        v-model="editedComp.name"
                        label="Competition name"
                        :rules="createCompValidation.nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <!-- <v-col cols="3">
                      <v-text-field
                        v-model="editedComp.totalCoins"
                        label="Total Coins"
                        :rules="createCompValidation.coinRules"
                        type="number"
                        min="0"
                        required
                      ></v-text-field>
                    </v-col> -->
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedComp.coinsPerQuestion"
                        label="Per Question Coins"
                        :rules="createCompValidation.quesCoinsRules"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="selectedQuizComp"
                        :items="quizes"
                        label="Quizes"
                        return-object
                        :rules="createCompValidation.quizRules"
                        item-text="quizName"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="6">
                      <v-combobox
                        v-model="editedComp.grades"
                        :items="gradesArray"
                        label="Grades"
                        multiple
                        :rules="createCompValidation.multiValueRules"
                        deletable-chips
                        small-chips
                        item-text="desc"
                        item-value="id"
                      >
                        <template v-slot:prepend-item>
                          <v-list-item @click="selectAll">
                            <v-list-item-action>
                              <v-icon>{{ icon() }}</v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                              <v-list-item-title>Select All</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider />
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="6">
                    <v-text-field
                      v-model="editedComp.duration"
                      label="Duration (in minutes)"
                      :rules="createCompValidation.durationRules"
                      type="number"
                      min="0"
                      required
                    ></v-text-field>
                  </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.startDate"
                            label="Start Date"
                            prepend-icon="mdi-calendar"
                            :rules="createCompValidation.nameRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedComp.startDate"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="startDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="startTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.startTime"
                            label="Start Time"
                            :rules="createCompValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="editedComp.startTime"
                          :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55]"
                          @input="startTimeMenu = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.endDate"
                            label="End Date"
                            prepend-icon="mdi-calendar"
                            :rules="createCompValidation.nameRules"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedComp.endDate"
                          :min="new Date().toISOString().substr(0, 10)"
                          @input="endDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="3">
                      <v-menu
                        v-model="endTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedComp.endTime"
                            label="End Time"
                            :rules="createCompValidation.nameRules"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="editedComp.endTime"
                          :allowed-minutes="[0,5,10,15,20,25,30,35,40,45,50,55]"
                          @input="endTimeMenu = false"
                        ></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close()"> Cancel </v-btn>
                <v-btn color="primary" text @click="saveCompetition()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-expansion-panels  class="compExp">
      <v-expansion-panel
        class="mt-2"
        v-for="quizCompetition in filteredComps"
        :key="quizCompetition.quizId"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon large color="#478FB2"> $expand </v-icon>
          </template>
          <CompHeader
            v-bind:competition="quizCompetition"
            @updateCompetetion="onChange($event)"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <CompDetail
            v-bind:competition="quizCompetition"
            @updateComp="onChange($event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import {
  BASE_URL,
  GET_ALL_QUIZ_COMPETITIONS,
  GET_ALL_QUIZ,
  CREATE_COMPETETION,
  GET_COMPETETION_BY_ID,
} from "@/common/apiEndpoints";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import CompHeader from "@/components/CompHeader.vue";
import CompDetail from "@/components/CompDetail.vue";
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  grades,
} from "@/common/constants";
export default {
  components: { CompHeader, CompDetail },
  data() {
    return {
      quizCompetitions: [],
      filteredComps: [],
      dialog: false,
      menu: false,
      startDateMenu: false,
      endDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      showSearchField: false,
      searchText: "",
      compStatusFilter: [],
      createCompValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        durationRules: [
          (v) => !!v || "This is required",
          (v) => Number(0) < v || "Duration cannot be 0",
          (v) => Number(181) > v || "Duration cannot be greater than 3 hours",
        ],
        coinRules: [(v) => !!v || v > 0 || "Coins should be greater than 0"],
        quesCoinsRules :  [ 
        (v) => !!v || "This is required",
        (v) => Number(0) < v || "Coins should be greater than 0",
        (v) => Number(50) >= v || "Coin must be less than 50",
       
        ],
        multiValueRules: [(v) => v.length > 0 || "Select atleast 1"],
        quizRules: [(v) => !!v || "Select quiz"],
      },
      editedIndex: -1,
      editedComp: {
        name: "",
        grades: [],
        quizes: [],
        startDate: "",
        endDate: "",
        totalCoins: 0,
        coinsPerQuestion:0,
      },
      defaultItem: {
        name: "",
        grades: [],
        quizes: [],

        startDate: "",
        endDate: "",
        totalCoins: 0,
        coinsPerQuestion: 0,
      },
      menu2: false,
      menu3: false,
      searchFilter: {
        active: false,
        status: "",
        onboarded: true,
        dateRange: {},
      },

      gradesArray: grades,
      quizes: [],
      selectedQuizComp: {},
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  created() {
    this.initialize();
  },
  mounted: function () {
    this.getAllCompetitions();
    this.filteredComps = this.quizCompetitions;
    this.getAllQuiz();
   
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    onChange() {
      this.getAllCompetitions();
    },
    validate() {
      this.$refs.form.validate();
    },
    getAllCompetitions() {
      let url = GET_ALL_QUIZ_COMPETITIONS;
      if (!this.compStatusFilter.includes("ALL")) {
        if (
          !(
            this.compStatusFilter.length == 0 ||
            this.compStatusFilter.length == 2
          )
        ) {
          url = url + "?status=" + this.compStatusFilter[0];
        }
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizCompetitions = res.data.quizCompetitions;
          for (let i = 0; i < this.quizCompetitions.length; i++) {
            const grade = this.quizCompetitions[i].grades;
            grade.sort((a, b) => {
              if (Number(a) < Number(b)) return -1;
              if (Number(a) > Number(b)) return 1;
              return 0;
            });
          }
          this.filteredComps = this.quizCompetitions;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getStatusColor(quizCompetition) {
      if (quizCompetition.status == "PUBLISHED") return "success";
      else return "red";
    },

    selectAllGrades() {
      return this.editedComp.grades.length === this.gradesArray.length;
      
    },
    selectSomeGrades() {
      return this.editedComp.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedComp.grades = [];
        } else {
          this.editedComp.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },

    searchComp() {
      if (this.searchText) {
        this.filteredComps = this.quizCompetitions.filter((comp) => {
          return comp.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredComps = this.quizCompetitions;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedComp = Object.assign({}, this.defaultItem);
        this.selectedQuizComp = {};
        this.editedIndex = -1;
      });
    },
    fetchCompetition(payload) {
      let url = GET_COMPETETION_BY_ID;
      // payload.quizCompId = this..quizCompId;
      // axios
      //     .post(url, payload, getAxiosOptions(BASE_URL, this.token))
      //     .then((res) => {
      //         this.competitions = res.data.competitions;
      //     })
      //     .catch((error) => {
      //         error = error.errorMessage;
      //     });
    },
    initialize() {
      const payload = {};
      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      let endDate = new Date();
      endDate.setHours(23, 59, 0, 0);
      payload.dateRange = {};
      payload.dateRange.startDate = startDate.toISOString();
      payload.dateRange.endDate = endDate.toISOString();
      this.fetchCompetition(payload);
    },
    searchCompetition() {
      this.menu = false;
      const payload = {
        dateRange: {},
      };
      if (this.searchFilter.status) {
        payload.status = this.searchFilter.status;
      }
      if (this.searchFilter.startDate) {
        payload.startDate = this.searchFilter.startDate;
      }
      if (this.searchFilter.endDate) {
        payload.endDate = this.searchFilter.endDate;
      }
      if (Object.keys(this.searchFilter.dateRange).length !== 0) {
        if (
          this.searchFilter.dateRange.startDate != "" &&
          this.searchFilter.dateRange.endDate != ""
        )
          payload.dateRange.startDate = new Date(
            this.searchFilter.dateRange.startDate
          ).toISOString();
        payload.dateRange.endDate = new Date(
          this.searchFilter.dateRange.endDate
        ).toISOString();
      }
      if (!payload.dateRange.startDate || !payload.dateRange.endDate) {
        delete payload.dateRange;
      }
      this.fetchCompetition(payload);
    },
    trimEditedComp(){
      this.editedComp.name=this.editedComp.name.trim();
    },

    saveCompetition() {
      this.validate();
      if (this.createCompValidation.valid) {
       this.trimEditedComp();
        if (!this.editedComp.quizes) {
          this.editedComp.quizes = [];
        }
        this.editedComp.quizes[0] = this.selectedQuizComp;
        // this.editedComp.quizComp = this.selectedQuizComp;
        let payload = Object.assign({}, this.editedComp);
        let startDate = payload.startDate + " " + payload.startTime;
        startDate = new Date(startDate);
        let endDate = payload.endDate + " " + payload.endTime;
        endDate = new Date(endDate);
        payload.startDate = startDate.toISOString();
        payload.endDate = endDate.toISOString();
        delete payload.startTime;
        delete payload.endTime;
        let url = CREATE_COMPETETION;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCompValidation.valid = false;
            this.close();
            this.getAllCompetitions();
            this.setOverlayValue(false);
            this.setApiSuccess("Competition created successfully");
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
    getAllQuiz() {
      let url = GET_ALL_QUIZ + "?status=PUBLISHED";
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizes = res.data.quizes;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
  },
};
</script>