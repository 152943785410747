<template>
    <v-card class="ma-2" style="border-radius: 10px;" min-height="650px">
        <v-toolbar dark color="#7A52CC" elevation=2>
            <v-toolbar-title style="font-weight: 500; font-size: 24px;">Assignments</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>

        <template >
          <v-tabs v-model="tab">
          
            <v-tab >Quiz Assignment</v-tab>
            <v-tab >Text Assignment</v-tab>
            <v-tab >Code Assignment</v-tab>


          </v-tabs>
          <v-tabs-items v-model="tab" class="expansion-color">
           
            <v-tab-item>
              <VariantQuizAssignment v-bind:textAssignments="aId" v-bind:courseId="cId"
                v-bind:chapterNo="1" v-bind:lessonNo="1" @update="onUpdate()" />
            </v-tab-item>
            <v-tab-item>
              <VariantTextAssignment v-bind:textAssignments="aId" v-bind:courseId="cId"
              v-bind:chapterNo="1" v-bind:lessonNo="1" @update="onUpdate()" />
            </v-tab-item>
            <v-tab-item>
              <VariantCodeAssignment v-bind:textAssignments="aId" v-bind:courseId="cId"
                v-bind:chapterNo="1" v-bind:lessonNo="1" @update="onUpdate()" />
            </v-tab-item>
            
          </v-tabs-items>
        </template>
    
</v-card>

</template>
<script>
import Contents from "@/components/Contents.vue";
import Notes from "@/components/Notes.vue";
import CourseQuizAssignment from "@/components/CourseQuizAssignment.vue";
import VariantTextAssignment from "@/components/VariantTextAssignment.vue";
import VariantCodeAssignment from "@/components/VariantCodeAssignment.vue";
import VariantQuizAssignment from "@/components/VariantQuizAssignment.vue";
import CourseCodeAssignment from "@/components/CourseCodeAssignment.vue"; 
import ContextMenu from "./ContextMenu.vue";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
} from "@/common/constants";
import { ADD_LESSON, BASE_URL, DELETE_CHAPTER, GET_COURSE_BY_ID, UPDATE_CHAPTER,GET_ALL_TEXT_ASSIGNMENTS,GET_ALL_CODE_ASSIGNMENTS,GET_ALL_QUIZ_ASSIGNMENTS } from '@/common/apiEndpoints';
import { GET_TOKEN, GET_USER_TYPE } from '@/store/getters';
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { mapGetters, mapMutations } from 'vuex';
import axios from "axios";
import { getAxiosOptions, rightClickHandler } from '@/common/utility';
export default {
  data() {
    return {
      contextMenuObject: {},
      tab: null,
      lessonDialog: false,
      editChapterDailog: false,
      deleteChapterDialog: false,
      editedIndex: -1,
      editedChapter: {},
      editedLesson: {},
      defaultItem: {},
      course: {},
      assignmentData:{},
      cId:"79035711-6dd9-433d-8c7d-87d4f999449b",
      
aId:[ { "assignmentNumber": 1, "quizId": "1b1b2e54-d214-4bed-957f-70e329642b27","type":"text", "quizName": "may new quiz","assignmentId":"abcd","title":"Demo Sarva Assign","Variations":[{'question':'This is Q?','answer':'This is Ans',difficultyLevel:'EASY'},{'question':'This is  modified Q?','answer':'This is modified Ans',difficultyLevel:'MEDIUM'}] },{ "assignmentNumber": 2, "quizId": "1b1b_SECOND", "quizName": "may new quiz","assignmentId":"efgh","title":"Demo Sarva Assign2","Variations":[{'question':'This is Q2?','answer':'This is Ans2',difficultyLevel:'MEDIUM'},{'question':'This is modified Q2?','answer':'This is modified Ans2',difficultyLevel:'HARD'}] }  ],
      itemsPerPage: -1,
      // lessons:[],
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createChapterValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      headers: [
        {
          text: 'Lessons',
          align: 'start',
          value: 'lessonName'
        },
        { text: '', value: 'data-table-expand' },
      ],

    };
  },
  components: {
    Contents, Notes, ContextMenu, CourseQuizAssignment, VariantTextAssignment,VariantCodeAssignment,VariantQuizAssignment ,CourseCodeAssignment
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  props: ['lessons', 'courseId', 'chapterNo'],
  mounted: function () {
    // this.getCourseById();
    this.getAssignments();
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(e.target.textContent, e.clientX, e.clientY)
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          // this.assignLessons();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },

    getAssignments() {
      const url = GET_ALL_TEXT_ASSIGNMENTS;
      const url2 = GET_ALL_CODE_ASSIGNMENTS;
      const url3 = GET_ALL_QUIZ_ASSIGNMENTS;
      // const payload = {};
      // payload.courseId = this.courseId;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.assignmentData = res.data;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
        axios
        .get(url3, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.assignmentData = res.data;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
        axios
        .get(url2, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.assignmentData = res.data;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    onUpdate() {
      this.$emit("updateChapter");
    },
    addLessonDialog() {
      this.lessonDialog = true;
      this.editedLesson = Object.assign({}, this.defaultItem);
      this.editedIndex = 0;
    },
    close() {
      this.deleteChapterDialog = false;
      this.editChapterDailog = false;
      this.lessonDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedlesson = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteChapterConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedChapter = Object.assign({}, item);
      this.deleteChapterDialog = true;
    },
    closeDeleteDailog() {
      this.deleteChapterDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteChapter() {
      const url = DELETE_CHAPTER;
      let payload = {};
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.$emit("updateChapter");
          this.closeDeleteDailog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter deleted successfully")
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    editChapter(item) {
      this.editChapterDailog = true;
      this.editedIndex = 0;
      this.editedChapter = Object.assign({}, item);
    },
    saveChapter() {
      if (this.createChapterValidation.valid) {
        let payload = {};
        if (this.editedIndex > -1) {
          payload.courseId = this.courseId;
          payload.chapterName = this.editedChapter.name;
          payload.chapterNo = this.chapterNo;
          let url = UPDATE_CHAPTER;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createChapterValidation.valid = false;
              this.editedIndex = -1;
              // this.getCourseById();
              this.$emit("updateChapter");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Updated successfully")
              // this.initialize();
            })
            .catch((error) => {
              this.setOverlayValue(false);
            });
        }
      }
    },
    saveCourseLesson() {
      this.$refs.form.validate();
      if (this.createLessonValidation.valid) {
        let payload = {};
        if (this.editedIndex > -1) {
          let url = BASE_URL + ADD_LESSON;
          payload.lessonName = this.editedLesson.lessonName;
          payload.chapterNo = this.chapterNo;
          payload.addType = "CHAPTER";
          payload.courseId = this.courseId;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              console.log(res);
              this.course = res.data;
              this.$emit("updateChapter");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Lession Added successfully")
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);

            });
        }
      }
    },
    // assignLessons(){
    //      this.course.chapters.filter((chapter)=>{
    //         if(chapter.chapterNo == this.chapterNo){
    //            this.lessons = chapter.lessons;
    //         }
    //   })
    // },
  },
};
</script>