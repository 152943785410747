<template>
  <div v-bind="$props">
    <v-card flat min-height="700px" style="overflow: hidden">
      <v-toolbar flat dark color="primary" enlevation="2">
        <v-toolbar-title style="font-weight: 500; font-size: 24px"
          ><v-icon
            v-if="paramsValue.isFinished == true"
            class="mr-2 mb-1"
            @click="$router.go(-1)"
            >mdi-arrow-left</v-icon
          >The Quiz
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-dialog persistent v-model="finishCompDialog" max-width="650px">
        <v-card>
          <v-card-title
            >Are you sure you want to finish the competition? If the competiton
            is finished once you cannot attempt it again.</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeFinishCompDialog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="finishComp()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="imgDialog" width="800px">
        <div style="position: relative">
          <v-btn
            color="red"
            dark
            width="20px"
            class="fixed-button"
            @click="closeImage()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-img :src="questionMedia"></v-img>
        </div>
      </v-dialog>
      <v-row
        class="mt-8 ml-8 d-flex justify-end"
        v-if="paramsValue.isFinished == false"
      >
        <v-col align="start" class="ml-8 mt-0 d-flex">
          <div class="d-flex align-center">
            <v-icon size="40" color="black" class="mr-2"
              >mdi-clock-alert</v-icon
            >
            <div>
              <div style="font-size: 16px; color: grey">Time remaining</div>
              <div class="text-h6">{{ formatTime(timer) }}</div>
            </div>
          </div>
        </v-col>
        <v-btn
          color="primary"
          class="mr-10 mt-3"
          width="170"
          outlined
          style="font-size: 16px"
          @click="openFinishCompDialog()"
        >
          Finish
        </v-btn>
      </v-row>
      <v-row align="start" class="mt-4 ml-8">
        <v-col cols="8">
          <v-card
            width="100%"
            class="ma-2"
            elevation="0"
            v-if="quizContent && quizContent.quizQuestions.length != 0"
          >
            <v-col class="ma-2">
              <div class="mb-1">
                <span style="font-size: 17px">Question </span>
                <span
                  class="mb-2"
                  style="font-size: 20px; font-weight: 400"
                  v-html="
                    formatQuestionTitle(
                      quizContent.quizQuestions[index].questionNo,
                      quizContent.quizQuestions[index].title
                    )
                  "
                ></span>
              </div>
              <div class="mt-4" v-if="quizContent.quizQuestions[index].media">
                <v-img
                  max-width="300"
                  max-height="300"
                  @click="viewImage(quizContent.quizQuestions[index])"
                  :src="quizContent.quizQuestions[index].media"
                ></v-img>
                <span style="font-size: 14px"
                  >If image is not clear , click on image to see full
                  image</span
                >
              </div>
              <div v-if="paramsValue.isFinished == false">
                <v-col
                  class="pl-0"
                  v-for="option in quizContent.quizQuestions[index].options"
                  :key="option.number"
                >
                  <label>
                    <input
                      v-model="selected"
                      type="radio"
                      :value="option.number"
                      :disabled="
                        alreadyAttempted || paramsValue.isFinished == true
                      "
                    />
                    {{ option.value }}</label
                  >
                </v-col>
              </div>
              <v-radio-group
                v-model="selected"
                v-if="paramsValue.isFinished == true"
              >
                <v-radio
                  v-for="option in quizContent.quizQuestions[index].options"
                  :key="option.number"
                  :label="option.value"
                  :color="isCorrect ? 'green' : 'red'"
                  :value="option.number"
                  class="mt-1"
                  readonly
                ></v-radio>
              </v-radio-group>
              <v-row class="ma-1" v-if="paramsValue.isFinished == true">
                <v-col cols="12" class="pl-0">
                  <span style="font-size: 18px; font-weight: 500"
                    >Correct Answer is :
                    <span
                      class="info--text"
                      style="font-weight: 400; font-size: 16px"
                      >{{ correctAnswer }}</span
                    ></span
                  >
                </v-col>
                <v-col cols="12" class="pl-0">
                  <span style="font-size: 18px; font-weight: 500"
                    >Explanation :
                    <span
                      class="purple--text"
                      style="font-weight: 400; font-size: 16px"
                      v-html="explaination"
                    ></span>
                  </span>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-btn
                  class="mr-2"
                  small
                  :disabled="index == 0"
                  color="primary"
                  @click="backpage()"
                  >Back</v-btn
                >
                <v-btn
                  class="ml-2 mr-2"
                  small
                  :disabled="index == quizContent.quizQuestions.length - 1"
                  color="primary"
                  @click="nextPage()"
                  >Next</v-btn
                >
                <v-btn
                  class="ml-2"
                  small
                  color="primary"
                  v-if="!alreadyAttempted && paramsValue.isFinished == false"
                  :disabled="selected == '' ? true : false"
                  @click="studentQuiz()"
                  >Submit</v-btn
                >
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
        <v-col cols="4" class="ml-0">
          <v-card
            min-height="90%"
            width="94%"
            class="mt-3 pa-4"
            elevation="0"
            style="
              background-color: rgba(255, 247, 232, 1);
              border-radius: 15px;
            "
          >
            <v-card
              min-height="100px"
              width="100%"
              elevation="0"
              style="background-color: rgba(255, 247, 232, 1)"
            >
              <div class="mb-2">
                <span
                  v-if="paramsValue.isFinished == false"
                  class="text-body-2"
                  style="color: black"
                  >Solved {{ solvedQues }}/{{
                    quizContent.quizQuestions.length
                  }}
                  Questions</span
                >
                <span
                  v-if="
                    paramsValue.isFinished == true &&
                    quizComp &&
                    quizComp.quizes[0] &&
                    quizComp.quizes[0].questions.length != 0
                  "
                  class="text-body-2"
                  style="color: black"
                  >Solved {{ quizComp.quizes[0].questions.length }}/{{
                    quizContent.quizQuestions.length
                  }}
                  Questions</span
                >
              </div>
              <div class="mb-4 d-flex align-center flex-wrap">
                <v-icon color="black" size="20" class="mr-1"
                  >mdi-square-outline</v-icon
                >
                <span class="mr-3" style="font-size: 12px; color: grey"
                  >Not Visited</span
                >
                <v-icon color="success" size="20" class="mr-1"
                  >mdi-square</v-icon
                >
                <span
                  v-if="paramsValue.isFinished == false"
                  class="mr-3"
                  style="font-size: 12px; color: grey"
                  >Answered</span
                >
                <span v-else class="mr-3" style="font-size: 12px; color: grey"
                  >Right</span
                >
                <v-icon
                  :color="paramsValue.isFinished ? 'red' : 'orange'"
                  size="20"
                  class="mr-1"
                  >mdi-square</v-icon
                >
                <span
                  v-if="paramsValue.isFinished == false"
                  class="mr-3"
                  style="font-size: 12px; color: grey"
                  >Visited</span
                >
                <span v-else class="mr-3" style="font-size: 12px; color: grey"
                  >Wrong</span
                >
              </div>
              <v-card
                elevation="0"
                style="background-color: rgba(255, 247, 232, 1)"
              >
                <v-row>
                  <v-col
                    v-for="(question, index) in quizContent.quizQuestions
                      .length"
                    :key="index"
                    cols="2"
                  >
                    <v-btn
                      :color="getcolor(index)"
                      class="ma-0"
                      small
                      @click="navpage(index)"
                      :style="getcolor(index) == 'black' ? 'color:white' : ''"
                    >
                      {{ index + 1 }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import {
  QUIZ_COMPETITION_QUIZ_PROGRESS,
  QUIZ_COMPETITION_COMP_PROGRESS,
  QUIZ_COMPETITION_QUIZ_END,
  GET_QUIZ_BY_ID,
  BASE_URL,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatQuestionTitle } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COIN_BALANCE,
} from "@/store/mutations";

export default {
  data() {
    return {
      index: 0,
      selected: false,
      imgDialog: false,
      finishCompDialog: false,
      questionMedia: "",
      solvedQues: 0,
      quizId: "",
      quizComp: { quizes: [] },
      competition: {},
      quizAnswers: {},
      quizContent: { quizQuestions: [] },
      explaination: "",
      isCorrect: "",
      earnedCoins: "",
      userResponse: "",
      correctAnswer: "",
      alreadyAttempted: false,
      quizTimeInSeconds: "",
      startTime: 0,
      timer: 0,
      formattedTime: "",
      visitedQuestions: new Set(),
    };
  },
  props: ["comp", "durationTime"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    // window.addEventListener("unload", this.handleUnload);
    if (this.paramsValue.isFinished == false) {
      window.onbeforeunload = this.warnBeforeLeave;
      window.onload = this.beforeLeave;
      this.startTime = Date.now();
      this.startTimer();
    }
    this.competition = this.paramsValue.competition;
    this.getCompProgress(this.index);
    for (let i = 0; i < this.competition.quizes.length; i++) {
      this.quizId = this.competition.quizes[i].quizId;
      this.quizContent = this.competition.quizes[i];
    }
    if (this.durationTime) {
      this.quizTimeInSeconds = this.competition.duration * 60;
    }
    if (this.paramsValue.isFinished == true) {
      this.getQuizBYQuizId(this.index);
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null;
    window.onload = null;
    clearInterval(this.timerInterval);
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),
    warnBeforeLeave() {
      return "The Test will be automatically submitted by this action";
    },
    beforeLeave() {
      return this.finishComp();
    },
    getQuizBYQuizId(index) {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = this.quizId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizAnswers = res.data;
          this.explaination = this.quizAnswers.questions[index].explaination;
          for (
            let i = 0;
            i < this.quizAnswers.questions[index].options.length;
            i++
          ) {
            if (this.quizAnswers.questions[index].options[i].isAnswer == true) {
              this.correctAnswer =
                this.quizAnswers.questions[index].options[i].number;
            }
          }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getcolor(i) {
      if (!this.quizContent || !this.quizContent.quizQuestions) {
        return "white";
      }
      const questionNo = this.quizContent.quizQuestions[i].questionNo;
      if (i == this.index) {
        return "black";
      }
      if (
        this.quizComp &&
        this.quizComp.quizes &&
        this.quizComp.quizes.length > 0
      ) {
        for (let j = 0; j < this.quizComp.quizes.length; j++) {
          let question = this.quizComp.quizes[j].questions.find(
            (question) => question.questionNo == questionNo
          );
          if (this.paramsValue.isFinished == true) {
            if (question && question.isCorrect) {
              return "green";
            } else if (question && question.isCorrect == false) {
              return "red";
            }
          }
          if (question) {
            return "green";
          }
        }
      }
      if (this.paramsValue.isFinished == false) {
        if (i == 0) {
          return "orange";
        }
        if (this.visitedQuestions && this.visitedQuestions.has(questionNo)) {
          return "orange";
        }
      }
      return "white";
    },
    startTimer() {
      let quizTimeAdjusted = false; // Flag to track if quizTimeInSeconds has been adjusted
      this.timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const remainingMilliseconds = Math.max(
          0,
          new Date(this.competition.endDate).getTime() - currentTime
        );
        if (
          !quizTimeAdjusted &&
          remainingMilliseconds <= this.quizTimeInSeconds * 1000
        ) {
          // Adjust quizTimeInSeconds only once
          this.quizTimeInSeconds = Math.floor(remainingMilliseconds / 1000);
          quizTimeAdjusted = true; // Set flag to true after adjustment
        }
        const elapsedMilliseconds = currentTime - this.startTime;
        const remainingTime = Math.max(
          0,
          this.quizTimeInSeconds * 1000 - elapsedMilliseconds
        );
        this.timer = Math.floor(remainingTime / 1000);

        if (this.timer <= 0) {
          this.finishComp();
          clearInterval(this.timerInterval);
        }
      }, 1000);
    },
    formatTime(timeInSeconds) {
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds % 3600) / 60);
      const seconds = timeInSeconds % 60;
      return `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
        seconds < 10 ? "0" : ""
      }${seconds}`;
    },
    viewImage(question) {
      this.imgDialog = true;
      this.questionMedia = question.media;
    },
    closeImage() {
      this.imgDialog = false;
    },
    openFinishCompDialog() {
      this.finishCompDialog = true;
    },
    closeFinishCompDialog() {
      this.finishCompDialog = false;
    },
    nextPage() {
      if (this.index < this.quizContent.quizQuestions.length) {
        this.index++;
        this.visitedQuestions.add(
          this.quizContent.quizQuestions[this.index].questionNo
        ); // Mark as visited
        this.getCompProgress(this.index);
        this.getQuizBYQuizId(this.index);
        this.selected = false;
        this.isCorrect = "";
        this.correctAnswer = "";
        this.explaination = "";
        this.checkForAlreadyAttempted(
          this.quizContent.quizQuestions[this.index].questionNo
        );
      }
    },
    navpage(navindex) {
      this.index = navindex;
      this.visitedQuestions.add(
        this.quizContent.quizQuestions[this.index].questionNo
      );
      this.getCompProgress(this.index);
      this.getQuizBYQuizId(this.index);
      this.selected = false;
      this.isCorrect = "";
      this.correctAnswer = "";
      this.explaination = "";
      this.checkForAlreadyAttempted(
        this.quizContent.quizQuestions[this.index].questionNo
      );
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
        this.visitedQuestions.add(
          this.quizContent.quizQuestions[this.index].questionNo
        );
        this.getCompProgress(this.index);
        this.getQuizBYQuizId(this.index);
        this.selected = false;
        this.isCorrect = "";
        this.correctAnswer = "";
        this.explaination = "";
        this.checkForAlreadyAttempted(
          this.quizContent.quizQuestions[this.index].questionNo
        );
      }
    },
    checkForAlreadyAttempted(questionNo) {
      if (this.quizComp && this.quizComp.quizes.length > 0) {
        for (let i = 0; i < this.quizComp.quizes.length; i++) {
          let question = this.quizComp.quizes[i].questions.find(
            (question) => question.questionNo == questionNo
          );
          if (question) {
            this.alreadyAttempted = true;
          } else {
            this.alreadyAttempted = false;
          }
        }
      } else {
        this.alreadyAttempted = false;
      }
    },
    studentQuiz() {
      const url = QUIZ_COMPETITION_QUIZ_PROGRESS;
      const payload = {};
      payload.competitionId = this.competition.quizCompId;
      payload.quizId = this.quizId;
      payload.questionNo =
        this.quizContent.quizQuestions[this.index].questionNo;
      payload.selectedOption = this.selected;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.solvedQues = this.solvedQues + 1;
          this.quizComp = res.data;
          this.earnedCoins = res.data.earnedCoins;
          this.getCompProgress();
          this.alreadyAttempted = true;
          this.setOverlayValue(false);
          if (this.isCorrect) {
            this.updateCoinBalance();
          }
          if (
            this.quizContent.quizQuestions[this.index].questionNo !=
            this.quizContent.quizQuestions.length
          ) {
            this.nextPage();
          }
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCompProgress(index) {
      const url = QUIZ_COMPETITION_COMP_PROGRESS;
      const payload = {};
      payload.competitionId = this.competition.quizCompId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizComp = res.data;
          this.checkForAlreadyAttempted(
            this.quizContent.quizQuestions[this.index].questionNo
          );
          this.getCorrectAnswer(index);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    finishComp() {
      const url = QUIZ_COMPETITION_QUIZ_END;
      const payload = {};
      payload.competitionId = this.competition.quizCompId;
      payload.quizId = this.quizId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizComp = res.data;
          this.closeFinishCompDialog();
          this.$router.push({
            name: "StudentCompetition",
            params: { compFinished: true },
          });
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCorrectAnswer(index) {
      let questionNo = this.quizContent.quizQuestions[index].questionNo;
      for (let i = 0; i < this.quizComp.quizes.length; i++) {
        let question = this.quizComp.quizes[i].questions.find(
          (question) => question.questionNo == questionNo
        );
        if (question) {
          this.selected = question.userResponse;
          this.isCorrect = question.isCorrect;
          this.correctAnswer = question.correctAnswer;
        }
      }
    },
    submitQuiz() {
      this.alreadyAttempted = true;
    },
    updateCoinBalance() {
      const url = GET_COIN_BALANCE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let newCoinBalance = res.data.balance;
          this.setCoinBalance(newCoinBalance);
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    formatQuestionTitle(questionNo, questionTitle, totalQuestions) {
      return formatQuestionTitle(questionNo, questionTitle, totalQuestions);
    },
  },
};
</script>
<style>
.fixed-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
</style>
