<template>
</template>

<script>
export default {
  data(){
    return{
      script: `https://checkout.razorpay.com/v1/checkout.js`,
      orderId:'',
      studentDetails:{},
      coursePrice:0,
    }
  },
   mounted: function () {
    // this.getTeacherById();
    // this.getCourseById();
     
  },
  methods :{ 
    async loadRazorPay(){
      return new Promise(resolve=>{
        const script = document.createElement('script')
        script.src = this.script
        script.onload = () =>{
          resolve(true)
        }
        script.onerror = () =>{
          resolve(false)
        }        
        document.body.appendChild(script)
      })      
    }
  },
  async created(){
     this.studentDetails = this.$route.params.studentDetails;
      this.orderId = this.$route.params.orderId;
      this.coursePrice = this.$route.params.price;
    const result = await this.loadRazorPay()
    if(!result){
      alert('Failed to load razorpay script')
      return
    }
    const oThis = this;
    const options = {
      key: process.env.VUE_APP_RAZORPAY_ID,
      amount: this.coursePrice,
      currency: "INR",
      name: `APTCODER`,
      description: `Course Payment`,
      order_id: this.orderId,
      image: "../assets/APT_LogoR.png",
      handler: function(){
        oThis.$router.push({
        name: "StudentOrders",
        params : { isRedirectedFromPayment : true }
      });
      },
      modal: {
        ondismiss: function(){
           oThis.$router.push({
        name: "StudentOrders",
        params : { isRedirectedFromPayment : true }
      });
        }
    },
      // prefill: {
      //   name: `Your customer name`,
      //   email: `Your customer email`,
      //   contact: `Your customer contact`
      // }
      prefill: {
        name: this.studentDetails.firstName+" "+this.studentDetails.lastName,
        email: this.studentDetails.email,
        contact: this.studentDetails.mobileNo
      }
    }; 
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
}
</script>