<template>
  <v-container>
    <v-toolbar flat dark color="primary">
      <v-btn icon>
        <v-icon @click="$router.go(-1)">mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title style="font-weight: 500; font-size: 24px;">Course Details</v-toolbar-title>
    </v-toolbar>
    <v-card flat class="ma-3">
      <v-row>
        <v-col cols="4">
          <v-img :src="course.thumbnail"></v-img>
        </v-col>
        <v-col cols="8">
          <v-list-item>
            <v-list-item-content>
              <div class="mb-4" style="color: #FF6666; font-size: 24px; font-weight: 500;">
                {{ course.courseName }}
              </div>
              <!-- <v-list-item-title class="text-h5 mb-1">
            Price : {{ course.price }}
          </v-list-item-title> -->
              <div style="font-size: 16px;" v-if="course.description">

                <span v-html="course.description"> </span>
              </div>

            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-divider class="mt-4"></v-divider>
    </v-card>
    <div>
      <BuyCourse v-bind:course="course" />
    </div>
  </v-container>
</template>
<script>
import { GET_TOKEN, GET_USER_TYPE,GET_PARAMS_VALUE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE,SET_PARAMS_VALUE } from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import BuyCourse from "@/components/BuyCourse.vue";

export default {
  data() {
    return {
      course: {},
    };
  },
  components: { BuyCourse },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    if(this.$route &&this.$route.params&& this.$route.params.course){
    this.course = this.$route.params.course;
    let courseParamsValue={}
      courseParamsValue.course=this.$route.params.course;
      this.setParams(courseParamsValue)
    } else{
      this.course=this.paramsValue.course;
    }
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
  },
};
</script>
