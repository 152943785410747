<template>
  <div v-bind="$props">
    <v-col cols="12">
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Question:
          </span>
        </v-col>
        <v-col class="ma-1 pa-0">
          <span
            style="font-size: 20px"
            v-html="assignmentProblem.question"
          ></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Answer:
          </span>
        </v-col>
        <v-col
          class="ma-1 pa-0"
          v-if="
            assignmentProblem.submissions &&
            assignmentProblem.submissions.length == 0
          "
        >
          <span>
            <quill-editor style="height: 140px; margin-bottom:40px;"  @input="validateTextAssignemnt()"
            :options="editorOptions" v-model="textAssignmentSubmission"></quill-editor>
          </span>
          <span class="red--text ">{{ textAssignmentError }}</span>
        </v-col>
        <v-col class="ma-1 pa-0" v-else>
          <span
            style="font-size: 20px"
            v-html="assignmentProblem.answer"
          ></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      v-if="
        assignmentProblem.submissions &&
        assignmentProblem.submissions.length != 0
      "
    >
      <v-row>
        <v-col cols="2" class="ma-1 pa-0">
          <span style="font-weight: 500; font-size: 20px" class="error--text"
            >Submission:
          </span>
        </v-col>
        <v-col class="ma-1 pa-0">
          <span
            style="font-size: 20px"
            v-html="assignmentProblem.submissions[0].submission"
          ></span>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-btn
        v-if="
          assignmentProblem.submissions && assignmentProblem.submissions == 0
        "
        color="error"
        @click="submitTextAssignment()"
        >Submit</v-btn
      >
    </v-col>
  </div>
</template>

<script>
import { BASE_URL, SUBMIT_TEXT_ASSIGNMENT } from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE} from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COIN_BALANCE,
} from "@/store/mutations";

export default {
  data() {
    return {
      editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
      assignmentProblem: {},
      textAssignmentSubmission: "",
      textAssignmentError:"",
    };
  },
  props: ["classOpId", "assignment"],
  watch: {
    assignment: {
      handler(assignment) {
        if (assignment) {
          this.assignmentProblem = assignment;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.assignmentProblem = this.assignment;
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),
  validateTextAssignemnt(){
    if (this.textAssignmentSubmission.length == 0) {
        this.textAssignmentError = "This is required";
      } else{
        this.textAssignmentError = '';
      }
  },
    submitTextAssignment() {
       if (this.textAssignmentSubmission.length>0) {
        this.textAssignmentError = '';
      const url = SUBMIT_TEXT_ASSIGNMENT;
      const payload = {};
      payload.assignmentNumber = this.assignmentProblem.assignmentNumber;
      payload.classOpId = this.classOpId;
      payload.submission = this.textAssignmentSubmission;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.$emit("assignmentCompleted",this.classOpId,'textAssignment');
          this.setApiSuccess("Assignment Submitted Successfully");
          this.textAssignmentSubmission = "";
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
       }else{
        this.textAssignmentError = 'This is required';
       }
    },
  },
};
</script>
