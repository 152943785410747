<template>
  <v-card>
    <v-toolbar flat color="transparent">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search Resource"
        single-line
        @input="searchResource()"
      ></v-text-field>
    </v-toolbar>
    <v-card-text class="py-0">
      <!-- <v-row v-for="(keyword, i) in keywords" :key="i" class="ma-2">
        <v-chip class="ma-1" x-small color="primary" label outlined>
          {{ keyword.name }}
        </v-chip>
        <v-chip v-for="word in keyword.values" :key="word" class="ma-1" x-small label>{{ word }}</v-chip>
      </v-row> -->
      <v-list three-line>
        <v-list-item
          v-for="(item, i) in searchResult"
          :key="i"
          ripple
          @click="selectResource(item)"
        >
          <v-list-item-avatar>
            <v-icon>
              {{ getResourceIcon(item) }}
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <h2 class="primary--text">{{ item.name }}</h2>
            <v-chip
              class="text-uppercase font-weight-regular text-caption"
              x-small
              outlined
              >{{ item.contentType }}</v-chip
            >
          </v-list-item-content>
        </v-list-item>

        <span
          v-if="searchResult.length == 0 && search"
          class="ma-10 primary--text text-h6"
          >{{ noResourceFoundTitle }}</span
        >
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
import axios from "axios";
import {
  BASE_URL,
  GET_RESOURCE,
  SEARCH_RESOURCE,
} from "@/common/apiEndpoints.js";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { formatDate, getAxiosOptions } from "@/common/utility";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
export default {
  data() {
    return {
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      search: "",
      searchResult: [],
      noResourceFoundTitle: "",
      keywords: [
        {
          name: "Dificulty",
          values: ["Hard", "Easy"],
        },
        {
          name: "Teach Mode",
          values: ["TEACHER", "SELF"],
        },
        {
          name: "Learn Mode",
          values: ["TEACHER", "SELF"],
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },

  mounted: function () {
    // this.getAllCourses();
    // this.filteredCourses = this.courses;
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    formatDate(date) {
      return formatDate(date);
    },

    searchResource() {
      if (this.search.length >= 2) {
        let payload = {};
        payload.name = this.search;
        payload.status = this.STATUS_PUBLISHED;
        let url = SEARCH_RESOURCE;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.searchResult = res.data.resources;
            if (this.searchResult) {
              this.noResourceFoundTitle = "No Resource Found";
            }
          });
      }
    },
    showResourceDetails(item) {
      let payload = {};
      payload.resourceId = item.resourceId;
      let url = GET_RESOURCE;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.resource = res.data;
          this.tab = "create-tab";
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    getResourceIcon(item) {
      if (item.mimeType && item.mimeType.includes("video")) {
        return "mdi-video";
      } else if (item.mimeType && item.mimeType.includes("image")) {
        return "mdi-image";
      } else if (item.mimeType && item.mimeType.includes("pdf")) {
        return "mdi-file-pdf-box";
      } else {
        return "mdi-table-of-contents";
      }
    },
    selectResource(item) {
      this.$emit("selectResource", item);
    },
  },
};
</script>