<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar flat dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 22px">
        <v-row class="ma-1">
        <v-icon class="mr-2" @click="$router.go(-1)">mdi-arrow-left</v-icon>
        <span
          >Class Schedule | {{ $route.params.className || this.paramsValue.className }}</span
        ></v-row>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-row justify="end">
        <v-col cols="1" class="pa-0 ma-1">
          <v-menu v-model="menu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="ma-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-filter</v-icon>
                  </template>
                  <span>Filter</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-checkbox
                  v-model="classStatusFilter"
                  label="Created"
                  color="primary"
                  value="CREATED"
                  hide-details
                  @click="searchClass()"
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="classStatusFilter"
                  label="Started"
                  color="primary"
                  value="STARTED"
                  hide-details
                  @click="searchClass()"
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="classStatusFilter"
                  label="Completed"
                  color="primary"
                  value="COMPLETED"
                  hide-details
                  @click="searchClass()"
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="classStatusFilter"
                  label="Cancelled"
                  color="primary"
                  value="CANCELLED"
                  hide-details
                  @click="searchClass()"
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="classStatusFilter"
                  label="Rescheduled"
                  color="primary"
                  value="RESCHEDULED"
                  hide-details
                  @click="searchClass()"
                ></v-checkbox>
              </v-list-item>
              <!-- <v-list-item>
                <v-btn color="primary" text @click="searchClass()">
                  Apply
                </v-btn>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="5">
          <v-tabs dark background-color="primary" v-model="tab" right>
            <v-tab>
              <v-icon>mdi-table</v-icon>
            </v-tab>
            <v-tab>
              <v-icon>mdi-calendar</v-icon>
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <ClassScheduleTableView
            v-bind:classOps="classOps"
            v-bind:topicCovered="topicCovered"
            v-bind:isActiveSession="isActiveSession"
            v-bind:classRoomId="classRoomId"
            v-bind:className="className"
            @updateTopicCovered="getAllClassOps"
          />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <ClassScheduleCalendarView
            v-bind:classOps="classOps"
            v-bind:topicCovered="topicCovered"
            v-bind:isActiveSession="isActiveSession"
            @updateTopicCovered="getAllClassOps"
          />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import ClassScheduleTableView from "@/components/ClassScheduleTableView.vue";
import ClassScheduleCalendarView from "@/components/ClassScheduleCalendarView.vue";
import { mapGetters, mapMutations } from "vuex";
import { SET_PARAMS_VALUE, SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { BASE_URL, GET_ALL_CLASS_OPERATIONS } from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import axios from "axios";
import { GET_PARAMS_VALUE, GET_TOKEN } from "@/store/getters";
export default {
  data() {
    return {
      tableView: true,
      tab: null,
      menu: false,
      classOps: [],
      filteredClass: [],
      isActiveSession: true,
      classRoomId: '',
      className: '',
      classStatusFilter: [],
      topicCovered: {},
    };
  },
  components: { ClassScheduleTableView, ClassScheduleCalendarView },
  mounted: function () {
    // this.$refs.calendar.checkChange();
    if(this.$route && this.$route.params&& this.$route.params.classRoomId){
      this.isActiveSession = this.$route.params.isActiveSession;
      this.getAllClassOps();
      let classParamsValue={}
      classParamsValue.classRoomId=this.$route.params.classRoomId;
      classParamsValue.className=this.$route.params.className;
      classParamsValue.isActiveSession=this.$route.params.isActiveSession;
      classParamsValue.teacherId=this.$route.params.teacherId;
      classParamsValue.studentId=this.$route.params.studentId;
      classParamsValue.userType=this.$route.params.userType;
      classParamsValue.firstName=this.$route.params.firstName;
      classParamsValue.lastName=this.$route.params.lastName;
      this.setParams(classParamsValue)
    }
    else{
      this.isActiveSession = this.paramsValue.isActiveSession;
      this.classRoomId = this.paramsValue.classRoomId;
      this.className = this.paramsValue.className;
      this.getAllClassOps();
    }
  },
  
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
     paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    getAllClassOps() {
      this.menu = false;
      let url = GET_ALL_CLASS_OPERATIONS;
      this.setOverlayValue(true);
      let payload = {};
      payload.classRoomId = this.$route.params.classRoomId || this.paramsValue.classRoomId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.filteredClass = res.data.classOperations;
          this.setLocaldateTime();
          this.classOps = this.filteredClass;
          this.topicCovered = new Map();
          this.filteredClass.forEach((classOp) => {
            if (classOp.topicCovered.length > 0) {
              if (this.topicCovered.has(classOp.topicCovered[0].chapterNo)) {
                let lessons = [];
                lessons = this.topicCovered.get(
                  classOp.topicCovered[0].chapterNo
                );
                if (
                  lessons.findIndex(
                    (lesson) => lesson == classOp.topicCovered[0].lessonNo
                  ) == -1
                ) {
                  lessons.push(classOp.topicCovered[0].lessonNo);
                }
                this.topicCovered.set(
                  classOp.topicCovered[0].chapterNo,
                  lessons
                );
              } else {
                let lessons = [];
                lessons.push(classOp.topicCovered[0].lessonNo);
                this.topicCovered.set(
                  classOp.topicCovered[0].chapterNo,
                  lessons
                );
              }
            }
          });
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    searchClass() {
      if (this.classStatusFilter.length == 0 || this.classStatusFilter.length == 5) {
        this.classOps = this.filteredClass;
      } else {
        this.classOps = this.filteredClass.filter((item) => {
          for (let index = 0; index < this.classStatusFilter.length; index++) {
            if (item.status == this.classStatusFilter[index]) {
              return this.classOps;
            }
          }
        });
      }
      this.menu = false;
    },
    setLocaldateTime(){
      for(let i=0;i<this.filteredClass.length;i++){
        let temp = this.filteredClass[i].date.substring(0, 10).split("-");
        let date = new Date(this.filteredClass[i].date);
        date.setHours(0, 0, 0, 0);
        date.setFullYear(Number(temp[0]), Number(temp[1]) - 1, Number(temp[2]));
        if(this.filteredClass[i].classInfo.batchInfo.timezoneOffset == null){
          this.filteredClass[i].classInfo.batchInfo.timezoneOffset = "-330";
        }
        let offset = ((Number(this.filteredClass[i].classInfo.batchInfo.timezoneOffset)) - date.getTimezoneOffset()) * 60 * 1000;
            let openHours =
            this.filteredClass[i].classInfo.batchInfo.openTime.split(":");
            let openMinutes = (Number(openHours[0]) * 60 + Number(openHours[1])) * 60 * 1000;
            let openTime = new Date(date.getTime() + openMinutes + offset);
            let hours = openTime.getHours()<=9 ? "0"+openTime.getHours():openTime.getHours();
            let minutes = openTime.getMinutes()==0 ? "0"+openTime.getMinutes():openTime.getMinutes();
            this.filteredClass[i].classInfo.batchInfo.openTime = `${hours}:${minutes}`;
            let closeHours = this.filteredClass[i].classInfo.batchInfo.closeTime.split(":");
            let closeMinutes = (Number(closeHours[0]) * 60 + Number(closeHours[1])) * 60 * 1000;
            let closeTime = new Date(date.getTime() + closeMinutes + offset);
            hours = closeTime.getHours()<=9 ? "0"+closeTime.getHours():closeTime.getHours();
            minutes = closeTime.getMinutes()==0 ? "0"+closeTime.getMinutes():closeTime.getMinutes();
            this.filteredClass[i].classInfo.batchInfo.closeTime = `${hours}:${minutes}`;
      }
    }
  },
};
</script>
