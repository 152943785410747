<template>
  <v-container>
    <v-dialog persistent v-model="editInstdialog" max-width="700">
      <v-form ref="form" v-model="createInstituteValidation.valid">
        <v-card>
          <v-container>
            <v-row>
              <v-card-title class="primary--text"
                >Edit Institute</v-card-title
              ></v-row
            >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedInstitute.instituteName"
                  label="Institute Name"
                  :rules="createInstituteValidation.nameRules"
                  required
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row><v-card-subtitle>Address</v-card-subtitle></v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                  v-model="editedInstitute.address.addressLine1"
                  label="Service Address Line1"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="editedInstitute.address.addressLine2"
                  label="Service Address Line2"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  @input="getAddressDetails()"
                  v-model="editedInstitute.address.pincode"
                  label="Pin Number"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="editedInstitute.address.city"
                  label="City"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedInstitute.address.state"
                  label="State"
                  :rules="createInstituteValidation.nameRules"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close()"> Cancel </v-btn>
            <v-btn color="primary" text @click="saveInstitute()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog persistent v-model="deleteConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title
          >Are you sure you want to end Current Session
          {{ institute.name }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDeleteDailog()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="EndCurrentSession()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="addBatchDialog" max-width="800px">
      <v-card>
        <v-card-title class="primary--text">New Batch</v-card-title>
        <v-form ref="form" v-model="createBatchValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="ma-1 pa-0">
                  <v-text-field
                    v-model="editedBatch.name"
                    label="Batch Name"
                    :rules="createBatchValidation.nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0 pt-0">
                <v-col cols="6" class="ma-0 pa-0">
                  <v-checkbox
                    hide-details
                    v-model="selectAllChecked"
                    label="Select All"
                    @change="selectAll()"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row v-if="timingWarningDialog">
                <span style="color: red" class="ma-1">
                  Please select timings in 1 hour interval only.
                </span>
              </v-row>
              <v-row
                class="pt-0 mt-0"
                v-for="(openday, index) in editedBatch.openDays"
                :key="openday.day"
              >
                <v-col cols="4" class="mt-1 pa-0">
                  <v-checkbox
                    hide-details
                    v-model="openday.checked"
                    :label="openday.label"
                    @change="checkForAllSelected()"
                  ></v-checkbox>
                </v-col>
                <v-col v-if="openday.checked" cols="4">
                  <v-dialog
                    max-width="300px"
                    persistent
                    v-model="openday.openTimeMenu"
                  >
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field
                        v-on:click="click"
                        v-model="openday.openTime"
                        label="Open Time"
                        required
                        :rules="createBatchValidation.nameRules"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="openday.openTime"
                      :allowed-minutes="[0, 30]"
                      scrollable
                      actions
                    >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="
                            openday.openTimeMenu = false;
                            timeMenu(openday.openTime, index, 'cancel', 'open');
                          "
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="primary"
                          @click="
                            openday.openTimeMenu = false;
                            timeMenu(openday.openTime, index, 'save', 'open');
                          "
                          >OK</v-btn
                        >
                      </v-card-actions>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col v-if="openday.checked" cols="4">
                  <v-dialog
                    max-width="300px"
                    persistent
                    v-model="openday.closeTimeMenu"
                  >
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field
                        v-on:click="click"
                        v-model="openday.closeTime"
                        label="Close Time"
                        :rules="createBatchValidation.nameRules"
                        required
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-model="openday.closeTime"
                      :allowed-minutes="[0, 30]"
                      scrollable
                      actions
                    >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          @click="
                            openday.closeTimeMenu = false;
                            timeMenu(
                              openday.closeTime,
                              index,
                              'cancel',
                              'close'
                            );
                          "
                          >Cancel</v-btn
                        >
                        <v-btn
                          color="primary"
                          @click="
                            openday.closeTimeMenu = false;
                            timeMenu(openday.closeTime, index, 'save', 'close');
                          "
                          >OK</v-btn
                        >
                      </v-card-actions>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeAddBatchDialog()"
                  >Cancel</v-btn
                >
                <v-btn
                  color="primary"
                  text
                  :disabled="timingWarningDialog"
                  @click="saveBatch()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-card flat>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-icon class="ma-1" @click="goToPreviousSession()"
          >mdi-chevron-left-circle-outline </v-icon
        ><span class="ma-1">{{
          session.sessionNo
            ? formatDateTime(session.startDate) +
              " to " +
              formatDateTime(session.endDate)
            : "No Acive Session"
        }}</span>
        <v-icon class="ma-1" @click="goToNextSession()"
          >mdi-chevron-right-circle-outline</v-icon
        >
      </v-toolbar>
      <Batches
        v-bind:batches="session.batches ? session.batches : []"
        v-bind:instituteId="institute.instituteId"
        v-bind:sessionNo="session.sessionNo ? session.sessionNo : undefined"
        v-bind:isActiveSession="session.isActive"
      />

      <v-card-actions>
        <v-row align="end" justify="end">
          <v-btn
            v-if="institute.currentSession"
            color="primary"
            icon
            @click="openAddBatchDialog()"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success">mdi-plus</v-icon>
              </template>
              <span>Add Batch</span>
            </v-tooltip>
          </v-btn>
          <v-btn
            color="primary"
            icon
            @click="openEditInstitueDialog(institute)"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-pencil</v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-btn>
          <v-btn
            v-if="institute.currentSession"
            color="primary"
            icon
            @click="deleteSessionConfirm()"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="error">mdi-delete</v-icon>
              </template>
              <span>delete session</span>
            </v-tooltip>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  BASE_URL,
  UPDATE_INSTITUTE,
  GET_ADDRESS_DETAILS,
  GET_INSTITUTE_BY_ID,
  CREATE_BATCH,
  END_CURRENT_SESSION,
} from "@/common/apiEndpoints";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import Batches from "./Batches.vue";
export default {
  data() {
    return {
      editInstdialog: false,
      addBatchDialog: false,
      editedIndex: -1,
      deleteConfirmDialog: false,
      selectAllChecked: false,
      timingWarningDialog: false,
      defaultOpenDays: [
        {
          day: "MON",
          label: "Monday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
        {
          day: "TUE",
          label: "Tuesday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
        {
          day: "WED",
          label: "Wednesday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
        {
          day: "THU",
          label: "Thursday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
        {
          day: "FRI",
          label: "Friday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
        {
          day: "SAT",
          label: "Saturday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
        {
          day: "SUN",
          label: "Sunday",
          openTime: "",
          closeTime: "",
          checked: false,
        },
      ],
      editedBatch: {
        openDays: [
          {
            id: 0,
            day: "MON",
            label: "Monday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            id: 1,
            day: "TUE",
            label: "Tuesday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            id: 2,
            day: "WED",
            label: "Wednesday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            id: 3,
            day: "THU",
            label: "Thursday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            id: 4,
            day: "FRI",
            label: "Friday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            id: 5,
            day: "SAT",
            label: "Saturday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            id: 6,
            day: "SUN",
            label: "Sunday",
            openTime: "",
            closeTime: "",
            checked: false,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
        ],
      },
      session: {},
      institute: {},
      editedInstitute: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      defaultItem: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      createInstituteValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createBatchValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  components: { Batches },
  props: ["instituteId"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted: function () {
    this.getInstituteById();
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    formatDateTime(date) {
      return formatDateTime(date);
    },
    getInstituteById() {
      const url = GET_INSTITUTE_BY_ID;
      let payload = {};
      payload.instituteId = this.instituteId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institute = res.data;
          if (this.institute.currentSession) {
            this.session = this.institute.currentSession;
          }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.editedInstitute.address.pincode.length == 6) {
        url += this.editedInstitute.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.district == null) {
              this.editedInstitute.address.city = "";
              this.editedInstitute.address.state = "";
            } else {
              this.editedInstitute.address.city = res.data.district;
              this.editedInstitute.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    openEditInstitueDialog(item) {
      this.quizDialogTitle = "Edit Institute";
      this.editInstdialog = true;
      this.editedIndex = 0;
      this.editedInstitute = Object.assign({}, item);
    },

    close() {
      this.editInstdialog = false;
      this.$nextTick(() => {
        this.editedInstitute = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    validate() {
      this.$refs.form.validate();
    },
     trimEditedInstitue(){
this.editedInstitute.address.addressLine1 = this.editedInstitute.address.addressLine1.trim();
this.editedInstitute.address.addressLine2 = this.editedInstitute.address.addressLine2.trim();
this.editedInstitute.address.city = this.editedInstitute.address.city.trim();
this.editedInstitute.address.state = this.editedInstitute.address.state.trim()

    },

    saveInstitute() {
      this.validate();
      if (this.createInstituteValidation.valid) {
        this.trimEditedInstitue();
        const payload = this.editedInstitute;
        let url = UPDATE_INSTITUTE;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createInstituteValidation.valid = false;
            this.editedIndex = -1;
            this.editedInstitute = res.data;
            this.institute = this.editedInstitute;
            this.close();
            this.setOverlayValue(false);
            this.setApiSuccess("Institute updated successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    closeDeleteDailog() {
      this.deleteConfirmDialog = false;
    },
    openAddBatchDialog() {
      this.addBatchDialog = true;
    },
    deleteSessionConfirm() {
      this.deleteConfirmDialog = true;
    },
    EndCurrentSession() {
      const url = END_CURRENT_SESSION;
      let payload = {};
      payload.instituteId = this.institute.instituteId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.getInstituteById();
          this.closeDeleteDailog();
          this.$emit("updateInstitute");
          this.setOverlayValue(false);
          this.setApiSuccess("session end successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.closeDeleteDailog();
        });
    },

    saveBatch() {
      this.validate();
      if (this.createBatchValidation.valid) {
        const payload = {};
        payload.name = this.editedBatch.name.trim();
        payload.openDays = [];
        for (let i = 0; i < this.editedBatch.openDays.length; i++) {
          if (this.editedBatch.openDays[i].checked) {
            let openDay = {};
            openDay.day = this.editedBatch.openDays[i].day;
            openDay.openTime = this.editedBatch.openDays[i].openTime;
            openDay.closeTime = this.editedBatch.openDays[i].closeTime;
            openDay.timezoneOffset = new Date().getTimezoneOffset();
            payload.openDays.push(openDay);
          }
        }
        payload.instituteId = this.institute.instituteId;
        let url = CREATE_BATCH;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createBatchValidation.valid = false;
            this.editedIndex = -1;
            this.editedInstitute = res.data;
            this.getInstituteById();
            this.closeAddBatchDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Batch created successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    closeAddBatchDialog() {
      this.addBatchDialog = false;
      this.timingWarningDialog = false;
      this.editedBatch.name = "";
      Object.assign(this.editedBatch.openDays, this.defaultOpenDays);
      this.selectAllChecked = false;
    },
    selectAllDays() {
      return this.editedBatch.openDays.findIndex(
        (openDay) => openDay.checked == false
      );
    },
    selectSomeDays() {
      return this.editedBatch.openDays.length > 0 && !this.selectAllDays();
    },
    checkForAllSelected() {
      if (this.selectAllDays() == -1) {
        this.selectAllChecked = true;
      } else {
        this.selectAllChecked = false;
      }
    },
    selectAll() {
      if (this.selectAllDays() == -1) {
        this.editedBatch.openDays.forEach(
          (openDay) => (openDay.checked = false)
        );
        this.selectAllChecked = false;
      } else {
        this.editedBatch.openDays.forEach(
          (openDay) => (openDay.checked = true)
        );
        this.selectAllChecked = true;
      }
    },
    goToPreviousSession() {
      if (
        this.session.sessionNo &&
        this.session.sessionNo > 1 &&
        this.institute.sessions.length > 0
      ) {
        this.session = this.institute.sessions[this.session.sessionNo - 2];
      } else if (
        !this.session.sessionNo &&
        this.institute.sessions.length > 0
      ) {
        this.session =
          this.institute.sessions[this.institute.sessions.length - 1];
      } else {
        this.setApiError("No Pervious Session Found");
      }
    },
    goToNextSession() {
      if (
        this.session.sessionNo &&
        this.institute.currentSession &&
        this.session.sessionNo == this.institute.sessions.length
      ) {
        this.session = this.institute.currentSession;
      } else if (
        this.session.sessionNo &&
        this.session.sessionNo < this.institute.sessions.length
      ) {
        this.session = this.institute.sessions[this.session.sessionNo];
      } else {
        this.setApiError("No Next Session Found");
      }
    },
    saveCloseTime() {},
    timeMenu(time, index, action, timingType) {
      if (action == "cancel") {
        if (timingType == "open")
          this.editedBatch.openDays[index].openTime = "";
        else {
          this.editedBatch.openDays[index].closeTime = "";
        }
      } else {
        if (timingType == "open")
          this.editedBatch.openDays[index].openTime = time;
        else {
          this.editedBatch.openDays[index].closeTime = time;
        }
      }
      if (
        timingType == "close" &&
        this.editedBatch.openDays[index].closeTime != "" &&
        this.editedBatch.openDays[index].openTime != ""
      ) {
        if (
          this.editedBatch.openDays[index].openTime.split(":")[1] !=
          this.editedBatch.openDays[index].closeTime.split(":")[1]
        ) {
          this.timingWarningDialog = true;
        } else {
          this.timingWarningDialog = false;
        }
        this.editedBatch.openDays.forEach(time => {
          if (time.openTime.split(":")[1] != time.closeTime.split(":")[1]) {
            this.timingWarningDialog = true;
          }
        });
      }
      if (
        timingType == "open" &&
        this.editedBatch.openDays[index].openTime != "" &&
        this.editedBatch.openDays[index].closeTime != ""
      ) {
        if (
          this.editedBatch.openDays[index].openTime.split(":")[1] !=
          this.editedBatch.openDays[index].closeTime.split(":")[1]
        ) {
          this.timingWarningDialog = true;
        } else {
          this.timingWarningDialog = false;
        }
        this.editedBatch.openDays.forEach(time => {
          if (time.openTime.split(":")[1] != time.closeTime.split(":")[1]) {
            this.timingWarningDialog = true;
          }
        });
      }
    },
  },
};
</script> 
