<template>
  <v-layout class="rounded rounded-md">
    <AppBarAuth />
    <v-menu v-model="fab" offset-x top offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
          v-on="on"
          class="fixed-btn"
        >
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-img
            v-else
            max-height="40"
            max-width="40"
            src="../assets/Icons/Group_227.svg"
          ></v-img>
        </v-btn>
      </template>
      <ChatBox></ChatBox>
    </v-menu>
    <v-app-bar flat dark app clipped-left height="50" color="primary">
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == true"
        >mdi-menu-open</v-icon
      >
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == false"
        >mdi-menu-close</v-icon
      >
      <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
        courseData.courseName
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="showCertificate" text @click="openCertificateDialog()"
        >View Certificate</v-btn
      >
      <v-icon size="30" @click="$router.push('/home/student-courses')"
        >mdi-close-circle</v-icon
      >
    </v-app-bar>
    <v-dialog v-model="certificateDialog" width="50%">
      <v-card>
        <v-toolbar dark dense color="primary">
          <v-toolbar-title style="font-weight: 500; font-size: 24px"
            >Course Certificate</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCertificateDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col class="ma-1">
            <span style="font-size: large">
              You are awarded this certificate for successfully completeing the
              <span style="font-weight: 500">{{
                certificateDetails.courseName
              }}</span>
              course.
            </span>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-col cols="4" class="ml-1 pl-0">
            <span style="font-size: large" class="mr-1"> No of Downloads  </span>
            <span style="font-size: large; font-weight: 500; color: #faaf19">{{
              certificateDetails.downloads
            }}</span>
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-btn text color="primary" @click="verifyCertificate()"
                >Verify Certificate</v-btn
              >
              <v-btn text color="primary" @click="downloadCertificate()"
                >Download Certificate</v-btn
              >
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet>
      <v-navigation-drawer
        app
        clipped
        width="22%"
        v-model="drawer"
        style="background-color: #f0fff0"
      >
        <v-list>
          <v-list-group
            :value="true"
            v-for="chapter in courseData.chapters"
            :key="chapter.chapterNo"
            prepend-icon="$expand"
            sub-group
            class="ml-n6"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content class="ml-n7">
                  <v-list-item-title
                    style="font-size: 20px; font-weight: 500"
                    >{{ chapter.chapterName }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="chapter.completed != null && chapter.completed != 0"
                    :color="chapter.completed == 100 ? 'success' : ''"
                    size="20"
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    v-if="chapter.completed == null || chapter.completed == 0"
                    size="20"
                    >mdi-check</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </template>
            <v-list-group
              :value="true"
              v-for="lesson in chapter.lessons"
              :key="lesson.lessonNo"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title
                    style="font-size: 18px; color: #ff6666"
                    class="text-wrap"
                    >{{ lesson.lessonName }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="lesson.completed != null && lesson.completed != 0"
                    :color="lesson.completed == 100 ? 'success' : ''"
                    size="20"
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    v-if="lesson.completed == null || lesson.completed == 0"
                    size="20"
                    >mdi-check</v-icon
                  >
                </v-list-item-icon>
              </template>
              <v-list-item-group
                :value="true"
                v-if="lesson.content.length != 0"
                :active-class="'border'"
              >
                <v-list-item
                  v-for="content in lesson.content"
                  :key="content.contentNo"
                  :ref="
                    contentRef(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      content.contentNo
                    )
                  "
                  :disabled="content.locked != false"
                  class="ml-3"
                  link
                  :input-value="
                    content.value && content.source == currentActiveElement
                      ? 'border'
                      : ''
                  "
                  @click="
                    showContent(
                      content,
                      chapter.chapterNo,
                      lesson.lessonNo,
                      lesson.classOpId
                    )
                  "
                >
                  <v-list-item-icon>
                    <v-icon size="20" color="black">
                      {{ `mdi-${getIcon(content)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-list-item-title
                      style="font-size: 14px"
                      class="text-wrap"
                    >
                      {{ content.title || content.quizName }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-if="content.completed == 100"
                      :color="content.completed == 100 ? 'success' : ''"
                      size="20"
                      >mdi-check-all</v-icon
                    >
                    <v-icon
                      v-if="
                        content.locked == false &&
                        (content.completed == null || content.completed == 0)
                      "
                      size="20"
                      >mdi-check</v-icon
                    >
                    <v-icon v-if="content.locked != false" size="20"
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>
    <v-main style="overflow: hidden">
      <v-card flat class="overflow-y-auto overflow-x-hidden" height="82vh">
        <div>
          <v-row
            justify="center"
            v-if="
              content.type == CONTENT_TYPE_TEXT ||
              (content.type == CONTENT_TYPE_MEDIA &&
                content.resource?.status === 'BLOCKED')
            "
          >
            <CourseTextView :htmlContent="content.source" />
          </v-row>
          <!-- Video player will be shown if content.resource.mimeType contains 'video' string -->
          <v-card
            flat
            v-if="
              content.type == CONTENT_TYPE_MEDIA &&
              !imagesArray &&
              content.resource?.status != 'BLOCKED' &&
              content.resource?.mimeType?.includes('video')
            "
          >
            <VideoPlayer
              :options="{
                autoplay: true,
                controls: true,
                sources: [
                  {
                    src: `${content.source}`,
                    type: 'video/mp4',
                  },
                ],
              }"
              :key="content.title"
            ></VideoPlayer>
          </v-card>
          <!-- Vue friendly Iframe will be shown if 
            content.resource.mimeType  does not contain 'video' string -->
          <div
            v-if="
              content.type == CONTENT_TYPE_MEDIA &&
              content.resource?.status != 'BLOCKED' &&
              !content.resource?.mimeType?.includes('video') &&
              !imagesArray
            "
          >
            <CourseMediaView
              :src="
                content.resource?.mimeType?.includes('pdf')
                  ? content.source + '#toolbar=0'
                  : content.source
              "
            />
          </div>
          <ImageArray
            v-if="
              imagesArray &&
              content.type === CONTENT_TYPE_MEDIA &&
              content.resource?.status != 'BLOCKED'
            "
            :title="content.title"
            :images="content.images"
          />
          <div v-if="content.type == CONTENT_TYPE_CODELY">
            <CourseCodelyContent :src="content.source" />
          </div>
          <v-row
            v-if="content.type == CONTENT_TYPE_QUIZ"
            justify="center"
            class="pt-8"
          >
            <v-card flat width="90%">
              <StudentQuizes
                v-bind:content="content"
                v-bind:chapterNo="selectedChapterNo"
                v-bind:lessonNo="selectedLessonNo"
                v-bind:courseId="courseData.courseId"
                v-bind:studentCourseProgress="studentCourseProgress"
                :key="content.contentNo"
                @questionCompleted="questionUpdate"
              />
            </v-card>
          </v-row>
        </div>
      </v-card>
      <v-card flat style="height: 6%">
        <v-row
          :style="
            drawer == true
              ? 'justify-content: center;'
              : 'justify-content: space-between;'
          "
        >
          <v-col cols="1" class="pa-0 ma-1 ml-6">
            <v-btn
              :disabled="disablePreviousButton"
              class="ml-2 mr-2"
              color="success"
              label="<<Pre"
              @click="goToPrevious()"
              >« Pre</v-btn
            >
          </v-col>
          <v-col cols="1" class="pa-0 ma-1">
            <v-btn
              :disabled="disableNextButton || loading"
              :loading="loading"
              class="ml-2"
              color="success"
              @click="goToNext()"
              >Next »</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-main>
  </v-layout>
</template>

<script>
import {
  BASE_URL,
  GET_STUDENT_COURSE_PROGRESS,
  SEARCH_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  VERIFY_CERTIFICATE,
  GET_STUDENT_LESSON_PROGRESS,
} from "@/common/apiEndpoints.js";
import {
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
} from "@/common/constants";
import Vue from "vue";
import AppBarAuth from "./AppBarAuth.vue";
import CourseTextView from "@/components/CourseViewComponents/CourseTextView.vue";
import CourseCodelyContent from "@/components/CourseViewComponents/CourseCodelyContent.vue";
import CourseMediaView from "@/components/CourseViewComponents/CourseMediaView.vue";
import StudentQuizes from "@/components/StudentQuizes.vue";
import VideoPlayer from "./VideoPlayer.vue";
import ChatBox from "./ChatBox.vue";
import axios from "axios";
import { GET_TOKEN, GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import ImageArray from "./ImageArray2.vue";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
export default Vue.extend({
  data() {
    return {
      loading: false,
      courseId: "",
      studentCourseProgress: [],
      drawer: true,
      disableNextButton: false,
      disablePreviousButton: false,
      courseData: {},
      content: {},
      selectedChapterNo: "",
      selectedLessonNo: "",
      showDescArr: [],
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      fab: false,
      top: false,
      left: false,
      currentActiveElement: null,
      lastCompletedContent: { chapterNo: 1, lessonNo: 1, contentNo: 1 },
      certificateDialog: false,
      certificateDetails: {},
      imagesArray: false,
      showCertificate: false,
    };
  },
  components: {
    AppBarAuth,
    CourseTextView,
    CourseCodelyContent,
    CourseMediaView,
    StudentQuizes,
    VideoPlayer,
    ChatBox,
    ImageArray,
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    this.courseData = this.paramsValue.courseData;
    this.courseId = this.paramsValue.courseData.courseId;
    this.courseOpen();
    setTimeout(() => {
      this.scrollToLastActiveContent();
    }, 1000);
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    openCertificateDialog() {
      this.searchCertificate();
    },
    closeCertificateDialog() {
      this.certificateDialog = false;
    },
    searchCertificate() {
      const url = SEARCH_CERTIFICATE;
      const payload = {};
      payload.courseProgressId =
        this.studentCourseProgress[0].progress.progressId;
      payload.type = "course";
      return axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          if (res) {
            this.certificateDetails = res.data;
            this.certificateDialog = true;
          }
        })
        .catch((error) => {
          this.setApiError("Certificate is not generated.");
          this.setOverlayValue(false);
        });
    },
    verifyCertificate() {
      var url = BASE_URL + VERIFY_CERTIFICATE;
      url = url + '?secureKey='+this.certificateDetails.certId;
      window.open(url);
    },
    downloadCertificate() {
      const url = DOWNLOAD_CERTIFICATE;
      const payload = { certId: this.certificateDetails.certId };

      return axios
        .post(url, payload, {
          responseType: "arraybuffer",
          ...getAxiosOptions(BASE_URL, this.token),
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(blob);
          this.closeCertificateDialog();
          window.open(pdfUrl, "_blank");
        })
        .catch((error) => {
          console.error(error);
          this.setOverlayValue(false);
        });
    },
    getIcon(content) {
      if (content.type == CONTENT_TYPE_QUIZ) {
        return "lightbulb-on-10";
      } else if (content.type == CONTENT_TYPE_TEXT) {
        return "text-box-outline";
      } else if (content.type == CONTENT_TYPE_MEDIA) {
        if (
          content.resource?.mimeType &&
          content.resource?.mimeType.includes("video")
        ) {
          return "video";
        } else if (
          content.resource?.mimeType &&
          content.resource?.mimeType.includes("image")
        ) {
          return "image";
        } else if (
          content.resource?.mimeType &&
          content.resource?.mimeType.includes("pdf")
        ) {
          return "file-pdf-box";
        } else {
          return "table-of-contents";
        }
      } else {
        return "code-block-braces";
      }
    },
    courseOpen() {
      this.fetchCourseProgress(this.courseData.courseId);
    },
    scrollToLastActiveContent() {
      this.courseData.chapters.forEach((chapter) => {
        chapter.lessons.forEach((lesson) => {
          lesson.content.forEach((content) => {
            if (content.completed === 100) {
              this.lastCompletedContent.chapterNo = chapter.chapterNo;
              this.lastCompletedContent.lessonNo = lesson.lessonNo;
              this.lastCompletedContent.contentNo = content.contentNo;
            }
          });
        });
      });
      const contentRef =
        this.$refs[
          `contentref_${this.lastCompletedContent.chapterNo}_${this.lastCompletedContent.lessonNo}_${this.lastCompletedContent.contentNo}`
        ];
      if (contentRef) {
        contentRef[0].$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    contentRef(chapterNo, lessonNo, contentNo) {
      return `contentref_${chapterNo}_${lessonNo}_${contentNo}`;
    },
    fetchCourseProgress(courseId) {
      let url = GET_STUDENT_COURSE_PROGRESS;
      let payload = {};
      payload.courseIds = [];
      payload.courseIds.push(courseId);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourseProgress = res.data.progresses;
          this.checkForCompletion();
          if (
            this.courseData.chapters.length > 0 &&
            this.courseData.chapters[0].lessons.length > 0
          ) {
            for (let i = 0; i < this.courseData.chapters.length; i++) {
              for (
                let j = 0;
                j < this.courseData.chapters[i].lessons.length;
                j++
              ) {
                for (
                  let k = 0;
                  k < this.courseData.chapters[i].lessons[j].content.length;
                  k++
                ) {
                  if (
                    this.courseData.chapters[i].lessons[j].content[k]
                      .completed == 100
                  ) {
                    this.selectedChapterNo =
                      this.courseData.chapters[i].chapterNo;
                    this.selectedLessonNo =
                      this.courseData.chapters[i].lessons[j].lessonNo;
                    this.showContent(
                      this.courseData.chapters[i].lessons[j].content[k],
                      this.selectedChapterNo,
                      this.selectedLessonNo
                    );
                    this.courseData.chapters[i].lessons[j].content[
                      k
                    ].value = true;
                    if (
                      this.courseData.chapters.slice(-1)[0].chapterNo == 1 &&
                      this.courseData.chapters[i].lessons.slice(-1)[0]
                        .lessonNo == 1 &&
                      this.content.contentNo == 1
                    ) {
                      this.disablePreviousButton = true;
                    }
                    if (
                      this.content.type == CONTENT_TYPE_QUIZ &&
                      this.content.completed != 100
                    ) {
                      this.disableNextButton = true;
                    }
                  } else if (
                    this.courseData.chapters[0].lessons[0].content[0]
                      .completed != 100 &&
                    this.courseData.chapters[0].lessons[0].content[0]
                  ) {
                    this.disablePreviousButton = true;
                    this.showContent(
                      this.courseData.chapters[0].lessons[0].content[0],
                      1,
                      1
                    );
                  }
                }
              }
            }
          }
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    showContent(content, chapterNo, lessonNo) {
      this.currentActiveElement = content.source;
      if (
        content.type == CONTENT_TYPE_TEXT ||
        content.type == CONTENT_TYPE_MEDIA ||
        content.type == CONTENT_TYPE_QUIZ ||
        content.type == CONTENT_TYPE_CODELY
      ) {
        if (content.images && content.images.length > 0) {
          this.imagesArray = true;
        } else {
          this.imagesArray = false;
        }
        content.value = true;
        this.content = content;
      }
      if (
        (this.content.type == CONTENT_TYPE_QUIZ &&
          this.content.completed != 100) ||
        this.content.source == "Content locked"
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
      this.checkForCompletion();
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      if (
        this.selectedChapterNo == 1 &&
        this.selectedLessonNo == 1 &&
        this.content.contentNo == 1
      ) {
        this.disablePreviousButton = true;
      } else {
        this.disablePreviousButton = false;
      }
    },
    lessonProgrss() {
      let url = GET_STUDENT_LESSON_PROGRESS;
      let payload = {};
      payload.courseId = this.courseData.courseId;
      payload.lessonNo = Number(this.selectedLessonNo);
      payload.chapterNo = Number(this.selectedChapterNo);
      payload.contentNo = Number(this.content.contentNo);
      this.loading = true;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.loading = false;
          this.studentCourseProgress[0].progress = res.data;
          this.checkForCompletion();
          this.goToNextContent();
        })
        .catch((error) => {
          error = error.errorMessage;
          this.loading = false;
        });
    },
    checkForCompletion() {
      if (
        this.studentCourseProgress[0].progress &&
        this.studentCourseProgress[0].progress.completed == 100
      ) {
        this.showCertificate = true;
      }
      if (this.studentCourseProgress[0].progress) {
        this.courseData.completed =
          this.studentCourseProgress[0].progress.completed;
        if (this.studentCourseProgress[0].progress.chapters.length > 1) {
          this.studentCourseProgress[0].progress.chapters.sort((c1, c2) => {
            return c1.chapterNo - c2.chapterNo;
          });
        }
        for (
          let i = 0;
          i < this.studentCourseProgress[0].progress.chapters.length;
          i++
        ) {
          var progressChapterNo =
            this.studentCourseProgress[0].progress.chapters[i].chapterNo;
          this.courseData.chapters[progressChapterNo - 1].completed =
            this.studentCourseProgress[0].progress.chapters[i].completed;
          if (
            this.studentCourseProgress[0].progress.chapters[i].lessons.length >
            1
          ) {
            this.studentCourseProgress[0].progress.chapters[i].lessons.sort(
              (l1, l2) => {
                return l1.lessonNo - l2.lessonNo;
              }
            );
          }
          for (
            let j = 0;
            j <
            this.studentCourseProgress[0].progress.chapters[i].lessons.length;
            j++
          ) {
            var progressLessonNo =
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .lessonNo;
            this.courseData.chapters[progressChapterNo - 1].lessons[
              progressLessonNo - 1
            ].completed =
              this.studentCourseProgress[0].progress.chapters[i].lessons[
                j
              ].completed;
            if (
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .contentProgress.length > 1
            ) {
              this.studentCourseProgress[0].progress.chapters[i].lessons[
                j
              ].contentProgress.sort((cp1, cp2) => {
                return cp1.contentNo - cp2.contentNo;
              });
            }
            for (
              let k = 0;
              k <
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .contentProgress.length;
              k++
            ) {
              if (
                this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                  .contentProgress[k].completed == 100
              ) {
                // this.courseData.chapters[i].lessons[j].content[
                //   k
                // ].locked = false;
                var progressContentNo =
                  this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                    .contentProgress[k].contentNo;
                Vue.set(
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo - 1
                  ].content[progressContentNo - 1],
                  "locked",
                  false
                );
                if (
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo - 1
                  ].content[progressContentNo]
                ) {
                  // this.courseData.chapters[i].lessons[j].content[
                  //   k + 1
                  // ].locked = false;
                  Vue.set(
                    this.courseData.chapters[progressChapterNo - 1].lessons[
                      progressLessonNo - 1
                    ].content[progressContentNo],
                    "locked",
                    false
                  );
                } else if (
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo
                  ] &&
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo
                  ].content[0]
                ) {
                  // this.courseData.chapters[i].lessons[
                  //   j + 1
                  // ].content[0].locked = false;
                  Vue.set(
                    this.courseData.chapters[i].lessons[j + 1].content[0],
                    "locked",
                    false
                  );
                } else if (
                  this.courseData.chapters[progressChapterNo] &&
                  this.courseData.chapters[progressChapterNo].lessons[0] &&
                  this.courseData.chapters[progressChapterNo].lessons[0]
                    .content[0]
                ) {
                  // this.courseData.chapters[
                  //   i + 1
                  // ].lessons[0].content[0].locked = false;
                  Vue.set(
                    this.courseData.chapters[progressChapterNo].lessons[0]
                      .content[0],
                    "locked",
                    false
                  );
                }
              }
              this.courseData.chapters[progressChapterNo - 1].lessons[
                progressLessonNo - 1
              ].content[progressContentNo - 1].completed =
                this.studentCourseProgress[0].progress.chapters[i].lessons[
                  j
                ].contentProgress[k].completed;
            }
          }
        }
      } else if (
        this.courseData &&
        this.courseData.chapters[0] &&
        this.courseData.chapters[0].lessons[0] &&
        this.courseData.chapters[0].lessons[0].content[0]
      ) {
        this.courseData.chapters[0].lessons[0].content[0].locked = false;
        Vue.set(
          this.courseData.chapters[0].lessons[0].content[0],
          "locked",
          false
        );
      }
    },
    questionUpdate(studentCourseProgress) {
      this.studentCourseProgress = studentCourseProgress;
      this.checkForCompletion();
      this.content =
        this.courseData.chapters[this.selectedChapterNo - 1].lessons[
          this.selectedLessonNo - 1
        ].content[this.content.contentNo - 1];
      if (this.content.completed == 100) {
        this.disableNextButton = false;
      }
    },
    goToPrevious() {
      if (this.courseData && this.content && this.content.contentNo) {
        this.goToPreviousContent();
      }
    },
    goToPreviousContent() {
      if (this.content.contentNo > 1) {
        this.showContent(
          this.courseData.chapters[this.selectedChapterNo - 1].lessons[
            this.selectedLessonNo - 1
          ].content[this.content.contentNo - 2],
          this.selectedChapterNo,
          this.selectedLessonNo
        );
      } else {
        this.goToPreviousChapterLesson();
      }
    },
    goToPreviousChapterLesson() {
      var chapterLessonFound = true;
      if (this.selectedLessonNo > 1) {
        this.selectedLessonNo -= 1;
      } else if (this.selectedChapterNo > 1) {
        this.selectedChapterNo = Number(this.selectedChapterNo) - 1;
        this.selectedLessonNo =
          this.courseData.chapters[this.selectedChapterNo - 1].lessons.length;
      } else {
        this.disablePreviousButton = true;
        chapterLessonFound = false;
      }
      if (
        chapterLessonFound &&
        this.courseData.chapters[this.selectedChapterNo - 1].lessons[
          this.selectedLessonNo - 1
        ].content.length > 0
      ) {
        this.showContent(
          this.courseData.chapters[this.selectedChapterNo - 1].lessons[
            this.selectedLessonNo - 1
          ].content.slice(-1)[0],
          this.selectedChapterNo,
          this.selectedLessonNo
        );
      }
    },
    goToNext() {
      if (this.courseData && this.content && this.content.contentNo) {
        if (
          this.content.completed != 100 &&
          this.content.type != this.CONTENT_TYPE_QUIZ
        ) {
          this.lessonProgrss();
        } else {
          this.disablePreviousButton = false;
          this.goToNextContent();
        }
      }
    },
    goToNextContent() {
      if (
        this.courseData.chapters[this.selectedChapterNo - 1].lessons[
          this.selectedLessonNo - 1
        ].content[this.content.contentNo]
      ) {
        this.showContent(
          this.courseData.chapters[this.selectedChapterNo - 1].lessons[
            this.selectedLessonNo - 1
          ].content[this.content.contentNo],
          this.selectedChapterNo,
          this.selectedLessonNo
        );
      } else {
        this.goToNextChapterLesson();
      }
    },
    goToNextChapterLesson() {
      var chapterLessonFound = true;
      if (
        this.courseData.chapters[this.selectedChapterNo - 1].lessons[
          this.selectedLessonNo
        ]
      ) {
        this.selectedLessonNo += 1;
      } else if (
        this.courseData.chapters[this.selectedChapterNo] &&
        this.courseData.chapters[this.selectedChapterNo].lessons[0]
      ) {
        this.selectedChapterNo = Number(this.selectedChapterNo) + 1;
        this.selectedLessonNo = 1;
      } else {
        this.disableNextButton = true;
        chapterLessonFound = false;
      }
      if (chapterLessonFound) {
        this.showContent(
          this.courseData.chapters[this.selectedChapterNo - 1].lessons[
            this.selectedLessonNo - 1
          ].content[0],
          this.selectedChapterNo,
          this.selectedLessonNo
        );
      } else {
        this.disableNextButton = true;
      }
    },
  },
});
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 80px;
  right: 20px;
}
.fixed-icon {
  z-index: 10;
  position: fixed;
  top: 20px;
}
.border {
  border: 1px dashed red;
}
.previousBtn {
  z-index: 10;
  position: fixed;
  top: 50vh;
  left: 1vh;
}
.nextBtn {
  z-index: 10;
  position: fixed;
  top: 50vh;
  right: 1vh;
}
.iframe {
  width: 100%;
  overflow: hidden;
  height: 81vh;
}
</style>
