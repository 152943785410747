<template>
    <div style="   width: 100%;
      max-width: 1000px;
      margin: auto auto;
      display: flex;
      justify-content: center; 
      align-items: center; 
      margin-top: 1rem;">
      <video  ref="videoPlayer" class="video-js vjs-theme-fantasy vjs-fluid "></video>
    </div>
  </template>
  
  <script>
  import videojs from 'video.js';
  
  export default {
    name: 'VideoPlayer',
    props: {
      options: {
        type: Object,
        default() {
          return {};
        }
      }
    },
    data() {
      return {
        player: null
      }
    },
    mounted() {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        this.player.log('onPlayerReady', this);
      });
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    }
  }
  </script>
  <!-- @import 'video.js/dist/video-js.css';
  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
  @import url('https://unpkg.com/@videojs/themes@1/dist/fantasy/index.css');
  </style>
  <video-player :options="videoOptions" />
  videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src:
              'https://drive.google.com/uc?export=download&id=1hbOBXcdcsgG6Acnnf3Aewy-s52vaeEUJ',
              type: 'video/mp4'
          }
        ]
      } -->
