<template>
  <v-sheet style="overflow: hidden">
    <!-- <v-toolbar dark color="primary" class="mb-1" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Coin History</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar> -->
    <v-row justify="end" class="ma-1">
      <v-col cols="2" class="pa-0 mt-2">
        <v-btn color="primary" @click="openRedeemDialog()">Reedem Coins</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="redeemDialog" width="50%">
      <v-toolbar dark dense color="primary">
        <v-toolbar-title style="font-weight: 500; font-size: 24px"
          >Redeem Coins</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeRedeemDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet>
        <v-form ref="form" v-model="coinsRules.valid">
          <v-container>
            <v-col> Select Coins to redeem </v-col>
            <v-col class="d-flex" cols="12" sm="8">
              <v-select
                :items="items"
                label="Select coins"
                v-model="coins"
                :rules="coinsRules.rules"
              ></v-select>
            </v-col>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            color="primary"
            text
            @click="redeemCoins()"
            :disabled="coinsRules.valid == false"
          >
            Redeem
          </v-btn>
        </v-card-actions>
      </v-sheet>
    </v-dialog>
    <v-dialog v-model="redeemSuccessDialog" max-width="40%">
      <v-card>
        <v-card-title
          >Your request to redeem coins has been submitted
          successfully.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeRedeemSuccessDialog()"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid class="pa-0">
      <v-data-table
        :headers="coinHistoryHeaders"
        :items="coinHistory"
        :items-per-page="7"
      >
        <template v-slot:[`item.courseName`]="{ item }">
          {{ item.courseName ? item.courseName : "--" }}
        </template>
        <template v-slot:[`item.chapterName`]="{ item }">
          {{ item.chapterName ? item.chapterName : "--" }}
        </template>
        <template v-slot:[`item.lessonName`]="{ item }">
          {{ item.lessonName ? item.lessonName : "--" }}
        </template>
        <template v-slot:[`item.compName`]="{ item }">
          {{ item.compName ? item.compName : "--" }}
        </template>
        <template v-slot:[`item.quizName`]="{ item }">
          {{ item.quizName ? item.quizName : "--" }}
        </template>
        <template v-slot:[`item.className`]="{ item }">
          {{ item.className ? item.className : "--" }}
        </template>
        <template v-slot:[`item.assignmentNumber`]="{ item }">
          {{ item.assignmentNumber ? item.assignmentNumber : "--" }} 
          <v-chip x-small 
          text-color="success"
          class="ml-1"
          color="success"
          outlined
          v-if="item.assignmentType"
          >
            {{ item.assignmentType }}
        </v-chip>
        </template>
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ formatDateTime(item.createdDate) }}
        </template>
        <template v-slot:[`item.coin`]="{ item }">
          <span
            v-if="item.type == 'CREDIT'"
            class="success--text"
            style="font-weight: 500"
          >
            + {{ item.coin }}
          </span>
          <span v-else class="error--text" style="font-weight: 500">
            - {{ item.coin }}
          </span>
        </template>
      </v-data-table>
    </v-container>
  </v-sheet>
</template>
<script>
import axios from "axios";
import {
  BASE_URL,
  GET_COIN_HISTORY,
  REDEEM_COINS,
  GET_COIN_BALANCE,
} from "@/common/apiEndpoints";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_COIN_BALANCE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      coinHistory: [],
      coins: "",
      redeemDialog: false,
      redeemSuccessDialog: false,
      items: ["400", "800", "1200", "1600","2000","2400"],
      coinsRules: {
        valid: false,
        rules: [(v) => !!v || "This is required"],
      },
      coinHistoryHeaders: [
        {
          text: "Course",
          value: "courseName",
          align: "start",
          width:"10%"
        },
        {
          text: "Chapter",
          align: "center",
          value: "chapterName",
          width:"10%"
        },
        {
          text: "Lesson",
          align: "center",
          value: "lessonName",
          width:"10%"
        },
        {
          text: "Competition",
          align: "center",
          value: "compName",
          width:"10%"
        },
        {
          text: "Quiz",
          align: "center",
          value: "quizName",
          width:"10%"
        },
        {
          text: "Class Name",
          align: "center",
          value: "className",
          width:"10%"
        },
        {
          text: "Assignment No",
          align: "center",
          value: "assignmentNumber",
          width:"10%"
        },
        {
          text: "Date",
          value: "createdDate",
          align: "center",
          width:"10%"
        },
        {
          text: "Coins",
          value: "coin",
          align: "center",
          width:"10%"
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.getCoinHistory();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    openRedeemDialog() {
      this.redeemDialog = true;
    },
    closeRedeemDialog() {
      this.redeemDialog = false;
      this.coins = "";
    },
    openRedeemSuccessDialog() {
      this.redeemSuccessDialog = true;
    },
    closeRedeemSuccessDialog() {
      this.redeemSuccessDialog = false;
    },
    getCoinHistory() {
      let url = GET_COIN_HISTORY;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.coinHistory = res.data.history;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    redeemCoins() {
      const url = REDEEM_COINS;
      const payload = {};
      payload.coins = this.coins;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.getCoinHistory();
          this.openRedeemSuccessDialog();
          this.updateCoinBalance();
          this.closeRedeemDialog();
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeRedeemDialog();
        });
    },
    updateCoinBalance() {
      const url = GET_COIN_BALANCE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let newCoinBalance = res.data.balance;
          this.setCoinBalance(newCoinBalance);
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
  },
};
</script>
