<template>
  <div>
    <AppBarAuth />
    <SideNavbar />
    <AnimatedVAlert
      v-if="noticeMessages.length > 0"
      v-bind:notificationMessages="noticeMessages"
    />
    <v-main>
      <v-overlay :value="overlay" v-if="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="mx-auto mt-15" max-width="500" v-if="tokenExpired">
        <v-img max-height="150" src="../assets/APT_LogoR.png"></v-img>
        <v-card-title class="ma-1 mx-5" style="font-weight: 500"
          >Your Token has Expired , please login again</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="reLoginPage()">Re-Login</v-btn>
        </v-card-actions>
      </v-card>

      <router-view v-else> </router-view>
      <v-menu
        v-model="fab"
        offset-x
        top
        offset-y
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            v-model="fab"
            color="primary"
            dark
            fab
            v-on="on"
            class="fixed-btn"
            :x-small="isSmall"
            :x-large="!isSmall"
          >
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-img
              v-else
              max-height="40"
              max-width="40"
              src="../assets/Icons/Group_227.svg"
            ></v-img>
          </v-btn>
        </template>
        <ChatBox></ChatBox>
      </v-menu>  
      <!-- <v-btn @click="toggleAlert">Click me</v-btn> -->
      <AppFooter></AppFooter>
    </v-main>
  </div>
</template>

<script>
import Vue from "vue";
import AppBarAuth from "../components/AppBarAuth.vue";
import SideNavbar from "../components/SideNavbar.vue";
import AppFooter from "../components/AppFooter.vue";
import ChatBox from "../components/ChatBox.vue";
import AnimatedVAlert from "../components/AnimatedVAlert.vue";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_OVERLAY_VALUE,
  SET_TOKEN_EXPIRED,
  SET_USER_PROFILE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";

import {
  GET_OVERLAY_VALUE,
  GET_TOKEN,
  GET_TOKEN_EXPIRED,
  GET_USER_PROFILE,
} from "@/store/getters";
import { USER_TYPE_SALES_MANAGER, USER_TYPE_STUDENT, USER_TYPE_TEACHER } from "@/common/constants";
import { BASE_URL, GET_NOTICE_BOARD_MESSAGES } from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
export default Vue.extend({
  data() {
    return {
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      USER_TYPE_SALES_MANAGER: USER_TYPE_SALES_MANAGER,
      relogin: false,
      fab: false,
      top: false,
      left: false,
      alertState: false,
      noticeMessages: [],
      interval : {},
      isSmall: false
    };
  },
  components: {
    SideNavbar,
    AppBarAuth,
    AppFooter,
    ChatBox,
    AnimatedVAlert,
  },
  watch: {
    userProfile: {
      handler(userProfile) {
        if (this.$router.history.current.path == "/home") {
          if (userProfile.userType === this.USER_TYPE_STUDENT) {
            if (userProfile.registrationCompleted) {
              // if (!document.fullscreenElement) {
              //   document.documentElement.requestFullscreen();
              // }
              this.$router.push({ path: "/home/dashboard" });
            } else {
              this.$router.push({ path: "/home/student/on-board" });
            }
          } else if (userProfile.userType === this.USER_TYPE_TEACHER) {
            if (!userProfile.registrationCompleted) {
              this.$router.push({ path: "/home/teacher/on-board" });
            } else {
              this.$router.push({ path: "/home/dashboard" });
            }
          } else if(userProfile.userType === this.USER_TYPE_SALES_MANAGER){
            this.$router.push({ path: "/home/uk/registrations" });
          } else {
            this.$router.push({ path: "/home/dashboard" });
          }
        }
      },
    },
  },
  mounted() {
     if(this.interval){
      clearInterval(this.interval);
      }
    this.intervalFunction();
  },
  beforeDestroy() {
  if (this.interval) {
    clearInterval(this.interval);
  }
},
  computed: {
    ...mapGetters({
      userProfile: `schoolModule/${GET_USER_PROFILE}`,
      overlay: `schoolModule/${GET_OVERLAY_VALUE}`,
      tokenExpired: `schoolModule/${GET_TOKEN_EXPIRED}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setTokenExpired: `schoolModule/${SET_TOKEN_EXPIRED}`,
      setUserProfile: `schoolModule/${SET_USER_PROFILE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),

    reLoginPage() {
      sessionStorage.clear();
      this.setParams({});
      this.setTokenExpired(true);
      this.setOverlayValue(false);
      this.setUserProfile({});
      this.$router.push({ path: "/login" });
    },
    toggleAlert() {
      this.alertState = !this.alertState;
      // console.log(this.alertState);
    },
    intervalFunction: function () {
       if(this.interval){
      clearInterval(this.interval);
      }
      var oThis = this;
      this.interval = setInterval(function () {
        oThis.messages = oThis.getNoticeBoardMessages();
      }, 15000);
    },
    getNoticeBoardMessages() {
      if (
        !this.tokenExpired &&
        (this.userProfile.userType === this.USER_TYPE_STUDENT ||
          this.userProfile.userType === this.USER_TYPE_TEACHER) && (this.userProfile.registrationCompleted)
      ) {
        let url = GET_NOTICE_BOARD_MESSAGES;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.noticeMessages = res.data.messages;
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
  },
});
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 80px;
  right: 20px;
}
</style>
