<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Quizes</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchQuiz"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="showSearchField = !showSearchField"
              >mdi-magnify</v-icon
            >
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="quizStatusFilter"
              label="Draft"
              color="primary"
              value="DRAFT"
              @click="getAllQuiz()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="quizStatusFilter"
              label="Published"
              color="primary"
              value="PUBLISHED"
              @click="getAllQuiz()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <!-- <v-list-item>
            <v-btn color="primary" text @click="getAllQuiz()"> Apply </v-btn>
          </v-list-item> -->
        </v-list>
      </v-menu>
      <v-dialog persistent v-model="dialog" max-width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 16px">
            New Quiz
          </v-btn>
        </template>

        <v-form ref="form" v-model="createQuizValidation.valid">
          <v-card>
            <v-container>
              <v-row>
                <v-card-title class="primary--text"
                  >New Quiz</v-card-title
                ></v-row
              >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editedQuiz.quizName"
                    label="Quiz name"
                    :rules="createQuizValidation.nameRules"
                    required
                    color="success--text"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close()"> Cancel </v-btn>
              <v-btn color="primary" text @click="saveQuiz()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog persistent v-model="copyQuizDialog" max-width="500px">
        <v-card>
          <v-card-title>Write the name of the New Quiz</v-card-title>
          <v-form ref="form" v-model="createQuizValidation.valid">
            <v-container>
              <v-col cols="8">
                <v-text-field
                  v-model="editedQuiz.name"
                  :rules="createQuizValidation.nameRules"
                  required
                  label="Quiz Name"
                ></v-text-field>
              </v-col>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeCopyQuizDialog()"
                >Cancel</v-btn
              >
              <v-btn color="primary" text @click="copyQuiz()">OK</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title
            >Are you sure you want to delete
            {{ editedQuiz.quizName }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDeleteDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="deleteQuiz()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="publishConfirmDialog" max-width="500px">
        <v-card>
          <v-card-title
            >Are you sure you want to publish
            {{ editedQuiz.quizName }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closePublishDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="publishQuiz()">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-container fluid>
      <v-data-table
        :headers="headers"
        :items="filteredQuiz"
        @contextmenu:row="rightClickHandler"
      >
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>

        <template v-slot:[`item.quizName`]="{ item }">
          <span style="font-size: 20px; font-weight: 500">
            {{ item.quizName }}
          </span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <v-chip
            small
            label
            background
            :color="item.status == STATUS_PUBLISHED ? 'success' : 'error'"
          >
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :key="1"
                v-if="item.status != STATUS_PUBLISHED"
                @click="editQuiz(item)"
              >
                <!-- <v-row justify="center"> -->
                <v-tooltip bottom v-if="item.status != STATUS_PUBLISHED">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="mr-2"
                      @click="editQuiz(item)"
                      color="primary"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <span>Edit</span>
              </v-list-item>
              <v-list-item :key="2" @click="openCopyQuizDialog(item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="mr-2"
                      color="blue"
                      @click="openCopyQuizDialog(item)"
                    >
                      mdi-content-copy
                    </v-icon>
                  </template>
                  <span>Copy</span>
                </v-tooltip>
                <span>Copy</span>
              </v-list-item>
              <v-list-item
                :key="3"
                v-if="item.status != STATUS_PUBLISHED"
                @click="deleteQuizConfirmDialog(item)"
              >
                <v-tooltip bottom v-if="item.status != STATUS_PUBLISHED">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      v-if="item.status != STATUS_PUBLISHED"
                      class="mr-2"
                      color="error"
                      @click="deleteQuizConfirmDialog(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
                <span>Delete</span>
              </v-list-item>
              <v-list-item :key="4" @click="openQuizView(item)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="mr-2"
                      @click="openQuizView(item)"
                      color="success"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View</span>
                </v-tooltip>
                <span>View</span>
              </v-list-item>
              <v-list-item
                :key="5"
                v-if="item.status != STATUS_PUBLISHED"
                @click="publishQuizConfirm(item)"
              >
                <v-tooltip bottom v-if="item.status != STATUS_PUBLISHED">
                  <template v-slot:activator="{ on }">
                    <v-img
                      v-on="on"
                      src="../assets/Publish.svg"
                      max-height="20"
                      max-width="20"
                      @click="publishQuizConfirm(item)"
                      class="mr-2"
                    ></v-img>
                  </template>
                  <span>Publish Quiz</span>
                </v-tooltip>
                <span>Publish Quiz</span>
              </v-list-item>
              <!-- </v-row> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import {
  BASE_URL,
  CREATE_QUIZ,
  DELETE_QUIZ,
  GET_ALL_QUIZ,
  UPDATE_QUIZ,
  QUIZ_PUBLISH,
  COPY_QUIZ,
} from "@/common/apiEndpoints";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  USER_TYPE_TEACHER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { getAxiosOptions } from "@/common/utility";
import { formatDateTime, rightClickHandler } from "@/common/utility";
import ContextMenu from "./ContextMenu.vue";

export default {
  data() {
    return {
      contextMenuObject: {},
      dialog: false,
      deleteDialog: false,
      copyQuizDialog: false,
      search: "",
      showSearchField: false,
      searchText: "",
      editedIndex: -1,
      filteredQuiz: [],
      quizDialogTitle: "New Quiz",
      publishConfirmDialog: false,
      headers: [
        {
          text: "Quiz Name",
          align: "start",
          sortable: false,
          value: "quizName",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },

        {
          text: "Questions",
          align: "center",
          value: "noOfQuestions",
        },
        {
          text: "Created On",
          align: "center",
          value: "createdOn",
        },
        {
          text: "Created By",
          align: "center",
          value: "createdBy",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
        },
      ],

      editedQuiz: {},
      defaultItem: {
        quizId: "",
        quizName: "",
      },
      createQuizValidation: {
        valid: false,
        nameRules: [(v) => !!v || "Quiz Name is required"],
      },
      quizes: [],
      menu: false,
      quizStatusFilter: [],
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  components: { ContextMenu },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDeleteDailog();
    },
    publishConfirmDialog(val) {
      val || this.closePublishDailog();
    },
    userType: {
      handler(userType) {
        if (userType) {
          this.getAllQuiz();
        }
      },
    },
  },
  created: function () {
    this.getAllQuiz();
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getAllQuiz() {
      this.menu = false;
      let url = GET_ALL_QUIZ;
      if (
        !(
          this.quizStatusFilter.length == 0 || this.quizStatusFilter.length == 2
        )
      ) {
        url = url + "?status=" + this.quizStatusFilter[0];
      }
      if (this.USER_TYPE_TEACHER == this.userType) {
        if (url.includes("status")) {
          url = url + "&createdByUser=true";
        } else {
          url = url + "?createdByUser=true";
        }
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.filteredQuiz = res.data.quizes;
          this.quizes = this.filteredQuiz;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    openCopyQuizDialog(item) {
      this.editedQuiz = Object.assign({}, item);
      this.copyQuizDialog = true;
    },
    closeCopyQuizDialog() {
      this.copyQuizDialog = false;
    },
    openQuizView(item) {
      this.$router.push({ name: "QuizView", params: { quizId: item.quizId } });
    },
    copyQuiz() {
      this.validate();
      if (this.createQuizValidation.valid) {
        const url = COPY_QUIZ;
        let payload = {};
        payload.quizId = this.editedQuiz.quizId;
        payload.quizName = this.editedQuiz.name;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.closeCopyQuizDialog();
            this.getAllQuiz();
            this.setOverlayValue(false);
            this.setApiSuccess("Quiz copied successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCopyQuizDialog();
            this.setOverlayValue(false);
          });
      }
    },
    editQuiz(item) {
      this.quizDialogTitle = "Edit Quiz";
      this.dialog = true;
      this.editedIndex = this.quizes.indexOf(item);
      this.editedQuiz = Object.assign({}, item);
    },
    deleteQuizConfirmDialog(item) {
      this.editedIndex = this.quizes.indexOf(item);
      this.editedQuiz = Object.assign({}, item);
      this.deleteDialog = true;
    },
    closeDeleteDailog() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.editedQuiz = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteQuiz() {
      const url = DELETE_QUIZ;
      let payload = {};
      payload.quizId = this.editedQuiz.quizId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.getAllQuiz();
          this.closeDeleteDailog();
          this.setOverlayValue(false);
          this.setApiSuccess("Quiz deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedQuiz = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closePublishDailog() {
      this.publishConfirmDialog = false;
      this.$nextTick(() => {
        this.editedQuiz = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    publishQuizConfirm(item) {
      this.editedIndex = this.quizes.indexOf(item);
      this.editedQuiz = Object.assign({}, item);
      this.publishConfirmDialog = true;
    },
    validate() {
      this.$refs.form.validate();
    },
    searchQuiz() {
      if (this.searchText) {
        this.filteredQuiz = this.quizes.filter((quiz) => {
          return quiz.quizName
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredQuiz = this.quizes;
      }
    },

    publishQuiz(item) {
      const url = QUIZ_PUBLISH;
      let payload = {};
      payload.quizId = this.editedQuiz.quizId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDailog();
          this.getAllQuiz();
          this.setOverlayValue(false);
          this.setApiSuccess("Quiz Published successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    trimEditedQuiz(){
      this.editedQuiz.quizName = this.editedQuiz.quizName.trim();
    },

    saveQuiz() {
      this.validate();
      if (this.createQuizValidation.valid) {
        this.trimEditedQuiz();
        const payload = this.editedQuiz;
        let url = "";
        if (this.editedIndex > -1) {
          url += UPDATE_QUIZ;
        } else {
          url += CREATE_QUIZ;
        }
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createQuizValidation.valid = false;
            this.editedIndex = -1;
            this.close();
            if (this.editedIndex > -1) {
              this.setApiSuccess("Quiz updated successfully");
            } else {
              this.setApiSuccess("Quiz created successfully");
            }
            this.getAllQuiz();
            this.setOverlayValue(false);
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
