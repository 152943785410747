<template>
  <v-layout class="rounded rounded-md">
    <AppBarAuth />
    <v-menu v-model="fab" offset-x top offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
          v-on="on"
          class="fixed-btn"
        >
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-img
            v-else
            max-height="40"
            max-width="40"
            src="../assets/Icons/Group_227.svg"
          ></v-img>
        </v-btn>
      </template>
      <ChatBox></ChatBox>
    </v-menu>
    <v-app-bar flat dark app clipped-left height="50" color="primary">
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == true"
        >mdi-menu-open</v-icon
      >
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == false"
        >mdi-menu-close</v-icon
      >
      <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
        courseData.courseName
      }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="showCertificate" text @click="openCertificateDialog()"
        >View Certificate</v-btn
      >
      <v-icon size="30" @click="$router.push('/home/student-courses')"
        >mdi-close-circle</v-icon
      >
    </v-app-bar>
    <v-dialog v-model="certificateDialog" width="50%">
      <v-card>
        <v-toolbar dark dense color="primary">
          <v-toolbar-title style="font-weight: 500; font-size: 24px"
            >Course Certificate</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeCertificateDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col class="ma-1">
            <span style="font-size: large">
              You are awarded this certificate for successfully completeing the
              <span style="font-weight: 500">{{
                certificateDetails.courseName
              }}</span>
              course.
            </span>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-col cols="4" class="ml-1 pl-0">
            <span style="font-size: large" class="mr-1"> No of Downloads </span>
            <span style="font-size: large; font-weight: 500; color: #faaf19">{{
              certificateDetails.downloads
            }}</span>
          </v-col>
          <v-col>
            <v-row justify="end">
              <v-btn text color="primary" @click="verifyCertificate()"
                >Verify Certificate</v-btn
              >
              <v-btn text color="primary" @click="downloadCertificate()"
                >Download Certificate</v-btn
              >
            </v-row>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet>
      <v-navigation-drawer
        app
        clipped
        width="22%"
        v-model="drawer"
        style="background-color: #f0fff0"
      >
        <v-list>
          <v-list-group
            :value="isChapterExpanded(chapter)"
            v-for="chapter in courseData.chapters"
            :key="chapter.chapterNo"
            prepend-icon="$expand"
            sub-group
            class="ml-n6"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content class="ml-n7">
                  <v-list-item-title
                    style="font-size: 20px; font-weight: 500"
                    >{{ chapter.chapterName }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="
                      courseData.isForUK == true &&
                      chapter.completed != null &&
                      chapter.completed != 0
                    "
                    :color="chapter.completed == 100 ? 'success' : ''"
                    size="20"
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    v-if="
                      courseData.isForUK == true &&
                      (chapter.completed == null || chapter.completed == 0)
                    "
                    size="20"
                    >mdi-check</v-icon
                  >
                </v-list-item-icon>
              </v-list-item>
            </template>
            <v-list-group
              :value="isLessonExpanded(lesson)"
              v-for="lesson in chapter.lessons"
              :key="lesson.lessonNo"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title
                    style="font-size: 18px; color: #ff6666"
                    class="text-wrap"
                    >{{ lesson.lessonName }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon
                    v-if="
                      courseData.isForUK == true &&
                      lesson.completed != null &&
                      lesson.completed != 0
                    "
                    :color="lesson.completed == 100 ? 'success' : ''"
                    size="20"
                    >mdi-check-all</v-icon
                  >
                  <v-icon
                    v-if="
                      courseData.isForUK == true &&
                      (lesson.completed == null || lesson.completed == 0)
                    "
                    size="20"
                    >mdi-check</v-icon
                  >
                </v-list-item-icon>
              </template>
              <v-list-item-group
                :value="true"
                v-if="lesson.content.length != 0 && courseData.isForUK == true"
                :active-class="'border'"
              >
                <v-list-item
                  v-for="content in lesson.content"
                  :key="content.contentNo"
                  :disabled="
                    courseData.isForUK == true &&
                    (content.source == 'Content locked' ||
                      content.locked != false)
                  "
                  :ref="
                    contentRef(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      content.contentNo
                    )
                  "
                  class="ml-3"
                  link
                  @click="
                    showContent(
                      content,
                      chapter.chapterNo,
                      lesson.lessonNo,
                      lesson.classOpId
                    )
                  "
                  :input-value="
                    highLightCurrentContent(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      content.contentNo,
                      content.assignmentNumber,
                      content.contentType
                    )
                      ? 'border'
                      : ''
                  "
                >
                  <v-list-item-icon>
                    <v-icon size="20" color="black">
                      {{ `mdi-${getContentIcon(content)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-list-item-title
                      style="font-size: 14px"
                      class="text-wrap"
                    >
                      {{ content.title || content.quizName }}
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-if="
                        courseData.isForUK == true && getMdiCheckAll(content)
                      "
                      :color="
                        content.completed == 100 ||
                        (content.submissions &&
                          content.submissions.length > 0) ||
                        (content.progress &&
                          content.progress.length > 0 &&
                          content.progress[0].completed == 100)
                          ? 'success'
                          : ''
                      "
                      size="20"
                      >mdi-check-all</v-icon
                    >
                    <v-icon
                      v-if="courseData.isForUK == true && getMdiCheck(content)"
                      size="20"
                      >mdi-check</v-icon
                    >
                    <v-icon
                      v-if="
                        courseData.isForUK == true &&
                        content.source == 'Content locked'
                      "
                      size="20"
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
              <v-list-item v-if="lesson.notes && lesson.notes.length != 0">
                <v-list-item-title
                  class="ml-n8"
                  style="font-size: 16px; font-weight: 500; color: #00308f"
                  >Notes</v-list-item-title
                >
              </v-list-item>
              <v-list-item-group
                :value="true"
                v-if="lesson.notes && lesson.notes.length != 0"
                :active-class="'border'"
              >
                <v-list-item
                  v-for="note in lesson.notes"
                  :key="note.NoteNo"
                  link
                  class="ml-3"
                  :value="note"
                  :disabled="
                    courseData.isForUK == true && note.source == 'Notes locked'
                  "
                  :input-value="
                    highLightCurrentNote(
                      chapter.chapterNo,
                      lesson.lessonNo,
                      note.NoteNo
                    )
                      ? 'border'
                      : ''
                  "
                  @click="showNotes(note, chapter.chapterNo, lesson.lessonNo)"
                >
                  <v-list-item-icon>
                    <v-icon size="20" color="black">
                      {{ `mdi-${getNoteIcon(note)}` }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content class="ml-n3">
                    <v-list-item-title
                      style="font-size: 14px"
                      class="text-wrap"
                      >{{ note.title }}</v-list-item-title
                    >
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon
                      v-if="
                        courseData.isForUK == true &&
                        note.source == 'Notes locked'
                      "
                      size="20"
                      >mdi-lock</v-icon
                    >
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>
    <v-main style="overflow: hidden">
      <v-card flat class="overflow-y-auto overflow-x-hidden" height="82vh">
        <div
          v-if="
            currentActiveElement.source != 'Notes locked' &&
            currentActiveElement.source != 'Content locked'
          "
        >
          <!-- <v-row
            justify="center"
            v-if="
              currentActiveElement.source != 'Content locked' &&
              currentActiveElement.type == CONTENT_TYPE_TEXT
            "
          >
            <CourseTextView :htmlContent="currentActiveElement.source" />
          </v-row> -->
          <v-row
            justify="center"
            v-if="
              ((currentActiveElement.source != 'Content locked' ||
                currentActiveElement.source != 'Notes locked') &&
                currentActiveElement.type == CONTENT_TYPE_TEXT) ||
              ((currentActiveElement.source != 'Notes locked' ||
                currentActiveElement.source != 'Content locked') &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status == 'BLOCKED')
            "
          >
            <CourseTextView :htmlContent="currentActiveElement.source" />
          </v-row>

          <!-- Video player will be shown if content.resource.mimeType contains 'video' string -->
          <v-card
            flat
            v-if="
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                currentActiveElement.resource?.mimeType?.includes('video')) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                currentActiveElement.resource?.mimeType?.includes('video'))
            "
          >
            <VideoPlayer
              :options="{
                autoplay: true,
                controls: true,
                sources: [
                  {
                    src: `${
                      currentActiveElement.source || currentActiveElement.source
                    }`,
                    type: 'video/mp4',
                  },
                ],
              }"
              :key="currentActiveElement.title || currentActiveElement.title"
            ></VideoPlayer>
          </v-card>
          <!-- Vue friendly Iframe will be shown if 
            content.resource.mimeType  does not contain 'video' string -->
          <div
            v-if="
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                !currentActiveElement.resource?.mimeType?.includes('video')) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                !currentActiveElement.resource?.mimeType?.includes('video'))
            "
          >
            <ImageArray
              v-if="
                imagesArray &&
                !currentActiveElement.resource?.mimeType?.includes('pdf') &&
                currentActiveElement.type &&
                currentActiveElement.resource?.status != 'BLOCKED'
              "
              :title="currentActiveElement.title"
              :images="currentActiveElement.images"
            />
            <CourseMediaView
              v-else
              :src="
                (currentActiveElement.resource?.mimeType?.includes('pdf')
                  ? currentActiveElement.source + '#toolbar=0'
                  : currentActiveElement.source) ||
                (currentActiveElement.resource?.mimeType?.includes('pdf')
                  ? currentActiveElement.source + '#toolbar=0'
                  : currentActiveElement.source)
              "
            />
          </div>
          <div
            v-if="
              currentActiveElement.source != 'Content locked' &&
              currentActiveElement.type == CONTENT_TYPE_CODELY
            "
          >
            <CourseCodelyContent :src="currentActiveElement.source" />
          </div>
          <v-row
            v-if="
              currentActiveElement.source != 'Content locked' &&
              currentActiveElement.type == CONTENT_TYPE_QUIZ
            "
            justify="center"
            class="pt-8"
          >
            <v-card flat width="90%">
              <StudentQuizes
                v-bind:content="currentActiveElement"
                v-bind:chapterNo="selectedChapterNo"
                v-bind:lessonNo="selectedLessonNo"
                v-bind:courseId="courseData.courseId"
                v-bind:studentCourseProgress="studentCourseProgress"
                :key="currentActiveElement.contentNo"
                @questionCompleted="questionUpdate"
              />
            </v-card>
          </v-row>
          <v-row
            justify="center"
            class="pt-8"
            v-if="showQuizAssignment == true"
          >
            <v-card flat width="90%">
              <QuizAssignment
                :key="assignmentNumber"
                v-bind:classOpId="classOpId"
                v-bind:quizAssignment="currentActiveElement"
                @assignmentQuestionCompleted="updateQuizAssignmentProgress"
              />
            </v-card>
          </v-row>
          <div v-if="showCodeAssignment == true">
            <v-row
              class="pa-2"
              justify="center"
              v-if="
                showCodeAssignment == true &&
                currentActiveElement.submissions.length == 0
              "
            >
              <v-card flat width="100%">
                <PracticeCoding
                  :key="currentActiveElement.assignmentNumber"
                  v-bind:classOpId="classOpId"
                  v-bind:assignment="currentActiveElement"
                  v-bind:isCompleteAssignment="true"
                  @assignmentCompleted="
                    getClassOpById(classOpId, 'codeAssignment')
                  "
                />
              </v-card>
            </v-row>
            <v-row justify="center" v-else>
              <CourseCodeAssignmentView
                :key="currentActiveElement.assignmentNumber"
                :assignment="currentActiveElement"
              />
            </v-row>
          </div>
          <div v-if="showCodelyAssignment == true">
            <CourseCodelyAssignmentView
              v-if="showCodelyAssignment == true"
              :key="currentActiveElement.assignmentNumber"
              :classOpId="classOpId"
              :assignment="currentActiveElement"
              @assignmentCompleted="
                getClassOpById(classOpId, 'codelyAssignment')
              "
            />
          </div>

          <div v-if="showTextAssignment == true">
            <v-row justify="center">
              <v-card flat class="pt-8" width="90%">
                <CourseTextAssignmentView
                  v-if="showTextAssignment == true"
                  :key="currentActiveElement.assignmentNumber"
                  :classOpId="classOpId"
                  :assignment="currentActiveElement"
                  @assignmentCompleted="
                    getClassOpById(classOpId, 'textAssignment')
                  "
                />
                <!-- do not call getall , call getClassopbyid -->
              </v-card>
            </v-row>
          </div>
        </div>
        <div v-else>
          <v-col align-self="center">
            <v-row
              class="ma-8"
              v-if="courseData.isForUK == true"
              flat
              justify="center"
              ><v-icon>mdi-lock-outline</v-icon>
              <span
                style="font-size: 18px"
                class="ma-2"
                v-if="currentActiveElement.source != 'Notes locked'"
                >Content will be unlocked after the class starts</span
              ><span style="font-size: 18px" class="ma-2" v-else
                >Notes will be unlocked after the class ends</span
              ></v-row
            >
            <v-row class="ma-8" v-else flat justify="center"
              ><v-icon>mdi-lock-outline</v-icon>
              <span style="font-size: 18px" class="ma-2"
                >Notes will be unlocked after the class is over</span
              ></v-row
            >
          </v-col>
        </div>
        <!-- </v-container> -->
      </v-card>
      <!-- </v-flex> -->
      <v-card flat style="height: 6%">
        <v-row
          :style="
            drawer == true
              ? 'justify-content: center;'
              : 'justify-content: space-between;'
          "
        >
          <v-col cols="1" class="pa-0 ma-1 ml-6">
            <v-btn
              :disabled="disablePreviousButton"
              class="ml-2 mr-2"
              color="success"
              label="<<Pre"
              @click="goToPrevious()"
              >« Pre</v-btn
            >
          </v-col>
          <v-col cols="1" class="pa-0 ma-1">
            <v-btn
              :disabled="disableNextButton || loading"
              :loading="loading"
              class="ml-2"
              color="success"
              @click="goToNext()"
              >Next »</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-main>
  </v-layout>
</template>

<script>
import {
  BASE_URL,
  GET_STUDENT_COURSE_PROGRESS,
  GET_STUDENT_LESSON_PROGRESS,
  GET_ALL_CLASS_OPERATIONS,
  GET_COURSE_BY_ID,
  GET_CLASS_OPERATIONS_BY_ID,
  SEARCH_CERTIFICATE,
  DOWNLOAD_CERTIFICATE,
  VERIFY_CERTIFICATE,
} from "@/common/apiEndpoints.js";
import {
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
  CLASS_STATUS_COMPLETED,
} from "@/common/constants";
import Vue from "vue";
import AppBarAuth from "./AppBarAuth.vue";
import StudentQuizes from "@/components/StudentQuizes.vue";
import QuizAssignment from "@/components/QuizAssignment.vue";
import CourseCodelyAssignmentView from "@/components/CourseViewComponents/CourseCodelyAssignmentView.vue";
import CourseCodeAssignmentView from "@/components/CourseViewComponents/CourseCodeAssignmentView.vue";
import CourseTextView from "@/components/CourseViewComponents/CourseTextView.vue";
import CourseTextAssignmentView from "@/components/CourseViewComponents/CourseTextAssignmentView.vue";
import CourseCodelyContent from "@/components/CourseViewComponents/CourseCodelyContent.vue";
import CourseMediaView from "@/components/CourseViewComponents/CourseMediaView.vue";
import PracticeCoding from "@/components/PracticeCoding.vue";

import VideoPlayer from "./VideoPlayer.vue";
import ChatBox from "./ChatBox.vue";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import ImageArray from "./ImageArray2.vue";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
export default Vue.extend({
  data() {
    return {
      loading: false,
      courseId: "",
      classRoomId: "",
      studentCourses: [],
      studentCourseProgress: [],
      drawer: true,
      disableNextButton: false,
      disablePreviousButton: false,
      courseData: {},
      content: {},
      note: {},
      showQuizAssignment: false,
      showTextAssignment: false,
      showCodeAssignment: false,
      showCodelyAssignment: false,
      showCertificate: false,
      certificateDialog: false,
      selectedChapterNo: "",
      selectedLessonNo: "",
      classOpId: "",
      assignmentNumber: "",
      textAssignmentSubmission: "",
      codelyAssignmentSubmission: "",
      showDescArr: [],
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      fab: false,
      top: false,
      left: false,
      currentActiveElement: {},
      certificateDetails: {},
      lastCompletedContent: {
        chapterNo: 1,
        lessonNo: 1,
        contentNo: 1,
        content: {},
      },
      ukCourseContentArray: [],
      imagesArray: false,
    };
  },
  components: {
    AppBarAuth,
    QuizAssignment,
    StudentQuizes,
    VideoPlayer,
    ChatBox,
    CourseCodelyAssignmentView,
    CourseCodeAssignmentView,
    CourseCodelyContent,
    CourseMediaView,
    CourseTextAssignmentView,
    CourseTextView,
    PracticeCoding,
    ImageArray,
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    if (this.paramsValue.courseData) {
      this.courseData = JSON.parse(JSON.stringify(this.paramsValue.courseData));
      this.courseId = this.paramsValue.courseData.courseId;
      this.classRoomId = this.paramsValue.courseData.classRoomId;
      this.courseOpen();
    }
    // setTimeout(() => {
    //   this.scrollToLastActiveContent();
    // }, 1000);
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    isChapterExpanded(chapter) {
      if (this.courseData.completed == 100) {
        return true;
      }
      if (chapter.completed == 100) {
        return false;
      }
      return true;
    },
    isLessonExpanded(lesson) {
      if (this.courseData.completed == 100) {
        return true;
      }
      if (lesson.completed == 100) {
        return false;
      }
      return true;
    },
    openCertificateDialog() {
      this.searchCertificate();
    },
    closeCertificateDialog() {
      this.certificateDialog = false;
    },
    searchCertificate() {
      const url = SEARCH_CERTIFICATE;
      const payload = {};
      payload.courseProgressId =
        this.studentCourseProgress[0].progress.progressId;
      payload.type = "course";
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          if (res) {
            this.certificateDetails = res.data;
            this.certificateDialog = true;
          }
        })
        .catch((error) => {
          this.setApiError("Certificate is not generated.");
          this.setOverlayValue(false);
        });
    },
    verifyCertificate() {
      var url = BASE_URL + VERIFY_CERTIFICATE;
      url = url + '?secureKey='+this.certificateDetails.certId;
      window.open(url);
      // axios
      //   .get(url, {
      //     params: {
      //       secureKey: certId,
      //     },
      //     responseType: "text",
      //     ...getAxiosOptions(BASE_URL, this.token),
      //   })
      //   .then((res) => {
      //     this.closeCertificateDialog();
      //     const htmlContent = res.data;
      //     const newWindow = window.open();
      //     newWindow.document.open();
      //     newWindow.document.write(htmlContent);
      //     newWindow.document.close();
      //   })
      //   .catch((error) => {
      //     error = error.errorMessage;
      //     this.closeCertificateDialog();
      //   });
    },
    downloadCertificate() {
      const url = DOWNLOAD_CERTIFICATE;
      const payload = { certId: this.certificateDetails.certId };
      axios
        .post(url, payload, {
          responseType: "arraybuffer",
          ...getAxiosOptions(BASE_URL, this.token),
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(blob);
          this.closeCertificateDialog();
          window.open(pdfUrl, "_blank");
        })
        .catch((error) => {
          console.error(error);
          this.setOverlayValue(false);
        });
    },
    // getCourseById() {
    //   const url = GET_COURSE_BY_ID;
    //   const payload = {};
    //   payload.courseId = this.courseId;
    //   axios
    //     .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.courseData = res.data;
    //       this.courseOpen();
    //       setTimeout(() => {
    //         this.scrollToLastActiveContent();
    //       }, 1000);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //     });
    // },
    highLightCurrentContent(
      chapterNo,
      lessonNo,
      contentNo,
      assignmentNumber,
      contentType
    ) {
      if (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        contentType
      ) {
        if (contentType == "content") {
          return contentNo == this.currentActiveElement.contentNo;
        } else if (contentType == this.currentActiveElement.contentType) {
          return assignmentNumber == this.currentActiveElement.assignmentNumber;
        }
      }
    },
    highLightCurrentNote(chapterNo, lessonNo, NoteNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        NoteNo == this.currentActiveElement.NoteNo
      );
    },
    getMdiCheckAll(content) {
      return (
        this.courseData.isForUK == true &&
        ((content.contentType &&
          content.contentType == "content" &&
          content.completed == 100 &&
          content.source != "Content locked") ||
          (content.contentType &&
            content.contentType.includes("assignment") &&
            ((content.submissions && content.submissions.length != 0) ||
              (content.progress &&
                content.progress.length != 0 &&
                content.progress[0].completed == 100))))
      );
    },
    getMdiCheck(content) {
      return (
        (content.locked != false &&
          content.source != "Content locked" &&
          content.contentType == "content" &&
          content.completed != 100) ||
        (content.contentType &&
          content.contentType.includes("assignment") &&
          !(
            (content.progress &&
              content.progress.length != 0 &&
              content.progress[0].completed == 100) ||
            (content.submissions && content.submissions.length != 0)
          ))
      );
    },
    getContentIcon(content) {
      if (content.contentType == "content") {
        if (content.type == CONTENT_TYPE_QUIZ) {
          return "lightbulb-on-10";
        } else if (content.type == CONTENT_TYPE_TEXT) {
          return "text-box-outline";
        } else if (content.type == CONTENT_TYPE_CODELY) {
          return "code-block-braces";
        } else if (content.type == CONTENT_TYPE_MEDIA) {
          if (
            content.resource?.mimeType &&
            content.resource?.mimeType.includes("video")
          ) {
            return "video";
          } else if (
            content.resource?.mimeType &&
            content.resource?.mimeType.includes("image")
          ) {
            return "image";
          } else if (
            content.resource?.mimeType &&
            content.resource?.mimeType.includes("pdf")
          ) {
            return "file-pdf-box";
          } else {
            return "table-of-contents";
          }
        }
      } else if (content.contentType == "codeassignment") {
        return "code-array";
      } else if (content.contentType == "textassignment") {
        return "text-box-edit-outline";
      } else if (content.contentType == "quizassignment") {
        return "lightbulb-on-50";
      } else if (content.contentType == "codelyassignment") {
        return "code-block-braces";
      }
    },
    getNoteIcon(note) {
      if (note.type == CONTENT_TYPE_MEDIA) {
        if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("video")
        ) {
          return "video";
        } else if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("image")
        ) {
          return "image";
        } else if (
          note.resource?.mimeType &&
          note.resource?.mimeType.includes("pdf")
        ) {
          return "file-pdf-box";
        } else {
          return "table-of-contents";
        }
      } else {
        return "text-box-outline";
      }
    },
    courseOpen() {
      this.getAllClassOps(this.classRoomId, true);
    },
    scrollToLastActiveContent() {
      var activeContentFound = false;
      if (this.courseData.isForUK == true) {
        if (this.courseData.chapters[0].lessons[0].content.length == 0) {
          this.setApiError("There is no Content in the Course");
        } else {
          this.courseData.chapters.forEach((chapter) => {
            // Iterate through lessons

            chapter.lessons.forEach((lesson) => {
              // Iterate through content

              lesson.content.forEach((content) => {
                // Check if content is completed and has value 100
                if (
                  content.contentType == "content" &&
                  content.completed == 100
                ) {
                  // Update lastCompletedContent if this content is the latest completed one
                  this.lastCompletedContent.chapterNo = chapter.chapterNo;
                  this.lastCompletedContent.lessonNo = lesson.lessonNo;
                  this.lastCompletedContent.content = content;
                  activeContentFound = true;
                } else if (
                  content.contentType == "quizassignment" &&
                  content.progress &&
                  content.progress.length > 0 &&
                  content.progress[0].completed == 100
                ) {
                  this.lastCompletedContent.chapterNo = chapter.chapterNo;
                  this.lastCompletedContent.lessonNo = lesson.lessonNo;
                  this.lastCompletedContent.content = content;
                  activeContentFound = true;
                } else if (
                  content.contentType.includes("assignment") &&
                  content.contentType != "quizassignment" &&
                  content.submissions &&
                  content.submissions.length > 0
                ) {
                  this.lastCompletedContent.chapterNo = chapter.chapterNo;
                  this.lastCompletedContent.lessonNo = lesson.lessonNo;
                  this.lastCompletedContent.content = content;
                  activeContentFound = true;
                }
              });
            });
          });
          if (activeContentFound) {
            this.showContent(
              this.lastCompletedContent.content,
              this.lastCompletedContent.chapterNo,
              this.lastCompletedContent.lessonNo,
              this.lastCompletedContent.content.classOpId
            );
          } else {
            this.showContent(
              this.courseData.chapters[0].lessons[0].content[0],
              1,
              1,
              this.courseData.chapters[0].lessons[0].content[0].classOpId
            );
          }
          const contentRef =
            this.$refs[
              `contentref_${this.lastCompletedContent.chapterNo}_${this.lastCompletedContent.lessonNo}_${this.lastCompletedContent.contentNo}`
            ];
          if (contentRef) {
            contentRef[0].$el.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }
      }
    },
    contentRef(chapterNo, lessonNo, contentNo) {
      return `contentref_${chapterNo}_${lessonNo}_${contentNo}`;
    },
    getClassOpById(classOpId, assignmentType) {
      const url = GET_CLASS_OPERATIONS_BY_ID;
      this.setOverlayValue(true);
      const payload = {};
      payload.classOpId = classOpId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          var classOp = res.data;
          if (classOp.topicCovered.length > 0) {
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].textAssignments =
              Object.assign([], classOp.textAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].codeAssignments =
              Object.assign([], classOp.codeAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].codelyAssignments =
              Object.assign([], classOp.codelyAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].quizAssignments =
              Object.assign([], classOp.quizAssignments);
            this.courseData.chapters[
              classOp.topicCovered[0].chapterNo - 1
            ].lessons[classOp.topicCovered[0].lessonNo - 1].classOpId =
              classOp.classOpId;
          }
          if (
            assignmentType == "textAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.textAssignments.find(
                (txtAsgnmnt) =>
                  txtAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          if (
            assignmentType == "codeAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.codeAssignments.find(
                (codeAsgnmnt) =>
                  codeAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          if (
            assignmentType == "codelyAssignment" &&
            classOpId == this.classOpId
          ) {
            this.currentActiveElement.submissions =
              classOp.codelyAssignments.find(
                (codelyAsgnmnt) =>
                  codelyAsgnmnt.assignmentNumber == this.assignmentNumber
              ).submissions;
          }
          this.checkForCompletion();
          this.checkForDisableNext();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getAllClassOps(classRoomId, onCourseOpen) {
      if (classRoomId) {
        let url = GET_ALL_CLASS_OPERATIONS;
        this.setOverlayValue(true);
        let payload = {};
        payload.classRoomId = classRoomId;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            res.data.classOperations.forEach((classOp) => {
              if (classOp.topicCovered.length > 0) {
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].textAssignments = Object.assign([], classOp.textAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].codeAssignments = Object.assign([], classOp.codeAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].codelyAssignments = Object.assign(
                  [],
                  classOp.codelyAssignments
                );
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[
                  classOp.topicCovered[0].lessonNo - 1
                ].quizAssignments = Object.assign([], classOp.quizAssignments);
                this.courseData.chapters[
                  classOp.topicCovered[0].chapterNo - 1
                ].lessons[classOp.topicCovered[0].lessonNo - 1].classOpId =
                  classOp.classOpId;
              }
              if (
                onCourseOpen &&
                (this.courseData.isForUK == false ||
                  this.courseData.isForUK == null)
              ) {
                var classId = this.courseData.chapters[0].lessons[0].classOpId;
                this.selectedChapterNo = 1;
                this.selectedLessonNo = 1;
                if (this.courseData.chapters[0].lessons[0].notes.length == 0) {
                  this.setApiError("There are no Notes in the Course");
                } else if (
                  this.courseData.chapters[0].lessons[0].notes.length > 0
                ) {
                  this.showNotes(
                    this.courseData.chapters[0].lessons[0].notes[0],
                    1,
                    1
                  );
                  this.disablePreviousButton = true;
                } else if (
                  this.courseData.chapters[0].lessons[0].notes[0].source ==
                  "Notes locked"
                ) {
                  this.disablePreviousButton = true;
                  this.disableNextButton = true;
                  this.showNotes(
                    this.courseData.chapters[0].lessons[0].notes[0],
                    1,
                    1
                  );
                } else if (
                  this.courseData.chapters[0].lessons[0].codeAssignments
                    .length > 0
                ) {
                  this.showAssignment(
                    this.courseData.chapters[0].lessons[0].codeAssignments[0],
                    "CODE",
                    classId,
                    1,
                    1
                  );
                  this.disablePreviousButton = true;
                } else if (
                  this.courseData.chapters[0].lessons[0].codelyAssignments
                    .length > 0
                ) {
                  this.showAssignment(
                    this.courseData.chapters[0].lessons[0].codelyAssignments[0],
                    "CODELY",
                    classId,
                    1,
                    1
                  );
                  this.disablePreviousButton = true;
                } else if (
                  this.courseData.chapters[0].lessons[0].quizAssignments
                    .length > 0
                ) {
                  this.showAssignment(
                    this.courseData.chapters[0].lessons[0].quizAssignments[0],
                    "QUIZ",
                    classId,
                    1,
                    1
                  );
                  this.disablePreviousButton = true;
                } else if (
                  this.courseData.chapters[0].lessons[0].textAssignments
                    .length > 0
                ) {
                  this.showAssignment(
                    this.courseData.chapters[0].lessons[0].textAssignments[0],
                    "TEXT",
                    classId,
                    1,
                    1
                  );
                  this.disablePreviousButton = true;
                }
              }
            });
            if (onCourseOpen && this.courseData.isForUK == true) {
              this.mergeAssignmentsIntoContnet();
              this.fetchCourseProgress(this.courseData.courseId);
            }
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    mergeAssignmentsIntoContnet() {
      this.ukCourseContentArray = [];
      for (var c = 0; c < this.courseData.chapters.length; c++) {
        for (var l = 0; l < this.courseData.chapters[c].lessons.length; l++) {
          var contentArray = [];
          for (
            var cnt = 0;
            cnt < this.courseData.chapters[c].lessons[l].content.length;
            cnt++
          ) {
            contentArray.push(
              this.courseData.chapters[c].lessons[l].content[cnt]
            );
            contentArray[contentArray.length - 1].contentType = "content";
            contentArray[contentArray.length - 1].chapterNo = c + 1;
            contentArray[contentArray.length - 1].lessonNo = l + 1;
            for (
              var ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].codeAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].codeAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].codeAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "codeassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
            for (
              ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].quizAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].quizAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].quizAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "quizassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
            for (
              ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].textAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].textAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].textAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "textassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
            for (
              ca = 0;
              ca <
              this.courseData.chapters[c].lessons[l].codelyAssignments.length;
              ca++
            ) {
              if (
                cnt + 1 ==
                this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
                  .afterContent
              ) {
                contentArray.push(
                  this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
                );
                contentArray[contentArray.length - 1].contentType =
                  "codelyassignment";
                contentArray[contentArray.length - 1].chapterNo = c + 1;
                contentArray[contentArray.length - 1].lessonNo = l + 1;
                contentArray[contentArray.length - 1].classOpId =
                  this.courseData.chapters[c].lessons[l].classOpId;
              }
            }
          }
          var homeworkContentArray = [];
          homeworkContentArray = this.mergeHomeWorkAssignments(c, l);
          contentArray = [...contentArray, ...homeworkContentArray];
          this.courseData.chapters[c].lessons[l].content = [];
          this.courseData.chapters[c].lessons[l].content = Object.assign(
            [],
            contentArray
          );
          this.ukCourseContentArray = [
            ...this.ukCourseContentArray,
            ...contentArray,
          ];
          if (this.courseData.chapters[c].lessons[l].notes.length > 0)
            this.courseData.chapters[c].lessons[l].notes.forEach((note) => {
              note.contentType = "note";
              note.chapterNo = c + 1;
              note.lessonNo = l + 1;
              this.ukCourseContentArray.push(note);
            });
        }
      }
    },
    mergeHomeWorkAssignments(c, l) {
      var homeworkContentArray = [];
      for (
        var ca = 0;
        ca < this.courseData.chapters[c].lessons[l].codeAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].codeAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].codeAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].codeAssignments[ca].isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].codeAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "codeassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }
      for (
        ca = 0;
        ca < this.courseData.chapters[c].lessons[l].quizAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].quizAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].quizAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].quizAssignments[ca].isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].quizAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "quizassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }
      for (
        ca = 0;
        ca < this.courseData.chapters[c].lessons[l].textAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].textAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].textAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].textAssignments[ca].isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].textAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "textassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }
      for (
        ca = 0;
        ca < this.courseData.chapters[c].lessons[l].codelyAssignments.length;
        ca++
      ) {
        if (
          this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            .afterContent == undefined ||
          this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            .afterContent >
            this.courseData.chapters[c].lessons[l].content.length ||
          this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
            .isHomework
        ) {
          homeworkContentArray.push(
            this.courseData.chapters[c].lessons[l].codelyAssignments[ca]
          );
          homeworkContentArray[homeworkContentArray.length - 1].contentType =
            "codelyassignment";
          homeworkContentArray[homeworkContentArray.length - 1].chapterNo =
            c + 1;
          homeworkContentArray[homeworkContentArray.length - 1].lessonNo =
            l + 1;
          homeworkContentArray[homeworkContentArray.length - 1].classOpId =
            this.courseData.chapters[c].lessons[l].classOpId;
        }
      }

      return homeworkContentArray;
    },
    fetchCourseProgress(courseId) {
      let url = GET_STUDENT_COURSE_PROGRESS;
      let payload = {};
      payload.courseIds = [];
      payload.courseIds.push(courseId);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourseProgress = res.data.progresses;
          this.checkForCompletion();
          this.scrollToLastActiveContent();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },

    showContent(content, chapterNo, lessonNo, classOpId) {
      if (content.contentType == "content") {
        this.showActualContent(content, chapterNo, lessonNo);
      } else if (content.contentType == "codeassignment") {
        this.showAssignment(content, "CODE", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "quizassignment") {
        this.showAssignment(content, "QUIZ", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "textassignment") {
        this.showAssignment(content, "TEXT", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "codelyassignment") {
        this.showAssignment(content, "CODELY", classOpId, chapterNo, lessonNo);
      } else if (content.contentType == "note") {
        this.showNotes(content, chapterNo, lessonNo);
      }
      this.checkForDisableNext();
    },
    showActualContent(content, chapterNo, lessonNo) {
      this.currentActiveElement = content;
      if (content.images && content.images.length > 0) {
        this.imagesArray = true;
      } else {
        this.imagesArray = false;
      }
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      // content.value = true;
      this.showQuizAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.showTextAssignment = false;
      if (
        (this.currentActiveElement.type == CONTENT_TYPE_QUIZ &&
          this.currentActiveElement.completed != 100) ||
        this.currentActiveElement.source == "Content locked"
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
      // this.checkForCompletion();
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      if (
        this.currentActiveElement.chapterNo == 1 &&
        this.currentActiveElement.lessonNo == 1 &&
        this.currentActiveElement.contentNo == 1
      ) {
        this.disablePreviousButton = true;
      } else {
        this.disablePreviousButton = false;
      }
    },
    lessonProgress() {
      let url = GET_STUDENT_LESSON_PROGRESS;
      let payload = {};
      payload.courseId = this.courseData.courseId;
      payload.lessonNo = Number(this.selectedLessonNo);
      payload.chapterNo = Number(this.selectedChapterNo);
      payload.contentNo = Number(this.currentActiveElement.contentNo);
      this.loading = true;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.loading = false;
          this.studentCourseProgress[0].progress = res.data;
          this.checkForCompletion();
          // this.goToNext();
        })
        .catch((error) => {
          this.loading = false;
          error = error.errorMessage;
        });
    },
    showNotes(note, chapterNo, lessonNo) {
      this.currentActiveElement = note;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.showQuizAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.showTextAssignment = false;
      if (note.source == "Notes locked") {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      if (
        this.selectedChapterNo == 1 &&
        this.selectedLessonNo == 1 &&
        (this.content.length == 0 || this.courseData.isForUK == false) &&
        note.NoteNo == 1
      ) {
        this.disablePreviousButton = true;
      } else {
        this.disablePreviousButton = false;
      }
    },
    showAssignment(assignment, assignmentType, classOpId, chapterNo, lessonNo) {
      this.assignmentNumber = assignment.assignmentNumber;
      this.classOpId = classOpId;
      this.currentActiveElement = assignment;
      if (assignmentType == "QUIZ") {
        this.showQuizAssignment = true;
        this.showCodeAssignment = false;
        this.showCodelyAssignment = false;
        this.showTextAssignment = false;
        if (
          this.currentActiveElement.progress &&
          this.currentActiveElement.progress.length > 0 &&
          this.currentActiveElement.progress[0].completed == 100
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else if (assignmentType == "TEXT") {
        this.showCodeAssignment = false;
        this.showCodelyAssignment = false;
        this.showQuizAssignment = false;
        this.showTextAssignment = true;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else if (assignmentType == "CODELY") {
        this.showTextAssignment = false;
        this.showQuizAssignment = false;
        this.showCodelyAssignment = true;
        this.showCodeAssignment = false;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      } else {
        this.showTextAssignment = false;
        this.showQuizAssignment = false;
        this.codelyAssignment = false;
        this.showCodeAssignment = true;
        this.showCodelyAssignment = false;
        if (
          this.currentActiveElement.submissions &&
          this.currentActiveElement.submissions.length > 0
        ) {
          this.disableNextButton = false;
        } else {
          this.disableNextButton = true;
        }
      }
      this.selectedChapterNo = chapterNo;
      this.selectedLessonNo = lessonNo;
      this.disablePreviousButton = false;
    },
    checkForCompletion() {
      if (
        this.studentCourseProgress[0].progress &&
        this.studentCourseProgress[0].progress.completed == 100
      ) {
        this.showCertificate = true;
      }
      if (this.studentCourseProgress[0].progress) {
        this.courseData.completed =
          this.studentCourseProgress[0].progress.completed;
        if (this.studentCourseProgress[0].progress.chapters.length > 1) {
          this.studentCourseProgress[0].progress.chapters.sort((c1, c2) => {
            return c1.chapterNo - c2.chapterNo;
          });
        }
        for (
          let i = 0;
          i < this.studentCourseProgress[0].progress.chapters.length;
          i++
        ) {
          var progressChapterNo =
            this.studentCourseProgress[0].progress.chapters[i].chapterNo;
          this.courseData.chapters[progressChapterNo - 1].completed =
            this.studentCourseProgress[0].progress.chapters[i].completed;
          if (
            this.studentCourseProgress[0].progress.chapters[i].lessons.length >
            1
          ) {
            this.studentCourseProgress[0].progress.chapters[i].lessons.sort(
              (l1, l2) => {
                return l1.lessonNo - l2.lessonNo;
              }
            );
          }
          for (
            let j = 0;
            j <
            this.studentCourseProgress[0].progress.chapters[i].lessons.length;
            j++
          ) {
            var progressLessonNo =
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .lessonNo;
            this.courseData.chapters[progressChapterNo - 1].lessons[
              progressLessonNo - 1
            ].completed =
              this.studentCourseProgress[0].progress.chapters[i].lessons[
                j
              ].completed;
            if (
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .contentProgress.length > 1
            ) {
              this.studentCourseProgress[0].progress.chapters[i].lessons[
                j
              ].contentProgress.sort((cp1, cp2) => {
                return cp1.contentNo - cp2.contentNo;
              });
            }
            for (
              let k = 0;
              k <
              this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                .contentProgress.length;
              k++
            ) {
              var contentIndex;
              var progressContentNo =
                this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                  .contentProgress[k].contentNo;
              contentIndex = this.courseData.chapters[
                progressChapterNo - 1
              ].lessons[progressLessonNo - 1].content.findIndex(
                (cnt) =>
                  cnt.contentType == "content" &&
                  cnt.contentNo == progressContentNo
              );
              if (
                this.studentCourseProgress[0].progress.chapters[i].lessons[j]
                  .contentProgress[k].completed == 100
              ) {
                Vue.set(
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo - 1
                  ].content[contentIndex],
                  "locked",
                  false
                );
                if (
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo - 1
                  ].content[contentIndex + 1]
                ) {
                  Vue.set(
                    this.courseData.chapters[progressChapterNo - 1].lessons[
                      progressLessonNo - 1
                    ].content[contentIndex + 1],
                    "locked",
                    false
                  );
                } else if (
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo
                  ] &&
                  this.courseData.chapters[progressChapterNo - 1].lessons[
                    progressLessonNo
                  ].content[0]
                ) {
                  Vue.set(
                    this.courseData.chapters[i].lessons[j + 1].content[0],
                    "locked",
                    false
                  );
                } else if (
                  this.courseData.chapters[progressChapterNo] &&
                  this.courseData.chapters[progressChapterNo].lessons[0] &&
                  this.courseData.chapters[progressChapterNo].lessons[0]
                    .content[0]
                ) {
                  Vue.set(
                    this.courseData.chapters[progressChapterNo].lessons[0]
                      .content[0],
                    "locked",
                    false
                  );
                }
              }
              this.courseData.chapters[progressChapterNo - 1].lessons[
                progressLessonNo - 1
              ].content[contentIndex].completed =
                this.studentCourseProgress[0].progress.chapters[i].lessons[
                  j
                ].contentProgress[k].completed;
            }
          }
        }
      } else if (
        this.courseData &&
        this.courseData.chapters[0] &&
        this.courseData.chapters[0].lessons[0] &&
        this.courseData.chapters[0].lessons[0].content[0]
      ) {
        Vue.set(
          this.courseData.chapters[0].lessons[0].content[0],
          "locked",
          false
        );
        this.disablePreviousButton = true;
      }
      this.checkForCompletionForAssignments();
    },
    checkForCompletionForAssignments() {
      if (
        this.courseData &&
        this.courseData.chapters &&
        this.courseData.chapters.length > 0
      ) {
        for (var i = 0; i < this.courseData.chapters.length; i++) {
          if (this.courseData.chapters[i].lessons.length > 0) {
            for (
              var j = 0;
              j < this.courseData.chapters[i].lessons.length;
              j++
            ) {
              if (this.courseData.chapters[i].lessons[j].content.length > 0) {
                for (
                  var k = 0;
                  k < this.courseData.chapters[i].lessons[j].content.length;
                  k++
                ) {
                  if (
                    this.courseData.chapters[i].lessons[j].content[
                      k
                    ].contentType.includes("assignment")
                  ) {
                    if (
                      this.courseData.chapters[i].lessons[j].content[k]
                        .contentType == "quizassignment"
                    ) {
                      if (
                        this.courseData.chapters[i].lessons[j].content[k]
                          .progress &&
                        this.courseData.chapters[i].lessons[j].content[k]
                          .progress.length > 0 &&
                        this.courseData.chapters[i].lessons[j].content[k]
                          .progress[0].completed == 100
                      ) {
                        Vue.set(
                          this.courseData.chapters[i].lessons[j].content[k],
                          "locked",
                          false
                        );
                        if (
                          this.courseData.chapters[i].lessons[j].content[k + 1]
                        ) {
                          Vue.set(
                            this.courseData.chapters[i].lessons[j].content[
                              k + 1
                            ],
                            "locked",
                            false
                          );
                        } else if (
                          this.courseData.chapters[i].lessons[j + 1] &&
                          this.courseData.chapters[i].lessons[j + 1].content[0]
                        ) {
                          Vue.set(
                            this.courseData.chapters[i].lessons[j + 1]
                              .content[0],
                            "locked",
                            false
                          );
                        } else if (
                          this.courseData.chapters[i + 1] &&
                          this.courseData.chapters[i + 1].lessons[0] &&
                          this.courseData.chapters[i + 1].lessons[0].content[0]
                        ) {
                          Vue.set(
                            this.courseData.chapters[i + 1].lessons[0]
                              .content[0],
                            "locked",
                            false
                          );
                        }
                      }
                    } else if (
                      this.courseData.chapters[i].lessons[j].content[k]
                        .submissions &&
                      this.courseData.chapters[i].lessons[j].content[k]
                        .submissions.length > 0
                    ) {
                      Vue.set(
                        this.courseData.chapters[i].lessons[j].content[k],
                        "locked",
                        false
                      );
                      if (
                        this.courseData.chapters[i].lessons[j].content[k + 1]
                      ) {
                        Vue.set(
                          this.courseData.chapters[i].lessons[j].content[k + 1],
                          "locked",
                          false
                        );
                      } else if (
                        this.courseData.chapters[i].lessons[j + 1] &&
                        this.courseData.chapters[i].lessons[j + 1].content[0]
                      ) {
                        Vue.set(
                          this.courseData.chapters[i].lessons[j + 1].content[0],
                          "locked",
                          false
                        );
                      } else if (
                        this.courseData.chapters[i + 1] &&
                        this.courseData.chapters[i + 1].lessons[0] &&
                        this.courseData.chapters[i + 1].lessons[0].content[0]
                      ) {
                        Vue.set(
                          this.courseData.chapters[i + 1].lessons[0].content[0],
                          "locked",
                          false
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    updateQuizAssignmentProgress(progress) {
      this.currentActiveElement.progress[0] = progress;
      this.checkForCompletion();
      if (this.currentActiveElement.progress[0].completed == 100) {
        this.disableNextButton = false;
      } else {
        this.disableNextButton = true;
      }
    },
    questionUpdate(studentCourseProgress) {
      this.studentCourseProgress = studentCourseProgress;
      this.checkForCompletion();
      // this.currentActiveElement =
      //   this.courseData.chapters[this.selectedChapterNo - 1].lessons[
      //     this.selectedLessonNo - 1
      //   ].content[this.currentActiveElement.contentNo - 1];
      if (this.currentActiveElement.completed == 100) {
        this.disableNextButton = false;
      }
    },
    checkForDisableNext() {
      var index = this.ukCourseContentArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.contentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.contentType == "note" &&
              this.currentActiveElement.NoteNo == content.NoteNo) ||
            (this.currentActiveElement.contentType.includes("assignment") &&
              this.currentActiveElement.contentType == content.contentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (index+1 == this.ukCourseContentArray.length && this.currentActiveElement.contentType != 'content') {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType == "note" &&
        this.ukCourseContentArray[index].source == "Notes locked"
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType == "content" &&
        (this.ukCourseContentArray[index].source == "Content locked" ||
          (this.ukCourseContentArray[index].type == CONTENT_TYPE_QUIZ &&
            this.ukCourseContentArray[index].completed != 100))
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType == "quizassignment" &&
        !(
          this.ukCourseContentArray[index].progress &&
          this.ukCourseContentArray[index].progress[0] &&
          this.ukCourseContentArray[index].progress[0].completed == 100
        )
      ) {
        this.disableNextButton = true;
      } else if (
        this.ukCourseContentArray[index].contentType.includes("assignment") &&
        this.ukCourseContentArray[index].contentType != "quizassignment" &&
        this.ukCourseContentArray[index].submissions &&
        this.ukCourseContentArray[index].submissions.length == 0
      ) {
        this.disableNextButton = true;
      } else {
        this.disableNextButton = false;
      }
    },
    goToNext() {
      var index = this.ukCourseContentArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.contentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.contentType == "note" &&
              this.currentActiveElement.NoteNo == content.NoteNo) ||
            (this.currentActiveElement.contentType.includes("assignment") &&
              this.currentActiveElement.contentType == content.contentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (
        index != -1 &&
        this.currentActiveElement.contentType == "content" &&
        this.currentActiveElement.type != CONTENT_TYPE_QUIZ &&
        this.currentActiveElement.completed != 100
      ) {
        this.lessonProgress();
      }
      if (index < this.ukCourseContentArray.length) {
        var content = this.ukCourseContentArray[index + 1];
        var chapterNo = this.ukCourseContentArray[index + 1].chapterNo;
        var lessonNo = this.ukCourseContentArray[index + 1].lessonNo;
        var classOpId = this.ukCourseContentArray[index + 1].classOpId;
        this.showContent(content, chapterNo, lessonNo, classOpId);
        // this.disableNextButton = false;
        this.disablePreviousButton = false;
      }
      if (index + 1 == this.ukCourseContentArray.length) {
        this.disableNextButton = true;
      }
    },
    goToPrevious() {
      var index = this.ukCourseContentArray.findIndex(
        (content) =>
          this.currentActiveElement.chapterNo == content.chapterNo &&
          this.currentActiveElement.lessonNo == content.lessonNo &&
          ((this.currentActiveElement.contentType == "content" &&
            this.currentActiveElement.contentNo == content.contentNo) ||
            (this.currentActiveElement.contentType == "note" &&
              this.currentActiveElement.NoteNo == content.NoteNo) ||
            (this.currentActiveElement.contentType.includes("assignment") &&
              this.currentActiveElement.contentType == content.contentType &&
              this.currentActiveElement.assignmentNumber ==
                content.assignmentNumber))
      );
      if (index > 0) {
        var content = this.ukCourseContentArray[index - 1];
        var chapterNo = this.ukCourseContentArray[index - 1].chapterNo;
        var lessonNo = this.ukCourseContentArray[index - 1].lessonNo;
        var classOpId = this.ukCourseContentArray[index - 1].classOpId;
        this.showContent(content, chapterNo, lessonNo, classOpId);
        this.disableNextButton = false;
        this.disablePreviousButton = false;
      }
      if (index - 1 == 0) {
        this.disablePreviousButton = true;
      }
    },
    // goToPrevious() {
    //   if (this.courseData && this.content && this.content.contentNo) {
    //     this.goToPreviousContent();
    //   } else if (this.courseData && this.note && this.note.NoteNo) {
    //     this.goToPreviousNote();
    //   } else if (
    //     this.courseData &&
    //     this.codeAssignment &&
    //     this.codeAssignment.assignmentNumber
    //   ) {
    //     this.goToPreviousCodeAssignment();
    //   } else if (
    //     this.courseData &&
    //     this.quizAssignment &&
    //     this.quizAssignment.assignmentNumber
    //   ) {
    //     this.goToPreviousQuizAssignment();
    //   } else if (
    //     this.courseData &&
    //     this.textAssignment &&
    //     this.textAssignment.assignmentNumber
    //   ) {
    //     this.goToPreviousTextAssignment();
    //   } else if (
    //     this.courseData &&
    //     this.codelyAssignment &&
    //     this.codelyAssignment.assignmentNumber
    //   ) {
    //     this.goToPreviousCodelyAssignment();
    //   }
    // },
    // goToPreviousContent() {
    //   if (this.courseData.isForUK && this.content.contentNo > 1) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content[this.content.contentNo - 2],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToPreviousChapterLesson();
    //   }
    // },
    // goToPreviousNote() {
    //   if (this.note.NoteNo > 1) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes[this.note.NoteNo - 2],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.isForUK &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content.length > 0
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToPreviousChapterLesson();
    //   }
    // },
    // goToPreviousCodeAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (this.codeAssignment.assignmentNumber > 1) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments[this.codeAssignment.assignmentNumber - 2],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.isForUK &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content.length > 0
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToPreviousChapterLesson();
    //   }
    // },
    // goToPreviousQuizAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (this.quizAssignment.assignmentNumber > 1) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments[this.quizAssignment.assignmentNumber - 2],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments.slice(-1)[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.isForUK &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content.length > 0
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToPreviousChapterLesson();
    //   }
    // },
    // goToPreviousTextAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (this.textAssignment.assignmentNumber > 1) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[this.textAssignment.assignmentNumber - 2],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments.slice(-1)[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments.slice(-1)[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.isForUK &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content.length > 0
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToPreviousChapterLesson();
    //   }
    // },
    // goToPreviousCodelyAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (this.codelyAssignment.assignmentNumber > 1) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[this.codelyAssignment.assignmentNumber - 2],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments.slice(-1)[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments.slice(-1)[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments.slice(-1)[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.isForUK &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content.length > 0
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToPreviousChapterLesson();
    //   }
    // },
    // goToPreviousChapterLesson() {
    //   var chapterLessonFound = true;
    //   if (this.selectedLessonNo > 1) {
    //     this.selectedLessonNo -= 1;
    //   } else if (this.selectedChapterNo > 1) {
    //     this.selectedChapterNo = Number(this.selectedChapterNo) - 1;
    //     this.selectedLessonNo =
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons.length;
    //   } else {
    //     this.disablePreviousButton = true;
    //     chapterLessonFound = false;
    //   }
    //   if (chapterLessonFound) {
    //     var classId = this.courseData.chapters
    //       .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //       .lessons.find(
    //         (lesson) => lesson.lessonNo == this.selectedLessonNo
    //       ).classOpId;
    //   }
    //   if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments.slice(-1)[0],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments.slice(-1)[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments.slice(-1)[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments.slice(-1)[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.isForUK &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content.length > 0
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content.slice(-1)[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   }
    // },
    // goToNext() {
    //   if (this.courseData && this.content && this.content.contentNo) {
    //     if (
    //       this.content.completed != 100 &&
    //       this.content.type != this.CONTENT_TYPE_QUIZ
    //     ) {
    //       this.lessonProgress();
    //     } else {
    //       this.disablePreviousButton = false;
    //       this.goToNextContent();
    //     }
    //   } else if (this.courseData && this.note && this.note.NoteNo) {
    //     this.goToNextNote();
    //   } else if (
    //     this.courseData &&
    //     this.codeAssignment &&
    //     this.codeAssignment.assignmentNumber
    //   ) {
    //     this.disablePreviousButton = false;
    //     this.goToNextCodeAssignment();
    //   } else if (
    //     this.courseData &&
    //     this.quizAssignment &&
    //     this.quizAssignment.assignmentNumber
    //   ) {
    //     this.disablePreviousButton = false;
    //     this.goToNextQuizAssignment();
    //   } else if (
    //     this.courseData &&
    //     this.textAssignment &&
    //     this.textAssignment.assignmentNumber
    //   ) {
    //     this.disablePreviousButton = false;
    //     this.goToNextTextAssignment();
    //   } else if (
    //     this.courseData &&
    //     this.codelyAssignment &&
    //     this.codelyAssignment.assignmentNumber
    //   ) {
    //     this.disablePreviousButton = false;
    //     this.goToNextCodelyAssignment();
    //   }
    // },
    // goToNextContent() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].content[this.content.contentNo]
    //   ) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content[this.content.contentNo],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[0],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToNextChapterLesson();
    //   }
    // },
    // goToNextNote() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes[this.note.NoteNo]
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes[this.note.NoteNo],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[0],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToNextChapterLesson();
    //   }
    // },
    // goToNextCodeAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments[this.codeAssignment.assignmentNumber]
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codeAssignments[this.codeAssignment.assignmentNumber],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[0],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToNextChapterLesson();
    //   }
    // },
    // goToNextQuizAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments[this.quizAssignment.assignmentNumber]
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].quizAssignments[this.quizAssignment.assignmentNumber],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[0],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToNextChapterLesson();
    //   }
    // },
    // goToNextTextAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments[this.textAssignment.assignmentNumber]
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[this.textAssignment.assignmentNumber],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments[this.codelyAssignment.assignmentNumber]
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[this.codelyAssignment.assignmentNumber],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToNextChapterLesson();
    //   }
    // },
    // goToNextCodelyAssignment() {
    //   var classId = this.courseData.chapters
    //     .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //     .lessons.find(
    //       (lesson) => lesson.lessonNo == this.selectedLessonNo
    //     ).classOpId;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments[this.codelyAssignment.assignmentNumber]
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[this.codelyAssignment.assignmentNumber],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.goToNextChapterLesson();
    //   }
    // },
    // goToNextChapterLesson() {
    //   var chapterLessonFound = true;
    //   if (
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo
    //     ]
    //   ) {
    //     this.selectedLessonNo += 1;
    //   } else if (
    //     this.courseData.chapters[this.selectedChapterNo] &&
    //     this.courseData.chapters[this.selectedChapterNo].lessons[0]
    //   ) {
    //     this.selectedChapterNo = Number(this.selectedChapterNo) + 1;
    //     this.selectedLessonNo = 1;
    //   } else {
    //     this.disableNextButton = true;
    //     chapterLessonFound = false;
    //   }
    //   if (chapterLessonFound) {
    //     var classId = this.courseData.chapters
    //       .find((chapter) => chapter.chapterNo == this.selectedChapterNo)
    //       .lessons.find(
    //         (lesson) => lesson.lessonNo == this.selectedLessonNo
    //       ).classOpId;
    //   }
    //   if (chapterLessonFound && this.courseData.isForUK) {
    //     this.showActualContent(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].content[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].notes.length > 0
    //   ) {
    //     this.showNotes(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].notes[0],
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codeAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "CODE",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].quizAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "QUIZ",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].textAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].textAssignments[0],
    //       "TEXT",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else if (
    //     chapterLessonFound &&
    //     this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //       this.selectedLessonNo - 1
    //     ].codelyAssignments.length > 0
    //   ) {
    //     this.showAssignment(
    //       this.courseData.chapters[this.selectedChapterNo - 1].lessons[
    //         this.selectedLessonNo - 1
    //       ].codelyAssignments[0],
    //       "CODELY",
    //       classId,
    //       this.selectedChapterNo,
    //       this.selectedLessonNo
    //     );
    //   } else {
    //     this.disableNextButton = true;
    //   }
    // },
  },
});
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 80px;
  right: 20px;
}
.fixed-icon {
  z-index: 10;
  position: fixed;
  top: 20px;
}
.border {
  border: 1px dashed red;
}
.previousBtn {
  z-index: 10;
  position: fixed;
  top: 50vh;
  left: 1vh;
}
.nextBtn {
  z-index: 10;
  position: fixed;
  top: 50vh;
  right: 1vh;
}
.iframe {
  width: 100%;
  overflow: hidden;
  height: 81vh;
}
</style>
