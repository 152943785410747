<template>
  <v-row align="center" class="ma-1">
    <v-dialog persistent v-model="addSessionDialog" max-width="700px">
      <v-card>
        <v-card-title class="primary--text">New Session</v-card-title>
        <v-form ref="form" v-model="createSessionValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedSession.startDate"
                        label="Start date"
                        :rules="createSessionValidation.nameRules"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedSession.startDate"
                      full-width
                      :min="new Date().toISOString().substr(0, 10)"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6">
                  <v-menu
                    v-model="endDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedSession.endDate"
                        label="End date"
                        :rules="createSessionValidation.nameRules"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedSession.endDate"
                      full-width
                      :min="new Date().toISOString().substr(0, 10)"
                      @input="endDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeAddSessionDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="saveSession()">Save</v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
    <v-col cols="4" class="pa-0">
      <span style="font-size: 24px; font-weight: 500">{{
        editedInstitute.instituteName
      }}</span>
    </v-col>
    <v-col cols="1" class="ma-0 pa-0">
      <v-row justify="start">
        <v-chip
          small
          :color="editedInstitute.status == 'CREATED' ? 'success' : 'error'"
          label
          background
        >
          {{ editedInstitute.status }}
        </v-chip>
      </v-row>
    </v-col>

    <v-col cols="3" class="pa-0">
      <v-row justify="center">
        <v-btn
          background
          v-if="institute.currentSession"
          class="primary"
          width="250"
          height="25"
          >{{ formatDate(editedInstitute.currentSession.startDate) }} To
          {{ formatDate(editedInstitute.currentSession.endDate) }}</v-btn
        >
        <span v-else class="primary--text">
          <v-btn
            text
            color="info"
            @click="openAddSessionDialog()"
            @click.native.stop
            >Add Session</v-btn
          >
        </span>
      </v-row>
    </v-col>
    <!-- <v-col>
      <v-chip x-small outlined :color="getStatusColor(editedInstitute)">{{editedInstitute .status }}
      </v-chip>
    </v-col> -->

    <!-- 
<v-dialog persistent  v-model="publishConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title>Are you sure you want to publish
          {{ competition.name }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePublishDailog()">Cancel</v-btn>
          <v-btn color="primary" text @click="publishCompetition()">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <v-col cols="4" class="mt-1">
      <v-row>
        <v-col>
          <v-row justify="center">
            <span style="font-size: 14px">{{ formatDateTime(editedInstitute.createdOn) }}</span>
          </v-row>
        </v-col>
        <v-col>
          <v-row justify="center">
            <span>{{ editedInstitute.createdBy.name }}</span>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {
  ADD_SESSION,
  BASE_URL,
  GET_ALL_INSTITUTES,
} from "@/common/apiEndpoints";
import { getAxiosOptions, formatDate, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
// import {USER_TYPE_RESOURCE_MANAGER} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      addSessionDialog: false,
      startDateMenu: false,
      endDateMenu: false,
      createSessionValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      editedSession: {},
      editedInstitute: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
        createdBy: "",
        createdOn: "",
        currentSession: "",
        startDate: "",
        endDate: "",
      },
      defaultItem: {
        instituteId: "",
        instituteName: "",
        createdBy: "",
        createdOn: "",
        currentSession: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      userType: `schoolModule/${GET_USER_TYPE}`,
    }),
  },
  props: ["institute"],
  mounted: function () {
    this.editedInstitute = this.institute;
    this.converText();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    // getStatusColor(quizCompetition) {
    //   if (quizCompetition.status == "PUBLISHED") return "success";
    //   else return "red";
    // },
    formatDate(date) {
      return formatDate(date);
    },
    converText() {
      this.editedInstitute.createdBy.name = this.editedInstitute.createdBy.name.toUpperCase();
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    validate() {
      this.$refs.form.validate();
    },
    saveSession() {
      this.validate();
      if (this.createSessionValidation.valid) {
        const payload = this.editedSession;
        payload.instituteId = this.institute.instituteId;
        let url = ADD_SESSION;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createSessionValidation.valid = false;
            this.editedIndex = -1;
            this.editedInstitute = res.data;
            this.closeAddSessionDialog();
            this.$emit("updateInstitute");
            this.setOverlayValue(false);
            this.setApiSuccess("Session created successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
    openAddSessionDialog() {
      this.addSessionDialog = true;
      this.editedIndex = 0;
    },
    closeAddSessionDialog() {
      this.addSessionDialog = false;
      this.editedSession = Object.assign({});
      this.editedIndex = -1;
    },
  },
};
</script>
