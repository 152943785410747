<template>
  <v-footer flat app clipped-left class="pb-1">
    <v-col class="text-right" cols="7">
      {{ new Date().getFullYear() }} — <strong>APTCODER </strong>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  data() {
    return {
      api1: {},
      api2: {},
    };
  },
};
</script>
