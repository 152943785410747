<template>
  <v-card class="ma-2" style="border-radius: 10px">
    <v-toolbar flat dark color="primary">
      <v-toolbar-title
        style="font-weight: 500; font-size: 24px"
        v-if="paramsValue.teacherId && userType == USER_TYPE_ADMIN"
      >
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)">mdi-arrow-left</v-icon
        >{{
          paramsValue.firstName + paramsValue.lastName
        }}</v-toolbar-title
      ><v-toolbar-title v-else style="font-weight: 500; font-size: 24px"
        >My Calendar</v-toolbar-title
      >
    </v-toolbar>
    <v-container fluid>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
              >
                Today
              </v-btn>
              <v-btn fab text color="grey darken-2" @click="prev">
                <v-icon> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text color="grey darken-2" @click="next">
                <v-icon> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="grey darken-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span>{{ viewTypeArray[viewType] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="viewType = 'day'">
                    <v-list-item-title>Day</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="viewType = 'week'">
                    <v-list-item-title>Week</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="viewType = 'month'">
                    <v-list-item-title>Month</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="500">
            <v-calendar
              ref="calendar"
              v-model="calendarDate"
              color="primary"
              :events="teacherClasses"
              :event-color="getEventColor"
              :type="viewType"
              @click:event="showClassDetails"
              @click:more="viewDay"
              @click:date="viewDay"
              event-overlap-mode="stack"
              event-overlap-threshold="0"
            >
              <template v-slot:event="{ eventParsed, event }">
                {{
                  event.color == "error"
                    ? eventParsed.start.time + " - " + eventParsed.end.time
                    : ""
                }}
              </template>
            </v-calendar>
            <v-menu
              v-model="openSelectedClass"
              :close-on-content-click="false"
              :activator="selectedClass"
              offset-y
            >
              <v-card min-width="350px" flat v-if="selectedClass.classInfo">
                <v-toolbar color="primary" dark>
                  <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
                    selectedClass.classInfo.name
                  }}</v-toolbar-title>
                </v-toolbar>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th
                          style="
                            font-weight: 500;
                            font-size: 14px;
                            color: black;
                          "
                        >
                          Course Name
                        </th>
                        <td>{{ selectedClass.courseName }}</td>
                      </tr>
                      <tr>
                        <th
                          style="
                            font-weight: 500;
                            font-size: 14px;
                            color: black;
                          "
                        >
                          Total Students
                        </th>
                        <td>{{ selectedClass.totalStudents.length }}</td>
                      </tr>
                      <tr>
                        <th
                          style="
                            font-weight: 500;
                            font-size: 14px;
                            color: black;
                          "
                        >
                          Status
                        </th>
                        <td>
                          {{ selectedClass.status }}
                          <v-icon
                            v-if="selectedClass.isExtra == true"
                            class="pa-0 mb-1"
                            medium
                            >mdi-alpha-e-circle-outline</v-icon
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    @click="openSelectedClass = false"
                  >
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import {
  BASE_URL,
  TEACHER_CALENDAR,
  GET_CLASS_OPERATIONS_BY_ID,
  GET_TEACHER_COURSES,
} from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import { GET_USER_TYPE, GET_PARAMS_VALUE, GET_TOKEN } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { USER_TYPE_ADMIN } from "@/common/constants";
export default {
  data() {
    return {
      openSelectedClass: false,
      selectedClass: {},
      teacherCalendar: [],
      courses: [],
      calendarDate: "",
      viewType: "week",
      viewTypeArray: {
        month: "Month",
        week: "Week",
        day: "Day",
      },
      teacherClasses: [],
      USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    };
  },
  mounted: function () {
    this.getTeacherCalender();
    this.fetchCourses();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getEventColor(event) {
      return event.color;
    },
    viewDay({ date }) {
      this.calendarDate = date;
      this.viewType = "day";
    },
    setToday() {
      this.calendarDate = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    getTeacherCalender() {
      let url = TEACHER_CALENDAR;
      if (this.$route.params.teacherId) {
        url = url + "?teacherId=" + this.$route.params.teacherId;
      } else if (this.paramsValue.teacherId) {
        url = url + "?teacherId=" + this.paramsValue.teacherId;
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teacherCalendar = res.data.calendar;
          this.updateRange();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    showClassDetails({ nativeEvent, event }) {
      const open = () => {
        if (event.classOpsId) {
          this.getClassOpById(event);
          this.selectedClass = nativeEvent.target;
          requestAnimationFrame(() =>
            requestAnimationFrame(() => (this.openSelectedClass = true))
          );
        }
      };
      if (this.openSelectedClass) {
        this.openSelectedClass = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      nativeEvent.stopPropagation();
    },
    getClassOpById(event) {
      const url = GET_CLASS_OPERATIONS_BY_ID;
      this.setOverlayValue(true);
      const payload = {};
      payload.classOpId = event.classOpsId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.selectedClass = res.data;
          this.selectedClass.courseName = this.getCourseNameById(
            this.selectedClass.courseId
          );
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    updateRange() {
      this.teacherClasses = [];
      const allDay = false;
      for (let i = 0; i < this.teacherCalendar.length; i++) {
        let date = new Date(this.teacherCalendar[i].date);
        for (let j = 0; j < this.teacherCalendar[i].slots.length; j++) {
          let openHours = this.teacherCalendar[i].slots[j].openTime.split(":");
          date.setHours(openHours[0], openHours[1]);
          let openTime = new Date(date);
          let closeHours =
            this.teacherCalendar[i].slots[j].closeTime.split(":");
          date.setHours(closeHours[0], closeHours[1]);
          let closeTime = new Date(date);
          let isAvailable = this.teacherCalendar[i].slots[j].isAvailable;
          let eventColor = isAvailable ? "success" : "error";
          let classOpsId = this.teacherCalendar[i].slots[j].classOpsId;
          this.teacherClasses.push({
            start: openTime,
            end: closeTime,
            timed: !allDay,
            color: eventColor,
            classOpsId: classOpsId,
          });
        }
      }
    },
    fetchCourses() {
      let url = GET_TEACHER_COURSES;
      if (this.$route.params.teacherId) {
        url = url + "?teacherId=" + this.$route.params.teacherId;
      } else if (this.paramsValue.teacherId) {
        url = url + "?teacherId=" + this.paramsValue.teacherId;
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          this.getClasses();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getCourseNameById(courseId) {
      if (courseId) {
        let c = this.courses.filter((course) => {
          return course.courseId == courseId;
        });
         if (c.length>0 && c[0]) return c[0].courseName;
      return "No Course";
      }
    },
  },
};
</script>
<style>
  div.v-event-timed.error.white--text{
    z-index: 2 !important;
  }
</style>
