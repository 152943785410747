<template>
  <v-container>
    <v-form ref="form" lazy-validation>
      <v-text-field
        v-model="quizCompetition.name"
        label="Competition Name"
        outlined
        required
      ></v-text-field>

      <v-file-input
        label="Thumbnail"
        v-model="quizCompetition.thumbnail"
        required
        outlined
      ></v-file-input>
      <v-row>
        <v-col cols="3">
          <v-menu
            v-model="menuForStartDateTime"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="quizCompetition.startDate"
                label="Start Date"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startMenuDate"
              @input="getStartDate"
            ></v-date-picker>
            <v-time-picker
              v-model="startMenuTime"
              :allowed-minutes="[0, 30]"
              @input="getStartDate"
              ampm-in-title
              format="ampm"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="menuForEndDateTime"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="quizCompetition.endDate"
                label="End Date"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endMenuDate" @input="getEndDate">
            </v-date-picker>
            <v-time-picker
              v-model="endMenuTime"
              :allowed-minutes="[0, 30]"
              @input="getEndDate"
              ampm-in-title
              format="ampm"
            ></v-time-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete
            v-model="selectedQuizNames"
            :items="existingQuizes"
            label="Existing Quizes"
            outlined
            dense
            item-value="quizName"
            item-text="quizName"
            @input="updateQuizes"
            multiple
            deletable-chips
            chips
          ></v-autocomplete>
        </v-col>
        <v-col>
          <v-combobox
            v-model="selectedGrades.grades"
            :items="gradesArray"
            label="Grades"
            multiple
            outlined
            dense
            deletable-chips
            chips
            @input="updateGrades"
            item-text="desc"
            item-value="id"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-btn color="error" class="mr-4">Cancel</v-btn>
      <v-btn color="success" class="mr-4" @click="saveCompettiion">
        Save
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import {
  BASE_URL,
  GET_ALL_QUIZ,
  GET_COMPETETION_BY_ID,
  SAVE_COMPETETION_DETAILS,
} from "@/common/apiEndpoints.js";
import { GET_TOKEN } from "@/store/getters";
import axios from "axios";
import { mapGetters } from "vuex";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import { grades } from "@/common/constants";

export default {
  data() {
    return {
      quizCompetition: {},
      menuForStartDateTime: false,
      menuForEndDateTime: false,
      startMenuDate: "",
      startMenuTime: "",
      endMenuDate: "",
      endMenuTime: "",
      startDate: "",
      endDate: "",
      existingQuizes: [],
      existingQuizNames: [],
      gradesArray: grades,

      selectedQuizNames: [],
      selectedGrades: [],
    };
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.getQuizByCompId();
    this.getAllQuiz();
  },
  methods: {
    getQuizByCompId() {
      const url = GET_COMPETETION_BY_ID;
      const payload = {};
      payload.quizCompId = this.$route.params.quizCompId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizCompetition = res.data;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getAllQuiz() {
      const url = GET_ALL_QUIZ;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.existingQuizes = res.data.quizes;
          //   this.getExistingQuizNames();
          this.getSelectedQuizNames();
          this.getSelectedGrades();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    // getExistingQuizNames() {
    //   this.existingQuizNames = this.existingQuizes.map((quiz) => {
    //     return quiz.quizName;
    //   });
    getSelectedGrades() {
      this.selectedGrades = this.quizCompetition.grades;
    },
    getSelectedQuizNames() {
      const selectedQuiz = [];
      this.existingQuizes.filter((quiz) => {
        this.quizCompetition.quizes.forEach((quizComp) => {
          if (quizComp.quizId == quiz.quizId) {
            selectedQuiz.push(quiz);
          }
        });
      });
      this.selectedQuizNames = selectedQuiz.map((quiz) => {
        return quiz.quizName;
      });
    },
    getStartDate() {
      if (this.startMenuDate != "" && this.startMenuTime != "") {
        this.quizCompetition.startDate =
          this.startMenuDate + this.startMenuTime;
        this.menuForStartDateTime = false;
      } else {
        console.log("ni");
      }
    },
    getEndDate() {
      if (this.endMenuDate != "" && this.endMenuTime != "") {
        this.quizCompetition.endDate = this.endMenuDate + this.endMenuTime;
        this.menuForEndDateTime = false;
      } else {
        console.log("ni");
      }
    },
    saveCompettiion() {
      const url = SAVE_COMPETETION_DETAILS;
      const payload = {};
      payload.quizCompId = this.quizCompetition.quizCompId;
      payload.startDate = this.quizCompetition.startDate;
      payload.endDate = this.quizCompetition.endDate;
      payload.name = this.quizCompetition.name;
      payload.thumbnail = this.quizCompetition.thumbnail;
      payload.quizes = this.quizCompetition.quizes;
      payload.grades = this.selectedGrades;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizCompetition = res.data;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    updateQuizes() {
      this.quizCompetition.quizes = [];
      this.existingQuizes.filter((quiz) => {
        this.selectedQuizNames.forEach((name) => {
          if (quiz.quizName == name) {
            const payloadQuizObj = { quizId: quiz.quizId };
            this.quizCompetition.quizes.push(payloadQuizObj);
          }
        });
      });
    },
    updateGrades() {
      this.quizCompetition.grades = this.selectedGrades;
    },
  },
};
</script>
