<template>
    <v-container fluid>
        <v-toolbar flat dark color="secondary">
            <v-btn icon class="hidden-xs-only" @click="$router.go(-1)">
        <v-icon >mdi-arrow-left</v-icon>
      </v-btn>
          <v-toolbar-title style="font-weight: 500; font-size: 24px;">{{textContent.title}}</v-toolbar-title>
        </v-toolbar>
        <p class="ma-2" v-html="textContent.source"></p>
  </v-container>
</template>

<script>
export default {
    data() {
    return {
        textContent : {},
    }
    },
    mounted:function (){
        this.textContent = this.$route.params.textContent;
    }
}
</script>