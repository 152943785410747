<template>
  <v-row>
    <v-dialog persistent v-model="publishConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title
          >Are you sure you want to publish {{ course.name }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePublishDailog">Cancel</v-btn>
          <v-btn color="primary" text @click="publishCourse()">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="enrollDialog" max-width="1000px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-switch
            v-model="deEnrollMode"
            @change="openCloseSearchPanel()"
            label="Remove Enrollment"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
            >mdi-magnify</v-icon
          >
          <v-text-field
            v-if="deEnrollMode"
            v-model="searchStudent"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-expansion-panels v-model="panel" multiple flat>
          <v-expansion-panel>
            <v-expansion-panel-content>
              <v-container>
                <v-form ref="form" class="p-2 m-2">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.mobileNo"
                        label="Mobile No"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.email"
                        label="Email"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.firstName"
                        label="First Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.lastName"
                        label="Last Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.schoolName"
                        label="School Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-combobox
                        v-model="studentFilter.grade"
                        :items="gradesArray"
                        label="Grades"
                        dense
                        deletable-chips
                        chips
                        item-text="desc"
                        item-value="id"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="searchStudents()">
                      Apply
                    </v-btn>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          v-if="!deEnrollMode"
          v-model="selectedStudents"
          :headers="studentHeadersForEnroll"
          :items="studentsList"
          show-select
          item-key="studentId"
          item-value="studentId"
          @toggle-select-all="selectAllStudents"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              x-small
              :color="item.status == 'ACTIVE' ? 'success' : 'error'"
              label
              outlined
            >
              {{ item.status }}
            </v-chip>
            <v-icon v-if="item.isEnrolled">mdi-account-check</v-icon>
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ formatDateTime(item.createdOn) }}
          </template>
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :disabled="item.status != 'ACTIVE' || item.isEnrolled"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.firstName`]="{ item }">
            {{ item.firstName ? item.firstName : "--" }}
          </template>
          <template v-slot:[`item.lastName`]="{ item }">
            {{ item.lastName ? item.lastName : "--" }}
          </template>
          <template v-slot:[`item.mobileNo`]="{ item }">
            {{ item.mobileNo ? item.mobileNo : "--" }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            {{ item.email ? item.email : "--" }}
          </template>
          <!-- <template v-slot:[`item.status`]="{ item }">
            {{ item.status ? item.status : "--" }}
          </template> -->
          <template v-slot:[`item.schoolName`]="{ item }">
            {{ item.schoolName ? item.schoolName : "--" }}
          </template>
          <template v-slot:[`item.grade`]="{ item }">
            {{ item.grade ? item.grade : "--" }}
          </template>
        </v-data-table>
        <v-data-table
          v-else
          :headers="studentHeadersForDeEnroll"
          :items="courseDetails.enrolledStudents"
          item-key="studentId"
          item-value="studentId"
          class="elevation-1"
          :search="searchStudent"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="mr-2"
                  color="primary"
                  @click="removeEnrollment(item)"
                >
                  mdi-account-minus-outline
                </v-icon>
              </template>
              <span>De-Enroll</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEnrollDialog()"
            >Cancel</v-btn
          >
          <v-btn
            v-if="!deEnrollMode"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="enrollStudents()"
            >Enroll</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row class="ma-1" align="center">
      <v-col cols="1" class="pa-0 ma-1">
        <v-card flat>
          <v-badge icon bottom avatar overlap @click.native.stop>
            <template v-slot:badge>
              <v-icon @click="addThumbnail">mdi-camera</v-icon>
              <input
                ref="uploader"
                class="d-none"
                accept="image/png, image/gif, image/jpeg"
                type="file"
                @change="onFileChanged"
              />
            </template>
            <v-avatar
              v-if="!preview && !course.thumbnail"
              style="border: 2px solid #f55b49"
              size="70"
              @click="imgDialog = !imgDialog"
            >
              <v-img height="70" src="@/assets/Avatar_sales.png" contain>
              </v-img>
            </v-avatar>
            <v-avatar
              v-else-if="!preview && course.thumbnail"
              style="border: 2px solid #f55b49"
              size="70"
              @click="imgDialog = !imgDialog"
            >
              <v-img :src="course.thumbnail"> </v-img>
            </v-avatar>
            <v-avatar
              style="border: 2px solid #f55b49"
              v-else
              size="50"
              @click="imgDialog = !imgDialog"
            >
              <v-img :src="preview"> </v-img>
            </v-avatar>
          </v-badge>
          <v-card-actions v-if="showUploadBtns">
            <v-btn color="primary" x-small icon @click.native.stop>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" @click="uploadThumbnail()"
                    >mdi-upload</v-icon
                  >
                </template>
                <span>Upload</span>
              </v-tooltip>
            </v-btn>
            <v-btn color="primary" x-small icon @click.native.stop>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error" @click="cancelUpload()"
                    >mdi-cancel</v-icon
                  >
                </template>
                <span>Cancel</span>
              </v-tooltip>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-dialog v-model="imgDialog" width="300">
        <v-card>
          <v-img
            v-if="!preview && !course.thumbnail"
            class="align-start text-white"
            height="50"
            src="@/assets/Avatar_sales.png"
            contain
          ></v-img>
          <v-img
            v-if="!preview && course.thumbnail"
            :src="course.thumbnail"
          ></v-img>
          <v-img v-if="preview" :src="preview"></v-img>
        </v-card>
      </v-dialog>
      <v-col cols="2" class="ma-1 pa-0">
        <span style="font-weight: 500; font-size: 22px">{{ course.name }}</span>
      </v-col>
      <v-col>
        <v-row justify="start">
          <v-img
            v-if="course.courseType == COURSE_TYPE_PRIVATE && course.buyable"
            src="../assets/premium-quality 1.svg"
            max-height="30"
            max-width="30"
          ></v-img>
          <v-img
            v-if="
              course.courseType == COURSE_TYPE_PRIVATE &&
              !course.buyable &&
              !course.isForUK
            "
            src="../assets/school.png"
            max-height="30"
            max-width="30"
          ></v-img>
          <v-img
            v-if="
              course.courseType == COURSE_TYPE_PRIVATE &&
              !course.buyable &&
              course.isForUK == true
            "
            src="../assets/alpha-u-circle-outline.svg"
            max-height="30"
            max-width="30"
          ></v-img>
        </v-row>
      </v-col>
      <v-col cols="1" class="pa-0">
        <v-row>
          <v-col cols="12" class="ma-1 pa-0">
            <v-row justify="center">
              <v-chip
                small
                outlined
                label
                background
                class="white--text"
                :color="getStatusColor(course)"
                >{{ course.status }}
              </v-chip>
            </v-row>
          </v-col>
          <v-col cols="12" class="ma-1 pa-0">
            <v-row justify="center" class="mt-2">
              <v-chip
                small
                outlined
                class="white--text"
                color="secondary"
                label
                background
                style="font-size: 14px"
                v-if="course.courseType == COURSE_TYPE_PRIVATE"
              >
                {{ "Classes: " + course.noOfClasses }}
              </v-chip>
            </v-row>
          </v-col>
          <v-col cols="12" class="ma-1 pa-0">
            <v-row justify="center" class="mt-2">
              <v-chip
                small
                outlined
                color="accent"
                label
                background
                style="font-size: 14px"
                v-if="course.buyable == true"
                class="white--text"
              >
                {{ "Price: ₹ " + course.price }}
              </v-chip>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="1">
        <v-row justify="center" class="ml-1">
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-img
                v-on="on"
                @click.native.stop
                src="../assets/Publish.svg"
                max-height="25"
                max-width="25"
                @click="publishCourseConfirm(course)"
              >
              </v-img>
            </template>
            <span>Publish Course</span>
          </v-tooltip>
        </v-row>
      </v-col>
      <v-col cols="3" class="ma-1 pa-0">
        <v-row>
          <v-col cols="4" class="pr-0">
            <span
              style="font-size: 20px; font-weight: 500"
              class="primary--text"
              >Grades:
            </span>
          </v-col>
          <v-col class="pl-0">
            <span
              style="font-size: 18px"
              v-for="(grade, index) in course.grades"
              :key="index"
              >{{ grade }}{{ index != course.grades.length - 1 ? "," : "" }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="ml-2 pa-0">
        <v-row align="center">
          <v-col cols="6" class="ma-1 pa-0">
            <v-col class="pa-0">
              <span
                style="font-size: 20px; font-weight: 500"
                class="primary--text"
                >Created On:</span
              >
            </v-col>
            <v-col class="mt-2 pa-0">
              <span style="font-size: 14px">{{
                formatDateTime(course.createdOn)
              }}</span>
            </v-col>
          </v-col>
          <v-col class="ma-1 pa-0">
            <span style="font-size: 20px">Chapters : </span>
            <span style="font-size: 20px">{{ course.chapterCount }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
import {
  BASE_URL,
  ADD_COURSE_THUMBNAIL,
  PUBLISH_COURSE,
  SEARCH_USERS,
  ENROLL_STUDENTS,
  GET_COURSE_BY_ID,
  DE_ENROLL_STUDENT,
  SEARCH_STUDENT,
} from "@/common/apiEndpoints";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import {
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
  USER_TYPE_ADMIN,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  grades,
} from "@/common/constants";

import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      USER_TYPE_ADMIN: USER_TYPE_ADMIN,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      disabledCount: 0,
      file: "",
      preview: "",
      panel: [],
      searchStudent: "",
      showUploadBtns: false,
      publishConfirmDialog: false,
      imgDialog: false,
      enrollDialog: false,
      enrolledStudentsId: [],
      studentHeadersForEnroll: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        { text: "School Name", value: "schoolName" },
        { text: "Grade", value: "grade" },
      ],
      studentHeadersForDeEnroll: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Grade", value: "grade" },
        { text: "School Name", value: "schoolName" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      selectedStudents: [],
      studentsList: [],
      deEnrollMode: false,
      courseDetails: {},
      menu: false,
      studentFilter: {},
      editedIndex: -1,
      gradesArray: grades,
    };
  },
  props: ["course"],
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      userType: `schoolModule/${GET_USER_TYPE}`,
    }),
  },
  watch: {
    publishConfirmDialog(val) {
      val || this.closePublishDailog();
    },
  },

  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.course.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courseDetails = res.data;
          this.enrolledStudentsId = this.courseDetails.enrolledStudents.map(
            (std) => {
              return std.studentId;
            }
          );
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },

    selectAllStudents(props) {
      if (
        this.selectedStudents.length !=
        this.studentsList.length - this.disabledCount
      ) {
        this.selectedStudents = [];
        const self = this;
        props.items.forEach((item) => {
          //  if(item.status=='ACTIVE') {
          if (item.status == "ACTIVE" && !item.isEnrolled) {
            self.selectedStudents.push(item);
          }
        });
      } else this.selectedStudents = [];
    },

    getStatusColor(course) {
      if (course.status == "PUBLISHED") return "success";
      else return "error";
    },
    getColorByType(course) {
      if (course.courseType == "PUBLIC") return "success";
      else return "error";
    },

    addThumbnail() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      if (this.file) {
        this.preview = URL.createObjectURL(e.target.files[0]);
      } else {
        this.preview = "";
      }
      this.showUploadBtns = true;
    },
    uploadThumbnail() {
      let payload = {};
      payload.file = this.file;
      payload.courseId = this.course.courseId;
      let url = ADD_COURSE_THUMBNAIL;
      this.setOverlayValue(true);
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      axios
        .post(url, payload, options)
        .then((res) => {
          this.$emit("onPublishCourse");
          this.setApiSuccess("Thumbnail updated successfully");
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    cancelUpload() {
      this.showUploadBtns = false;
      this.file = "";
      this.preview = "";
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    publishCourseConfirm() {
      this.publishConfirmDialog = true;
    },
    publishCourse() {
      const url = PUBLISH_COURSE;
      let payload = {};
      payload.courseId = this.course.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDailog();
          this.$emit("onPublishCourse");
          this.setApiSuccess("Course published successfully");
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.closePublishDailog();
        });
    },
    closePublishDailog() {
      this.publishConfirmDialog = false;
    },
    closeEnrollDialog() {
      this.deEnrollMode = false;
      this.selectedStudents = [];
      this.studentsList = [];
      this.studentFilter = {};
      this.enrollDialog = false;
    },
    openEnrollStudentDialog() {
      this.enrollDialog = true;
      this.panel = [0];
      this.getCourseById();
    },
    enrollStudents() {
      let url = ENROLL_STUDENTS;
      let payload = {};
      payload.courseId = this.course.courseId;
      payload.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.setApiSuccess(res.data.message);
          this.closeEnrollDialog();
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
        });
    },
    removeEnrollment(student) {
      let url = DE_ENROLL_STUDENT;
      let payload = {};
      payload.courseId = this.courseDetails.courseId;
      payload.studentId = student.studentId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          console.log("stduent de-enrolled succesfully");
          this.editedIndex =
            this.courseDetails.enrolledStudents.indexOf(student);
          this.courseDetails.enrolledStudents.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
        });
    },
    searchStudents() {
      const payload = {};
      let flag = false;
      if (this.studentFilter.mobileNo) {
        payload.mobileNo = this.studentFilter.mobileNo;
        flag = true;
      }
      if (this.studentFilter.email) {
        payload.email = this.studentFilter.email;
        flag = true;
      }
      if (this.studentFilter.firstName) {
        payload.firstName = this.studentFilter.firstName;
        flag = true;
      }
      if (this.studentFilter.lastName) {
        payload.lastName = this.studentFilter.lastName;
        flag = true;
      }
      if (this.studentFilter.schoolName) {
        payload.schoolName = this.studentFilter.schoolName;
        flag = true;
      }
      if (this.studentFilter.grade) {
        payload.grade = this.studentFilter.grade;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
            const self = this;
            self.disabledCount = 0;
            this.studentsList.map((item) => {
              if (item.status != "ACTIVE") {
                self.disabledCount += 1;
              }
              if (this.enrolledStudentsId.includes(item.studentId)) {
                item.isEnrolled = true;
              } else {
                item.isEnrolled = false;
              }
            });
            this.panel = [];
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      } else {
        this.panel = [];
      }
    },
    openCloseSearchPanel() {
      this.panel.length == 0 ? (this.panel = [0]) : (this.panel = []);
    },
  },
};
</script>
