<template>
    <v-container fluid>
        <v-card flat>
            <!-- <v-expansion-panels > -->
                <v-data-table    :headers="headers" :items="textAssignments" hide-default-header hide-default-footer
                item-key="assignmentNumber" :items-per-page="itemsPerPage"     :single-expand="singleExpand"
    :expanded.sync="expanded"
      show-expand>
                <template v-slot:[`item.actions`]="{ item }">
                <!-- <v-expansion-panel class="mt-2" :items="textAssignments" item-key="assignmentNumber">
                    <v-expansion-panel-header> -->
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="primary" @click="editTextAssignment(item)">
                                mdi-pencil-plus
                            </v-icon>
                        </template>
                        <span>Add variant</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="error" @click="deleteAssignmentConfirmDialog(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Delete</span>
                    </v-tooltip>
                    <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="success" @click="previewTextAssignment(item)">
                                mdi-eye
                            </v-icon>
                        </template>
                        <span>View</span>
                    </v-tooltip> -->
                </template>
                <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-list-item v-for="(i, index) in item.Variations" :key="index" class="ma-2 deep-purple lighten-5 rounded-xl" > <v-chip
      class="ma-2 ml-5"
      color="green"
      text-color="white"
    >
   {{i.difficultyLevel}}
    </v-chip>
            <v-btn
              color="primary"
              fab
              small
              class="ma-2 "
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn></v-list-item>
      </td>
    </template>
            </v-data-table>
<!-- </v-expansion-panels> -->
            <v-card-actions>
                <v-row align="end" justify="end">
                    <v-dialog persistent v-model="assignmentDialog" width="600">
                         
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on" @click="addAssignmentDialog()" icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="success"> mdi-plus</v-icon>
                                    </template>
                                    <span>Add Text Assignment</span>
                                </v-tooltip>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="primary--text">New Text Assignment</v-card-title>
                            <v-card-text>
                               
                                <v-form ref="form" v-model="createAssignmentValidation.valid">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="editedAssignment.title" label="Title"
                                                    :rules="createLessonValidation.nameRules" required></v-text-field>
                                                <span>Question</span>
                                                <quill-editor style="height: 140px; margin-bottom:30px;"
                                                :options="editorOptions" v-model="editedAssignment.question"
                                                    @input="validateCKeditorValues()"></quill-editor>
                                                <span class="red--text text-caption">{{
                                                    questionFieldError
                                                }}</span>

                                                <v-col>

                                                    <v-row>
                                                        <span>Answer</span>
                                                        <quill-editor style="height: 140px; margin-bottom:30px;"
                                                        :options="editorOptions" v-model="editedAssignment.answer"
                                                            @input="validateCKeditorValues()">
                                                        </quill-editor>
                                                        <span class="red--text text-caption">{{
                                                            solutionFieldError
                                                        }}</span>
                                                    </v-row>
                                                </v-col>
                                            <v-col>  <v-select
                                v-model="editedAssignment.difficultyLevel"
                                :rules="createAssignmentValidation.nameRules"
                                :items="createAssignmentValidation.items"
                                required
                                item-text="value"
                                item-value="key"
                                label="Difficulty level"
                              ></v-select></v-col>
                                               
                                            </v-col>
                                        </v-row>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="close()">Cancel </v-btn>
                                            <v-btn color="primary" text @click="saveTextAssignment()">
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="previewTextAssignmentDialog" width="60%" class="pa-0">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title style="font-weight: 500; font-size: 24px">Text Assignment</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="closepreviewTextAssignmentDialog()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card v-model="editedAssignment">
                            <v-col>
                                <v-row class="mt-1 pl-3">
                                    <span style="font-weight: 500; font-size: 20px" class="error--text">Title: </span>
                                    <span class="ml-1" style="font-size: 20px" v-html="editedAssignment.title"></span>
                                </v-row>
                            </v-col>
                            <v-col>
                                <!-- <v-row class="mt-1 pl-3">
                                    <span style="font-weight: 500; font-size: 20px" class="error--text">Question: </span>
                                    <v-card-text class="mt-0 mr-2">
                                        <v-textarea v-model="editedAssignment.question" rows="6" outlined
                                            :readonly="true"></v-textarea></v-card-text></v-row> -->
                                <span class="mt-4 ml-0 error--text"
                                    style="font-weight: 450; font-size: 20px">Question:</span>
                                <div class="overflow-auto my-2" style="border: 2px solid #ccc;padding: 5px;height:200px"
                                    v-html="editedAssignment.question"> </div>
                            </v-col>
                            <v-col>
                                <!-- <v-row class="mt-1 pl-3">
                                    <span style="font-weight: 500; font-size: 20px" class="error--text mt-n2">Answer:
                                    </span>
                                     <span class="ml-1" style="font-size: 20px" v-html="editedAssignment.answer"></span> 
                                    <v-card-text class="mt-0 mr-2">
                                        <v-textarea v-model="editedAssignment.answer" rows="6" outlined
                                            :readonly="true"></v-textarea></v-card-text>
                                </v-row> -->
                                <span class="mt-4 ml-0 error--text" style="font-weight: 450; font-size: 20px">Answer:</span>
                                <div class="overflow-auto my-2" style="border: 2px solid #ccc;padding: 5px;height:200px"
                                    v-html="editedAssignment.answer"> </div>
                            </v-col>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="editLessonDialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="course.status != STATUS_PUBLISHED
                                " color="primary" v-bind="attrs" v-on="on" @click="editLesson()" icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-pencil</v-icon>
                                    </template>
                                    <span>Edit Lesson</span>
                                </v-tooltip>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="primary--text">Edit Lesson</v-card-title>
                            <v-form ref="form" v-model="createLessonValidation.valid">
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="editedLesson.name" label="Lesson Name"
                                                    :rules="createLessonValidation.nameRules" required></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="close()">Cancel </v-btn>
                                            <v-btn color="primary" text @click="saveLesson()">
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-container>
                                </v-card-text>
                            </v-form>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="deleteLessonDialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="course.status != STATUS_PUBLISHED
                                " color="primary" dark v-bind="attrs" v-on="on" @click="deleteLessonConfirmDialog()"
                                icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="error">mdi-delete</v-icon>
                                    </template>
                                    <span>Delete Lesson</span>
                                </v-tooltip>
                            </v-btn>

                        </template>
                        <v-card>
                            <v-card-title>Are you sure you want to delete this Lesson?
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDeleteDialog()">Cancel</v-btn>
                                <v-btn color="primary" text @click="deleteLesson()">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <!-- <v-dialog v-model="previewDialog" eager fullscreen hide-overlay transition="dialog-bottom-transition">
                        <v-card>
                            <v-toolbar dark color="primary">
                                <v-btn icon dark @click="closePreviewDialog()">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title style="font-weight: 500; font-size: 24px;">{{ previewData.title
                                }}</v-toolbar-title>
                            </v-toolbar>
                            <vue-friendly-iframe v-if="previewData.source" :src="previewData.source"></vue-friendly-iframe>

                        </v-card>
                    </v-dialog> -->
                    <v-dialog persistent v-model="deleteAssignmentDialog" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete this ? </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDeleteDialog">Cancel</v-btn>
                                <v-btn color="primary" text @click="deleteAssignment">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>
</template> 
<script>
import {
    STATUS_PUBLISHED,
    USER_TYPE_CONTENT_MANAGER,
    USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {

    DELETE_ASSIGNMENT,
    UPDATE_TEXTASSIGNMENT,
    CREATE_VARIANT_TEXT_ASSIGNMENTS,
    BASE_URL,
    DELETE_LESSON,
    GET_COURSE_BY_ID,
    UPDATE_LESSON,
} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
import ResourceSearch from "./ResourceSearch.vue";
import { SET_OVERLAY_VALUE } from '@/store/mutations';
import { SET_API_SUCCESS } from '@/store/mutations';
export default {
    data() {
        return {
            editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
            expanded: [],
        singleExpand: false,
            editorConfig: {},
            headers: [
                {
                    text: "Assignment Id",
                    align: "start",
                    value: "assignmentId"
                },

                { text: "Title", value: "title" },

                { text: "Actions", value: "actions", sortable: false },
            ],

            assignmentDialog: false,
            deleteAssignmentDialog: false,
            editLessonDialog: false,
            deleteLessonDialog: false,
            previewTextAssignmentDialog: false,
            previewData: {},
            editedIndex: -1,
            itemsPerPage: -1,
            editedAssignment: {},
            editedLesson: {},
            defaultItem: {},
            course: {},
            overlay: false,
            quizes: [],
            questionFieldError: '',
            solutionFieldError: '',
            createLessonValidation: {
                valid: false,
                nameRules: [(v) => !!v || "This is required"],
            },
            createAssignmentValidation: {
                valid: false,
                nameRules: [(v) => !!v || "This is required"],
                items: [
          { value: "Easy", key: "EASY" },
          { value: "Medium", key: "MEDIUM" },
          { value: "Hard", key: "HARD" },
          { value: "Very Hard", key: "VHARD" },
        ],
            },
            USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
            USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
            STATUS_PUBLISHED: STATUS_PUBLISHED,
            menu: false
        };
    },
    components: { ResourceSearch },
    props: ["textAssignments", "courseId", "chapterNo", "lessonNo"],
    mounted: function () {
        // this.getAllQuiz();
        this.getCourseById();
    },
    computed: {
        ...mapGetters({
            userType: `schoolModule/${GET_USER_TYPE}`,
            token: `schoolModule/${GET_TOKEN}`,
        }),
    },
    methods: {
        ...mapMutations({
            setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
            setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`

        }),
        // getAllQuiz() {
        //     let url = GET_ALL_QUIZ + "?status=PUBLISHED";
        //     this.setOverlayValue(true);
        //     axios
        //         .get(url, getAxiosOptions(BASE_URL, this.token))
        //         .then((res) => {
        //             this.quizes = res.data.quizes;
        //             this.setOverlayValue(false);
        //         })
        //         .catch((error) => {
        //             error = error.errorMessage;
        //             this.setOverlayValue(false);
        //         });
        // },

        getCourseById() {
            const url = GET_COURSE_BY_ID;
            const payload = {};
            payload.courseId = this.courseId;

            axios
                .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.course = res.data;
                    // this.assignLessons();

                })
                .catch((error) => {
                    error = error.errorMessage;
                });
        },
        // getColor(type) {
        //     if (type == "MEDIA")
        //         return "success";
        //     else if (type == "TEXT")
        //         return "red";
        //     else if (type == "QUIZ")
        //         return "purple";
        //     else
        //         return "primary";
        // },

        validate() {
            this.$refs.form.validate();
        },
        addAssignmentDialog() {
            this.assignmentDialog = true;
            this.editedAssignment = Object.assign({}, this.defaultItem);
        },
        close() {
            this.deleteLessonDialog = false;
            this.assignmentDialog = false;
            this.editLessonDialog = false;

            // this.assignmentDialog = false;
            this.deleteAssignmentDialog = false;
            this.$nextTick(() => {
                this.editedLesson = Object.assign({}, this.defaultItem);
                this.editedAssignment = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        deleteLessonConfirmDialog(item) {
            this.editedIndex = 0;
            this.editedLesson = Object.assign({}, item);
            this.deleteLessonDialog = true;
        },
        closeDeleteDialog() {
            this.deleteLessonDialog = false;
            this.deleteAssignmentDialog = false;
            this.$nextTick(() => {
                this.editedChapter = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        validateCKeditorValues() {
            if (this.editedAssignment.question.length == 0) {
                this.questionFieldError = "This is required";
            } else {
                this.questionFieldError = '';
            }
            if (this.editedAssignment.answer.length == 0) {
                this.solutionFieldError = "This is required";
            } else {
                this.solutionFieldError = '';
            }

        },
        deleteLesson() {
            const url = DELETE_LESSON;
            let payload = {};
            payload.courseId = this.courseId;
            payload.chapterNo = this.chapterNo;
            payload.lessonNo = this.lessonNo;
            payload.deleteType = "CHAPTER";
            this.setOverlayValue(true);
            axios
                .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.$emit("update");
                    this.closeDeleteDialog();
                    this.setOverlayValue(false);
                    this.setApiSuccess("Chapter Lesson deleted successfully");
                })
                .catch((error) => {
                    error = error.errorMessage;
                    this.setOverlayValue(false);
                });
        },
        editLesson(item) {
            this.editLessonDialog = true;
            this.editedIndex = 0;
            this.editedLesson = Object.assign({}, item);
        },
        previewTextAssignment(item) {
            this.editedAssignment = item;
            this.previewTextAssignmentDialog = true;
        },
        closepreviewTextAssignmentDialog() {
            this.previewTextAssignmentDialog = false;
        },


        editTextAssignment(item) {
            this.assignmentDialog = true;
            this.editedIndex = 0;
            this.editedAssignment = Object.assign({}, item);
        },
        saveLesson() {
            if (this.createLessonValidation.valid) {
                let payload = {};
                this.setOverlayValue(true);
                if (this.editedIndex > -1) {
                    payload.courseId = this.courseId;
                    payload.lessonName = this.editedLesson.name;
                    payload.lessonNo = this.lessonNo;
                    payload.chapterNo = this.chapterNo;
                    let url = UPDATE_LESSON;
                    axios
                        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                        .then((res) => {
                            this.createLessonValidation.valid = false;
                            this.editedIndex = -1;
                            this.$emit("update");
                            this.close();
                            this.setOverlayValue(false);
                            this.setApiSuccess("Chapter Lesson updated successfully");
                            // this.getCourseById();
                            // this.initialize();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        },

        saveTextAssignment() {
            this.validate();
            this.setOverlayValue(true);
            if (this.createAssignmentValidation.valid) {
                this.overlay = true;
                const payload = {};
                let url = CREATE_VARIANT_TEXT_ASSIGNMENTS;
                if (this.editedIndex > -1) {
                    url = UPDATE_TEXTASSIGNMENT;
                    payload.assignmentNumber = this.editedAssignment.assignmentNumber;
                }
                payload.title = this.editedAssignment.title;
                payload.question = this.editedAssignment.question;
                payload.answer = this.editedAssignment.answer;
                // payload.courseId = this.courseId;
                // payload.chapterNo = this.chapterNo;
                // payload.lessonNo = this.lessonNo;
                payload.difficultyLevel=this.editedAssignment.difficultyLevel;
                axios
                    .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                    .then((res) => {
                        this.createAssignmentValidation.valid = false;
                        this.overlay = false;
                        this.editedIndex = -1;
                        this.$emit("update");
                        this.close();
                        this.setOverlayValue(false);
                        this.setApiSuccess("Assignment saved successfully");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.overlay = false;
                    });
            }
        },
        deleteAssignmentConfirmDialog(item) {
            this.editedAssignment = Object.assign({}, item);
            this.deleteAssignmentDialog = true;
        },
        deleteAssignment() {
            let url = DELETE_ASSIGNMENT;
            let payload = {};
            this.setOverlayValue(true);
            payload.courseId = this.courseId;
            payload.chapterNo = this.chapterNo;
            payload.lessonNo = this.lessonNo;
            payload.assignmentNumber = this.editedAssignment.assignmentNumber;
            payload.assingmentType = "TEXT";


            axios
                .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.createAssignmentValidation.valid = false;
                    this.editedIndex = -1;
                    this.$emit("update");
                    this.close();
                    this.setOverlayValue(false);
                    this.setApiSuccess("Assignment deleted successfully");
                })
                .catch((error) => {
                    console.log(error);
                    this.setOverlayValue(false);
                });
        },

        //     previewDialogOpen(item) {
        //         this.previewData = Object.assign({}, item);
        //         this.previewDialog = true;

        //     },
        //     closePreviewDialog() {
        //         this.previewData = {};
        //         this.previewDialog = false;
        //     }
    },



};
</script>
