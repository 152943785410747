import { render, staticRenderFns } from "./CompHeader.vue?vue&type=template&id=dcbe58c4"
import script from "./CompHeader.vue?vue&type=script&lang=js"
export * from "./CompHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports