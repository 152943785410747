<template>
  <v-container fluid>
    <v-card flat>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-data-table
        :headers="headers"
        :items="notes"
        hide-default-header
        hide-default-footer
        item-key="noteNo"
        :items-per-page="itemsPerPage"
        @contextmenu:row="rightClickHandler"
      >
        <!-- @click:row="openQuizView" -->
        <template v-slot:[`item.quizName`]="{ item }">
          <!-- <span v-if="item.type == 'QUIZ'">{{ item.quizName }}</span> -->
          <span v-if="item.type == 'MEDIA'"
            ><v-btn
              color="blue"
              class="text-caption"
              text
              @click="previewDialogOpen(item)"
              x-small
              >preview</v-btn
            ></span
          >
          <span
            v-if="item.type == 'TEXT'"
            v-html="item.source.substring(0, 30) + '...'"
          ></span>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          <v-chip :color="getColor(item.type)" dark small>
            {{
              item.type == "MEDIA" && item.resource.status == "BLOCKED"
                ? item.resource.status + " " + item.type
                : item.type
            }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="primary"
                @click="editNote(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="error"
                @click="deleteNoteConfirmDialog(item)"
              >
                <!-- @click="deleteItem(item)" -->
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.noteNo == 1"
                @click="openMoveNoteDialog(item, 'UP')"
              >
                mdi-arrow-up-bold
              </v-icon>
            </template>
            <span>Move Notes Up</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.noteNo == notes.length"
                @click="openMoveNoteDialog(item, 'DOWN')"
              >
                mdi-arrow-down-bold
              </v-icon>
            </template>
            <span>Move Notes Down</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.type == 'TEXT'">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="success"
                @click="openQuizView(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>View</span>
          </v-tooltip>
          <!-- <v-tooltip bottom v-if="item.type == 'MEDIA'">
            <template v-slot:activator="{on}">
          <v-btn :href="item.source" download icon v-on="on">
            <v-icon class="mr-2" color="primary">
              mdi-download
            </v-icon>
          </v-btn>
            </template>
            <span>Download</span>
          </v-tooltip> -->
        </template>
      </v-data-table>
      <v-dialog persistent v-model="moveNoteDialog" width="500">
        <v-card>
          <v-card-title class="primary--text"
            >Move Notes {{ direction }}</v-card-title
          >
          <v-form ref="form" v-model="moveNoteValidation.valid">
            <v-card-text>
              <v-chip class="primary ml-1">Note {{ editedNote.noteNo }}</v-chip>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedNote.destinationIndex"
                      :rules="moveNoteValidation.nameRules"
                      type="number"
                      required
                      label="Move Notes to"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeMoveNoteDialog()"
                    >Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="moveNote()"> Save </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-card-actions>
        <v-row align="end" justify="end">
          <v-dialog
            persistent
            v-model="noteDialog"
            width="600"
            :retain-focus="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addNotesDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="success"> mdi-plus</v-icon>
                  </template>
                  <span>Add Notes</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">New Note</v-card-title>
              <v-card-text>
                <v-chip-group column>
                  <v-chip class="primary">Chapter {{ chapterNo }}</v-chip>
                  <v-chip class="primary">Lesson {{ lessonNo }}</v-chip>
                </v-chip-group>
                <v-form ref="form" v-model="createNoteValidation.valid">
                  <v-radio-group
                    v-model="editedNote.type"
                    row
                    :disabled="editedIndex == 0"
                  >
                    <!-- <v-radio label="Quiz" value="QUIZ"></v-radio> -->
                    <v-radio label="Media" value="MEDIA"></v-radio>
                    <v-radio label="Text" value="TEXT"></v-radio>
                  </v-radio-group>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-if="
                            editedNote.type == 'MEDIA' ||
                            editedNote.type == 'TEXT'
                          "
                          v-model="editedNote.title"
                          label="Title"
                          :rules="createLessonValidation.nameRules"
                          required
                        ></v-text-field>
                        <!-- <v-autocomplete v-if="editedNote.type == 'QUIZ'" :items="quizes"
                          v-model="editedNote.source" label="Quiz Name" :rules="createNoteValidation.nameRules"
                          dense item-text="quizName" item-value="quizId" :disabled="course.status == Note_STATUS_PUBLISHED"></v-autocomplete> -->
                        <!-- <v-text-field v-if="editedNote.type == 'MEDIA'" v-model="editedNote.mediaFile"
                          label="Choose Resource" :rules="createNoteValidation.nameRules" required></v-text-field> -->
                        <span v-if="editedNote.type == 'TEXT'">Notes</span>
                        <quill-editor
                          style="height: 140px; margin-bottom: 30px"
                          :options="editorOptions"
                          v-if="editedNote.type == 'TEXT'"
                          v-model="editedNote.source"
                          :config="editorConfig"
                          :rules="createLessonValidation.nameRules"
                          required
                        ></quill-editor>
                      </v-col>
                      <!-- search component for resource commented for now. uncomment later -->
                    </v-row>
                    <v-col v-if="editedNote.type == 'MEDIA'">
                      <v-menu
                        v-model="menu"
                        max-height="350px"
                        offset-y
                        top
                        offset-x
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" color="primary"
                            >Search source
                          </v-btn>
                        </template>
                        <ResourceSearch
                          @selectResource="OnSelected"
                        ></ResourceSearch>
                      </v-menu>
                      <v-btn
                        v-if="userType == USER_TYPE_RESOURCE_MANAGER"
                        color="primary"
                        class="ml-2"
                        @click="openResourceDialog()"
                        >New Resource</v-btn
                      >
                    </v-col>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveNotes()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="editLessonDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="editLesson()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-pencil</v-icon>
                  </template>
                  <span>Edit Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="primary--text">Edit Lesson</v-card-title>
              <v-form ref="form" v-model="createLessonValidation.valid">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedLesson.name"
                          label="Lesson Name"
                          :rules="createLessonValidation.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveLesson()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteLessonDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteLessonConfirmDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error">mdi-delete</v-icon>
                  </template>
                  <span>Delete Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                >Are you sure you want to delete this Lesson?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteLesson()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="previewDialog"
            eager
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="closePreviewDialog()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
                  previewData.title
                }}</v-toolbar-title>
              </v-toolbar>
              <vue-friendly-iframe
                v-if="previewData.source"
                :src="previewData.source"
              ></vue-friendly-iframe>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteNoteDialog" max-width="500px">
            <v-card>
              <v-card-title
                >Are you sure you want to delete this ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDialog"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteNote">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="resourceDialog" width="60%">
            <v-card class="pt-1">
              <div class="ma-4">
                <v-stepper v-model="e6" vertical non-linear>
                  <v-stepper-step
                    :complete="e6 > 1"
                    step="1"
                    editable
                    color="#F55B49"
                    style="font-size: 20px; padding: 10px; font-weight: 400"
                    ><span class="red--text mt-4"> Basic Details</span>
                    <small class="mt-2">Name, Content Type....</small>
                  </v-stepper-step>
                  <v-stepper-content step="1">
                    <v-container>
                      <v-form
                        v-if="!resource.resourceId || editedIndex > -1"
                        ref="form"
                        v-model="resourceValidation.valid"
                      >
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="resource.name"
                              label="Resource name"
                              :rules="resourceValidation.nameRules"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-combobox
                              v-model="resource.contentType"
                              :items="contentTypes"
                              label="Select content type"
                            ></v-combobox>
                          </v-col>
                          <v-col>
                            <v-btn
                              color="primary"
                              icon
                              @click="createResource()"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on"
                                    >mdi-content-save-check</v-icon
                                  >
                                </template>
                                <span>Save</span>
                              </v-tooltip>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-form>
                      <v-row v-else>
                        <v-col>
                          <h3 class="primary--text">
                            {{ resource.name }}
                          </h3>
                        </v-col>
                        <v-col>
                          <v-chip class="ma-2" color="secondary" small>
                            {{ resource.contentType }}
                          </v-chip>
                        </v-col>
                        <v-col>
                          <v-chip class="ma-2" color="secondary" small>
                            {{ resource.status }}
                          </v-chip>
                        </v-col>
                        <v-col>
                          <span class="ma-2 text-caption">{{
                            formatDateTime(resource.createdOn)
                          }}</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-stepper-content>
                  <v-stepper-step
                    :complete="e6 > 2"
                    step="2"
                    editable
                    style="font-size: 20px; padding: 10px; font-weight: 400"
                  >
                    Source
                  </v-stepper-step>
                  <v-stepper-content step="2">
                    <v-container v-if="resource.contentType == 'MEDIA'">
                      <v-row>
                        <v-col v-if="resource.source" cols="8">
                          <v-card-title>
                            <v-chip class="ma-2" small color="primary">
                              {{ resource.sourceType }}
                            </v-chip>
                            <v-chip
                              class="ma-1"
                              x-small
                              color="green"
                              label
                              outlined
                            >
                              Mime Type
                            </v-chip>
                            <v-chip
                              class="ma-1"
                              x-small
                              color="green"
                              label
                              outlined
                            >
                              {{ resource.mimeType }}
                            </v-chip>
                          </v-card-title>
                        </v-col>
                        <v-col cols="4">
                          <v-card flat>
                            <v-badge icon bottom avatar overlap>
                              <template v-slot:badge>
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" @click="addThumbnail()"
                                      >mdi-upload</v-icon
                                    >
                                  </template>
                                  <span>Upload</span>
                                </v-tooltip>
                                <input
                                  ref="uploader"
                                  class="d-none"
                                  type="file"
                                  @change="onFileChanged"
                                />
                              </template>
                              <v-avatar
                                v-if="!preview && !resource.source"
                                size="50"
                              >
                                <v-img
                                  class="align-start text-white"
                                  height="50"
                                  src="@/assets/Avatar_sales.png"
                                  contain
                                >
                                </v-img>
                              </v-avatar>
                              <vue-friendly-iframe
                                v-else-if="!preview && resource.source"
                                :src="resource.source"
                              ></vue-friendly-iframe>
                              <v-avatar v-else size="50">
                                <v-img :src="preview"> </v-img>
                              </v-avatar>
                            </v-badge>
                          </v-card>
                          <v-card-actions v-if="showUploadBtns">
                            <v-btn color="primary" x-small icon>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on" @click="uploadFile()"
                                    >mdi-upload</v-icon
                                  >
                                </template>
                                <span>Upload</span>
                              </v-tooltip>
                            </v-btn>
                            <v-btn color="primary" x-small icon>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon
                                    v-on="on"
                                    color="error"
                                    @click="cancelUpload()"
                                    >mdi-cancel</v-icon
                                  >
                                </template>
                                <span>Cancel</span>
                              </v-tooltip>
                            </v-btn>
                          </v-card-actions>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-container v-else>
                      <v-row>
                        <v-col cols="12">
                          <v-row>
                            <template
                              v-if="
                                resource.images && resource.images.length > 0
                              "
                            >
                              <v-col
                                v-for="(image, index) in resource.images"
                                :key="index"
                                cols="auto"
                                class="d-flex flex-column align-items-center"
                              >
                                <v-avatar size="50">
                                  <v-img :src="image.source"></v-img>
                                </v-avatar>
                                <span>{{ image.sequence }}</span>
                              </v-col>
                            </template>
                            <template v-else>
                              <v-col
                                v-for="(image, index) in selectedImages"
                                :key="index"
                                cols="auto"
                                class="d-flex flex-column align-items-center"
                              >
                                <v-avatar size="50">
                                  <v-img :src="image.url"></v-img>
                                </v-avatar>
                                <v-icon
                                  small
                                  color="error"
                                  class="mt-2"
                                  @click="removeImage(index)"
                                  >mdi-close-circle</v-icon
                                >
                              </v-col>
                              <v-col
                                cols="auto"
                                class="d-flex flex-column align-items-center"
                              >
                                <v-avatar size="50">
                                  <v-icon @click="selectImages()"
                                    >mdi-plus</v-icon
                                  >
                                </v-avatar>
                                <span class="mt-2">Add Images</span>
                              </v-col>
                            </template>
                          </v-row>
                          <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            accept="image/*"
                            multiple
                            @change="onFileChanged2"
                          />
                          <v-row>
                            <v-col
                              cols="12"
                              class="d-flex justify-end"
                              v-if="selectedImages.length > 0"
                            >
                              <v-btn color="primary" text @click="viewImages3"
                                >View</v-btn
                              >
                              <v-btn
                                color="primary"
                                text
                                @click="sequenceImages"
                                >Sequence</v-btn
                              >
                              <v-btn color="primary" text @click="uploadImages"
                                >Upload</v-btn
                              >
                            </v-col>
                            <v-col
                              cols="12"
                              class="d-flex justify-end"
                              v-if="
                                resource.images && resource.images.length > 0
                              "
                            >
                              <v-btn color="primary" text @click="viewImages2"
                                >View</v-btn
                              >
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-container>

                    <v-dialog v-model="viewPdfDialog3" max-width="800px">
                      <v-card>
                        <v-card-title class="headline"
                          >View Images</v-card-title
                        >
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                v-for="(image, index) in selectedImages"
                                :key="index"
                                cols="12"
                                class="mb-4"
                              >
                                <v-img
                                  :src="image.url"
                                  aspect-ratio="1.5"
                                ></v-img>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="viewPdfDialog3 = false"
                            >Close</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="viewPdfDialog2" max-width="800px">
                      <v-card>
                        <v-card-title class="headline"
                          >View Images</v-card-title
                        >
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col
                                v-for="(image, index) in resource.images"
                                :key="index"
                                cols="12"
                                class="mb-4"
                              >
                                <v-img
                                  :src="image.source"
                                  aspect-ratio="1.5"
                                ></v-img>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="viewPdfDialog2 = false"
                            >Close</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>

                    <v-dialog v-model="sequenceDialog" max-width="800px">
                      <v-card>
                        <v-card-title class="headline"
                          >Sequence Images</v-card-title
                        >
                        <v-card-text>
                          <v-list>
                            <v-list-item
                              v-for="(image, index) in selectedImages"
                               :key="sequenceKey + '-' + index"
                              class="d-flex align-center"
                            >
                              <v-list-item-avatar>
                                <v-img :src="image.url"></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title
                                  >Image {{ index + 1 }}</v-list-item-title
                                >
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-select
                                  v-model="image.sequence"
                                  :items="sequenceOptions"
                                  label="Sequence"
                                  dense
                                  outlined
                                  @change="
                                    onSequenceChange(index, image.sequence)
                                  "
                                ></v-select>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="sequenceDialog = false"
                            >Close</v-btn
                          >
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-stepper-content>
                </v-stepper>
              </div>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeResourceDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="publishResource()"
                  >Publish</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="viewPdfDialog"
            eager
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dense dark color="primary">
                <v-btn icon dark @click="viewPdfDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
                  previewData.title
                }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="(image, index) in previewData.images"
                      :key="index"
                      cols="12"
                      class="mb-4"
                    >
                      <v-img :src="image.source" aspect-ratio="1.5"></v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="viewPdfDialog = false"
                  >Close</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  ADD_Note,
  BASE_URL,
  CREATE_QUIZ,
  DELETE_NOTES,
  DELETE_LESSON,
  GET_ALL_QUIZ,
  GET_COURSE_BY_ID,
  UPDATE_NOTES,
  UPDATE_LESSON,
  UPLOAD_FILE,
  MOVE_NOTE,
  ADD_NOTES,
  UPLOAD_IMAGES,
  UPDATE_RESOURCE,
  CREATE_RESOURCE,
  UPLOAD_RESOURCE,
  GET_RESOURCE,
  PUBLISH_RESOURCE,

} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions, rightClickHandler, formatDateTime } from "@/common/utility";
import ResourceSearch from "./ResourceSearch.vue";
import Resources from "@/components/Resources.vue";
import ContextMenu from "./ContextMenu.vue";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import { SET_API_SUCCESS } from "@/store/mutations";
export default {
  data() {
    return {
      editorOptions: {
        theme: "snow",
        viewPdfDialog: false,
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: [] }],
            [{ color: [] }, { background: [] }],

            ["link"],
          ],
        },
      },
      e6: 1,
      resource: {
        tags: [],
      },
      contextMenuObject: {},
      selectedImages: [],
      viewPdfDialog3: false,
      viewPdfDialog2: false,
      sequenceDialog: false,
      sequenceOptions: [],
      selectedFiles: [],
      editorConfig: {},
      imagesPdf: {},
      viewPdfDialog: false,
      headers: [
        {
          text: "Note No",
          align: "start",
          value: "NoteNo",
        },
        { text: "Title", value: "title" },
        { text: "Quiz Name", value: "quizName" },
        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
        //   { text: 'Carbs (g)', value: 'carbs' },
        //   { text: 'Protein (g)', value: 'protein' },
        //   { text: 'Iron (%)', value: 'iron' },
      ],
      contentTypes: ["MEDIA", "IMAGE_ARRAY"],
      noteDialog: false,
      resourceDialog: false,
      moveNoteDialog: false,
      deleteNoteDialog: false,
      editLessonDialog: false,
      deleteLessonDialog: false,
      editMode: false,
      previewDialog: false,
      previewData: {},
      sequenceKey: 0 ,
      editedIndex: -1,
      itemsPerPage: -1,
      editedNote: {},
      editedLesson: {},
      defaultItem: {},
      course: {},
      direction: "",
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      moveNoteValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createNoteValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      resourceValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      menu: false,
      
    };
  },
  components: { ResourceSearch, ContextMenu, Resources },
  props: ["notes", "courseId", "chapterNo", "lessonNo"],
  mounted: function () {
    // this.getAllQuiz();
    this.getCourseById();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    // getAllQuiz() {
    //   let url = GET_ALL_QUIZ + "?status=PUBLISHED";
    //    this.setOverlayValue(true);
    //   axios
    //     .get(url, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.quizes = res.data.quizes;
    //        this.setOverlayValue(false);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          // this.assignLessons();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getColor(type) {
      if (type == "MEDIA") return "success";
      else if (type == "TEXT") return "red";
      else if (type == "QUIZ") return "purple";
      else return "primary";
    },
    openResourceDialog() {
      this.resourceDialog = true;
    },
    closeResourceDialog() {
      this.resourceDialog = false;
      this.resource = {};
    },
    getResourceDetails(item) {
      let payload = {};
      payload.resourceId = item.resourceId;
      let url = GET_RESOURCE;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.resource = res.data;
          this.OnSelected(res.data);
        })
        .catch((error) => {});
    },
    createResource() {
      if (this.resourceValidation.valid) {
        let payload = {};
        let url = "";
        if (this.editedIndex > -1) {
          url = UPDATE_RESOURCE;
        } else {
          url = CREATE_RESOURCE;
        }
        payload = this.resource;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.resourceValidation.valid = false;
            this.resource = res.data;
            if (this.editedIndex > -1) {
              this.setApiSuccess("Resource updated successfully");
            } else {
              this.setApiSuccess("Resource created successfully");
              this.resource.unshift(res.data);
            }
            this.e6 = 2;
            this.editedIndex = -1;
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
          });
      }
    },
    publishResource() {
      let url = PUBLISH_RESOURCE;
      let payload = {};
      payload.resourceId = this.resource.resourceId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeResourceDialog();
          this.setApiSuccess("Resource published successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
        });
    },
    uploadFile() {
      let payload = {};
      payload.file = this.file;
      payload.resourceId = this.resource.resourceId;
      let url = UPLOAD_RESOURCE;
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
          this.getResourceDetails(this.resource);
          this.setOverlayValue(false);
          this.setApiSuccess("Source added successfully");
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    cancelUpload() {
      this.showUploadBtns = false;
      this.file = {};
      this.preview = "";
    },
    uploadImages() {
      const formData = new FormData();
      this.selectedFiles.forEach((file) => {
        formData.append("files", file);
      });
      formData.append("resourceId", this.resource.resourceId);
      let url = UPLOAD_IMAGES;
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, formData, options)
        .then((res) => {
          this.selectedFiles = [];
          this.selectedImages = [];
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
          this.getResourceDetails(this.resource);
          this.setOverlayValue(false);
          this.setApiSuccess("Source added successfully");
        })
        .catch((error) => {
          this.setOverlayValue(false);
          console.error("Error uploading files:", error);
        });
    },
    viewImages(item) {
      this.imagesPdf = Object.assign({}, item);
      console.log(item);
      console.log(this.imagesPdf);
    },
    sequenceImages() {
      this.updateSequenceOptions();
      this.sequenceDialog = true;
    },
    onSequenceChange(index, newSequence) {
      if (newSequence < 1 || newSequence > this.selectedImages.length) {
        return;
      }
      const movedImage = this.selectedImages.splice(index, 1)[0];
      this.selectedImages.splice(newSequence - 1, 0, movedImage);
      const movedFile = this.selectedFiles.splice(index, 1)[0];
      this.selectedFiles.splice(newSequence - 1, 0, movedFile);
      this.selectedImages.forEach((image, idx) => {
        image.sequence = idx + 1;
      });

      this.updateSequenceOptions();
      this.sequenceKey++;
    },
    saveSequence() {
      console.log("New sequence:", this.selectedImages);
      this.sequenceDialog = false;
    },
    updateSequenceOptions() {
      const length = this.selectedImages.length;
      this.sequenceOptions = Array.from({ length }, (_, i) => i + 1);
    },
    viewImages3() {
      this.viewPdfDialog3 = true;
    },
    viewImages2() {
      this.viewPdfDialog2 = true;
    },
    onFileChanged2(event) {
  const newFiles = Array.from(event.target.files);

  if (!this.selectedFiles) {
    this.selectedFiles = newFiles;
    return;
  }

  this.selectedFiles = [...this.selectedFiles, ...newFiles];

  for (let i = 0; i < newFiles.length; i++) {
    const file = newFiles[i];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedImages.push({
        url: e.target.result,
        sequence: this.selectedImages.length + 1,
      });
      this.updateSequenceOptions();
    };
    reader.readAsDataURL(file);
  }
}
,

    selectImages() {
      this.$refs.uploader.click();
    },
    removeImage(index) {
      this.selectedImages.splice(index, 1);
      this.selectedFiles.splice(index, 1);
    },
    openQuizView(item) {
      this.$router.push({ name: "TextView", params: { textContent: item } });
    },
    // getMediaName(item) {
    //   // if(item.includes("_"))
    //   return item.source.substring(item.source.indexOf("_") + 1);
    //   // return item.source;
    // },
    validate() {
      this.$refs.form.validate();
    },
    addNotesDialog() {
      this.noteDialog = true;
      this.editedNote = Object.assign({}, this.defaultItem);
    },
    close() {
      this.deleteLessonDialog = false;
      this.editLessonDialog = false;
      this.noteDialog = false;
      this.deleteNoteDialog = false;
      this.$nextTick(() => {
        this.editedLesson = Object.assign({}, this.defaultItem);
        this.editedNote = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLessonConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
      this.deleteLessonDialog = true;
    },
    closeDeleteDialog() {
      this.deleteLessonDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLesson() {
      const url = DELETE_LESSON;
      let payload = {};
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.deleteType = "CHAPTER";
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.$emit("update");
          this.closeDeleteDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter Lesson deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    editLesson(item) {
      this.editLessonDialog = true;
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
    },
    editNote(item) {
      this.noteDialog = true;
      this.editMode = true;
      this.editedIndex = 0;
      this.editedNote = Object.assign({}, item);
    },
    openMoveNoteDialog(item, direction) {
      this.moveNoteDialog = true;
      this.editedNote.noteNo = item.noteNo;
      this.direction = direction;
      if (direction == "UP") {
        this.editedNote.direction = "UP";
      } else {
        this.editedNote.direction = "DOWN";
      }
    },
    closeMoveNoteDialog() {
      this.moveNoteDialog = false;
      this.$nextTick(() => {
        this.editedNote = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveNote() {
      if (this.moveNoteValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
        payload.noteNo = this.editedNote.noteNo;
        payload.direction = this.editedNote.direction;
        payload.destinationIndex = this.editedNote.destinationIndex;
        let url = MOVE_NOTE;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.moveNoteValidation.valid = false;
            this.editedIndex = -1;
            this.$emit("update");
            this.close();
            this.setOverlayValue(false);
            this.closeMoveNoteDialog();
            this.setApiSuccess("Notes moved successfully");
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveNoteDialog();
          });
      }
    },
    saveLesson() {
      if (this.createLessonValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        if (this.editedIndex > -1) {
          payload.courseId = this.courseId;
          payload.lessonName = this.editedLesson.name;
          payload.lessonNo = this.lessonNo;
          payload.chapterNo = this.chapterNo;
          let url = UPDATE_LESSON;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createLessonValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Lesson updated successfully");
              // this.getCourseById();
              // this.initialize();
            })
            .catch((error) => {
              this.setOverlayValue(false);
              console.log(error);
            });
        }
      }
    },
    saveNotes() {
      this.validate();
      this.setOverlayValue(true);
      if (this.createNoteValidation.valid) {
        const payload = {};
        let url = ADD_NOTES;
        if (this.editedIndex > -1) {
          url = UPDATE_NOTES;
          payload.noteNo = this.editedNote.noteNo;
        }
        if (this.editedNote.type == "QUIZ") {
          // payload.title = this.editedNote.title;
          payload.type = this.editedNote.type;
          payload.source = this.editedNote.source;
          payload.title = this.editedNote.title;
          this.quizes.filter((quiz) => {
            if (quiz.quizId == this.editedNote.quizId) {
              payload.quizName = quiz.quizName;
            }
          });
          payload.courseId = this.courseId;
          payload.chapterNo = this.chapterNo;
          payload.lessonNo = this.lessonNo;
          payload.addType = "CHAPTER";
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createNoteValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Note saved successfully");
            })
            .catch((error) => {
              console.log(error);
              this.setOverlayValue(false);
            });
        } else if (this.editedNote.type == "MEDIA") {
          payload.title = this.editedNote.title.trim();
          payload.type = "RESOURCE";
          payload.source = this.editedNote.source;
          payload.courseId = this.courseId;
          payload.chapterNo = this.chapterNo;
          payload.lessonNo = this.lessonNo;
          payload.resourceId = this.editedNote.resourceId;
          payload.addType = "CHAPTER";
          // url = UPLOAD_FILE;
          const options = getAxiosOptions(BASE_URL, this.token);
          // options.headers["Note-Type"] = "multipart/form-data";
          axios
            .post(url, payload, options)
            .then((res) => {
              this.createNoteValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Resource added successfully");
            })
            .catch((error) => {
              console.log(error);
              this.setOverlayValue(false);
            });
        } else if (this.editedNote.type == "TEXT") {
          if (!(this.editedNote.source.length > 1)) {
            this.setOverlayValue(false);
            return;
          }
          payload.courseId = this.courseId;
          payload.chapterNo = this.chapterNo;
          payload.lessonNo = this.lessonNo;
          payload.addType = "CHAPTER";
          payload.title = this.editedNote.title.trim();
          payload.type = this.editedNote.type;
          payload.source = this.editedNote.source;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createNoteValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Note saved successfully");
            })
            .catch((error) => {
              console.log(error);
              this.setOverlayValue(false);
            });
        } else {
          payload.courseId = this.courseId;
          payload.chapterNo = this.chapterNo;
          payload.lessonNo = this.lessonNo;
          payload.addType = "CHAPTER";
          payload.title = this.editedNote.title;
          payload.source = this.editedNote.source;
          payload.type = this.editedNote.type;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createNoteValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Note saved successfully");
            })
            .catch((error) => {
              console.log(error);
              this.setOverlayValue(false);
            });
        }
      }
    },
    deleteNoteConfirmDialog(item) {
      this.editedNote = Object.assign({}, item);
      this.deleteNoteDialog = true;
    },
    deleteNote() {
      let url = DELETE_NOTES;
      let payload = {};
      this.setOverlayValue(true);
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.noteNo = this.editedNote.noteNo;
      payload.deleteType = "CHAPTER";
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.createNoteValidation.valid = false;
          this.editedIndex = -1;
          this.$emit("update");
          this.close();
          this.setOverlayValue(false);
          this.setApiSuccess("Note deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    OnSelected(resource) {
      this.editedNote.title = resource.name;
      this.editedNote.resourceId = resource.resourceId;
      this.editedNote.source = resource.source;
      this.menu = false;
    },
    previewDialogOpen(item) {
      this.previewData = Object.assign({}, item);
      if (this.previewData.images && this.previewData.images.length > 0) {
        this.viewPdfDialog = true;
      } else {
        this.previewDialog = true;
      }
    },
    closePreviewDialog() {
      this.previewData = {};
      this.previewDialog = false;
    },
  },
};
</script>
