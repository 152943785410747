export const SET_ISAUTHENTICATED = "setIsAuthenticated";
export const SET_TOKEN = "setToken";
export const SET_USER_PROFILE = "setUserProfile";
export const SET_API_ERROR = "setApiError";
export const SET_API_SUCCESS = "setApiSuccess";
export const SET_OVERLAY_VALUE = "setOverlayValue";
export const SET_TOKEN_EXPIRED = "setTokenExpired";
export const SET_PARAMS_VALUE = "setParamsValue";
export const SET_COIN_BALANCE = "setCoinBalance";
export const SET_COURSE_EXPANSION_PANEL = "setCourseExpansion";
export const SET_COURSE_EXPANSION_CHAPTER = "setCourseExpansionChapter";
export const SET_COURSE_EXPANSION_LESSON = "setCourseExpansionLesson";
export const SET_INSTITUTE_EXPANSION_PANEL = "setInstituteExpansion";
export const SET_INSTITUTE_EXPANSION_BATCH = "setInstituteExpansionBatch";
export const SET_STUDENT_RANK = "setStudentRank";

