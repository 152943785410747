<template>
  <div>
    <v-row class="ma-2">
    <v-toolbar flat dark color="primary">
      <v-toolbar-title style="font-weight: 500; font-size: 24px;">Upload Blockly Files</v-toolbar-title>
    </v-toolbar>
    </v-row>
    <div class="ma-2 pa-2">
    <v-input class="ma-4 down"> Blockly </v-input>
    <v-file-input
      v-model="editedBlockly.media"
      label="File Input"
      outlined
    ></v-file-input>

    <v-btn color="success" class="ma-4" @click="uploadBlockly()">
      Save Changes</v-btn
    >
    <v-btn color="error" @click="cancelBlocklyUpload()"> Cancel</v-btn>
  </div>
  </div>
</template>



<script>
import { BASE_URL, UPLOAD_BLOCKLY_FILE } from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import { mapGetters, mapMutations } from 'vuex';
import { GET_TOKEN } from '@/store/getters';
import { SET_API_SUCCESS } from '@/store/mutations';
import axios from 'axios';
export default {
  data() {
    return {
      editedBlockly: {},
    };
  },
    computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
    }),

    uploadBlockly() {
      let payload = {};
      payload.file = this.editedBlockly.media;
      let url = UPLOAD_BLOCKLY_FILE;
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      axios
        .post(url, payload, options)
        .then((res) => {
          this.setApiSuccess("Blockly File uploaded successfully");
          this.editedBlockly = {};
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.editedBlockly = {};
        });
    },
    cancelBlocklyUpload() {
      this.editedBlockly = {};
    },
  },
};
</script>