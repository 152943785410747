<template>
  <v-dialog
    height="350"
    width="400"
    v-model="dialogState"
    light
    transition="fab-transition"
    persistent
  >
    <!-- <v-carousel
      cycle
      height="350"
      hide-delimiters
      show-arrows-on-hover
    > -->
      <!-- <v-carousel-item v-for="(nm, i) in notificationMessages" :key="i"> -->
        <v-card height="350"  style="border:  1px solid #f55b49; border-radius: 4px">
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-img
              class="mt-1 mr-1"
              max-height="30"
              max-width="30"
              src="../assets/Notification/close_button.png"
              @click="dialogState=false"
              ></v-img>
            </v-row>
            <v-spacer></v-spacer>
            <v-card-title class="primary--text text-subtitle-1 justify-center ">{{ notificationMessages[0].message }}</v-card-title>
            <v-row>
              <v-spacer></v-spacer>
              <v-img
                class="mx-auto"
                max-height="150"
                max-width="150"
                :src="getImage(notificationMessages[0].type)"
              ></v-img>
              <v-spacer></v-spacer>
            </v-row>
            <v-card-text class="font-weight-bold text-subtitle-2 primary--text pa-2">
              Course Name:
              <span class="text-body-2 error--text"
                >{{notificationMessages[0].courseName}}</span
              >
            </v-card-text>
            <v-card-text class="font-weight-bold text-subtitle-2 primary--text pa-2">
              Classroom Name:
              <span class="text-body-2 error--text">{{
                notificationMessages[0].classRoomName
              }}</span>
            </v-card-text>
            <v-card-text v-if="notificationMessages[0].type == 'CLASS_END'">
              <v-chip-group column>
                <v-chip class="primary">{{ notificationMessages[0].chapterNo }}</v-chip>
                <v-chip class="primary">{{ notificationMessages[0].lessonNo }}</v-chip>
              </v-chip-group>
            </v-card-text>
            <v-card-text v-if="notificationMessages[0].type == 'CLASS_RESCHEDULED'" class="ma-0 pa-0">
              <v-chip-group column >
                <v-chip class="primary">{{ formatDateTime(notificationMessages[0].date) }}</v-chip>
                <v-chip class="primary">{{ notificationMessages[0].openTime }}</v-chip>
                <v-chip class="primary">{{ notificationMessages[0].closeTime }}</v-chip>
              </v-chip-group>
            </v-card-text>

            <v-card-actions v-if="notificationMessages[0].type == 'CLASS_STARTED'">
              <v-btn color="primary" @click="joinClass(notificationMessages[0].classLink)">
                Join Class
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      <!-- </v-carousel-item> -->
    <!-- </v-carousel> -->
  </v-dialog>
</template>

<script>
import {  formatDateTime,} from "@/common/utility";
export default {
  data() {
    return {
      dialogState: true,
    };
  },
  props: ["notificationMessages"],
  mounted() {
    this.playAudio();
  },
  methods: {
    playAudio() {
      var audio = new Audio(require("../assets/Sounds/notes_released.mp3"));
      audio.play();
    },
    joinClass(classLink) {
      if (classLink.startsWith("http://") || classLink.startsWith("https://")) {
        window.open(classLink, "_blank");
      } else {
        let x = "https://";
        classLink = x.concat(classLink);
        window.open(classLink, "_blank");
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getImage(notificationType){
      if(notificationType=='CLASS_STARTED'){
        return require("../assets/Notification/class_start.png");
      }else if(notificationType=='CLASS_END'){
        return require("../assets/Notification/class_end.png");
      }else if(notificationType=='CLASS_RESCHEDULED'){
        return require("../assets/Notification/class_rescheduled.png");
      }else if(notificationType=='ASSIGNMENT_SUBMITTED'){
        return require("../assets/Notification/assignment_submitted.png")
      }else if(notificationType=='ASSIGNMENT_EVALUATED'){
        return require("../assets/Notification/assignment_evaluated.png");
      }else{
        return "";
      }
    }
  },
};
</script>
