<template>
  <v-container fluid>
    <v-card flat>
      <v-data-table
        :headers="quizAssignmentHeaders"
        :items="quizAssignments"
        hide-default-header
        hide-default-footer
        item-key="assignmentNumber"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="primary"
                @click="editQuizAssignment(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="error"
                @click="deleteQuizConfirmDialog(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.assignmentNumber == 1"
                @click="openMoveAssignmentDialog(item, 'UP')"
              >
                mdi-arrow-up-bold
              </v-icon>
            </template>
            <span>Move Assignment Up</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
               :disabled="item.assignmentNumber == quizAssignments.length"
                @click="openMoveAssignmentDialog(item, 'DOWN')"
              >
                mdi-arrow-down-bold
              </v-icon>
            </template>
            <span>Move Assignment Down</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="success"
                @click="getQuizByQuizId(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>View</span>
          </v-tooltip>
          </template>
          
          <template v-slot:[`item.type`]="{ item }">
  <v-chip
    small
    :color="item.afterContent == null ? 'green' : 'blue'"
    text-color="white"
  >
    {{ item.afterContent == null ? 'Homework' : 'After Content' }}
    <span v-if="item.afterContent != null">&nbsp;{{ item.afterContent }}</span>
  </v-chip>
</template>

      </v-data-table>
        <v-dialog v-model="imgDialog">
          <v-card>
            <v-img :src="questionMedia"></v-img>
          </v-card>
        </v-dialog>
      <v-dialog v-model="quizPreviewDialog" eager persistent max-width="700">

          <v-card>
            <v-toolbar dark color="primary">
              <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
                selectedQuiz.quizName
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closeQuizPreviewDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <div
              v-if="
                selectedQuiz &&
                selectedQuiz.questions &&
                selectedQuiz.questions.length > 0
              "
            >
              <v-col class="ma-2">
                <span style="font-size: 18px" v-html="formatQuestionTitle(selectedQuiz.questions[index].questionNo,selectedQuiz.questions[index].title,selectedQuiz.questions.length)">
                  </span
                >
                <div class="mt-4" v-if="selectedQuiz.questions[index].media">
                  <v-img
                    max-width="300"
                    max-height="300"
                    @click="viewImage(selectedQuiz.questions[index])"
                    :src="selectedQuiz.questions[index].media"
                  ></v-img>
                  <span
                    v-if="selectedQuiz.questions[index].media"
                    style="font-size: 14px"
                    >If image is not clear , click on image to see full
                    image</span
                  >
                </div>
                <v-col
                  class="pl-0"
                  v-for="option in selectedQuiz.questions[index].options"
                  :key="option.number"
                >
                  <label :class="option.isAnswer ? 'success--text' : ''">
                    <input
                      type="radio"
                      name="option.value"
                      :value="option.number"
                      disabled
                      :checked="option.isAnswer"
                    />
                    {{ option.value }}</label
                  >
                </v-col>
              </v-col>
              <v-row class="ma-4">
                <span style="font-weight: 500; font-size: 18px">
                  Explanation :
                </span>
                <span
                  class="purple--text"
                  style="font-weight: 400; font-size: 16px"
                  v-html="selectedQuiz.questions[index].explaination"
                >
                </span>
              </v-row>
              <v-row class="ma-2">
                <v-spacer></v-spacer>
                <v-btn
                  class="ma-2"
                  small
                  :disabled="index == 0"
                  color="primary"
                  @click="backpage()"
                  >Back</v-btn
                >
                <v-btn
                  class="ma-2"
                  small
                  :disabled="index == selectedQuiz.questions.length - 1"
                  color="primary"
                  @click="nextPage()"
                  >Next</v-btn
                >
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-card>
        </v-dialog>
      <v-dialog persistent v-model="moveAssignmentDialog" width="500">
        <v-card>
          <v-card-title class="primary--text">Move Quizes {{ direction }}</v-card-title>
          <v-form ref="form" v-model="moveAssignmentValidation.valid">
            <v-card-text>
              <v-chip class="primary ml-1">Quiz {{ editedQuiz.assignmentNumber }}</v-chip>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedQuiz.destinationIndex"
                      :rules="moveAssignmentValidation.nameRules"
                      type="number"
                      required
                      label="Move Quizes to"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeMoveAssignmentDialog()"
                    >Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="moveAssignment()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-card-actions>
        <v-row align="end" justify="end">
          <v-dialog
            persistent
            v-model="quizAssignmentDialog"
            width="600"
            :retain-focus="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addQuizDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="success"> mdi-plus</v-icon>
                  </template>
                  <span>Add Quiz Assignment</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text"
                >New Quiz Assignment</v-card-title
              >
              <v-card-text>
                <v-chip-group column>
                  <v-chip class="primary">Chapter {{ chapterNo }}</v-chip>
                  <v-chip class="primary">Lesson {{ lessonNo }}</v-chip>
                </v-chip-group>
                <v-form ref="form" v-model="createQuizValidation.valid">
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          :items="quizes"
                          v-model="editedQuiz.quizId"
                          label="Quiz Name"
                          :rules="createQuizValidation.nameRules"
                          dense
                          item-text="quizName"
                          item-value="quizId"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                     <v-col col="2"
                      v-if="editedQuiz.isHomework!='true'"
                        :disabled="true"
                        >
                          <v-text-field
                          type="number"
                          required 
                          label="After content"
                          v-model="editedQuiz.afterContent"
                          :rules="createQuizValidation.contentRules"
                          
                          >
                          </v-text-field>
                        </v-col>
                          <v-col
                        >
                          <v-checkbox
                          v-model="editedQuiz.isHomework"
                          label="Home Work"
                          value="true"
                          required
                          :disabled="editedIndex != -1"
                          >
                          </v-checkbox>
                        </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveQuiz()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="editLessonDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="editLesson()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-pencil</v-icon>
                  </template>
                  <span>Edit Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">Edit Lesson</v-card-title>
              <v-form ref="form" v-model="createLessonValidation.valid">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedLesson.name"
                          label="Lesson Name"
                          :rules="createLessonValidation.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveLesson()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteLessonDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteLessonConfirmDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error">mdi-delete</v-icon>
                  </template>
                  <span>Delete Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                >Are you sure you want to delete this Lesson?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteLesson()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteQuizDialog" max-width="500px">
            <v-card>
              <v-card-title
                >Are you sure you want to delete this ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDialog"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteQuizAssignment"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template> 
<script>
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  BASE_URL,
  ADD_QUIZASSIGNMENT,
  UPDATE_QUIZASSIGNMENT,
  DELETE_LESSON,
  GET_ALL_QUIZ,
  GET_COURSE_BY_ID,
  UPDATE_LESSON,
  UPLOAD_FILE,
  MOVE_ASSIGNMENT,
  GET_QUIZ_BY_ID,
  DELETE_ASSIGNMENT,
} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions, rightClickHandler,formatQuestionTitle } from "@/common/utility";
import ResourceSearch from "./ResourceSearch.vue";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import { SET_API_SUCCESS } from "@/store/mutations";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      quizAssignmentHeaders: [
        {
          text: "Assignment No",
          align: "start",
          value: "assignmentNumber",
        },
        { text: "Quiz Name", value: "quizName" },
        { text: 'Type', value: 'type' },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editLessonDialog: false,
      deleteLessonDialog: false,
      deleteQuizDialog: false,
      moveAssignmentDialog: false,
      quizPreviewDialog:false,
      index:0,
      quizAssignmentDialog: false,
      selectedQuiz: { questions: [] },
      imgDialog:false,
      questionMedia:"",
      editedIndex: -1,
      itemsPerPage: -1,
      editedQuiz: {},
      editedLesson: {},
      defaultItem: {},
      course: {},
      direction: "",
      overlay: false,
      quizes: [],
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      moveAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createQuizValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
         contentRules:[
        (v) => !!v || "This is required",
          (v) => (Number(0) < v && Number(this.noOfContents) >= v) || "content must be greater than 0 and less than "+(this.noOfContents+1),
        ],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  components: { ResourceSearch, ContextMenu },
  props: ["quizAssignments", "courseId", "chapterNo", "lessonNo","noOfContents"],
  mounted: function () {
    this.getAllQuiz();
    this.getCourseById();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    getAllQuiz() {
      let url = GET_ALL_QUIZ + "?status=PUBLISHED";
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizes = res.data.quizes;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getColor(type) {
      if (type == "MEDIA") return "success";
      else if (type == "TEXT") return "red";
      else if (type == "QUIZ") return "purple";
      else return "primary";
    },
    // openQuizView(item) {
    //   this.$router.push({
    //     name: "QuizView",
    //     params: { quizId: item.quizId },
    //   });
    // },
    closeQuizPreviewDialog() {
      this.quizPreviewDialog = false;
    },
    viewImage(question) {
      this.imgDialog = true;
      this.questionMedia = question.media;
    },
    getQuizByQuizId(item) {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = item.quizId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.selectedQuiz = res.data;
          this.quizPreviewDialog = true;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    nextPage() {
      if (this.index < this.selectedQuiz.questions.length) {
        this.index++;
      }
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    addQuizDialog() {
      this.quizAssignmentDialog = true;
      this.editedQuiz = Object.assign({}, this.defaultItem);
    },
    close() {
      this.deleteLessonDialog = false;
      this.editLessonDialog = false;
      this.quizAssignmentDialog = false;
      this.deleteQuizDialog = false;
      this.$nextTick(() => {
        this.editedLesson = Object.assign({}, this.defaultItem);
        this.editedQuiz = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLessonConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
      this.deleteLessonDialog = true;
    },
    closeDeleteDialog() {
      this.deleteLessonDialog = false;
      this.deleteQuizDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLesson() {
      const url = DELETE_LESSON;
      let payload = {};
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.deleteType = "CHAPTER";
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.$emit("update");
          this.closeDeleteDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter's Lesson deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    editLesson(item) {
      this.editLessonDialog = true;
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
    },
    editQuizAssignment(item) {
      this.quizAssignmentDialog = true;
      this.editedIndex = 0;
      this.editedQuiz = Object.assign({}, item);
    },
    openMoveAssignmentDialog(item, direction) {
      this.moveAssignmentDialog = true;
      this.editedQuiz.assignmentNumber = item.assignmentNumber;
      this.direction = direction;
      if (direction == "UP") {
        this.editedQuiz.direction = "UP";
      } else {
        this.editedQuiz.direction = "DOWN";
      }
    },
    closeMoveAssignmentDialog() {
      this.moveAssignmentDialog = false;
      this.$nextTick(() => {
        this.editedQuiz = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveAssignment() {
      if (this.moveAssignmentValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
        payload.assignmentNumber = this.editedQuiz.assignmentNumber;
        payload.direction = this.editedQuiz.direction;
        payload.destinationIndex = this.editedQuiz.destinationIndex;
        payload.assignmentType= "QUIZ";
        let url = MOVE_ASSIGNMENT;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.moveAssignmentValidation.valid = false;
            this.editedIndex = -1;
            this.$emit("update");
            this.close();
            this.setOverlayValue(false);
            this.closeMoveAssignmentDialog();
            this.setApiSuccess("Quiz Assignment moved successfully");
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveAssignmentDialog();
          });
      }
    },
    saveLesson() {
      if (this.createLessonValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        if (this.editedIndex > -1) {
          payload.courseId = this.courseId;
          payload.lessonName = this.editedLesson.name;
          payload.lessonNo = this.lessonNo;
          payload.chapterNo = this.chapterNo;
          let url = UPDATE_LESSON;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createLessonValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Lesson updated successfully");
            })
            .catch((error) => {
              console.log(error);
              this.setOverlayValue(false);
            });
        }
      }
    },
    saveQuiz() {
      this.validate();
      if (this.createQuizValidation.valid) {
        this.setOverlayValue(true);
        this.overlay = true;
        const payload = {};
        let url = ADD_QUIZASSIGNMENT;
        if (this.editedIndex > -1) {
          url = UPDATE_QUIZASSIGNMENT;
          payload.assignmentNumber = this.editedQuiz.assignmentNumber;
        }
        payload.quizId = this.editedQuiz.quizId;
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
         if(this.editedQuiz.isHomework){
          this.editedQuiz.afterContent=null;
        }
        payload.afterContent= this.editedQuiz.afterContent;
        payload.isHomework= this.editedQuiz.isHomework;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createQuizValidation.valid = false;
            this.overlay = false;
            this.$emit("update");
            this.close();
            this.setOverlayValue(false);
            if (this.editedIndex > -1) {
              this.setApiSuccess("Quiz Assignment updated successfully");
            } else {
              this.setApiSuccess("Quiz Assignment created successfully");
            }
            this.editedIndex = -1;
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.overlay = false;
            this.close();
          });
      }
    },
    deleteQuizConfirmDialog(item) {
      this.editedQuiz = Object.assign({}, item);
      this.deleteQuizDialog = true;
    },
    deleteQuizAssignment() {
      let url = DELETE_ASSIGNMENT;
      let payload = {};
      this.setOverlayValue(true);
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.assignmentNumber = this.editedQuiz.assignmentNumber;
      payload.assingmentType = "QUIZ";
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.createQuizValidation.valid = false;
          this.editedIndex = -1;
          this.$emit("update");
          this.close();
          this.setOverlayValue(false);
          this.setApiSuccess("Quiz Assignment deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    formatQuestionTitle(questionNo,questionTitle,totalQuestions){
      return formatQuestionTitle(questionNo,questionTitle,totalQuestions);
    },
  },
};
</script>