<template>
  <v-container>
    <ContextMenu   :contextMenu="contextMenuObject" ></ContextMenu>
    <v-data-table
      :headers="headers"
      :items="batches"
      single-expand
      item-key="batchNo"
      show-expand
      :hide-default-footer="batches.length<=10"  @contextmenu:row="rightClickHandler"          :expanded.sync="expandedRow"
    >
      <template v-slot:[`item.openDays`]="{ item }">
        <v-row justify="center">
          <v-col>
            <span v-for="i in item.openDays.length" :key="i">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="ma-1"
                    x-small
                    v-on="on"
                    v-bind="attrs"
                    color="green"
                    outlined
                    v-if="item.openDays[i - 1] != null"
                  >
                    {{ item.openDays[i - 1].day }}
                  </v-chip>
                </template>
                <div v-if="item.openDays[i - 1] != null">
                  <v-chip
                    class="ma-1 pa-1"
                    color="red"
                    outlined 
                  > openTime : {{ item.openDays[i - 1].openTime }}
                  closeTime : {{ item.openDays[i - 1].closeTime }} </v-chip>
                </div>
              </v-menu>
            </span>
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td colspan="12" class="elevation-0">
          <ClassRoom
            v-bind:batchNo="item.batchNo"
            v-bind:batch="item"
            v-bind:instituteId="instituteId"
            v-bind:sessionNo="sessionNo ? sessionNo : undefined"
            v-bind:isActiveSession="isActiveSession"
          />
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { rightClickHandler } from "@/common/utility";
import {
  GET_INSTITUTE_EXPANSION
} from "@/store/getters";
import {
 SET_INSTITUTE_EXPANSION_BATCH
} from "@/store/mutations";
import ClassRoom from "./ClassRoom.vue";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject:{}, 
      menu: true,
      headers: [
        {
          text: "Batch No.",
          align: "start",
          value: "batchNo"
        },
        {
          text: "Name",
          value: "name"
        },
        {
          text: "opendays",
          sortable: false,
          value: "openDays"
        },
        { text: "", value: "data-table-expand" },
      ],
      expandedRow: [null],
    };
  },
  components: { ClassRoom ,ContextMenu},
  watch:{
    expandedRow(newValue, oldValue) {
      this.setInstituteExpansionChapter({
        id: this.instituteId,
        data: newValue,
      });
    },
  },
  computed: {
    ...mapGetters({
      instituteExpansion: `schoolModule/${GET_INSTITUTE_EXPANSION}`,
    }),
  },
  props: ["batches", "instituteId", "sessionNo", "isActiveSession"],
  mounted: function () {
   setTimeout(()=>{
   if (
            typeof this.instituteExpansion.batchExpansion[this.instituteId] !==
            "undefined"
          ) {
            // Course Chapter data table expansion data exists
            this.expandedRow =
            this.instituteExpansion.batchExpansion[this.instituteId];
          }
        },500)
  },
  methods: {
    ...mapMutations({
      setInstituteExpansionChapter: `schoolModule/${SET_INSTITUTE_EXPANSION_BATCH}`,
    }),
    rightClickHandler(e, item) {
      e.preventDefault();
            // do something with e and/or item
           if(!e.target.textContent) return;
           this.contextMenuObject= rightClickHandler(e.target.textContent,e.clientX,e.clientY)
        },

  },
};
</script>
