<template>
  <v-container fluid>
    <v-card flat class="expansion-color">
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-data-table :headers="headers" :items="lessons" single-expand item-key="lessonNo" show-expand hide-default-footer
        :items-per-page="itemsPerPage" class="expansion-color" @contextmenu:row="rightClickHandler" :expanded.sync="expandedRow">
        <template v-slot:[`expanded-item`]="{ item }">
          <v-tabs v-model="tab">
            <v-tab>Content</v-tab>
            <v-tab v-if="course.courseType == COURSE_TYPE_PRIVATE">Notes</v-tab>
            <v-tab v-if="course.courseType == COURSE_TYPE_PRIVATE">Quiz Assignment</v-tab>
            <v-tab v-if="course.courseType == COURSE_TYPE_PRIVATE">Text Assignment</v-tab>
            <v-tab v-if="course.courseType == COURSE_TYPE_PRIVATE">Code Assignment</v-tab>
            <v-tab v-if="course.courseType == COURSE_TYPE_PRIVATE">Codely Assignment</v-tab>


          </v-tabs>
          <v-tabs-items v-model="tab" class="expansion-color">
            <v-tab-item>
              <Contents v-bind:contents="item.content" v-bind:courseId="course.courseId" v-bind:chapterNo="chapterNo"
                v-bind:lessonNo="item.lessonNo" @update="onUpdate()" />
            </v-tab-item>
            <v-tab-item>
              <Notes v-bind:notes="item.notes" v-bind:courseId="course.courseId" v-bind:chapterNo="chapterNo"
                v-bind:lessonNo="item.lessonNo" @update="onUpdate()" />
            </v-tab-item>
            <!-- <v-tab-item>
              <Assignments v-bind:codeAssignments="item.codeAssignments" v-bind:quizAssignments="item.quizAssignments"
                v-bind:textAssignments="item.textAssignments" v-bind:courseId="course.courseId"
                v-bind:chapterNo="chapterNo" v-bind:lessonNo="item.lessonNo" @update="onUpdate()" />
            </v-tab-item> -->
            <v-tab-item>
              <CourseQuizAssignment v-bind:quizAssignments="item.quizAssignments" v-bind:courseId="course.courseId"
                v-bind:chapterNo="chapterNo" v-bind:lessonNo="item.lessonNo" v-bind:noOfContents="item.content.length" @update="onUpdate()" />
            </v-tab-item>
            <v-tab-item>
              <CourseTextAssignment v-bind:textAssignments="item.textAssignments" v-bind:courseId="course.courseId"
                v-bind:chapterNo="chapterNo" v-bind:lessonNo="item.lessonNo" v-bind:noOfContents="item.content.length" @update="onUpdate()" />
            </v-tab-item>
            <v-tab-item>
              <CourseCodeAssignment v-bind:codeAssignments="item.codeAssignments" v-bind:courseId="course.courseId"
                v-bind:chapterNo="chapterNo" v-bind:lessonNo="item.lessonNo" v-bind:noOfContents="item.content.length" @update="onUpdate()" />
            </v-tab-item>
            <v-tab-item>
               <CourseCodelyAssignment v-bind:codelyAssignments="item.codelyAssignments" v-bind:courseId="course.courseId"
                v-bind:chapterNo="chapterNo" v-bind:lessonNo="item.lessonNo" v-bind:noOfContents="item.content.length" @update="onUpdate()" />
              </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          
            
          
          
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.lessonNo == 1"
                @click="openMoveContentDialog(item, 'UP')"
              >
                mdi-arrow-up-bold
              </v-icon>
            </template>
            <span>Move Content Up</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.lessonNo == lessons.length"
                @click="openMoveContentDialog(item, 'DOWN')"
              >
                mdi-arrow-down-bold
              </v-icon>
            </template>
            <span>Move Content Down</span>
          </v-tooltip>
          
          
        </template>
      </v-data-table>
      <v-row align="end" justify="end">
        <v-card-actions>

          <v-dialog persistent v-model="lessonDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="course.status != STATUS_PUBLISHED
                " color="primary" v-bind="attrs" v-on="on" @click="addLessonDialog()" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="success">mdi-plus</v-icon>
                  </template>
                  <span>Add Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">Add Lesson</v-card-title>
              <v-form ref="form" v-model="createLessonValidation.valid">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field v-model="editedLesson.lessonName" label="Lesson Name"
                          :rules="createLessonValidation.nameRules" required></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveCourseLesson">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="editChapterDailog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="course.status != STATUS_PUBLISHED
                " color="primary" v-bind="attrs" v-on="on" @click="editChapter" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit Chapter</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">Edit Chapter</v-card-title>
              <v-form ref="form" v-model="createChapterValidation.valid">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field v-model="editedChapter.name" label="Chapter Name"
                          :rules="createChapterValidation.nameRules" required></v-text-field>
                      </v-col>
                    </v-row>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()">Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveChapter()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteChapterDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-if="course.status != STATUS_PUBLISHED
                " color="primary" dark v-bind="attrs" v-on="on" @click="deleteChapterConfirmDialog()" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error">mdi-delete</v-icon>
                  </template>
                  <span>Delete Chapter</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Are you sure you want to delete this chapter?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDailog">Cancel</v-btn>
                <v-btn color="primary" text @click="deleteChapter">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="moveContentDialog" width="500">
            <v-card>
              <v-card-title class="primary--text"
                >Move Lesson {{ direction }}</v-card-title
              >
              <v-form ref="form" v-model="moveContentValidation.valid">
                <v-card-text>
                  <v-chip class="primary ml-1"
                    >Lesson {{ editedContent.lessonNo }}</v-chip
                  >
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedContent.destinationIndex"
                          :rules="moveContentValidation.nameRules"
                          type="number"
                          required
                          label="Move Lesson to"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeMoveContentDialog()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="moveContent()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Contents from "@/components/Contents.vue";
import Notes from "@/components/Notes.vue";
import CourseQuizAssignment from "@/components/CourseQuizAssignment.vue";
import CourseTextAssignment from "@/components/CourseTextAssignment.vue";
import CourseCodeAssignment from "@/components/CourseCodeAssignment.vue";
import CourseCodelyAssignment from "@/components/CourseCodelyAssignment.vue";
import ContextMenu from "./ContextMenu.vue";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
} from "@/common/constants";
import { ADD_LESSON, BASE_URL, DELETE_CHAPTER, GET_COURSE_BY_ID, UPDATE_CHAPTER , MOVE_LESSON} from '@/common/apiEndpoints';
import { GET_TOKEN, GET_USER_TYPE ,GET_COURSE_EXPANSION} from '@/store/getters';
import { SET_API_SUCCESS, SET_OVERLAY_VALUE ,SET_COURSE_EXPANSION_LESSON} from "@/store/mutations";
import { mapGetters, mapMutations } from 'vuex';
import axios from "axios";
import { getAxiosOptions, rightClickHandler } from '@/common/utility';
export default {
  data() {
    return {
      contextMenuObject: {},
      tab: null,
      lessonDialog: false,
      editChapterDailog: false,
      deleteChapterDialog: false,
      editedIndex: -1,
      editedChapter: {},
      editedLesson: {},
      defaultItem: {},
      course: {},
      itemsPerPage: -1,
      // lessons:[],
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createChapterValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      headers: [
        {
          text: 'Lessons',
          align: 'start',
          value: 'lessonName'
        },
        { text: "Actions", value: "actions", align: "end", sortable: false },
        { text: '', value: 'data-table-expand' },
      ],
      expandedRow: [null],
      moveContentDialog : false,
      editedContent: {},
      direction: "",
      moveContentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
    };
  },
  components: {
    Contents, Notes, ContextMenu, CourseQuizAssignment, CourseTextAssignment, CourseCodeAssignment ,CourseCodelyAssignment
  },
  watch:{
    expandedRow(newValue, oldValue) {
      this.setCourseExpansionLesson({id:this.course.courseId,data:newValue})
    }
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      courseExpansion: `schoolModule/${GET_COURSE_EXPANSION}`,
    }),
  },
  props: ['lessons', 'courseId', 'chapterNo'],
  mounted: function () {
    this.getCourseById();
  },
  methods: {
    openMoveContentDialog(item, direction) {
      this.moveContentDialog = true;
      this.editedContent.chapterNo = this.chapterNo;
      this.editedContent.lessonNo = item.lessonNo;
      this.editedContent.courseId =  this.courseId;
      this.direction = direction;
      if (direction == "UP") {
        this.editedContent.direction = "UP";
      } else {
        this.editedContent.direction = "DOWN";
      }
    },
    closeMoveContentDialog() {
      this.moveContentDialog = false;
      this.$nextTick(() => {
        this.editedContent = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveContent() {
      if (this.moveContentValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.editedContent.courseId;
   
        payload.chapterNo = this.editedContent.chapterNo;
        payload.lessonNo = this.editedContent.lessonNo;
        payload.direction = this.editedContent.direction;
        payload.destinationIndex = this.editedContent.destinationIndex;
        let url = MOVE_LESSON;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.moveContentValidation.valid = false;
            this.editedIndex = -1;
            this.$emit("updateChapter");
            this.getCourseById();
            this.close();
            this.setOverlayValue(false);
            this.closeMoveContentDialog();
            this.setApiSuccess("Content moved successfully");
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveContentDialog();
          });
      }
    },
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(e.target.textContent, e.clientX, e.clientY)
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCourseExpansionLesson:`schoolModule/${SET_COURSE_EXPANSION_LESSON}`,
    }),
    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          if (typeof this.courseExpansion.lessonExpansion[this.courseId] !== 'undefined') {
          // Course Lesson data table expansion data exists
          this.expandedRow=this.courseExpansion.lessonExpansion[this.courseId];
          }
          // this.assignLessons();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    onUpdate() {
      console.log("Inside onUpdateLesson for update lesson")
      this.$emit("updateChapter");
    },
    addLessonDialog() {
      this.lessonDialog = true;
      this.editedLesson = Object.assign({}, this.defaultItem);
      this.editedIndex = 0;
    },
    close() {
      this.deleteChapterDialog = false;
      this.editChapterDailog = false;
      this.lessonDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedlesson = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteChapterConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedChapter = Object.assign({}, item);
      this.deleteChapterDialog = true;
    },
    closeDeleteDailog() {
      this.deleteChapterDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteChapter() {
      const url = DELETE_CHAPTER;
      let payload = {};
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.$emit("updateChapter");
          this.closeDeleteDailog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter deleted successfully")
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    editChapter(item) {
      this.editChapterDailog = true;
      this.editedIndex = 0;
      this.editedChapter = Object.assign({}, item);
    },
    saveChapter() {
      if (this.createChapterValidation.valid) {
        let payload = {};
        if (this.editedIndex > -1) {
          payload.courseId = this.courseId;
          payload.chapterName = this.editedChapter.name;
          payload.chapterNo = this.chapterNo;
          let url = UPDATE_CHAPTER;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createChapterValidation.valid = false;
              this.editedIndex = -1;
              // this.getCourseById();
              this.$emit("updateChapter");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Updated successfully")
              // this.initialize();
            })
            .catch((error) => {
              this.setOverlayValue(false);
            });
        }
      }
    },
    saveCourseLesson() {
      this.$refs.form.validate();
      if (this.createLessonValidation.valid) {
        let payload = {};
        if (this.editedIndex > -1) {
          let url = BASE_URL + ADD_LESSON;
          payload.lessonName = this.editedLesson.lessonName;
          payload.chapterNo = this.chapterNo;
          payload.addType = "CHAPTER";
          payload.courseId = this.courseId;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.course = res.data;
              this.$emit("updateChapter");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Lession Added successfully")
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);

            });
        }
      }
    },
    // assignLessons(){
    //      this.course.chapters.filter((chapter)=>{
    //         if(chapter.chapterNo == this.chapterNo){
    //            this.lessons = chapter.lessons;
    //         }
    //   })
    // },
  },
};
</script>