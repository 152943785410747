<template>
  <div v-bind="$props">
    <v-dialog v-model="imgDialog" width="500">
      <v-card>
        <v-img :src="quizQuestionMedia"></v-img>
      </v-card>
    </v-dialog>
    <v-col v-if="content && content.quizQuestions && content.quizQuestions.length != 0">
      <!-- <div align="center">
        <v-img
          width="300"
          height="300"
          v-if="content.quizQuestions[index].media"
          :src="content.quizQuestions[index].media"
        ></v-img>
      </div> -->
      <v-col class="ma-2"
        ><span style="font-size: 20px" v-html="formatQuestionTitle(content.quizQuestions[index].questionNo,content.quizQuestions[index].title,content.quizQuestions.length)"
          ></span
        >
        <div class="mt-4">
          <v-img
            max-width="300"
            max-height="300"
            v-if="content.quizQuestions[index].media"
            @click="viewImage(content.quizQuestions[index])"
            :src="content.quizQuestions[index].media"
          ></v-img>
          <span
            v-if="content.quizQuestions[index].media"
            style="font-size: 14px"
            >If image is not clear , click on image to see full image</span
          >
        </div>
        <v-col
          class="pl-0"
          v-for="option in content.quizQuestions[index].options"
          :key="option.number"
        >
          <label>
            <input
              v-model="selected"
              type="radio"
              name="option.value"
              :value="option.number"
              :disabled="alreadyAttempted"
            />
            <!--v-bind:style= "[correctOption ? {accentColor: 'green'} : {accentColor: 'red'}]" -->
            {{ option.value }}</label
          >
        </v-col>
      </v-col>
      <v-row class="ma-2">
        <v-col cols="12" v-if="alreadyAttempted">
          <span style="font-size: 18px; font-weight: 500"
            >Result :
            <span
              :class="isCorrect ? 'success--text' : 'error--text'"
              style="font-weight: 400; font-size: 16px"
              >{{ isCorrect ? "Right" : "Wrong" }}</span
            ></span
          >
        </v-col>
        <v-col cols="12" v-if="alreadyAttempted">
          <span style="font-size: 18px; font-weight: 500"
            >Correct Answer is :
            <span
              class="info--text"
              style="font-weight: 400; font-size: 16px"
              >{{ correctAnswer }}</span
            ></span
          >
          <!-- getCorrectAnswer(index) -->
        </v-col>
        <v-col cols="12" v-if="alreadyAttempted">
          <span style="font-size: 18px; font-weight: 500">Explanation : </span>
          <span
            class="purple--text"
            style="font-weight: 400; font-size: 16px"
            v-html="explaination ? explaination : getExplaination(index)"
          >
          </span>
        </v-col>
        <v-col cols="12" v-if="displaySuccessMsg">
          <v-chip color="success" style="font-size: 18px; font-weight: 500"
            >Congratulations! You have earned {{ earnedCoins }} coins
            <v-avatar right>
              <v-img src="../assets/Coin.svg"></v-img>
            </v-avatar>
          </v-chip>
        </v-col>
      </v-row>
      <v-row class="ma-2">
        <v-btn
          class="ma-2"
          medium
          :disabled="index === 0"
          color="primary"
          @click="backpage()"
          >BacK</v-btn
        >
        <v-btn
          class="ma-2"
          medium
          :disabled="this.index === this.content.quizQuestions.length - 1"
          color="primary"
          @click="nextPage()"
          >Next</v-btn
        >
        <v-btn
          class="ma-2"
          medium
          color="success"
          v-if="!alreadyAttempted"
          :disabled="selected == '' ? true : false"
          @click="studentQuiz()"
          >Submit</v-btn
        >
      </v-row>
      <!--  -->
    </v-col>
  </div>
</template>
<script>
import {
  // STUDENT_START_QUIZ,
  BASE_URL,
  STUDENT_CONTENT_QUIZ_PROGRESS,
  GET_STUDENT_COURSE_PROGRESS,
  GET_COIN_BALANCE,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions,formatQuestionTitle } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COIN_BALANCE,
} from "@/store/mutations";
export default {
  data() {
    return {
      index: 0,
      selected: false,
      imgDialog: false,
      isCorrect: false,
      quizQuestionMedia: "",
      alreadyAttempted: false,
      studentCourseProgress: [],
      correctAnswer: "",
      explaination: "",
      earnedCoins: "",
      displaySuccessMsg: false,
    };
  },
  props: ["content", "chapterNo", "lessonNo", "courseId"],

  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },

  mounted: function () {
    this.fetchCourseProgress(this.courseId);
  },

  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),

    nextPage() {
      if (this.index < this.content.quizQuestions.length) {
        this.index++;
      }
      this.selected = false;
      this.correctAnswer = "";
      this.checkForAlreadyAttempted(
        this.content.quizQuestions[this.index].questionNo
      );
      this.displaySuccessMsg = false;
      this.getExplaination(this.index);
    },
    backpage() {
      if (this.index > 0) {
        this.index--;
      }
      this.selected = false;
      this.correctAnswer = "";
      this.checkForAlreadyAttempted(
        this.content.quizQuestions[this.index].questionNo
      );
      this.displaySuccessMsg = false;
      this.getExplaination(this.index);
    },
    fetchCourseProgress() {
      let url = GET_STUDENT_COURSE_PROGRESS;
      let payload = {};
      payload.courseIds = [];
      payload.courseIds.push(this.courseId);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourseProgress = res.data.progresses;
          //   this.checkForcompletion();
          this.checkForAlreadyAttempted(1);
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    checkForAlreadyAttempted(questionNo) {
      if (this.studentCourseProgress[0].progress) {
        let question;
        let chapter = this.studentCourseProgress[0].progress.chapters.find(
          (chapter) => chapter.chapterNo == this.chapterNo
        );
        if (chapter) {
          let lesson = chapter.lessons.find(
            (lesson) => lesson.lessonNo == this.lessonNo
          );
          let contentProgress = lesson.contentProgress.find(
            (cntntProgress) => this.content.contentNo == cntntProgress.contentNo
          );

          if (contentProgress) {
            question = contentProgress.questions.find(
              (question) => question.questionNo == questionNo
            );
          }
        }
        if (question) {
          this.alreadyAttempted = true;
        } else {
          this.alreadyAttempted = false;
        }
      } else {
        this.alreadyAttempted = false;
      }
    },
    studentQuiz(event) {
      const url = STUDENT_CONTENT_QUIZ_PROGRESS;
      const payload = {};
      payload.quizId = this.content.source;
      payload.competitionId = null;
      payload.questionNo = this.content.quizQuestions[this.index].questionNo;
      payload.selectedOption = this.selected;
      payload.courseId = this.courseId;
      payload.contentNo = this.content.contentNo;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourseProgress[0].progress = res.data;
          this.earnedCoins = res.data.earnedCoins;
          this.alreadyAttempted = true;
          this.$emit("questionCompleted", this.studentCourseProgress);
          this.setOverlayValue(false);
          this.getExplaination(this.index);
          if (this.isCorrect) {
            this.playAudio("success");
            this.displaySuccessMsg = true;
            this.updateCoinBalance();
          } else {
            this.playAudio("failure");
          }
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    viewImage(quizQuestions) {
      this.imgDialog = true;
      this.quizQuestionMedia = quizQuestions.media;
    },
    playAudio(soundToPlay) {
      let audioName = "";
      if (soundToPlay === "success") {
        audioName = "coin_added.mp3";
      }
      if (soundToPlay === "failure") {
        audioName = "wrong_outcome.wav";
      }
      var audio = new Audio(require(`../assets/Sounds/${audioName}`));
      audio.play();
    },
    getExplaination(index) {
      let questionNo = this.content.quizQuestions[index].questionNo;
      if (this.studentCourseProgress[0].progress) {
        let question;
        let chapter = this.studentCourseProgress[0].progress.chapters.find(
          (chapter) => chapter.chapterNo == this.chapterNo
        );
        if (chapter) {
          let lesson = chapter.lessons.find(
            (lesson) => lesson.lessonNo == this.lessonNo
          );
          let contentProgress = lesson.contentProgress.find(
            (cntntProgress) => this.content.contentNo == cntntProgress.contentNo
          );

          if (contentProgress) {
            question = contentProgress.questions.find(
              (question) => question.questionNo == questionNo
            );
          }
        }
        if (question) {
          this.selected = question.userResponse;
          this.isCorrect = question.isCorrect;
          this.correctAnswer =
            this.content.quizQuestions[index].options[
              question.correctAnswer - 1
            ].value;
          this.explaination = question.explaination;
        }
      }
    },
    updateCoinBalance() {
      const url = GET_COIN_BALANCE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let newCoinBalance = res.data.balance;
          this.setCoinBalance(newCoinBalance);
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    formatQuestionTitle(questionNo,questionTitle,totalQuestions){
      return formatQuestionTitle(questionNo,questionTitle,totalQuestions);
    },
    // checkForcompletion(){
    // //   this.courseData.completed = this.studentCourseProgress[0].progress.completed;
    //   for(let i=0;i<this.studentCourseProgress[0].progress.chapters.length;i++){
    //     this.courseData.chapters[i].completed = this.studentCourseProgress[0].progress.chapters[i].completed;
    //     for(let j=0;j<this.studentCourseProgress[0].progress.chapters[i].lessons.length;j++){
    //     this.courseData.chapters[i].lessons[j].completed = this.studentCourseProgress[0].progress.chapters[i].lessons[j].completed;
    //     for(let k=0;k<this.studentCourseProgress[0].progress.chapters[i].lessons[j].contentProgress.length;k++){
    //     this.courseData.chapters[i].lessons[j].content[k].completed = this.studentCourseProgress[0].progress.chapters[i].lessons[j].contentProgress[k].completed;
    //   }
    //   }
    //   }
    // },
  },
};
</script>