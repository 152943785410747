<template>
  <v-container fluid>
    <v-card flat>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-data-table
        :headers="headers"
        :items="course.chapters"
        single-expand
        item-key="chapterNo"
        show-expand
        hide-default-footer
        :items-per-page="itemsPerPage"
        @contextmenu:row="rightClickHandler"
        :expanded.sync="expandedRow"
      >
        <template v-slot:expanded-item="{ item }">
          <td colspan="12" class="elevation-0">
            <Lessons
              v-bind:lessons="item.lessons"
              v-bind:chapterNo="item.chapterNo"
              v-bind:courseId="course.courseId"
              @updateChapter="onUpdateChapter($event)"
            />
          </td>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          
            
          
          
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.chapterNo == 1"
                @click="openMoveContentDialog(item, 'UP')"
              >
                mdi-arrow-up-bold
              </v-icon>
            </template>
            <span>Move Content Up</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.chapterNo == course.chapters.length"
                @click="openMoveContentDialog(item, 'DOWN')"
              >
                mdi-arrow-down-bold
              </v-icon>
            </template>
            <span>Move Content Down</span>
          </v-tooltip>
          
          
        </template>
      </v-data-table>

      <v-card-actions>
        <v-row align="end" justify="end">
          <v-dialog persistent v-model="chapterDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                medium
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addChapterDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="success">mdi-plus</v-icon>
                  </template>
                  <span>Add Chapter </span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">{{
                chapterDialogTitle
              }}</v-card-title>
              <v-form ref="form" v-model="createChapterValidation.valid">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="editedChapter.chapterName"
                          label="Chapter Name"
                          :rules="createChapterValidation.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveCourseChapter()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog
            persistent
            v-model="editCourseDailog"
            width="1000"
            :retain-focus="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="editCourse()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-pencil</v-icon>
                  </template>
                  <span>Edit Course</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card>
                <v-card-title class="primary--text">Edit Course</v-card-title>
                <v-form ref="form" v-model="createCourseValidation.valid">
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedCourse.name"
                            label="Course Name"
                            :rules="createCourseValidation.nameRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-combobox
                            v-model="editedCourse.grades"
                            :items="gradesArray"
                            label="Grades"
                            multiple
                            dense
                            deletable-chips
                            chips
                            item-text="desc"
                            item-value="id"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item @click="selectAll">
                                <v-list-item-action>
                                  <v-icon>{{ icon() }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title
                                    >Select All</v-list-item-title
                                  >
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider />
                            </template>
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <span>Description</span>
                          <quill-editor
                            v-model="editedCourse.description"
                            @input="validateCourseDesc()"
                             style="height: 140px; margin-bottom:30px;"
                             :options="editorOptions"
                          ></quill-editor>
                          <span class="red--text">{{ courseDescError }}</span>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="3"
                          v-if="editedCourse.courseType == 'PRIVATE'"
                        >
                          <v-text-field
                            v-model="editedCourse.noOfClasses"
                            :rules="createCourseValidation.nameRules"
                            dense
                            type="number"
                            label="No of Classes"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" v-if="editedCourse.buyable">
                          <v-text-field
                            v-model="editedCourse.price"
                            :rules="createCourseValidation.nameRules"
                            dense
                            type="number"
                            label="Price"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close()"
                          >Cancel
                        </v-btn>
                        <v-btn color="primary" text @click="saveCourse()">
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card-text>
                </v-form>
              </v-card>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="copyCourseDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                v-bind="attrs"
                v-on="on"
                @click="openCopyCourseDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-content-copy</v-icon>
                  </template>
                  <span>Copy Course</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Write the name of the New Course</v-card-title>
              <v-form ref="form" v-model="createCourseValidation.valid">
                <v-container>
                  <v-col cols="8">
                    <v-text-field
                      v-model="editedCourse.courseName"
                      :rules="createCourseValidation.nameRules"
                      required
                      label="Course Name"
                    ></v-text-field>
                  </v-col>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeCopyCourseDialog()"
                    >Cancel</v-btn
                  >
                  <v-btn color="primary" text @click="copyCourse()">OK</v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteCourseDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteCourseConfirmDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error">mdi-delete</v-icon>
                  </template>
                  <span>Delete Course</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                >Are you sure you want to delete "{{
                  editedCourse.name
                }}"</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDailog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteCourse()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="moveContentDialog" width="500">
            <v-card>
              <v-card-title class="primary--text"
                >Move Chapter {{ direction }}</v-card-title
              >
              <v-form ref="form" v-model="moveContentValidation.valid">
                <v-card-text>
                  <v-chip class="primary ml-1"
                    >Chapter {{ editedContent.chapterNo }}</v-chip
                  >
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          v-model="editedContent.destinationIndex"
                          :rules="moveContentValidation.nameRules"
                          type="number"
                          required
                          label="Move Chapter to"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="closeMoveContentDialog()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="moveContent()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  GET_COURSE_BY_ID,
  BASE_URL,
  ADD_CHAPTER,
  COURSE_DELETE,
  UPDATE_COURSE,
  COPY_COURSE,
  MOVE_CHAPTER,
} from "@/common/apiEndpoints.js";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  grades,
} from "@/common/constants";
import axios from "axios";
import Lessons from "@/components/Lessons.vue";
import {
  SET_API_SUCCESS,
  SET_COURSE_EXPANSION_CHAPTER,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import {
  GET_TOKEN,
  GET_USER_TYPE,
  GET_COURSE_EXPANSION,
} from "@/store/getters";
import { getAxiosOptions, rightClickHandler } from "@/common/utility";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      course: {
        // courseId: '',
        // courseType: '',
        // grades: [],
        // name: '',
        // status: '',
        // thumbnail: '',
      },
      headers: [
        {
          text: "Chapters",
          align: "start",
          value: "chapterName",
        },
        { text: "Actions", value: "actions", align: "end", sortable: false },
        { text: "", value: "data-table-expand" },
      ],
      chapterDialogTitle: "New Chapter",
      courseDescError: "",
      chapterDialog: false,
      editCourseDailog: false,
      deleteCourseDialog: false,
      copyCourseDialog: false,
      itemsPerPage: -1,
      editedIndex: -1,
      editedChapter: {},
      gradesArray: grades,
      editedCourse: {
        grades: [],
      },
      defaultItem: {
        grades: [],
      },

      createCourseValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createChapterValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      expandedRow: [null],
      moveContentDialog : false,
      editedContent: {},
      direction: "",
      moveContentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
      
    };
  },
  components: { Lessons, ContextMenu },
  watch: {
    dialog(val) {
      val || this.close();
    },
    expandedRow(newValue, oldValue) {
      this.setCourseExpansionChapter({
        id: this.course.courseId,
        data: newValue,
      });
    },
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      courseExpansion: `schoolModule/${GET_COURSE_EXPANSION}`,
    }),
  },
  props: ["courseId"],
  mounted: function () {
    this.getCourseById();
  },
  methods: {
    openMoveContentDialog(item, direction) {
      this.moveContentDialog = true;
      this.editedContent.chapterNo = item.chapterNo;
      this.editedContent.courseId =  this.course.courseId;
      this.direction = direction;
      if (direction == "UP") {
        this.editedContent.direction = "UP";
      } else {
        this.editedContent.direction = "DOWN";
      }
    },
    closeMoveContentDialog() {
      this.moveContentDialog = false;
      this.$nextTick(() => {
        this.editedContent = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveContent() {
      if (this.moveContentValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.editedContent.courseId;
   
        payload.chapterNo = this.editedContent.chapterNo;
        payload.direction = this.editedContent.direction;
        payload.destinationIndex = this.editedContent.destinationIndex;
        let url = MOVE_CHAPTER;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.moveContentValidation.valid = false;
            this.editedIndex = -1;
            this.getCourseById();
            this.close();
            this.setOverlayValue(false);
            this.closeMoveContentDialog();
            this.setApiSuccess("Content moved successfully");
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveContentDialog();
          });
      }
    },
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCourseExpansionChapter: `schoolModule/${SET_COURSE_EXPANSION_CHAPTER}`,
    }),
    validateCourseDesc() {
      if (this.editedCourse.description.length == 0) {
        this.courseDescError = "Description is required";
      } else if (this.editedCourse.description.length < 50) {
        this.courseDescError = "Description must be minimum 50 character";
      } else if (this.editedCourse.description.length > 500) {
        this.courseDescError = "Description must not be above 500 character";
      } else {
        this.courseDescError = "";
      }
    },
    selectAllGrades() {
      return this.editedCourse.grades.length === this.gradesArray.length;
    },
    selectSomeGrades() {
      return this.editedCourse.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedCourse.grades = [];
        } else {
          this.editedCourse.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },
    openCopyCourseDialog() {
      this.copyCourseDialog = true;
    },
    closeCopyCourseDialog() {
      this.copyCourseDialog = false;
    },
    copyCourse() {
      this.$refs.form.validate();
      if (this.createCourseValidation.valid) {
        this.setOverlayValue(true);
        let payload = {};
        let url = COPY_COURSE;
        payload.courseId = this.courseId;
        payload.courseName = this.editedCourse.courseName;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCourseValidation.valid = false;
            this.editedIndex = -1;
            this.getCourseById();
            this.$emit("updateCourse");
            this.closeCopyCourseDialog();
            this.setOverlayValue(false);
            this.setApiSuccess("Course copied successfully");
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeCopyCourseDialog();
            this.setOverlayValue(false);
          });
      }
    },
    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.courseId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          if (
            typeof this.courseExpansion.chapterExpansion[this.courseId] !==
            "undefined"
          ) {
            // Course Chapter data table expansion data exists
            this.expandedRow =
              this.courseExpansion.chapterExpansion[this.courseId];
          }
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    onUpdateChapter() {
      this.getCourseById();
    },
    addChapterDialog() {
      this.chapterDialog = true;
      this.editedChapter = Object.assign({}, this.defaultItem);
    },
    close() {
      this.deleteCourseDialog = false;
      this.editCourseDailog = false;
      this.chapterDialog = false;
      this.createCourseValidation.valid = false;
      this.createChapterValidation.valid = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteCourseConfirmDialog() {
      this.editedIndex = 0;
      this.editedCourse = Object.assign({}, this.course);
      this.deleteCourseDialog = true;
    },
    closeDeleteDailog() {
      this.deleteCourseDialog = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteCourse() {
      const url = COURSE_DELETE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeDeleteDailog();
          this.$emit("updateCourse");
          this.setOverlayValue(false);
          this.setApiSuccess("Course Deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeDeleteDailog();
          this.setOverlayValue(false);
        });
    },
    editCourse() {
      this.editCourseDailog = true;
      this.editedIndex = 0;
      this.editedCourse = Object.assign({}, this.course);
    },
    saveCourse() {
      if (this.createCourseValidation.valid && this.editedCourse.description) {
        let payload = {};
        this.setOverlayValue(true);
        if (this.editedIndex > -1) {
          payload.courseId = this.editedCourse.courseId;
          payload.courseName = this.editedCourse.name;
          payload.grades = this.editedCourse.grades;
          payload.courseType = this.editedCourse.courseType;
          payload.noOfClasses = this.editedCourse.noOfClasses;
          payload.price = this.editedCourse.price;
          payload.description = this.editedCourse.description;
          let url = UPDATE_COURSE;

          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createCourseValidation.valid = false;
              this.editedIndex = -1;
              this.close();
              this.getCourseById();
              this.$emit("updateCourse");
              this.setOverlayValue(false);
              this.setApiSuccess("Course updated successfully");
              // this.initialize();
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);
            });
        }
      }
    },
    saveCourseChapter() {
      this.$refs.form.validate();
      if (this.createChapterValidation.valid) {
        this.setOverlayValue(true);
        let payload = {};
        let url = ADD_CHAPTER;
        payload.courseId = this.courseId;
        payload.chapterName = this.editedChapter.chapterName;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            console.log(res);
            this.course = res.data;
            this.close();
            this.setOverlayValue(false);
            this.setApiSuccess("Course chapter saved successfully");
            // setTimeout(()=>{this.setOverlayValue(false);},500);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>
