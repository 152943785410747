<template>
  <v-card elevation="0" class="mt-0" color="light-blue lighten-4">
  <v-carousel hide-delimiters class="carousel-container">
      <v-carousel-item v-for="(image, index) in images" :key="index">
        <v-img :src="image.source" class="carousel-image" contain></v-img>
        <div class="numbering">{{ index + 1 }} / {{ images.length }}</div>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: "Preview",
    },
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.carousel-container {
  margin: auto;
  max-width: 1000px;
}

.carousel-image {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}

.numbering {
  position: absolute;
  bottom: 1px;
  right: 476px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
}
</style>
