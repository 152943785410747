import { FETCH_USER_PROFILE, SAVE_AUTH_FROM_SESSION, SAVE_TOKEN } from "./actions";
import { GET_ISAUTHENTICATED, GET_TOKEN, GET_USER_PROFILE,GET_USER_TYPE,GET_API_ERROR_TEXT, GET_API_SUCCESS_TEXT, GET_OVERLAY_VALUE, GET_TOKEN_EXPIRED, GET_USER_ROLE ,GET_PARAMS_VALUE,GET_COURSE_EXPANSION,GET_INSTITUTE_EXPANSION,GET_STUDENT_RANK} from "./getters";
import { SET_ISAUTHENTICATED, SET_TOKEN, SET_USER_PROFILE,SET_API_ERROR, SET_API_SUCCESS, SET_OVERLAY_VALUE, SET_TOKEN_EXPIRED, SET_REGISTRATION_COMPLETED, SET_USER_ROLE,SET_PARAMS_VALUE,SET_COIN_BALANCE,SET_COURSE_EXPANSION_PANEL,SET_COURSE_EXPANSION_CHAPTER,SET_COURSE_EXPANSION_LESSON,SET_INSTITUTE_EXPANSION_PANEL,SET_INSTITUTE_EXPANSION_BATCH,SET_STUDENT_RANK } from "./mutations";
import { BASE_URL, GET_PROFILE } from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
export default {
  namespaced: true,
  state: () => ({
    token: "",
    isAuthenticated: false,
    userProfile: {},
    studentRank: "",
    apiErrorText:"",
    apiSuccessText:"",
    overlay:false,
    tokenExpired:false,
    paramsValue:{},
    courseExpansion:{coursePanel:[0],chapterExpansion:{},lessonExpansion:{}},
    instituteExpansion:{institutePanel:[0],batchExpansion:{}}
  }),
  getters: {

    [GET_ISAUTHENTICATED](state, getters, rootState, rootGetters) {
      return state.isAuthenticated;
    },
    [GET_USER_PROFILE](state, getters, rootState, rootGetters) {
      return state.userProfile;
    },
    [GET_TOKEN](state, getters, rootState, rootGetters) {

      return state.token;
    },
    [GET_USER_TYPE](state,getters,rootState,rootGetters){
      return state.userProfile.userType;
    },
    [GET_STUDENT_RANK](state,getters,rootState,rootGetters){
      return state.studentRank;
    },
    [GET_API_ERROR_TEXT](state, getters, rootState, rootGetters) {
      return state.apiErrorText;
    },
    [GET_API_SUCCESS_TEXT](state, getters, rootState, rootGetters) {
      return state.apiSuccessText;
    },
    [GET_OVERLAY_VALUE](state, getters, rootState, rootGetters) {
      return state.overlay;
    },
    [GET_TOKEN_EXPIRED](state, getters, rootState, rootGetters) {
      return state.tokenExpired;
    },
    [GET_PARAMS_VALUE](state, getters, rootState, rootGetters) {
      return state.paramsValue;
    },
    [GET_COURSE_EXPANSION](state, getters, rootState, rootGetters) {
      return state.courseExpansion;
    },
    [GET_INSTITUTE_EXPANSION](state, getters, rootState, rootGetters) {
      return state.instituteExpansion;
    },
  },
  mutations: {
    [SET_ISAUTHENTICATED](state, payload) {
      state.isAuthenticated = payload;
    },
    [SET_TOKEN](state, payload) {
      state.token = payload;
    },
    [SET_USER_PROFILE](state, payload) {
      state.userProfile = payload;
    },
    [SET_STUDENT_RANK](state,payload){
      state.studentRank = payload;
    },
    [SET_API_ERROR](state, payload) {
      state.apiErrorText = payload;
    },
    [SET_API_SUCCESS](state, payload) {
      state.apiSuccessText = payload;
    },
    [SET_OVERLAY_VALUE](state, payload) {
      state.overlay = payload;
    },
    [SET_TOKEN_EXPIRED](state, payload) {
      state.tokenExpired = payload;
    },
    [SET_PARAMS_VALUE](state, payload) {
      state.paramsValue = payload;
    },
    [SET_COIN_BALANCE](state, payload) {
      state.userProfile.aptCoins = payload;
    },
    [SET_COURSE_EXPANSION_PANEL](state, payload) {
      state.courseExpansion.coursePanel = payload;
    },
    [SET_COURSE_EXPANSION_CHAPTER](state, payload) {
      const { id, data } = payload;
      state.courseExpansion.chapterExpansion[id]=data;
    },
    [SET_COURSE_EXPANSION_LESSON](state, payload) {
      const { id, data } = payload;
      state.courseExpansion.lessonExpansion[id]=data;
    },
    [SET_INSTITUTE_EXPANSION_PANEL](state, payload) {
      state.instituteExpansion.institutePanel = payload;
    },
    [SET_INSTITUTE_EXPANSION_BATCH](state, payload) {
      const { id, data } = payload;
      state.instituteExpansion.batchExpansion[id]=data;
    },
  },
  actions: {
    async  [SAVE_TOKEN](context, payload) {
      sessionStorage.setItem("token", payload.token);
       context.commit(SET_TOKEN, payload.token);
       context.commit(SET_ISAUTHENTICATED, true);
       context.commit(SET_TOKEN_EXPIRED, false);
    },
    async  [SAVE_AUTH_FROM_SESSION](context, payload) {
      if (sessionStorage.getItem("token")) {
         context.commit(SET_TOKEN, sessionStorage.getItem("token"));
         context.commit(SET_ISAUTHENTICATED, true);
        }     
    },
    async  [FETCH_USER_PROFILE](context, payload) {
      let auth = await  context.getters[GET_TOKEN];
      return new Promise((resolve, reject) => {
        if(!this.state.schoolModule.tokenExpired){
        axios.get(GET_PROFILE,getAxiosOptions(BASE_URL,auth)).then((res) => {
          context.commit(SET_USER_PROFILE,res.data);
          resolve(res);
     });
    }
    })
    },
    // async  [CLEAR_USER_PROFILE](context, payload) {
    //   if (sessionStorage.getItem("token")) {
    //      context.commit(SET_TOKEN, sessionStorage.getItem("token"));
    //      context.commit(SET_ISAUTHENTICATED, true);
    //      context.commit(SET_REGISTRATION_COMPLETED, true);      
    //   }
    // },
  },

}

