import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify";
import store from './store'
import router from './router'
import { FETCH_USER_PROFILE, SAVE_AUTH_FROM_SESSION } from './store/actions';
import { GET_ISAUTHENTICATED,} from './store/getters';
import VueFriendlyIframe from 'vue-friendly-iframe';

import { quillEditor } from 'vue-quill-editor';
import Quill from 'quill';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';


Vue.config.productionTip = false
Vue.use(VueFriendlyIframe);
Vue.component('quill-editor', quillEditor);
const app = new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
});

app.$store.dispatch(`schoolModule/${SAVE_AUTH_FROM_SESSION}`).then(()=>{
  const auth = app.$store.getters[`schoolModule/${GET_ISAUTHENTICATED}`];
  if(auth==true){
    app.$store.dispatch(`schoolModule/${FETCH_USER_PROFILE}`)
   }
app.$mount('#app');
});

