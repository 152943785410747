import Vue from "vue";
import Vuex from "vuex";

import schoolModule from "../store/schoolModule"
import createPersistedState from "../store/vuexSessionStoragePlugin";
Vue.use(Vuex);


export default new Vuex.Store({
 modules:{
  schoolModule
 },plugins: [createPersistedState()]
});




