<template>
 
   <v-card class="ma-2" style="border-radius: 10px;" min-height="650px">
   
            <v-toolbar  dark color="primary" elevation=2>
          <v-toolbar-title style="font-weight: 500; font-size: 24px;">Institues</v-toolbar-title>
          
          <v-spacer></v-spacer>
          
            
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              v-if="showSearchField"
              v-model="searchText"
              @input="searchInstitute"
              clearable
            ></v-text-field>
          </v-col>
          <v-btn icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="showSearchField = !showSearchField"
                  >mdi-magnify</v-icon
                >
              </template>
              <span>Search</span>
            </v-tooltip>
          </v-btn>
          <v-menu v-model="menu" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-filter</v-icon>
                  </template>
                  <span>Filter</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-checkbox
                  v-model="InstituteStatusFilter"
                  label="Draft"
                  color="primary"
                  value="DRAFT"
                  @click="getAllInstitutes()"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <v-list-item>
                <v-checkbox
                  v-model="InstituteStatusFilter"
                  label="Published"
                  color="primary"
                  value="PUBLISHED"
                  @click="getAllInstitutes()"
                  hide-details
                ></v-checkbox>
              </v-list-item>
              <!-- <v-list-item>
                <v-btn color="primary" text @click="getAllInstitutes()">
                  Apply
                </v-btn>
              </v-list-item> -->
            </v-list>
          </v-menu>
          <v-dialog persistent v-model="dialog" max-width="700">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 16px;">New Institute</v-btn>
            </template>

            <v-form ref="form" v-model="createInstituteValidation.valid">
              <v-card>
                <v-container>
                  <v-row>
                    <v-card-title class="primary--text"
                      >New Institute</v-card-title
                    ></v-row
                  >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedInstitute.instituteName"
                        label="Institute Name"
                        :rules="createInstituteValidation.instituteRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row><v-card-subtitle>Address</v-card-subtitle></v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedInstitute.address.addressLine1"
                        label="Service Address Line1"
                        :rules="createInstituteValidation.addressRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        v-model="editedInstitute.address.addressLine2"
                        label="Service Address Line2"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        @input="getAddressDetails()"
                        v-model="editedInstitute.address.pincode"
                        label="Pin Number"
                        :rules="createInstituteValidation.pinRules"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedInstitute.address.city"
                        label="City"
                        :rules="createInstituteValidation.cityRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="editedInstitute.address.state"
                        label="State"
                        :rules="createInstituteValidation.stateRules"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close()"> Cancel </v-btn>
                  <v-btn color="primary" text @click="saveInstitute()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-dialog>
        </v-toolbar>
      <v-expansion-panels multiple class="instExp" v-model="panel">
      <v-expansion-panel
        class="mt-2"
        v-for="institute in filteredInstitutes"
        :key="institute.instituteId"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon large color="primary">
              $expand
            </v-icon>
          </template>
          <InstituteHeader
          v-bind:institute="institute"
          @updateInstitute="onUpdateInsitute($event)"/>
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <InstituteDetail v-bind:instituteId="institute.instituteId"
          @updateInstitute="onUpdateInsitute($event)"/>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
   
  </v-card>
  
</template>



<script>
import {
  BASE_URL,
  GET_ALL_INSTITUTES,
  CREATE_INSTITUTE,
  GET_ADDRESS_DETAILS,
  GET_INSTITUTE_BY_ID,
} from "@/common/apiEndpoints";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE,GET_INSTITUTE_EXPANSION } from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE,SET_INSTITUTE_EXPANSION_PANEL } from "@/store/mutations";
import { getAxiosOptions, formatDate } from "@/common/utility";
import InstituteHeader from './InstituteHeader.vue';
import InstituteDetail from './InstituteDetail.vue';
export default {
  data() {
    return {
      dialog: false,
      showSearchField: false,
      searchText: "",
      editedIndex: -1,
      filteredInstitutes: [],
      InstituteDialogTitle: "New Institute",
      InstituteStatusFilter: false,
      publishConfirmDialog: false,
      editedInstitute: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      defaultItem: {
        instituteId: "",
        instituteName: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
      },
      createInstituteValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        instituteRules:  [(v) => !!v || "Institute Name is required"],
        addressRules:  [(v) => !!v || "Address Line1 is required"],
        addresslineRules: [(v) => !!v || "Address Line2 is required"],
        cityRules: [(v) => !!v || "City is required"],
        stateRules: [(v) => !!v || "State is required"],
        pinRules: [
          (v) => !!v || "Pin is required",
          (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
        ],
      },
      institutes: [],
      menu: false,
      panel:[0],
      instituteStatusFilter: [],
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  components : {InstituteHeader , InstituteDetail},
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      instituteExpansion: `schoolModule/${GET_INSTITUTE_EXPANSION}`,
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    // deleteDialog(val) {
    //   val || this.closeDeleteDailog();
    // },
    publishConfirmDialog(val) {
      val || this.closePublishDailog();
    },
    panel(newValue, oldValue) {
      this.setInstituteExpansion(newValue)
    }
  },
  mounted: function () {
    this.getAllInstitutes();
    this.panel=this.instituteExpansion.institutePanel;
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setInstituteExpansion: `schoolModule/${SET_INSTITUTE_EXPANSION_PANEL}`,
    }),
    onUpdateInsitute(){
      console.log("inside institute management");
      this.getAllInstitutes();
    },
    formatDate(date) {
      return formatDate(date);
    },
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.editedInstitute.address.pincode.length == 6) {
        url += this.editedInstitute.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.district == null) {
              this.editedInstitute.address.city = "";
              this.editedInstitute.address.state = "";
            } else {
              this.editedInstitute.address.city = res.data.district;
              this.editedInstitute.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    getAllInstitutes() {
      this.menu = false;
      let url = GET_ALL_INSTITUTES;
      if (
        !(
          this.instituteStatusFilter.length == 0 ||
          this.instituteStatusFilter.length == 2
        )
      ) {
        url = url + "?status=" + this.instituteStatusFilter[0];
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institutes = res.data.institutes;
          this.filteredInstitutes = this.institutes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedInstitute = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    searchInstitute() {
      if (this.searchText) {
        this.filteredInstitutes = this.institutes.filter((inst) => {
          return inst.instituteName
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredInstitutes = this.institutes;
      }
    },
    trimEditedInstitue(){
this.editedInstitute.instituteName = this.editedInstitute.instituteName.trim();
this.editedInstitute.address.addressLine1 = this.editedInstitute.address.addressLine1.trim();
this.editedInstitute.address.addressLine2 = this.editedInstitute.address.addressLine2.trim();
this.editedInstitute.address.city = this.editedInstitute.address.city.trim();
this.editedInstitute.address.state = this.editedInstitute.address.state.trim()

    },
    saveInstitute() {
      this.validate();
      if (this.createInstituteValidation.valid) {
        this.trimEditedInstitue();
        const payload = this.editedInstitute;
        let url =  CREATE_INSTITUTE;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createInstituteValidation.valid = false;
            this.editedIndex = -1;
            this.editedInstitute = res.data;
            this.getAllInstitutes();
            this.close();
            this.setOverlayValue(false);
            this.setApiSuccess("Institute created successfully");
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
  },
};
</script>