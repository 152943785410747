<template>
  <v-app>
    <router-view></router-view>
    <!-- <AppFooter></AppFooter> -->
  </v-app>
</template>

<script>
import Vue from 'vue'
import AppFooter from './components/AppFooter.vue';
import { mapGetters,mapMutations } from 'vuex';
import { GET_ISAUTHENTICATED } from './store/getters';
import {SET_API_ERROR, SET_OVERLAY_VALUE, SET_TOKEN_EXPIRED , 
  SET_USER_PROFILE,
  SET_PARAMS_VALUE,} from './store/mutations'
import axios from 'axios';
export default Vue.extend({
  name: 'App',
  components: {
    AppFooter,

  },

  computed: {
    ...mapGetters({
      getIsAuth: `schoolModule/${GET_ISAUTHENTICATED}`,

    }),
    
  },
  created :function(){
    this.axiosInterceptors();
   
  },
methods:{
  ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setTokenExpired: `schoolModule/${SET_TOKEN_EXPIRED}`, 
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,  
      setUserProfile: `schoolModule/${SET_USER_PROFILE}`, 
      setParams: `schoolModule/${SET_PARAMS_VALUE}`, 
    }),
  axiosInterceptors(){
    const oThis =this;
    axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const response = error.response.data;
        if(response.errorCode==401 || response.code==401){
          if(response.errorMessage =="Authorization token is not valid"){
            // this.$store.commit(`schoolModule/${SET_TOKEN_EXPIRED}`,true);
            oThis.setTokenExpired(true);
            this.setParams({});
            this.setOverlayValue(false);
            this.setUserProfile({});
            localStorage.clear();
            sessionStorage.clear();
         }
        }
    else if(response.code==500 || response.errorCode==500 || response.status == 'INTERNAL_SERVER_ERROR'){
      oThis.setApiError("Some Technical Error");
    }
    else if(response.message){
      oThis.setApiError(response.message);
    }
    else if(response.errorMessage){
      oThis.setApiError(response.errorMessage);
    }
    else if(response.errors){
      oThis.setApiError(JSON.stringify(response.errors));
    }
     
      console.log(error);
    oThis.setOverlayValue(false);
    return Promise.reject(error);
  });
  }

}

});
</script>
<style>
body {overflow: auto;}
@import './assets/Styles/scroller.css';
@import './assets/Styles/main.css';
@import 'video.js/dist/video-js.css';
@import url('https://unpkg.com/@videojs/themes@1/dist/fantasy/index.css');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
</style>