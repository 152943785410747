<template>
  <v-system-bar
    style="background-color: rgba(61, 66, 77, 255)"
    flat
    app
    clipped-left
    height="60"
  >
    <div style="color: aliceblue" class="ma-1">v.5.2</div>

    <v-alert
      v-model="errorAlert"
      class="ma-2"
      v-if="apiErrorText"
      dense
      type="error"
      dismissible
      >{{ apiErrorText }}
    </v-alert>
    <v-alert
      v-model="successAlert"
      class="ma-2"
      v-if="apiSuccessText"
      dense
      type="success"
      dismissible
      >{{ apiSuccessText }}
    </v-alert>
    <v-spacer></v-spacer>
    <!-- <v-btn
      class="ma-2"
      color="#FAAF19"
      text
      v-if="profile.userType === USER_TYPE_STUDENT"
      style="
        font-weight: 600;
        font-size: 14px;
        outline-color: #faaf19;
        outline-width: 1px;
        outline-style: solid;
        color: white;
      "
      @click="toggleFullScreen()"
    >
      {{ fullScreenState ? "" : "Exit" }} Full screen
      <v-icon
        v-if="fullScreenState"
        dark
        color="white"
        class="ml-1 mr-0"
        size="20"
        >mdi-fullscreen
      </v-icon>
      <v-icon
        v-if="!fullScreenState"
        dark
        color="white"
        class="ml-1 mr-0"
        size="20"
        >mdi-fullscreen-exit
      </v-icon>
    </v-btn> -->
    <v-tooltip v-if="profile.userType === USER_TYPE_STUDENT" bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          v-on="on"
          text-color="white"
          class="ma-2 mr-3"
          color="#FAAF19"
          style="font-weight: 600; font-size: 16px"
          outlined
          @click="openleaderBoardDialog()"
        >
          <v-icon v-on="on" color="white" class="ma-2" size="25"
            >mdi-trophy-outline</v-icon
          >{{ studentRank }}
        </v-chip>
      </template>
      <span>Leaderboard</span>
    </v-tooltip>
    <v-tooltip v-if="profile.userType === USER_TYPE_STUDENT" bottom>
      <template v-slot:activator="{ on }">
        <v-chip
          v-on="on"
          v-if="profile.aptCoins"
          text-color="white"
          color="#FAAF19"
          style="font-weight: 600; font-size: 16px"
          outlined
          @click="openCoinHistoryDialog"
        >
          <v-avatar left>
            <v-img src="../assets/Coin.svg"></v-img>
          </v-avatar>
          {{ profile.aptCoins }}
        </v-chip>
        <v-chip
          v-on="on"
          v-else
          text-color="white"
          color="#FAAF19"
          style="font-weight: 600; font-size: 16px"
          outlined
        >
          <v-avatar left>
            <v-img src="../assets/Coin.svg"></v-img>
          </v-avatar>
          0
        </v-chip>
      </template>
      <span>Coin History</span>
    </v-tooltip>
    <v-chip
      v-if="profile"
      class="ma-2 white--text"
      color="#FAAF19"
      style="font-weight: 600; font-size: 16px"
    >
      {{ profile.userType }}
    </v-chip>
    <v-btn
      class="white--text pa-0"
      style="font-weight: 500; font-size: 16px"
      text
      @click="logout()"
    >
      Logout
    </v-btn>
    <v-dialog v-model="coinHistoryDialog" width="95%">
      <v-toolbar dark dense color="primary">
        <v-toolbar-title style="font-weight: 500; font-size: 24px"
          >Coin History</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeCoinHistoryDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <CoinHistory :key="componentKey" />
    </v-dialog>
    <v-dialog v-model="leaderBoardDialog" width="70%">
      <v-toolbar dark dense color="primary">
        <v-toolbar-title style="font-weight: 500; font-size: 24px"
          >Leaderboard</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeleaderBoardDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <LeaderBoard
        v-bind:userId="profile.userId"
        v-bind:leaderBoard="leaderBoard"
      />
    </v-dialog>
  </v-system-bar>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";

import Vue from "vue";
import CoinHistory from "@/components/CoinHistory.vue";
import LeaderBoard from "@/components/LeaderBoard.vue";
import {
  GET_USER_PROFILE,
  GET_API_ERROR_TEXT,
  GET_API_SUCCESS_TEXT,
  GET_TOKEN,
  GET_STUDENT_RANK,
} from "@/store/getters";
import { USER_TYPE_STUDENT } from "@/common/constants";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_TOKEN_EXPIRED,
  SET_USER_PROFILE,
  SET_PARAMS_VALUE,
  SET_STUDENT_RANK,
  SET_COIN_BALANCE,
} from "@/store/mutations";
import {
  BASE_URL,
  LOGOUT_URL,
  STUDENT_LEADERBOARD,
} from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import axios from "axios";
export default Vue.extend({
  data() {
    return {
      errorAlert: false,
      successAlert: false,
      errorTimeout: "",
      successTimeout: "",
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      fullScreenState: true,
      coinHistoryDialog: false,
      leaderBoardDialog: false,
      leaderBoard: [],
      componentKey: 0,
    };
  },
  components: {
    LeaderBoard,
    CoinHistory,
  },
  computed: {
    ...mapGetters({
      profile: `schoolModule/${GET_USER_PROFILE}`,
      apiErrorText: `schoolModule/${GET_API_ERROR_TEXT}`,
      apiSuccessText: `schoolModule/${GET_API_SUCCESS_TEXT}`,
      token: `schoolModule/${GET_TOKEN}`,
      studentRank: `schoolModule/${GET_STUDENT_RANK}`,
    }),
  },
  watch: {
    apiErrorText(newText) {
      if (newText) {
        this.errorAlert = true;
        this.clearErrorAlert();
      }
    },
    errorAlert(newValue) {
      if (!newValue) {
        clearTimeout(this.errorTimeout);
        this.setApiError("");
      }
    },
    apiSuccessText(newText) {
      if (newText) {
        this.successAlert = true;
        this.clearSuccessAlert();
      }
    },
    successAlert(newValue) {
      if (!newValue) {
        clearTimeout(this.successTimeout);
        this.setApiSuccess("");
      }
    },
    profile: {
      handler(profile) {
        if (
          profile.registrationCompleted &&
          profile.userType == this.USER_TYPE_STUDENT
        ) {
          this.getLeaderBoard();
        }
        if (
          profile.aptCoins &&
          profile.userType == this.USER_TYPE_STUDENT
        ) {
          this.setCoinBalance(profile.aptCoins);
        }
      },
    },
    // studentRank: {
    //   handler(studentRank) {
    //     if (studentRank) {
    //       this.studentRank = studentRank;
    //     }
    //   },
    //   immediate: true,
    // },
  },
  // destroyed() {
  //   // Remove the event listener for updating the fullscreen state when the app is destroyed
  //   document.removeEventListener(
  //     "fullscreenchange",
  //     this.updateFullScreenState
  //   );
  // },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setTokenExpired: `schoolModule/${SET_TOKEN_EXPIRED}`,
      setUserProfile: `schoolModule/${SET_USER_PROFILE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
      setStudentRank: `schoolModule/${SET_STUDENT_RANK}`,
      setCoinBalance: `schoolModule/${SET_COIN_BALANCE}`,
    }),
    generateRandomKey() {
      this.componentKey = Math.random();
    },
    getLeaderBoard() {
      let url = STUDENT_LEADERBOARD;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.leaderBoard = res.data.leaderBoard;

          var studentRank = this.leaderBoard.find(
            (lb) => lb.userId == this.profile.userId
          ).rank;
          this.setStudentRank(studentRank);
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    openCoinHistoryDialog() {
      this.generateRandomKey();
      this.coinHistoryDialog = true;
    },
    closeCoinHistoryDialog() {
      this.coinHistoryDialog = false;
    },
    openleaderBoardDialog() {
      this.leaderBoardDialog = true;
    },
    closeleaderBoardDialog() {
      this.leaderBoardDialog = false;
    },
    toggleFullScreen() {
      if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
      } else if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    },
    updateFullScreenState() {
      // Update the fullscreen state based on whether the document is in fullscreen mode
      this.fullScreenState = Boolean(!document.fullscreenElement);
    },
    clearErrorAlert: function () {
      const oThis = this;
      this.errorTimeout = setTimeout(function () {
        oThis.errorAlert = false;
      }, 4000);
    },
    clearSuccessAlert: function () {
      const oThis = this;
      this.successTimeout = setTimeout(function () {
        oThis.successAlert = false;
      }, 4000);
    },
    logout: function () {
      let url = LOGOUT_URL;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          sessionStorage.clear();
          localStorage.clear();
          this.setParams({});
          this.$router.push({ path: "/login" }).catch(() => {});
          this.setTokenExpired(true);
          this.setOverlayValue(false);
          this.setUserProfile({});
        })
        .catch((error) => {
          error = error.errorMessage;
          sessionStorage.clear();
          localStorage.clear();
          this.setParams({});
          this.setTokenExpired(true);
          this.$router.push({ path: "/login" }).catch(() => {});
          this.setOverlayValue(false);
          this.setUserProfile({});
        });
    },
  },
});
</script>

 
