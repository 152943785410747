<template>
  <v-card class="ma-1 pa-1" flat>
    <v-toolbar flat dark color="primary">
      <v-toolbar-title style="font-weight: 500; font-size: 24px">OnBoarding</v-toolbar-title>
    </v-toolbar>
    <v-card-title>
      Please fill below details to complete your on-boarding
    </v-card-title>
    <v-card-text>
      <v-stepper non-linear v-model="onboardingStep">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="basicDetailsValidation.valid">
            Basic Details
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2" :complete="qualificationValidation.valid">
            Qualification
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" :complete="addressValidation.valid">
            Address
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="4"> Schedule </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card-text style="font-size: 18px; color: #ff6666; font-weight: 600">Enter Your Basic Details</v-card-text>
            <v-form ref="form" v-model="basicDetailsValidation.valid">
              <v-row class="ma-2">
                <v-col cols="4">
                  <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="userDetails.dob" label="Date of Birth"
                        :rules="basicDetailsValidation.dobRules" prepend-icon="mdi-clock-time-four-outline" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="userDetails.dob" full-width @input="dateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="userDetails.experience" label="Experience"
                    :rules="basicDetailsValidation.experienceRules" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="userDetails.classLink" label="Class Link"
                    hint="e.g.:https://meet.google.com/abc-defg-hij" :rules="basicDetailsValidation.classRules"
                    required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-col cols="12" sm="6" md="4">
                  <span>Select Your Gender</span>
                  <v-row>
                    <v-col>
                      <v-radio-group row v-model="userDetails.gender" :rules="basicDetailsValidation.nameRules">
                        <v-radio label="Male" color="primary" value="MALE"></v-radio>
                        <v-radio label="Female" color="primary" value="FEMALE"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4">
                  <v-combobox v-model="userDetails.preferredGrades" :items="gradesArray" label="Prefrred Grades" multiple
                    dense deletable-chips chips :rules="basicDetailsValidation.preferredGradesRules" item-text="desc"
                    item-value="id">
                    <template v-slot:prepend-item>
                      <v-list-item @click="selectAllForGrades">
                        <v-list-item-action>
                          <v-icon>{{ iconForGrades() }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="ma-2">
              <v-spacer></v-spacer>
              <!-- <v-btn color="primary" @click="onboardingStep--" class="ma-1" :disabled="onboardingStep == 1">
                previous
              </v-btn>
              <v-btn color="primary" @click="
                validate();
              onboardingStep++;
              " class="ma-1">
                next
              </v-btn> -->

              <v-btn color="primary" @click="onboardingStep--" class="ma-1" :disabled="onboardingStep === 1">
                Previous
              </v-btn>
              <v-btn color="primary" @click="nextStep" class="ma-1" :disabled="!basicDetailsValidation.valid">
                Next
              </v-btn>


            </v-row>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card-text style="font-size: 18px; color: #ff6666; font-weight: 600">Enter Your Qualification
              Details</v-card-text>
            <v-form ref="form" v-model="qualificationValidation.valid">
              <v-row class="ma-2">
                <v-col cols="4">
                  <v-text-field v-model="userDetails.highestQualification.qName" label="Qualification Name"
                    :rules="qualificationValidation.qualifiactionRules" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="userDetails.highestQualification.institute" label="Institute"
                    :rules="qualificationValidation.instituteRules" required></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-col cols="4">
                  <v-text-field v-model="userDetails.highestQualification.passingYear" label="Passing Year"
                    :rules="qualificationValidation.passingRules" required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-select v-model="userDetails.speakingLanguages" :items="filteredLanguages" label="Speaking Languages"
                    multiple dense deletable-chips chips :rules="qualificationValidation.languageRules">
                    <template v-slot:prepend-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-text-field v-model="searchTerm" placeholder="Search" @input="searchLanguage"></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="ma-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onboardingStep--" class="ma-1" :disabled="onboardingStep == 1">
                previous
              </v-btn>
              <!-- <v-btn color="primary" @click="onboardingStep++" class="ma-1">
                next
              </v-btn> -->
              <v-btn color="primary" @click="nextStep" class="ma-1" :disabled="!qualificationValidation.valid">
                Next
              </v-btn>

            </v-row>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card-text style="font-size: 18px; color: #ff6666; font-weight: 600">Enter Your Address
              Details</v-card-text>
            <v-form ref="form" v-model="addressValidation.valid">
              <v-row class="ma-2">
                <v-col cols="6">
                  <v-text-field v-model="userDetails.address.addressLine1" label="Service Address Line1"
                    :rules="addressValidation.addressRules" required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="userDetails.address.addressLine2" label="Service Address Line2"></v-text-field>
                </v-col>
              </v-row>
              <v-row class="ma-2">
                <v-col cols="4">
                  <v-text-field @input="getAddressDetails()" v-model="userDetails.address.pincode" label="Pin Number"
                    :rules="addressValidation.pinRules" required type="number"></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="userDetails.address.city" label="City" :rules="addressValidation.cityRules"
                    required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field v-model="userDetails.address.state" label="State" :rules="addressValidation.stateRules"
                    required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="ma-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onboardingStep--" class="ma-1" :disabled="onboardingStep == 1">
                previous
              </v-btn>
              <!-- <v-btn color="primary" @click="onboardingStep++" class="ma-1">
                next
              </v-btn> -->

              <v-btn color="primary" @click="nextStep" class="ma-1" :disabled="!addressValidation.valid">
                Next
              </v-btn>

            </v-row>
          </v-stepper-content>
          <v-stepper-content step="4">
            <v-card-text style="font-size: 18px; color: #ff6666; font-weight: 600">Enter Your Schedule Details (select
              timings in multiple of hours
              e.g. 9:00 to 12:00 or 9:30 to 12:30)</v-card-text>
            <v-form ref="form" v-model="scheduleValidation.valid">
              <v-row>
                <v-col cols="2" class="ma-2">
                  <v-checkbox hide-details v-model="selectAllChecked" label="Select All"
                    @change="selectAllForSchedule()"></v-checkbox>
                </v-col>
                <v-col cols="3">
                  <v-select  @change="updateDaysCopyToList()" v-model="dateCopyFrom" :items="userDetails.daysCopyFrom" label="copy from" chips
                    hint="Select the day from which you want to copy the timings" persistent-hint></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select v-model="dateCopyTo" :items="userDetails.daysCopyTo" label="copy to" deletable-chips chips multiple
                    hint="Select the days to which you want to copy the timings" persistent-hint>
                    <template v-slot:prepend-item>
                      <v-list-item @click="selectAllForCopyTimings">
                        <v-list-item-action>
                          <v-icon>{{ iconForCopyTimings() }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider />
                    </template>
                  </v-select>
                </v-col>
                <v-icon @click="copyTimings()" color="primary">mdi-content-save-check</v-icon>
              </v-row>
              <v-row v-for="(   scheduleDay, index   ) in    userDetails.schedule   " :key="index" class="ma-2">
                <v-col cols="3" class="ma-0 pa-0">
                  <v-checkbox hide-details v-model="scheduleDay.checked" :label="scheduleDay.label"
                    @change="checkForAllSelected()"></v-checkbox>
                  <!-- :rules="scheduleValidation.nameRules" -->
                </v-col>
                <v-col v-if="scheduleDay.timings.length != 0" class="ma-0 pa-0">
                  <v-row class="ma-0 pa-0">
                    <span v-for="   timing    in    scheduleDay.timings   " :key="timing.id">
                      <v-chip v-if="timing.isActive" class="ma-1" close @click:close="RemoveTiming(scheduleDay, timing)">
                        {{ timing.openTime }} to {{ timing.closeTime }}
                      </v-chip>
                    </span>
                  </v-row>
                </v-col>
                <v-col v-if="scheduleDay.checked" cols="2">
                  <v-dialog max-width="300px" persistent v-model="scheduleDay.openTimeMenu">
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field v-on:click="click" v-model="scheduleDay.timings[scheduleDay.timingIndex].openTime"
                        label="Open Time" required></v-text-field>
                    </template>
                    <v-time-picker v-model="scheduleDay.timings[scheduleDay.timingIndex].openTime"
                      :allowed-minutes="[0, 30]" scrollable actions>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="
                          scheduleDay.openTimeMenu = false;
                        timeMenu(
                          openTime,
                          index,
                          scheduleDay.timingIndex,
                          'cancel',
                          'open'
                        );
                        ">Cancel</v-btn>
                        <v-btn color="primary" @click="
                          scheduleDay.openTimeMenu = false;
                        timeMenu(
                          openTime,
                          index,
                          scheduleDay.timingIndex,
                          'save',
                          'open'
                        );
                        ">OK
                          <!--  -->
                        </v-btn>
                      </v-card-actions>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col v-if="scheduleDay.checked" cols="2">
                  <v-dialog max-width="300px" persistent v-model="scheduleDay.closeTimeMenu">
                    <template v-slot:activator="{ on: { click } }">
                      <v-text-field v-on:click="click" v-model="scheduleDay.timings[scheduleDay.timingIndex].closeTime"
                        label="Close Time" required></v-text-field>
                    </template>
                    <v-time-picker v-model="scheduleDay.timings[scheduleDay.timingIndex].closeTime"
                      :allowed-minutes="[0, 30]" scrollable actions>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="
                          scheduleDay.closeTimeMenu = false;
                        timeMenu(
                          closeTime,
                          index,
                          scheduleDay.timingIndex,
                          'cancel',
                          'close'
                        );
                        ">Cancel</v-btn>
                        <v-btn color="primary" @click="
                          scheduleDay.closeTimeMenu = false;
                        timeMenu(
                          closeTime,
                          index,
                          scheduleDay.timingIndex,
                          'save',
                          'close'
                        );
                        ">OK</v-btn>
                      </v-card-actions>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col v-if="scheduleDay.checked">
                  <v-icon @click="saveTimings(scheduleDay)" color="primary" :disabled="enableSaveTimingButton(index, scheduleDay.timingIndex)
                    ">mdi-content-save-check</v-icon>
                </v-col>
              </v-row>
            </v-form>
            <v-row class="ma-2">
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onboardingStep--" class="ma-2" :disabled="onboardingStep == 1">
                previous
              </v-btn>
              <v-btn color="primary" @click="onBoard()" class="ma-2">
                Onboard
              </v-btn>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import {
  BASE_URL,
  GET_ADDRESS_DETAILS,
  TEACHER_ONBOARD,
} from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import axios from "axios";
import { GET_TOKEN } from "@/store/getters";
import { grades } from "@/common/constants";
import { FETCH_USER_PROFILE, SAVE_TOKEN } from "@/store/actions";
export default {
  data() {
    return {
      openTime: "",
      closeTime: "",
      onboardingStep: 1,
      selectAllChecked: false,
      dateCopyFrom: "",
      searchTerm: "",
      dateCopyTo: [],
      filteredLanguages: [],
      allLanguages: [
        "Hindi",
        "English",
        "Assamese",
        "Bengali",
        "Bodo",
        "Dogri",
        "Gujarati",
        "Kannada",
        "Kashmiri",
        "Konkani",
        "Maithili",
        "Malayalam",
        "Marathi",
        "Meitei",
        "Nepali",
        "Odia",
        "Punjabi",
        "Sanskrit",
        "Santali",
        "Sindhi",
        "Tamil",
        "Telugu",
        "Urdu",
      ],
      basicDetailsValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        dobRules: [(v) => !!v || "Date of Birth is required"],
        experienceRules: [
          (v) => !!v || "Experience is required",
          (v) => /^\d+$/.test(v) || "Experience must be a number",
          (v) => +v >= 0 || "Experience must be a positive number",
          (v) => (+v >= 1 && +v <= 99) || "Invalid Experience",
        ],

        classRules: [(v) => !!v || "Class Link is required"],
        preferredGradesRules: [v => !!v && v.length > 0 || 'Please select at least one preferred grade',],
      },
      qualificationValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        qualifiactionRules: [(v) => !!v || "Qualification name is required"],
        instituteRules: [(v) => !!v || "Institute is required"],
        passingRules: [
          (v) => !!v || "Passing Year is required",
          (v) => /^\d{4}$/.test(v) || "Invalid Passing Year ",
        ],
        languageRules: [v => !!v && v.length > 0 || 'Please select Speaking Language ',],
      },
      addressValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        addressRules: [(v) => !!v || "Address is required"],
        pinRules: [
          (v) => !!v || "Pin is required",
          (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
        ],
        cityRules: [(v) => !!v || "City is required"],
        stateRules: [(v) => !!v || "State is required"],
      },
      scheduleValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      timing: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      userDetails: {
        preferredGrades: [],
        highestQualification: {},
        speakingLanguages: [],
        address: {
          addressLine1: "",
          addressLine2: "",
          city: "",
          state: "",
          pincode: "",
        },
        daysCopyFrom: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        daysCopyTo: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        schedule: [
          {
            day: "MON",
            label: "Monday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            day: "TUE",
            label: "Tuesday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            day: "WED",
            label: "Wednesday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            day: "THU",
            label: "Thursday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            day: "FRI",
            label: "Friday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            day: "SAT",
            label: "Saturday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
          {
            day: "SUN",
            label: "Sunday",
            timings: [{ openTime: "", closeTime: "" }],
            checked: false,
            timingIndex: 0,
            openTimeMenu: false,
            closeTimeMenu: false,
          },
        ],
      },
      dateMenu: false,
      gradesArray: grades,
    };
  },
  mounted: function () {
    this.filteredLanguages = this.allLanguages;
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
    }),
    ...mapActions({
      saveToken: `schoolModule/${SAVE_TOKEN}`,
      fetchProfile: `schoolModule/${FETCH_USER_PROFILE}`,
    }),
    searchLanguage() {
      if (!this.searchTerm) {
        this.filteredLanguages = this.allLanguages;
      } else {
        this.filteredLanguages = this.allLanguages.filter((lang) => {
          return lang.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
        });
      }
    },
    selectAllGrades() {
      return (
        this.userDetails.preferredGrades.length === this.gradesArray.length
      );
    },
    selectSomeGrades() {
      return (
        this.userDetails.preferredGrades.length > 0 && !this.selectAllGrades()
      );
    },
    iconForGrades() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllForGrades() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.userDetails.preferredGrades = [];
        } else {
          this.userDetails.preferredGrades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },
    updateDaysCopyToList() {
      if (this.dateCopyFrom) {
        this.userDetails.daysCopyTo = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
        const index = this.userDetails.daysCopyTo.indexOf(this.dateCopyFrom);
        if (index > -1) {
          this.userDetails.daysCopyTo.splice(index, 1);
        }
      }
    },
    selectAllDayForCopyTimings() {
      return this.dateCopyTo.length == 6;
    },
    selectSomeForCopyTimings() {
      return this.dateCopyTo.length > 0 && !this.selectAllDayForCopyTimings();
    },
    iconForCopyTimings() {
      if (this.selectAllDayForCopyTimings()) return "mdi-close-box";
      if (this.selectSomeForCopyTimings()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAllForCopyTimings() {
      this.$nextTick(() => {
        if (this.selectAllDayForCopyTimings()) {
          this.dateCopyTo = [];
        } else {
          this.dateCopyTo = this.userDetails.daysCopyTo;
        }
      });
    },
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.userDetails.address.pincode.length == 6) {
        url += this.userDetails.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.district == null) {
              this.userDetails.address.city = "";
              this.userDetails.address.state = "";
            } else {
              this.userDetails.address.city = res.data.district;
              this.userDetails.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    selectAllDays() {
      return this.userDetails.schedule.findIndex((day) => day.checked == false);
    },
    selectSomeDays() {
      return this.userDetails.schedule.length > 0 && !this.selectAllDays();
    },
    checkForAllSelected() {
      if (this.selectAllDays() == -1) {
        this.selectAllChecked = true;
      } else {
        this.selectAllChecked = false;
      }
    },
    selectAllForSchedule() {
      if (this.selectAllDays() == -1) {
        this.userDetails.schedule.forEach((day) => (day.checked = false));
        this.selectAllChecked = false;
      } else {
        this.userDetails.schedule.forEach((day) => (day.checked = true));
        this.selectAllChecked = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },

    nextStep() {

      this.onboardingStep++;

    },

    onBoard() {
      this.validate();
      if (
        this.basicDetailsValidation.valid &&
        this.qualificationValidation.valid &&
        this.addressValidation.valid
      ) {
        //&& this.scheduleValidation.valid
        // console.log(this.userDetails);
        var payload = {};
        payload.dob = this.userDetails.dob;
        payload.gender = this.userDetails.gender;
        payload.experience = this.userDetails.experience;
        payload.classLink = this.userDetails.classLink;
        payload.preferredGrades = this.userDetails.preferredGrades;
        payload.highestQualification = this.userDetails.highestQualification;
        payload.address = this.userDetails.address;
        payload.speakingLanguages = this.userDetails.speakingLanguages;
        payload.schedule = [];
        for (let i = 0; i < this.userDetails.schedule.length; i++) {
          if (this.userDetails.schedule[i].checked) {
            let scheduleDay = { timings: [] };
            scheduleDay.day = this.userDetails.schedule[i].day;
            for (
              let j = 0;
              j < this.userDetails.schedule[i].timings.length;
              j++
            ) {
              if (this.userDetails.schedule[i].timings[j].isActive) {
                let timing = {};
                timing.openTime =
                  this.userDetails.schedule[i].timings[j].openTime;
                timing.closeTime =
                  this.userDetails.schedule[i].timings[j].closeTime;
                scheduleDay.timings.push(timing);
              }
            }
            payload.schedule.push(scheduleDay);
          }
        }
        if (payload.schedule.length >= 2) {
          let url = TEACHER_ONBOARD;
          this.setOverlayValue(true);
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.basicDetailsValidation.valid = false;
              this.addressValidation.valid = false;
              this.qualificationValidation.valid = false;
              this.scheduleValidation.valid = false;
              const storePayload = {};
              let oThis = this;
              storePayload.token = res.data.newToken;
              this.saveToken(storePayload).then(() => {
                oThis.fetchProfile();
                oThis.$router.push({ path: "/home/dashboard" });
                this.setApiSuccess("Teacher onboarded successfully");
              });
              this.setOverlayValue(false);
            })
            .catch((error) => {
              error = error.errorMessage;
              this.setOverlayValue(false);
            });
        } else {
          this.setApiError("Select Atleast two days in a week");
          payload = {};
        }
      }
    },
    RemoveTiming(scheduleDay, timing) {
      let index = this.userDetails.schedule.findIndex(
        (schday) => schday.day === scheduleDay.day
      );
      //  if(this.userDetails.schedule[index].timings[].)
      let tIndex = this.userDetails.schedule[index].timings.findIndex(
        (t) => t.id == timing.id
      );
      this.userDetails.schedule[index].timings.splice(tIndex, 1);
      this.userDetails.schedule[index].timingIndex--;
    },
    saveTimings(scheduleDay) {
      let index = this.userDetails.schedule.findIndex(
        (schday) => schday.day === scheduleDay.day
      );
      let lastIndex = this.userDetails.schedule[index].timings.length - 2;
      let maxId = 0;
      if (
        lastIndex >= 0 &&
        this.userDetails.schedule[index].timings[lastIndex].id >= 1
      ) {
        maxId = this.userDetails.schedule[index].timings[lastIndex].id;
      }
      this.userDetails.schedule[index].timings[scheduleDay.timingIndex].id =
        maxId + 1;
      this.userDetails.schedule[index].timings[
        scheduleDay.timingIndex
      ].isActive = true;
      this.userDetails.schedule[index].timingIndex++;
      let newTimings = {};
      newTimings.openTime = "";
      newTimings.closeTime = "";
      this.userDetails.schedule[index].timings.push(newTimings);
      // this.openTime = "";
      // this.closeTime = "";
    },
    copyTimings() {
      this.setOverlayValue(true);
      if (this.dateCopyFrom && this.dateCopyTo.length != 0) {
        let schedule = this.userDetails.schedule.find(
          (sch) => sch.day.toLowerCase() == this.dateCopyFrom.toLowerCase()
        );
        if (schedule.timings.length > 1) {
          for (var i = 0; i < 7; i++) {
            for (var j = 0; j < this.dateCopyTo.length; j++) {
              if (
                this.userDetails.schedule[i].day.toLowerCase() ==
                this.dateCopyTo[j].toLowerCase()
              ) {
                if (schedule.checked) {
                  this.userDetails.schedule[i].timings = [];
                  for (var k = 0; k < schedule.timings.length - 1; k++) {
                    let newTimings = {};
                    newTimings.openTime = schedule.timings[k].openTime;
                    newTimings.closeTime = schedule.timings[k].closeTime;
                    newTimings.id = schedule.timings[k].id;
                    newTimings.isActive = true;
                    this.userDetails.schedule[i].timings.push(newTimings);
                  }
                  let newTimings = {};
                  newTimings.openTime = "";
                  newTimings.closeTime = "";
                  this.userDetails.schedule[i].timings.push(newTimings);
                  //  this.userDetails.schedule[i].timings =  Object.assign([],schedule.timings)
                  // this.userDetails.schedule[i].timings =
                  //   schedule.timings.slice(0);
                  this.userDetails.schedule[i].checked = true;
                  this.userDetails.schedule[i].timingIndex =
                    schedule.timingIndex;
                }
              }
            }
          }
          this.dateCopyTo = [];
          this.dateCopyFrom = "";
        } else {
          this.setApiError("Timings are not available to Copy");
        }
      }
      this.setOverlayValue(false);
      this.userDetails.daysCopyTo = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    },
    timeMenu(time, index, timingIndex, action, timingType) {
      if (action == "cancel") {
        if (timingType == "open")
          this.userDetails.schedule[index].timings[timingIndex].openTime = "";
        else {
          this.userDetails.schedule[index].timings[timingIndex].closeTime = "";
        }
        // } else {
        //   if (timingType == "open")
        //     this.userDetails.schedule[index].timings[timingIndex].openTime = time;
        //   else {
        //     this.userDetails.schedule[index].timings[timingIndex].closeTime =
        //       time;
        //   }
      }
    },
    enableSaveTimingButton(index, timingIndex) {
      if (
        this.userDetails.schedule[index].timings[timingIndex].openTime.split(
          ":"
        )[1] !=
        this.userDetails.schedule[index].timings[timingIndex].closeTime.split(
          ":"
        )[1]
      ) {
        return true;
      }
      if (
        this.userDetails.schedule[index].timings[timingIndex].openTime &&
        this.userDetails.schedule[index].timings[timingIndex].closeTime
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
