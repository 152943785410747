<template>
  <v-row class="ma-2 pa-2">
    <v-col cols="12">
      <v-toolbar dark color="primary">
        <v-toolbar-title style="font-weight: 500; font-size: 24px"
          >Resources</v-toolbar-title
        >
        <v-spacer></v-spacer>

        <v-btn
          v-if="userType == USER_TYPE_RESOURCE_MANAGER"
          dark
          text
          @click="openCreateTab()"
          style="font-size: 16px"
        >
          New Resource
        </v-btn>
      </v-toolbar>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-tabs v-model="tab">
        <v-tab href="#search-tab">
          <v-icon left> mdi-magnify </v-icon>
          Search
        </v-tab>
        <v-tab href="#create-tab">
          <v-icon left> mdi-folder-plus-outline </v-icon>

          {{ resource.name }}
        </v-tab>
        <v-tab-item value="search-tab">
          <!-- <ResourceSearch></ResourceSearch> -->
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="3">
                  <v-text-field
                    label="Resource Name"
                    v-model="searchResource.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-combobox
                    v-model="searchResource.contentType"
                    :items="contentTypes"
                    label="Select content type"
                  ></v-combobox>
                </v-col>
                <v-col cols="3">
                  <v-combobox
                    v-model="searchResource.status"
                    :items="ResourceStatus"
                    label="Select Status"
                  >
                  </v-combobox>
                </v-col>
                <v-col cols="2">
                  <v-btn icon color="primary" @click="searchResources()">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">mdi-magnify</v-icon>
                      </template>
                      <span>Search</span>
                    </v-tooltip>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-data-table
                    :headers="resourceHeaders"
                    :items="resourceList"
                    @contextmenu:row="rightClickHandler"
                  >
                    <template v-slot:[`item.createdBy`]="{ item }">
                      <span>{{ item.createdBy.name }}</span>
                    </template>
                    <template v-slot:[`item.status`]="{ item }">
                      <v-chip
                        small
                        label
                        :color="
                          item.status == STATUS_PUBLISHED ? 'success' : 'error'
                        "
                        >{{ item.status }}</v-chip
                      >
                    </template>
                    <template v-slot:[`item.contentType`]="{ item }">
                      <v-chip small label color="primary">{{
                        item.contentType
                      }}</v-chip>
                    </template>
                    <template v-slot:[`item.createdOn`]="{ item }">
                      <span>{{ formatDateTime(item.createdOn) }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            v-on="on"
                            color="success"
                            @click="getResourceDetails(item)"
                            >mdi-eye</v-icon
                          >
                        </template>
                        <span>View</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            v-on="on"
                            color="accent"
                            :disabled="
                              item.status == STATUS_PUBLISHED ||
                              item.status == CONTENT_STATUS_BLOCKED ||
                              !item.source
                            "
                            @click="publishResource(item)"
                            >mdi-file-upload-outline</v-icon
                          >
                        </template>
                        <span>Publish</span>
                      </v-tooltip>
                      <v-tooltip
                        bottom
                        v-if="item.status != CONTENT_STATUS_BLOCKED"
                      >
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            v-on="on"
                            color="error"
                            @click="blockResource(item)"
                            >mdi-cancel</v-icon
                          >
                        </template>
                        <span>Block</span>
                      </v-tooltip>
                      <v-tooltip bottom v-else>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            v-on="on"
                            color="info"
                            @click="unBlockResource(item)"
                            >mdi-cancel</v-icon
                          >
                        </template>
                        <span>Unblock</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            v-on="on"
                            color="primary"
                            :disabled="item.status == STATUS_PUBLISHED"
                            @click="editResource(item)"
                            >mdi-pencil</v-icon
                          >
                        </template>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="mr-2"
                            v-on="on"
                            color="error"
                            :disabled="
                              item.status == STATUS_PUBLISHED ||
                              item.status == CONTENT_STATUS_BLOCKED
                            "
                            @click="deleteResource(item)"
                            >mdi-delete</v-icon
                          >
                        </template>
                        <span>Delete</span>
                      </v-tooltip>
                    </template>
                  </v-data-table></v-col
                ></v-row
              ></v-card-text
            ></v-card
          ></v-tab-item
        ><v-tab-item value="create-tab">
          <v-stepper v-model="e6" vertical non-linear class="mt-4">
            <v-stepper-step
              :complete="e6 > 1"
              step="1"
              editable
              color="#F55B49"
              style="font-size: 20px; padding: 10px; font-weight: 400"
              ><span class="red--text mt-4"> Basic Details</span>
              <small class="mt-2">Name, Content Type....</small>
            </v-stepper-step>
            <v-stepper-content step="1">
              <v-container>
                <v-form
                  v-if="!resource.resourceId || editedIndex > -1"
                  ref="form"
                  v-model="resourceValidation.valid"
                >
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="resource.name"
                        label="Resource name"
                        :rules="resourceValidation.nameRules"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-combobox
                        v-model="resource.contentType"
                        :items="contentTypes"
                        label="Select content type"
                      ></v-combobox>
                      <!-- <v-text-field
                        v-model="resource.contentType"
                        label="Content Type"
                        :rules="resourceValidation.nameRules"
                        required
                      ></v-text-field> -->
                    </v-col>
                    <v-col>
                      <v-btn color="primary" icon @click="createResource()">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-content-save-check</v-icon>
                          </template>
                          <span>Save</span>
                        </v-tooltip>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row v-else>
                  <v-col>
                    <!-- <v-hover v-slot="{ hover }"> -->
                    <!-- <v-col> -->
                    <h3 class="primary--text">
                      {{ resource.name }}
                    </h3>
                    <!-- <v-textfield
                        v-else
                        v-model="name"
                        dense
                        appendicon="mdi-content-save-check"
                        @click:append="updateResource()"
                        single-line
                        outlined
                      ></v-textfield> -->
                    <!-- </v-col> -->
                    <!-- </v-hover> -->
                  </v-col>
                  <v-col>
                    <v-chip class="ma-2" color="secondary" small>
                      {{ resource.contentType }}
                    </v-chip>
                  </v-col>
                  <v-col>
                    <v-chip class="ma-2" color="secondary" small>
                      {{ resource.status }}
                    </v-chip>
                  </v-col>
                  <v-col>
                    <span class="ma-2 text-caption">{{
                      formatDateTime(resource.createdOn)
                    }}</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-stepper-step
              :complete="e6 > 2"
              step="2"
              editable
              style="font-size: 20px; padding: 10px; font-weight: 400"
            >
              Source
            </v-stepper-step>
            <v-stepper-content step="2">
              <v-container v-if="resource.contentType == 'MEDIA'">
                <v-row>
                  <v-col v-if="resource.source" cols="8">
                    <v-card-title>
                      <v-chip class="ma-2" small color="primary">
                        {{ resource.sourceType }}
                      </v-chip>
                      <v-chip class="ma-1" x-small color="green" label outlined>
                        Mime Type
                      </v-chip>
                      <v-chip class="ma-1" x-small color="green" label outlined>
                        {{ resource.mimeType }}
                      </v-chip>
                    </v-card-title>
                  </v-col>
                  <v-col cols="4">
                    <v-card flat>
                      <v-badge icon bottom avatar overlap>
                        <template v-slot:badge>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on" @click="addThumbnail()"
                                >mdi-upload</v-icon
                              >
                            </template>
                            <span>Upload</span>
                          </v-tooltip>
                          <input
                            ref="uploader"
                            class="d-none"
                            type="file"
                            @change="onFileChanged"
                          />
                        </template>
                        <v-avatar v-if="!preview && !resource.source" size="50">
                          <v-img
                            class="align-start text-white"
                            height="50"
                            src="@/assets/Avatar_sales.png"
                            contain
                          >
                          </v-img>
                        </v-avatar>
                        <vue-friendly-iframe
                          v-else-if="!preview && resource.source"
                          :src="resource.source"
                        ></vue-friendly-iframe>
                        <v-avatar v-else size="50">
                          <v-img :src="preview"> </v-img>
                        </v-avatar>
                      </v-badge>
                    </v-card>
                    <v-card-actions v-if="showUploadBtns">
                      <v-btn color="primary" x-small icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="uploadFile()"
                              >mdi-upload</v-icon
                            >
                          </template>
                          <span>Upload</span>
                        </v-tooltip>
                      </v-btn>
                      <v-btn color="primary" x-small icon>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="error"
                              @click="cancelUpload()"
                              >mdi-cancel</v-icon
                            >
                          </template>
                          <span>Cancel</span>
                        </v-tooltip>
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
              <v-container v-else>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <template
                        v-if="resource.images && resource.images.length > 0"
                      >
                        <v-col
                          v-for="(image, index) in resource.images"
                          :key="index"
                          cols="auto"
                          class="d-flex flex-column align-items-center"
                        >
                          <v-avatar size="50">
                            <v-img :src="image.source"></v-img>
                          </v-avatar>
                          <span>{{ image.sequence }}</span>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col
                          v-for="(image, index) in selectedImages"
                          :key="index"
                          cols="auto"
                          class="d-flex flex-column align-items-center"
                        >
                          <v-avatar size="50">
                            <v-img :src="image.url"></v-img>
                          </v-avatar>
                          <v-icon
                            small
                            color="error"
                            class="mt-2"
                            @click="removeImage(index)"
                            >mdi-close-circle</v-icon
                          >
                        </v-col>
                        <v-col
                          cols="auto"
                          class="d-flex flex-column align-items-center"
                        >
                          <v-avatar size="50">
                            <v-icon @click="selectImages()">mdi-plus</v-icon>
                          </v-avatar>
                          <span class="mt-2">Add Images</span>
                        </v-col>
                      </template>
                    </v-row>
                    <input
                      ref="uploader"
                      class="d-none"
                      type="file"
                      accept="image/*"
                      multiple
                      @change="onFileChanged2"
                    />
                    <v-row>
                      <v-col
                        cols="12"
                        class="d-flex justify-end"
                        v-if="selectedImages.length > 0"
                      >
                        <v-btn color="primary" text @click="viewImages"
                          >View</v-btn
                        >
                        <v-btn color="primary" text @click="sequenceImages"
                          >Sequence</v-btn
                        >
                        <v-btn color="primary" text @click="uploadImages"
                          >Upload</v-btn
                        >
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-end"
                        v-if="resource.images && resource.images.length > 0"
                      >
                        <v-btn color="primary" text @click="viewImages2"
                          >View</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>

              <v-dialog v-model="viewPdfDialog" max-width="800px">
                <v-card>
                  <v-card-title class="headline">View Images</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          v-for="(image, index) in selectedImages"
                          :key="index"
                          cols="12"
                          class="mb-4"
                        >
                          <v-img :src="image.url" aspect-ratio="1.5"></v-img>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="viewPdfDialog = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="viewPdfDialog2" max-width="800px">
                <v-card>
                  <v-card-title class="headline">View Images</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col
                          v-for="(image, index) in resource.images"
                          :key="index"
                          cols="12"
                          class="mb-4"
                        >
                          <v-img :src="image.source" aspect-ratio="1.5"></v-img>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="viewPdfDialog2 = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="sequenceDialog" max-width="800px">
                <v-card>
                  <v-card-title class="headline">Sequence Images</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item
                        v-for="(image, index) in selectedImages"
                        :key="sequenceKey + '-' + index"
                        class="d-flex align-center"
                      >
                        <v-list-item-avatar>
                          <v-img :src="image.url"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            >Image {{ index + 1 }}</v-list-item-title
                          >
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-select
                            v-model="image.sequence"
                            :items="sequenceOptions"
                            label="Sequence"
                            dense
                            outlined
                            @change="onSequenceChange(index, image.sequence)"
                          ></v-select>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="sequenceDialog = false"
                      >Close</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-stepper-content>
            <v-stepper-step
              :complete="e6 > 2"
              step="3"
              editable
              style="font-size: 20px; padding: 10px; font-weight: 400"
            >
              Tags
            </v-stepper-step>
            <v-stepper-content step="3">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <ul>
                      <li v-for="item in resource.tags" :key="item.category">
                        <v-row>
                          <v-col cols="4">
                            <span class="ma-2 primary--text text-h6">{{
                              item.category
                            }}</span>
                          </v-col>
                          <v-col cols="7">
                            <v-combobox
                              v-model="item.values"
                              :search-input.sync="item.search"
                              hide-selected
                              multiple
                              dense
                              depressed
                              small-chips
                              outlined
                            >
                              <template v-slot:no-data>
                                <v-list-item>
                                  <span class="subheading"
                                    >Press Enter To Create:</span
                                  >
                                  <v-chip color="primary" label small>
                                    {{ item.search }}
                                  </v-chip>
                                </v-list-item>
                              </template>
                            </v-combobox>
                          </v-col>
                          <v-col cols="1">
                            <v-btn
                              color="primary"
                              icon
                              @click="addNewTag(item)"
                            >
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-icon v-on="on"
                                    >mdi-content-save-check</v-icon
                                  >
                                </template>
                                <span>Save</span>
                              </v-tooltip>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </li>
                    </ul>
                  </v-col>
                  <v-col class="ma-2 pa-2">
                    <v-chip class="ma-1" x-small color="green" label outlined>
                      Teach Mode
                    </v-chip>
                    <v-chip
                      v-if="resource.teachMode"
                      class="ma-1"
                      x-small
                      color="green"
                      label
                      outlined
                    >
                      {{ resource.teachMode }}
                    </v-chip>
                    <v-text-field
                      v-else
                      v-model="teachMode"
                      label="Teach mode"
                      dense
                      append-icon="mdi-content-save-check"
                      @click:append="updateResource()"
                      single-line
                      outlined
                    >
                    </v-text-field>
                    <v-chip class="ma-1" x-small color="green" label outlined>
                      Learn Mode
                    </v-chip>
                    <v-chip
                      v-if="resource.learnMode"
                      class="ma-1"
                      x-small
                      color="green"
                      label
                      outlined
                    >
                      {{ resource.learnMode }}
                    </v-chip>
                    <v-text-field
                      v-else
                      v-model="learnMode"
                      label="Learn mode"
                      dense
                      append-icon="mdi-content-save-check"
                      @click:append="updateResource()"
                      single-line
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="ma-2">
                  <v-col cols="2">
                    <v-text-field
                      v-model="newTag.category"
                      label="Category"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2">
                    <v-text-field
                      v-model="newTag.tag"
                      label="Tag"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      color="primary"
                      icon
                      @click="addNewTagCategory()"
                      x-small
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on">mdi-content-save-check</v-icon>
                        </template>
                        <span>Save</span>
                      </v-tooltip>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-btn color="primary" text @click="publishResource(resource)"
                  >Publish</v-btn
                >
              </v-col>
            </v-row>
          </v-stepper>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";
import {
  BASE_URL,
  CREATE_RESOURCE,
  GET_RESOURCE,
  UPDATE_RESOURCE,
  UPLOAD_RESOURCE,
  SEARCH_RESOURCE,
  PUBLISH_RESOURCE,
  BLOCK_RESOURCE,
  UNBLOCK_RESOURCE,
  DELETE_RESOURCE,
  UPLOAD_IMAGES,
} from "@/common/apiEndpoints.js";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  CONTENT_STATUS_BLOCKED,
  CONTENT_STATUS_DRAFT,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import {
  formatDateTime,
  getAxiosOptions,
  rightClickHandler,
} from "@/common/utility";
import { SET_API_SUCCESS } from "@/store/mutations";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import ResourceSearch from "./ResourceSearch.vue";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      selectedImages: [],
      viewPdfDialog: false,
      viewPdfDialog2: false,
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      CONTENT_STATUS_BLOCKED: CONTENT_STATUS_BLOCKED,
      CONTENT_STATUS_DRAFT: CONTENT_STATUS_DRAFT,
      e6: 1,
      resourceValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      resourceHeaders: [
        {
          text: "Resource Name ",
          align: "start",
          value: "name",
        },
        {
          text: "Content Type",
          align: "center",
          value: "contentType",
        },
        { text: "Status", align: "center", value: "status" },
        {
          text: "CreatedOn",
          align: "center",
          value: "createdOn",
        },
        {
          text: "Created By",
          value: "createdBy",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      resourceList: [],
      editedIndex: -1,
      resource: {
        tags: [],
      },
      newTag: {
        category: "",
        values: [],
        search: null,
        tag: "",
      },
      file: "",
      preview: "",
      showUploadBtns: false,
      searchResource: {},
      contentTypes: ["MEDIA", "IMAGE_ARRAY"], //"TEXT", "CODELY",
      ResourceStatus: ["DRAFT", "PUBLISHED", "BLOCKED"],
      status: "",
      tab: "search-tab",
      teachMode: "",
      learnMode: "",
      overlay: false,
      name: "",
      sequenceDialog: false,
      sequenceOptions: [],
      selectedFiles: [],
      sequenceKey: 0,
    };
  },
  components: {},
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  // watch: {
  //   dialog(val) {
  //     val || this.close();
  //   },
  //   publishConfirmDialog(val) {
  //     val || this.closePublishDailog();
  //   },
  // },
  mounted: function () {
    this.searchResources();
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    sequenceImages() {
      this.updateSequenceOptions();
      this.sequenceDialog = true;
    },
    onSequenceChange(index, newSequence) {
      if (newSequence < 1 || newSequence > this.selectedImages.length) {
        return;
      }

      const movedImage = this.selectedImages.splice(index, 1)[0];
      const movedFile = this.selectedFiles.splice(index, 1)[0];

      this.selectedImages.splice(newSequence - 1, 0, movedImage);
      this.selectedFiles.splice(newSequence - 1, 0, movedFile);

      this.selectedImages.forEach((image, idx) => {
        image.sequence = idx + 1;
      });

      this.updateSequenceOptions();
      this.sequenceKey++;
    },

    saveSequence() {
      console.log("New sequence:", this.selectedImages);
      this.sequenceDialog = false;
    },

    updateSequenceOptions() {
      const length = this.selectedImages.length;
      this.sequenceOptions = Array.from({ length }, (_, i) => i + 1);
    },
    viewImages() {
      this.viewPdfDialog = true;
    },
    viewImages2() {
      this.viewPdfDialog2 = true;
    },
    trimResources() {
      this.resource.name = this.resource.name.trim();
    },
    createResource() {
      if (this.resourceValidation.valid) {
        this.trimResources();
        let payload = {};
        let url = "";
        if (this.editedIndex > -1) {
          this.setOverlayValue(true);
          url = UPDATE_RESOURCE;
        } else {
          this.setOverlayValue(true);
          url = CREATE_RESOURCE;
        }
        payload = this.resource;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.resourceValidation.valid = false;
            this.resource = res.data;
            if (this.editedIndex > -1) {
              this.setApiSuccess("Resource updated successfully");
            } else {
              this.setApiSuccess("Resource created successfully");
              this.resource.unshift(res.data);
            }
            this.e6 = 2;
            this.editedIndex = -1;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
            this.setOverlayValue(false);
          });
      }
    },
    addNewTag(item) {
      let payload = {};
      payload.resourceId = this.resource.resourceId;
      payload.category = item.category;
      payload.values = item.values;
      this.saveTags(payload);
    },
    saveTags(payload) {
      let url = UPDATE_RESOURCE;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.resource = res.data;
          this.newTag = {
            category: "",
            values: [],
            search: null,
            tag: "",
          };
          this.setOverlayValue(false);
          this.setApiSuccess("Resource created successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    updateResource() {
      let payload = {};
      payload.resourceId = this.resource.resourceId;
      if (this.learnMode) {
        payload.learnMode = this.learnMode;
      }
      if (this.teachMode) {
        payload.teachMode = this.teachMode;
      }
      if (this.name) {
        payload.name = this.name;
      }
      let url = UPDATE_RESOURCE;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.resource = res.data;
          this.learnMode = "";
          this.teachMode = "";
          this.name = "";
          this.setOverlayValue(false);
          this.setApiSuccess("Resource updated successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    addNewTagCategory() {
      let exits = this.resource.tags.filter((tag) => {
        return tag.category == this.newTag.category;
      });
      if (exits.length == 0) {
        let payload = {};
        this.newTag.values.push(this.newTag.tag);
        delete this.newTag.tag;
        payload.resourceId = this.resource.resourceId;
        payload.category = this.newTag.category;
        payload.values = this.newTag.values;
        this.saveTags(payload);
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    onFileChanged2(event) {
  const newFiles = Array.from(event.target.files);

  if (!this.selectedFiles) {
    this.selectedFiles = newFiles;
    return;
  }

  this.selectedFiles = [...this.selectedFiles, ...newFiles];

  for (let i = 0; i < newFiles.length; i++) {
    const file = newFiles[i];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.selectedImages.push({
        url: e.target.result,
        sequence: this.selectedImages.length + 1,
      });
      this.updateSequenceOptions();
    };
    reader.readAsDataURL(file);
  }
}
,

    selectImages() {
      this.$refs.uploader.click();
    },
    removeImage(index) {
      this.selectedImages.splice(index, 1);
      this.selectedFiles.splice(index, 1);
    },
    addThumbnail() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      if (this.file) {
        this.preview = URL.createObjectURL(e.target.files[0]);
      } else {
        this.preview = "";
      }
      this.showUploadBtns = true;
    },
    uploadFile() {
      this.overlay = true;
      let payload = {};
      payload.file = this.file;
      payload.resourceId = this.resource.resourceId;
      let url = UPLOAD_RESOURCE;
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.overlay = false;
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
          this.getResourceDetails(this.resource);
          this.setOverlayValue(false);
          this.setApiSuccess("Source added successfully");
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    uploadImages() {
      this.overlay = true;

      const formData = new FormData();

      this.selectedFiles.forEach((file) => {
        formData.append("files", file);
      });

      formData.append("resourceId", this.resource.resourceId);

      let url = UPLOAD_IMAGES;
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";

      this.setOverlayValue(true);

      axios
        .post(url, formData, options)
        .then((res) => {
          this.overlay = false;
          this.selectedFiles = [];
          this.selectedImages = [];
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
          this.getResourceDetails(this.resource);
          this.setOverlayValue(false);
          this.setApiSuccess("Source added successfully");
        })
        .catch((error) => {
          this.setOverlayValue(false);
          console.error("Error uploading files:", error);
        });
    },
    cancelUpload() {
      this.showUploadBtns = false;
      this.file = "";
      this.preview = "";
    },
    searchResources() {
      let payload = {};
      if (this.searchResource.name) {
        payload.name = this.searchResource.name;
      }
      if (this.searchResource.status) {
        payload.status = this.searchResource.status;
      }
      let url = SEARCH_RESOURCE;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.resourceList = res.data.resources;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    getResourceDetails(item) {
      let payload = {};
      payload.resourceId = item.resourceId;
      let url = GET_RESOURCE;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.resource = res.data;
          this.tab = "create-tab";
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    openCreateTab() {
      this.tab = "create-tab";
      this.resource = {
        tags: [],
      };
    },
    publishResource(resource) {
      let url = PUBLISH_RESOURCE;
      let payload = {};
      payload.resourceId = resource.resourceId || this.resource.resourceId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.searchResources();
          this.setOverlayValue(false);
          this.setApiSuccess("Resource published successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    deleteResource(resource) {
      let url = DELETE_RESOURCE;
      let payload = {};
      payload.resourceId = resource.resourceId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.searchResources();
          this.setOverlayValue(false);
          this.setApiSuccess("Resource deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    blockResource(resource) {
      let url = BLOCK_RESOURCE;
      let payload = {};
      payload.resourceId = resource.resourceId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.searchResources();
          this.setOverlayValue(false);
          this.setApiSuccess("Resource blocked successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.setOverlayValue(false);
        });
    },
    editResource(item) {
      this.resource = Object.assign({}, item);
      this.editedIndex = 0;
      this.openCreateTab();
      this.resource = item;
    },
    unBlockResource(resource) {
      let url = UNBLOCK_RESOURCE;
      let payload = {};
      payload.resourceId = resource.resourceId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.searchResources();
          this.setOverlayValue(false);
          this.setApiSuccess("Resource unblocked successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.setOverlayValue(false);
        });
    },
  },
  components: { ResourceSearch, ContextMenu },
};
</script>
<style>
.v-stepper__step__step {
  width: 40px;
  height: 40px;
  font-size: 20px;
}
.drag-container {
  display: flex;
  flex-direction: column;
}
</style>
