<template>
    <v-card flat class="pt-8" width="90%">
      <p style="font-size: 20px" v-html="htmlContent"></p>
    </v-card>
</template>
  
<script>
  export default {
    props: {
      htmlContent: {
        type: String,
        required: true
      }
    }
  };
</script>
  
  