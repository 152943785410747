<template>
    <v-card elevation="0" class="mt-2">
      <v-toolbar dense dark color="white" elevation="0">
       
        <v-toolbar-title style="font-weight: 500; font-size: 24px; color: black;">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col v-for="(image, index) in images" :key="index" cols="12" class="mb-4">
              <v-img :src="image.source" aspect-ratio="1.5"></v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
     
    </v-card>
  </template>
  
  <script>
  export default {
    props: {
      images: {
        type: Array,
        required: true
      },
      title: {
        type: String,
        default: 'Preview'
      }
    },
    methods: {
      close() {
        this.$router.go(-1);
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  