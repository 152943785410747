<template>
  <v-container>
    <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
    <!-- <v-dialog persistent v-model="createScheduleDialog" width="500">
      <v-card>
        <v-card-title class="primary--text">Create Schedule</v-card-title>
        <v-form ref="form" v-model="createClassRoomValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    type="number"
                    v-model="editedClassRoom.noOfClasses"
                    label="Enter No. of classes"
                    :rules="createClassRoomValidation.nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="CloseCreateClassSchedule()"
                  >Cancel
                </v-btn>
                <v-btn color="primary" text @click="createClassSchedule()">
                  Save
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog> -->
    <v-dialog persistent v-model="extraClassDialog" max-width="500px">
      <v-card>
        <v-card-title>How many extra classes you want to create?</v-card-title>
        <v-form ref="form" v-model="extraClassValidation.valid">
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="editedClassRoom.extraClass"
                type="number"
                label="No of Classes"
                :rules="extraClassValidation.noOfClassesRules"
                required
              ></v-text-field>
            </v-container>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeExtraClassDialog()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="extraClass()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="enrollStdDialog" max-width="1000px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title style="font-weight: 500; font-size: 24px"
            >Student Enrollment</v-toolbar-title
          >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-switch
                  class="mt-4 pa-2"
                  v-model="deEnrollMode"
                  @change="changeEnrollMode()"
                  label=""
                ></v-switch>
              </div>
            </template>
            <span>Remove Enrollment</span>
          </v-tooltip>
          <v-spacer></v-spacer>
          <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
            >mdi-magnify</v-icon
          >
          <v-text-field
            v-if="deEnrollMode"
            v-model="searchStudent"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
        <v-expansion-panels v-if="!deEnrollMode" v-model="panel" multiple flat>
          <v-expansion-panel>
            <v-expansion-panel-content>
              <v-container>
                <v-form ref="form" class="p-2 m-2">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.mobileNo"
                        label="Mobile No"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.email"
                        label="Email"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.firstName"
                        label="First Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="studentFilter.lastName"
                        label="Last Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <!-- <v-text-field
                        v-model="studentFilter.schoolName"
                        label="School Name"
                        color="primary"
                      ></v-text-field> -->
                      <v-select
                        v-model="selectedInstitute"
                        :items="institute"
                        label="Institute Name"
                        outlined
                        dense
                        item-text="instituteName"
                        item-value="instituteId"
                      >
                      </v-select>
                    </v-col>
                    <v-col>
                      <v-combobox
                        v-model="studentFilter.grade"
                        :items="gradesArray"
                        label="Grades"
                        dense
                        deletable-chips
                        chips
                        item-text="desc"
                        item-value="id"
                      ></v-combobox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="searchStudents()">
                      Apply
                    </v-btn>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          v-model="selectedStudents"
          :headers="studentHeadersForEnroll"
          :items="studentsList"
          show-select
          item-key="studentId"
          item-value="studentId"
          :search="searchStudent"
          @toggle-select-all="selectAllStudents"
          @contextmenu:row="rightClickHandler"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-row justify="center">
              <v-col>
                <v-chip
                  x-small
                  :color="item.status == 'ACTIVE' ? 'success' : 'error'"
                  label
                >
                  {{ item.status }}
                </v-chip>
              </v-col>
              <v-col>
                <v-icon v-if="!deEnrollMode && item.isEnrolled" color="primary"
                  >mdi-account-check</v-icon
                >
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ formatDateTime(item.createdOn) }}
          </template>
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :value="isSelected"
              :disabled="
                item.status != 'ACTIVE' || (!deEnrollMode && item.isEnrolled)
              "
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEnrollDialog()"
            >Cancel</v-btn
          >
          <v-btn
            v-if="!deEnrollMode && !addClassRoomDialog"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="enrollDeEnrollStudents('Assign')"
            >Enroll</v-btn
          >
          <v-btn
            v-if="!deEnrollMode && addClassRoomDialog"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="selectStudents()"
            >Enroll</v-btn
          >
          <v-btn
            v-if="deEnrollMode"
            :disabled="selectedStudents.length == 0"
            color="primary"
            text
            @click="enrollDeEnrollStudents('UnAssign')"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="enrollTeacherDialog" max-width="1000px">
      <v-card>
        <v-toolbar color="primary" dark>
          <v-toolbar-title style="font-weight: 500; font-size: 24px"
            >Teacher Enrollment</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-icon v-if="!deEnrollMode" @click="openCloseSearchPanel()"
            >mdi-magnify</v-icon
          >
        </v-toolbar>
        <v-expansion-panels v-if="!deEnrollMode" v-model="panel" multiple flat>
          <v-expansion-panel>
            <v-expansion-panel-content>
              <v-container>
                <v-form ref="form" class="p-2 m-2">
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="teacherFilter.mobileNo"
                        label="Mobile No"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="teacherFilter.email"
                        label="Email"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="teacherFilter.firstName"
                        label="First Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="teacherFilter.lastName"
                        label="Last Name"
                        color="primary"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="searchTeachers()">
                      Apply
                    </v-btn>
                  </v-row>
                </v-form>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-data-table
          v-model="selectedTeacher"
          :headers="teacherAssignmentHeaders"
          :items="teachers"
          show-select
          item-key="teacherId"
          item-value="teacherId"
          :single-select="true"
          @contextmenu:row="rightClickHandler"
        >
          <template v-slot:[`item.status`]="{ item }">
            <v-row justify="center">
              <v-col>
                <v-chip
                  x-small
                  :color="item.status == 'ACTIVE' ? 'success' : 'error'"
                  label
                >
                  {{ item.status }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.createdOn`]="{ item }">
            {{ formatDateTime(item.createdOn) }}
          </template>
          <template
            v-slot:[`item.data-table-select`]="{ item, isSelected, select }"
          >
            <v-simple-checkbox
              :disabled="item.status != 'ACTIVE'"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
          </template>
        </v-data-table>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeEnrollDialog()"
            >Cancel</v-btn
          >
          <v-btn
            v-if="addClassRoomDialog"
            color="primary"
            text
            @click="selectTeacher()"
            >Assign</v-btn
          >
          <v-btn v-else color="primary" text @click="enrollTeacher()"
            >Assign</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="addClassRoomDialog" max-width="700px">
      <v-card>
        <v-card-title class="primary--text">{{
          classRoomDialogTitle
        }}</v-card-title>
        <v-row class="mx-2">
          <v-card-title>{{ batch.name }}</v-card-title>
          <v-col class="ma-1">
            <span v-for="i in batch.openDays.length" :key="i">
              <v-menu open-on-hover offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    class="ma-1"
                    x-small
                    v-on="on"
                    v-bind="attrs"
                    color="green"
                    outlined
                    v-if="batch.openDays[i - 1] != null"
                  >
                    {{ batch.openDays[i - 1].day }}
                  </v-chip>
                </template>
                <div v-if="batch.openDays[i - 1] != null">
                  <v-chip class="ma-1 pa-1" color="red" outlined>
                    openTime : {{ batch.openDays[i - 1].openTime }} closeTime :
                    {{ batch.openDays[i - 1].closeTime }}
                  </v-chip>
                </div>
              </v-menu>
            </span>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="createClassRoomValidation.valid">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    :disabled="editedClassRoom.status == 'SCHEDULED'"
                    v-model="editedClassRoom.name"
                    label="Classroom Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    :disabled="editedClassRoom.status == 'SCHEDULED'"
                    v-model="editedClassRoom.courseId"
                    :items="notBuyableCourses"
                    label="Course"
                    outlined
                    dense
                    item-text="name"
                    item-value="courseId"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="editedIndex != 0">
                <v-col cols="6">
                  <v-text-field
                    v-model="editedClassRoom.teacherName"
                    :rules="createClassRoomValidation.nameRules"
                    label="Assign Teacher"
                    required
                    readonly
                    append-outer-icon="mdi-account-plus-outline"
                    @click="openEnrollTeacherDialog()"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="studentNames"
                    :rules="createClassRoomValidation.nameRules"
                    label="Assign Students"
                    required
                    readonly
                    append-outer-icon="mdi-account-multiple-plus-outline"
                    @click="openEnrollStudentDialog()"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-menu
                    v-model="startDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="editedClassRoom.status == 'SCHEDULED'"
                        v-model="editedClassRoom.startDate"
                        label="Start date"
                        :rules="createClassRoomValidation.nameRules"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="editedClassRoom.startDate"
                      full-width
                      :min="new Date().toISOString().substr(0, 10)"
                      @input="startDateMenu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="6" v-if="editedIndex >= 0">
                  <v-text-field
                    v-model="editedClassRoom.classLink"
                    label="Classroom Link"
                    required
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeAddClassRoomDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="saveClassRoom()"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>

    <v-card flat>
      <v-data-table
        :headers="ClassRoomheaders"
        :items="classRooms"
        hide-default-header
        :hide-default-footer="classRooms.length < 10"
        item-key="classRoomId"
        item-value="classRoomId"
        @contextmenu:row="rightClickHandler"
      >
        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatDateTime(item.startDate) }}
        </template>
        <template v-slot:[`item.teacherName`]="{ item }">
          {{ item.teacherInfo.teacherName }}
        </template>
        <template v-slot:[`item.courseId`]="{ item }">
          <router-link
            style="text-decoration: none"
            class="text-h6"
            to="/home/courses"
            >{{ getCourseNameById(item.courseId) }}</router-link
          >
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-row>
            <v-col class="pa-0 ma-0">
              <v-chip
                small
                :color="item.status == 'SCHEDULED' ? 'success' : 'info'"
                label
              >
                {{ item.status }}
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pa-0 ma-0">
              <v-chip
                x-small
                :color="item.status == 'SCHEDULED' ? 'success' : 'info'"
                label
              >
                {{ "C : " + item.classes
                }}{{
                  item.extraClasses != 0 && item.extraClasses != null
                    ? " + " + item.extraClasses
                    : ""
                }}
              </v-chip>
              <v-chip
                class="mx-1"
                x-small
                :color="item.status == 'SCHEDULED' ? 'success' : 'info'"
                label
              >
                {{ "S : " + item.students.length }}
              </v-chip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :key="1"
                v-if="isActiveSession"
                @click="openAddClassRoomDialog(item)"
              >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="success"  class="mr-2">mdi-eye</v-icon>
                    </template>
                    <span>Classroom Details</span>
                  </v-tooltip>
                <v-list-item-title>{{ "Classroom Details" }}</v-list-item-title>
              </v-list-item>
              <!-- <v-tooltip bottom v-else>
            <template v-slot:activator="{ on }">
              <v-icon
                v-if="isActiveSession"
                v-on="on"
                class="mr-2"
                color="primary"
                @click="removeEnrolledTeacher(item)"
              >
                mdi-account-minus-outline
              </v-icon>
            </template>
            <span>Remove Teacher</span>
          </v-tooltip> -->
              <v-list-item
                :key="2"
                v-if="isActiveSession"
                @click="openEnrollDialog(item, 'Student')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="isActiveSession"
                      v-on="on"
                      class="mr-2"
                      color="primary"
                      @click="openEnrollDialog(item, 'Student')"
                    >
                      mdi-account-multiple-plus-outline
                    </v-icon>
                  </template>
                  <span>Enroll Student</span>
                </v-tooltip>
                <v-list-item-title>{{ " Enroll Student" }}</v-list-item-title>
              </v-list-item>
              <!-- <v-list-item
                :key="3"
                v-if="item.status == 'CREATED'"
                @click="openCreateClassSchedule(item)"
              >
                <v-tooltip v-if="item.status == 'CREATED'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="isActiveSession"
                      v-on="on"
                      class="mr-2"
                      color="primary"
                      @click="openCreateClassSchedule(item)"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <span>Create Schedule</span>
                </v-tooltip>
                <v-list-item-title>{{ "Create Schedule" }}</v-list-item-title>
              </v-list-item> -->
              <v-list-item
                :key="4"
                v-if="item.status == 'SCHEDULED'"
                @click="viewSchedule(item)"
              >
                <v-tooltip v-if="item.status == 'SCHEDULED'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="mr-2"
                      color="success"
                      @click="viewSchedule(item)"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span>View Schedule</span>
                </v-tooltip>
                <v-list-item-title>{{ "View Schedule" }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="5"
                v-if="isActiveSession"
                @click="openEnrollDialog(item, 'Teacher')"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-if="isActiveSession"
                      v-on="on"
                      class="mr-2"
                      color="primary"
                      @click="openEnrollDialog(item, 'Teacher')"
                    >
                      mdi-account-plus-outline
                    </v-icon>
                  </template>
                  <span>Change Teacher</span>
                </v-tooltip>
                <v-list-item-title>{{ " Change Teacher" }}</v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="6"
                v-if="item.status == 'SCHEDULED'"
                @click="openExtraClassDialog(item)"
              >
                <v-tooltip v-if="item.status == 'SCHEDULED'" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      class="mr-2"
                      color="primary"
                      @click="openExtraClassDialog(item)"
                    >
                      mdi-google-classroom
                    </v-icon>
                  </template>
                  <span>Extra Class</span>
                </v-tooltip>
                <v-list-item-title>{{ "Extra Class" }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-row align="end" justify="end">
          <v-btn
            color="primary"
            icon
            @click="openAddClassRoomDialog(undefined)"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="success">mdi-plus</v-icon>
              </template>
              <span>Add ClassRoom</span>
            </v-tooltip>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {
  BASE_URL,
  CLASSROOM_ASSIGN_STUDENTS,
  CLASSROOM_ASSIGN_TEACHER,
  CLASSROOM_CREATE_SCHEDULE,
  CLASSROOM_UN_ASSIGN_STUDENTS,
  CLASSROOM_UN_ASSIGN_TEACHER,
  CREATE_CLASSROOM,
  GET_ALL_CLASSROOMS,
  GET_ALL_COURSES,
  GET_ALL_INSTITUTES,
  SEARCH_STUDENT,
  SEARCH_TEACHER,
  UPDATE_CLASSROOM,
  EXTRA_CLASS,
} from "@/common/apiEndpoints";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import {
  getAxiosOptions,
  formatDateTime,
  rightClickHandler,
} from "@/common/utility";
import { GET_TOKEN } from "@/store/getters";
import {
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
  grades,
} from "@/common/constants";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      addClassRoomDialog: false,
      createScheduleDialog: false,
      enrollStdDialog: false,
      enrollTeacherDialog: false,
      startDateMenu: false,
      extraClassDialog: false,
      searchStudent: "",
      classRoomDialogTitle: "New Classroom",
      editedIndex: -1,
      classRooms: [],
      panel: [],
      deEnrollMode: false,
      selectedStudents: [],
      selectedInstitute: "",
      studentsList: [],
      studentFilter: {},
      institute: [],
      teacherFilter: {},
      enrolledStudentsId: [],
      gradesArray: grades,
      teachers: [],
      selectedTeacher: [],
      studentNames: [],
      classLink: "",
      teacherAssignmentHeaders: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "emailId" },
        { text: "Status", value: "status" },
      ],
      studentHeadersForEnroll: [
        {
          text: "First Name",
          align: "start",
          sortable: false,
          value: "firstName",
        },
        { text: "Last Name", value: "lastName" },
        { text: "Mobile No", value: "mobileNo" },
        { text: "Email ID", value: "email" },
        { text: "Status", value: "status" },
        { text: "Institute Name", value: "schoolName" },
        { text: "Grade", value: "grade" },
      ],
      ClassRoomheaders: [
        {
          text: "ClassRoom Name",
          align: "start",
          value: "name",
        },
        { text: "StartDate", value: "startDate" },
        {
          text: "Teacher",
          value: "teacherName",
        },
        { text: "CourseName", value: "courseId" },
        { text: "Status", value: "status", align: "center" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      editedClassRoom: {
        courseId: "",
      },
      defaultItem: {},
      courses: [],
      notBuyableCourses: [],
      createClassRoomValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      extraClassValidation: {
        valid: false,
        noOfClassesRules: [(v) => !!v || "This is required", 
        (v) => Number(1) <= v || "No of classes must be greater than 0",
        ],
      },
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
    };
  },
  props: ["batchNo", "batch", "instituteId", "sessionNo", "isActiveSession"],
  mounted: function () {
    this.getAllCourses();
  },
  watch: {
    batchNo: function () {
      this.getAllClassRoomByBatchNo();
    },
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  components: { ContextMenu },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    isURL(str) {
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    getAllClassRoomByBatchNo() {
      let url = GET_ALL_CLASSROOMS;
      this.setOverlayValue(true);
      let payload = {};
      payload.instituteId = this.instituteId;
      payload.sessionNo = this.sessionNo;
      payload.batchNo = this.batchNo;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.classRooms = res.data.classrooms;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    getAllCourses() {
      let url = GET_ALL_COURSES;
      this.setOverlayValue(true);
      url = url + "?status=" + this.STATUS_PUBLISHED;
      url = url + "&courseType=" + this.COURSE_TYPE_PRIVATE;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          this.notBuyableCourses = this.courses.filter(
            (course) => course.buyable == false || course.buyable == null
          );
          this.getAllClassRoomByBatchNo();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    openAddClassRoomDialog(classRoom) {
      this.addClassRoomDialog = true;
      this.studentNames = [];
      if (classRoom) {
        this.editedIndex = 0;
        this.editedClassRoom = Object.assign({}, classRoom);
        this.editedClassRoom.startDate =
          this.editedClassRoom.startDate.substring(0, 10);
        this.classRoomDialogTitle = "Classroom Details";
      }
    },
    closeAddClassRoomDialog() {
      this.addClassRoomDialog = false;
      this.selectedTeacher = [];
      this.selectedStudents = [];
      this.editedClassRoom = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.classRoomDialogTitle = "New Classroom";
    },
    openExtraClassDialog(item) {
      this.extraClassDialog = true;
      this.editedClassRoom.classRoomId = item.classRoomId;
    },
    closeExtraClassDialog() {
      this.extraClassDialog = false;
    },
    extraClass() {
      this.$refs.form.validate();
      if (this.extraClassValidation.valid) {
        const payload = {};
        payload.classRoomId = this.editedClassRoom.classRoomId;
        payload.noOfClasses = this.editedClassRoom.extraClass;
        let url = EXTRA_CLASS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.extraClassValidation.valid = false;
            this.closeExtraClassDialog();
            this.setApiSuccess("Extra Class created Successfully");
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.closeExtraClassDialog();
            this.setOverlayValue(false);
          });
      }
    },

    saveClassRoom() {
      if (this.createClassRoomValidation.valid) {
        const payload = {};
        let url = "";
        payload.name = this.editedClassRoom.name.trim();
        payload.courseId = this.editedClassRoom.courseId;
        if (this.editedIndex > -1) {
          payload.classRoomId = this.editedClassRoom.classRoomId;
          url = UPDATE_CLASSROOM;
        } else {
          payload.instituteId = this.instituteId;
          payload.batchNo = this.batchNo;
          payload.teacherId = this.editedClassRoom.teacherId;
          payload.students = this.editedClassRoom.students;
          let startDate = new Date(this.editedClassRoom.startDate);
          payload.startDate = startDate.toISOString();
          url = CREATE_CLASSROOM;
        }
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createClassRoomValidation.valid = false;
            this.editedClassRoom = res.data;
            this.setOverlayValue(false);
            if (this.editedIndex > -1) {
              this.updateClassRoomData(this.editedClassRoom);
              this.setApiSuccess("Classroom updated successfully");
            } else {
              this.setApiSuccess("Classroom created successfully");
              this.getAllClassRoomByBatchNo();
            }
            this.closeAddClassRoomDialog();
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
            this.closeAddClassRoomDialog();
          });
      }
    },
    closeEnrollDialog() {
      this.deEnrollMode = false;
      this.selectedStudents = [];
      this.studentsList = [];
      this.studentFilter = {};
      this.selectedInstitute = "";
      this.teacherFilter = {};
      this.selectedTeacher = [];
      this.enrollStdDialog = false;
      this.enrollTeacherDialog = false;
      this.teachers = [];
    },
    openEnrollTeacherDialog() {
      this.enrollTeacherDialog = true;
      this.panel = [0];
    },
    openEnrollStudentDialog() {
      this.enrollStdDialog = true;
      this.studentFilter.studentIds = [];
      this.getAllInstitutes();
      this.panel = [0];
    },
    selectTeacher() {
      this.editedClassRoom.teacherId = this.selectedTeacher[0].teacherId;
      this.editedClassRoom.teacherName =
        this.selectedTeacher[0].firstName +
        " " +
        this.selectedTeacher[0].lastName;
      this.enrollTeacherDialog = false;
      this.selectedTeacher = [];
    },
    selectStudents() {
      this.editedClassRoom.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      this.studentNames = [];
      this.selectedStudents.forEach((element) => {
        this.studentNames.push(element.firstName + " " + element.lastName);
      });
      this.enrollStdDialog = false;
    },
    openEnrollDialog(item, assigneeType) {
      if (assigneeType === "Student") {
        this.enrollStdDialog = true;
        this.studentFilter.studentIds = item.students;
        this.getAllInstitutes();
      } else if (assigneeType === "Teacher") {
        this.enrollTeacherDialog = true;
      }
      this.editedClassRoom.classRoomId = item.classRoomId;
      this.editedClassRoom.teacherId = item.teacherId;
      this.editedClassRoom.courseId = item.courseId;
      this.panel = [0];
    },
    enrollDeEnrollStudents(action) {
      let url = "";
      if (action == "Assign") url = CLASSROOM_ASSIGN_STUDENTS;
      else if (action == "UnAssign") {
        url = CLASSROOM_UN_ASSIGN_STUDENTS;
      }
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      payload.students = this.selectedStudents.map((std) => {
        return std.studentId;
      });
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassRoomData(res.data);
          if (action == "Assign")
            this.setApiSuccess("Students Assigned Succesfully");
          else if (action == "UnAssign") {
            this.setApiSuccess("Students Un-Assigned Succesfully");
          }
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        });
    },
    searchStudents() {
      const payload = {};
      let flag = false;
      if (
        this.deEnrollMode &&
        this.studentFilter.studentIds &&
        this.studentFilter.studentIds.length > 0
      ) {
        payload.studentIds = this.studentFilter.studentIds;
        this.panel = [];
        flag = true;
      }
      if (this.studentFilter.mobileNo) {
        payload.mobileNo = this.studentFilter.mobileNo.trim();
        flag = true;
      }
      if (this.studentFilter.email) {
        payload.email = this.studentFilter.email.trim();
        flag = true;
      }
      if (this.studentFilter.firstName) {
        payload.firstName = this.studentFilter.firstName.trim();
        flag = true;
      }
      if (this.studentFilter.lastName) {
        payload.lastName = this.studentFilter.lastName.trim();
        flag = true;
      }
      if (this.selectedInstitute) {
        payload.instituteId = this.selectedInstitute;
        flag = true;
      }
      if (this.studentFilter.grade) {
        payload.grade = this.studentFilter.grade;
        flag = true;
      }
      let url = SEARCH_STUDENT;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.studentsList = res.data.students;
            const self = this;
            self.disabledCount = 0;
            if (!this.deEnrollMode) {
              this.studentsList.map((item) => {
                if (item.status != "ACTIVE") {
                  self.disabledCount += 1;
                }
                if (
                  this.studentFilter.studentIds.includes(item.studentId) ||
                  item.enrolledCourses.includes(this.editedClassRoom.courseId)
                ) {
                  item.isEnrolled = true;
                  self.disabledCount += 1;
                } else {
                  item.isEnrolled = false;
                }
              });
            }
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
          });
      }
      if (!this.deEnrollMode) {
        this.panel = [0];
      }
    },
    getAllInstitutes() {
      let url = GET_ALL_INSTITUTES;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.institute = res.data.institutes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    searchTeachers() {
      const payload = {};
      let flag = false;
      if (this.teacherFilter.mobileNo) {
        payload.mobileNo = this.teacherFilter.mobileNo.trim();
        flag = true;
      }
      if (this.teacherFilter.email) {
        payload.emailId = this.teacherFilter.email.trim();
        flag = true;
      }
      if (this.teacherFilter.firstName) {
        payload.firstName = this.teacherFilter.firstName.trim();
        flag = true;
      }
      if (this.teacherFilter.lastName) {
        payload.lastName = this.teacherFilter.lastName.trim();
        flag = true;
      }
      let url = SEARCH_TEACHER;
      if (flag) {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.teachers = res.data.teachers;
            const self = this;
            self.disabledCount = 0;
            this.teachers.map((item) => {
              if (item.status != "ACTIVE") {
                self.disabledCount += 1;
              }
            });
            this.panel = [];
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      } else {
        this.panel = [];
      }
    },
    openCloseSearchPanel() {
      this.panel.length == 0 ? (this.panel = [0]) : (this.panel = []);
    },
    selectAllStudents(props) {
      if (
        this.selectedStudents.length !=
        this.studentsList.length - this.disabledCount
      ) {
        this.selectedStudents = [];
        const self = this;
        props.items.forEach((item) => {
          //  if(item.status=='ACTIVE') {
          if (item.status == "ACTIVE" && !item.isEnrolled) {
            self.selectedStudents.push(item);
          }
        });
      } else this.selectedStudents = [];
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
       if (c.length>0 && c[0]) return c[0].courseName;
      return "No Course";
    },
    enrollTeacher() {
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      let url = CLASSROOM_ASSIGN_TEACHER;
      payload.teacherId = this.selectedTeacher[0].teacherId;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateClassRoomData(res.data);
          this.closeEnrollDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Teacher Assigned Successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.closeEnrollDialog();
          this.setOverlayValue(false);
        });
    },
    changeEnrollMode() {
      this.selectedStudents = [];
      this.studentsList = [];
      this.searchStudents();
    },
    openCreateClassSchedule(item) {
      this.createScheduleDialog = true;
      this.editedClassRoom.classRoomId = item.classRoomId;
    },
    CloseCreateClassSchedule() {
      this.createScheduleDialog = false;
      this.editedClassRoom = Object.assign({}, this.defaultItem);
    },
    createClassSchedule() {
      let url = CLASSROOM_CREATE_SCHEDULE;
      let payload = {};
      payload.classRoomId = this.editedClassRoom.classRoomId;
      payload.noOfClasses = this.editedClassRoom.noOfClasses;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.CloseCreateClassSchedule();
          this.getAllClassRoomByBatchNo();
          this.setOverlayValue(false);
          this.setApiSuccess("Schedule created Successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.CloseCreateClassSchedule();
          this.setOverlayValue(false);
        });
    },
    viewSchedule(item) {
      this.$router.push({
        name: "ClassSchedule",
        params: {
          classRoomId: item.classRoomId,
          isActiveSession: this.isActiveSession,
          className: item.name,
        },
      });
    },
    updateClassRoomData(classRoom) {
      var foundIndex = this.classRooms.findIndex(
        (cls) => cls.classRoomId == classRoom.classRoomId
      );
      this.classRooms[foundIndex] = Object.assign(
        this.classRooms[foundIndex],
        classRoom
      );
    },
  },
};
</script>
