export function getAxiosOptions(baseUrl, token) {
  const options = {
    headers: {},
  };
  options.baseURL = baseUrl;
  if (typeof token == "object") {
    options.headers.Authorization = token.token;
  } else {
    options.headers.Authorization = token;
  }
  return options;
}
export function formatDate(date) {
  date = new Date(date);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en", options);
}
export function formatDateTime(date) {
  date = new Date(date);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return date.toLocaleString("en", options);
}
export function formatDateTimeWithDay(date) {
  date = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return date.toLocaleString("en", options);
}
export function formatTime(date) {
  date = new Date(date);
  const options = {
    hourCycle: "h23",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleTimeString("en", options);
}
export function formattedTime(date) {
  const [hours, minutes] = date.split(":");
  const time = new Date();
  time.setHours(hours);
  time.setMinutes(minutes);
  const ampm = time.getHours() >= 12 ? "PM" : "AM";
  let hours12 = time.getHours() % 12;
  hours12 = hours12 ? hours12 : 12;
  return `${hours12}:${minutes} ${ampm}`;
}

export function checkVisibility(currentRole) {
  const ROLES = ["ADMIN", "RESOURCE_MANAGER", "CONTENT_MANAGER", "TEACHER"];
}

export function formatDateNumeric(date) {
  var getYear = date.toLocaleString("default", { year: "numeric" });
  var getMonth = date.toLocaleString("default", { month: "2-digit" });
  var getDay = date.toLocaleString("default", { day: "2-digit" });
  return getYear + "-" + getMonth + "-" + getDay;
}
export function rightClickHandler(textToCopy,x,y){
var contextMenuObj={showMenu:true,xPosProp:x,yPosProp:y,copyText:textToCopy}
return contextMenuObj;
}
export function formatQuestionTitle(questionNo,questionTitle,totalQuestions){
  if(questionTitle.substring(0,3) == '<p>'){
    if(totalQuestions){
      return '<p>'+questionNo+'/'+totalQuestions+' . '+questionTitle.substring(3);
    }else{
    return '<p>'+questionNo+' . '+questionTitle.substring(3);
    }
  }else{
    if(totalQuestions){
      return questionNo+'/'+totalQuestions+' . '+questionTitle;
    }else{
    return questionNo+' . '+questionTitle;
    }
  }
}