<template>
  <v-container v-if="registrationCompleted">
    <v-card class="mx-auto px-6 py-8" max-width="600">
      <v-img max-height="150" src="../assets/APT_LogoR.png"></v-img>
      <v-card-title class="font-weight-green">Congratulations , you have successfully registered.</v-card-title>
      <a><span class="text-subtitle-2 ma-3" color="primary" @click="backToLogin()">Back to login</span></a>
    </v-card>
  </v-container>
  <v-container v-else>
    <v-row justify="center" class="ma-0 mx-0 pa-0 mt-n3">
      <v-card class="ma-2 mt-1" width="90%" style="border-radius: 10px">
        <v-toolbar dense dark color="#006A80" enlevation="2" class="mb-1">
          <v-toolbar-title style="font-weight: 500; font-size: 24px">Your contact details.
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-col cols="3" class="mt-3">
            <v-select :items="userTypes" item-text="key" item-value="value"></v-select>
          </v-col> -->
        </v-toolbar>
        <v-img max-height="10%" max-width="50%" src="../assets/APT_LogoR.png" class="mx-auto"></v-img>
        <v-card-title class="mt-n4">Parents/Guardian Details</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="studentDetailsValidation.valid">
            <v-row class="ma-2 mt-n5">
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <v-text-field v-model="studentDetails.emailId" label="Email" auto-grow outlined rows="2" dense
                  class="mt-n2" hide-details="auto" :rules="studentDetailsValidation.emailRules" required>
                </v-text-field>
                <v-text-field v-model="studentDetails.firstName" label="First Name" auto-grow outlined rows="1" dense
                  class="mt-2" hide-details="auto" row-height="5" :rules="studentDetailsValidation.nameRules"
                  required></v-text-field>
                <v-text-field v-model="studentDetails.lastName" label="Last Name" outlined dense hide-details="auto"
                  class="mt-2" :rules="studentDetailsValidation.nameRules" required></v-text-field>
                <v-text-field v-model="studentDetails.street" label="Street" :rules="studentDetailsValidation.streetRules"
                  hide-details="auto" class="mt-2" dense outlined required></v-text-field>
                <v-text-field v-model="studentDetails.suburb" label="Suburb" :rules="studentDetailsValidation.suburbRules"
                  hide-details="auto" class="mt-2" dense outlined required></v-text-field>
                <v-text-field v-model="studentDetails.country" label="Country"
                  :rules="studentDetailsValidation.countryRules" hide-details="auto" class="mt-2" dense outlined
                  required></v-text-field>
                <v-text-field v-model="studentDetails.postcode" label="Post Code" outlined dense class="mt-2"
                  hide-details="auto" :rules="studentDetailsValidation.postRules" required></v-text-field>
                <v-select v-model="studentDetails.referalCode" :rules="studentDetailsValidation.referalRules"
                  :items="referralArray" hide-details="auto" label="Referral Source" outlined dense class="mt-2" required>
                </v-select>
                <v-card-title class="ml-n8 mt-n4 black--text">Student Details</v-card-title>
                <v-text-field v-model="studentDetails.studentFirstName" label="Student First Name" outlined dense
                  hide-details="auto" class="mt-n4" :rules="studentDetailsValidation.nameRules" required></v-text-field>
                <v-text-field v-model="studentDetails.studentLastName" label="Student Last Name" outlined dense
                  hide-details="auto" class="mt-2" :rules="studentDetailsValidation.nameRules" required></v-text-field>
                <v-select v-model="studentDetails.schoolYear" :items="yearArray" label="Year At school" outlined dense
                  hide-details="auto" class="mt-2" :rules="studentDetailsValidation.schoolRules">
                </v-select>
                <v-select v-model="studentDetails.gender" :items="genderArray" label="Gender Identity" outlined dense
                  hide-details="auto" class="mt-2" :rules="studentDetailsValidation.genderRules">
                </v-select>
              </v-col>
              <v-col xs="12" sm="12" md="12" lg="6" xl="6">
                <!-- <v-img max-height="25%" max-width="100%" class="ma-2 mt-n8  mr-n16" src="../assets/APT_LogoR.png"></v-img> -->
                <v-text-field v-model="studentDetails.mobileNo" label="Mobile*" auto-grow outlined rows="1"
                  hide-details="auto" :rules="studentDetailsValidation.mobileRules" row-height="10" dense
                  required></v-text-field>
                <v-checkbox v-model="isCheckboxChecked" class=" ma-2"
                  label="I agree to being contacted by APTCODER & its associated companies/partners from time to time via email/call/whatsapp about services or offers which may be relevant and of interest to me.">
                </v-checkbox>
                <v-btn color="primary" @click="studentRegistration()" class="ma-1"
                  :disabled="!studentDetailsValidation.valid || !isCheckboxChecked
                    " style="font-size: 16px">
                  CONFIRM AND BOOK NOW
                </v-btn>
                <p class=" mt-8" style="font-weight: 400; font-size: 17px; color: #006A80;">
                  APTCODER Colchester<br>
                  Address : 97 Crouch Street, Colchester, CO3 3HA<br>
                  Contact Number : 07789257929<br>
                  email : contact.colchester.uk@aptcoder.com<br>
                  portal.aptcoder.com/#/uk/colchester<br>
                </p>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { BASE_URL, REGISTRATION_URL } from "@/common/apiEndpoints";
import { getAxiosOptions } from "@/common/utility";
import axios from "axios";
import { GET_TOKEN } from "@/store/getters";


export default {
  name: "UkRegistration",
  data() {
    return {
      studentDetails: [],
      isCheckboxChecked: false,
      yearArray: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      // userTypes: [
      //   { key: "Select", value: "" },
      //   { key: "Mumbai", value: "MUMBAI" },
      //   { key: "Baanglore", value: "BANGLORE" },
      //   { key: "Delhi", value: "Delhi" },
      //   { key: "Noida", value: "NOIDA" },

      // ],
      genderArray: ["Male", "Female", "Non-binary", "Prefer not to answer", "Other"],
      referralArray: ["Newspaper",
        "TV Advertisement",
        "Facebook",
        "Internet Search",
        "Aptcoder Website",
        "Word of Mouth",
        "Radio",
        "Flyer/Brochure/Poster",
        "Centre Signage",
        "Business Listing",
        "Local Advertising",
        "Past Student",
        "Sibling Referal",
        "Other"],
      studentDetailsValidation: {
        valid: false,
        nameRules: [
          (v) => !!v || "Name is required",
          (v) =>
            /^[A-Za-z\s]+$/.test(v) ||
            "Please enter alphabetic characters only.",
        ],
        countryRules: [(v) => !!v || "Country Name is required"],
        mobileRules: [(v) => !!v || "Mobile Number is required"],
        referalRules: [(v) => !!v || "Referral source is required"],
        suburbRules: [(v) => !!v || "Suburb Name is required"],
        streetRules: [(v) => !!v || "Street Name is required"],
        schoolRules: [(v) => !!v || "School Year is required"],
        genderRules: [(v) => !!v || "Gender is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        postRules: [(v) => !!v || "Post Code is required"],
      },
      registrationCompleted: false,
    };
  },
  mounted: function () { },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    validate() {
      this.$refs.form.validate();
    },
    updateButtonState() {
      return this.isCheckboxChecked;
    },

    studentRegistration() {
      this.validate();
      if (this.studentDetailsValidation.valid) {
        const payload = {};
        payload.firstName = this.studentDetails.firstName;
        payload.lastName = this.studentDetails.lastName;
        payload.emailId = this.studentDetails.emailId;
        payload.street = this.studentDetails.street;
        payload.suburb = this.studentDetails.suburb;
        payload.country = this.studentDetails.country;
        payload.postcode = this.studentDetails.postcode;
        payload.studentFirstName = this.studentDetails.studentFirstName;
        payload.studentLastName = this.studentDetails.studentLastName;
        payload.schoolYear = this.studentDetails.schoolYear;
        payload.gender = this.studentDetails.gender;
        payload.mobileNo = this.studentDetails.mobileNo;
        payload.referalCode = this.studentDetails.referalCode;

        let url = REGISTRATION_URL;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL))
          .then((res) => {
            this.studentDetailsValidation.valid = false;
            this.registrationCompleted = true;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },

    backToLogin() {
      this.$router.push({ path: "/login" });
    },
  },
};
</script>
