<template>
  <v-card class="ma-2" style="border-radius: 10px">
    <v-toolbar dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px">Coupons</v-toolbar-title>
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4">
        <v-text-field v-if="showSearchField" v-model="searchText" @input="searchCoupon" clearable></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="showSearchField = !showSearchField">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-dialog persistent v-model="dialog" max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 16px">
            New Coupon
          </v-btn>
        </template>

        <v-form ref="form" v-model="createCouponValidation.valid">
          <v-card>
            <v-container>
              <v-row>
                <v-card-title class="primary--text">{{
                  couponDialogTitle
                }}</v-card-title></v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="editedCoupon.code" :disabled="editedIndex != -1" label="Coupon name"
                    :rules="createCouponValidation.nameRules" required @keyup="uppercase"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="editedCoupon.maxDiscount" label="Maximum Discount"
                    :rules="createCouponValidation.nameRules" required type="number"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="editedCoupon.discountPercentage" label="Discount Percentage"
                    :rules="createCouponValidation.nameRules" required type="number"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editedCoupon.validTill" label="Valid Till"
                        :rules="createCouponValidation.nameRules" prepend-icon="mdi-clock-time-four-outline" readonly
                        v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editedCoupon.validTill" full-width
                      :min="new Date().toISOString().substr(0, 10)" @input="dateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close()"> Cancel </v-btn>
              <v-btn color="primary" text @click="saveCoupon()"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog persistent v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title>Are you sure you want to delete
            {{ editedCoupon.code }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDeleteDailog()">Cancel</v-btn>
            <v-btn color="primary" text @click="deleteCoupon()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="enableDisableConfirmDialog" max-width="500px">
        <v-card>
          <v-card-title>
            {{ enableDisableConfirmDialogTitle }}
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeEnableDisableCouponDailog()">Cancel</v-btn>
            <v-btn color="primary" text @click="enableDisableCoupon()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-container fluid>
      <v-data-table :headers="headers" :items="filteredCoupons" @contextmenu:row="rightClickHandler">
        <template v-slot:[`item.validTill`]="{ item }">
          {{ formatDateTime(item.validTill) }}
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-chip small :color="item.isActive == true ? 'success' : 'error'" label background>
            {{ item.isActive == true ? "ACTIVE" : "BLOCKED" }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row justify="center">
            <v-tooltip bottom v-if="item.isActive">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-2" @click="editCoupon(item)" color="primary">
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip bottom v-if="item.isActive">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-2" @click="enableDisableCouponConfirmDialog(item, 'disable')" color="error">
                  mdi-cancel
                </v-icon>
              </template>
              <span>Disable</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.isActive">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" class="mr-2" @click="enableDisableCouponConfirmDialog(item, 'enable')" color="error">
                  mdi-cancel
                </v-icon>
              </template>
              <span>Enable</span>
            </v-tooltip>
            <!-- <v-tooltip bottom  v-if="item.isActive">
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              class="mr-2"
              color="primary"
              @click="deleteCouponConfirmDialog(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip> -->
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import {
  BASE_URL,
  CREATE_COUPON,
  DELETE_COUPON,
  EDIT_COUPON,
  GET_ALL_COUPONS,
} from "@/common/apiEndpoints";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import { getAxiosOptions, formatDateTime, rightClickHandler } from "@/common/utility";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      dialog: false,
      dateMenu: false,
      deleteDialog: false,
      enableDisableConfirmDialogTitle: "",
      actionType: "",
      enableDisableConfirmDialog: false,
      search: "",
      showSearchField: false,
      searchText: "",
      editedIndex: -1,
      filteredCoupons: [],
      couponDialogTitle: "New Coupon",
      headers: [
        {
          text: "Coupon Code",
          align: "start",
          value: "code",
        },
        {
          text: "Discount(%)",
          align: "center",
          value: "discountPercentage",
        },
        {
          text: "Max discount",
          align: "center",
          value: "maxDiscount",
        },
        {
          text: "Expire on",
          value: "validTill",
        },
        {
          text: "Created on",
          value: "createdOn",
          sortable: true,
        },
        {
          text: "Status",
          align: "center",
          value: "isActive",
        },
        {
          text: "Actions",
          sortable: false,
          value: "actions",
        },
      ],
      editedCoupon: {},
      defaultItem: {
        code: "",
        discountPercentage: 0,
      },
      createCouponValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      coupons: [],
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    deleteDialog(val) {
      val || this.closeDeleteDailog();
    },
  },
  created: function () {
    this.getAllCoupons();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    uppercase() {
      this.editedCoupon.code = this.editedCoupon.code.toUpperCase();
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },

    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(e.target.textContent, e.clientX, e.clientY)
    },
    getAllCoupons() {
      this.menu = false;
      let url = GET_ALL_COUPONS;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.coupons = res.data.coupons;
          this.filteredCoupons = this.coupons;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    editCoupon(item) {
      this.couponDialogTitle = "Edit Coupon";
      this.dialog = true;
      this.editedIndex = this.coupons.indexOf(item);
      this.editedCoupon = Object.assign({}, item);
      this.editedCoupon.validTill = this.editedCoupon.validTill.substring(
        0,
        10
      );
    },
    enableDisableCouponConfirmDialog(item, actionType) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedCoupon = Object.assign({}, item);
      this.enableDisableConfirmDialog = true;
      this.actionType = actionType;
      if (actionType == "disable")
        this.enableDisableConfirmDialogTitle =
          "Are you Sure , You want to disable this coupon?";
      if (actionType == "enable")
        this.enableDisableConfirmDialogTitle =
          "Are you Sure , You want to enable this coupon?";
    },
    enableDisableCoupon() {
      const url = EDIT_COUPON;
      let payload = {};
      payload = this.editedCoupon;
      if (this.actionType == "disable") payload.isActive = false;
      if (this.actionType == "enable") payload.isActive = true;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.updateCoupon(res.data);
          this.setOverlayValue(false);
          this.closeEnableDisableCouponDailog();
          if (this.actionType == "disable")
            this.setApiSuccess("Coupon disabled successfully");
          if (this.actionType == "enable")
            this.setApiSuccess("Coupon enabled successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    closeEnableDisableCouponDailog() {
      this.editedCoupon = {};
      this.editedIndex = -1;
      this.actionType = "";
      this.enableDisableConfirmDialog = false;
    },
    deleteCouponConfirmDialog(item) {
      this.editedIndex = this.coupons.indexOf(item);
      this.editedCoupon = Object.assign({}, item);
      this.deleteDialog = true;
    },
    closeDeleteDailog() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.editedCoupon = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteCoupon() {
      const url = DELETE_COUPON;
      let payload = {};
      payload.code = this.editedCoupon.code;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.getAllCoupons();
          this.closeDeleteDailog();
          this.setOverlayValue(false);
          this.setApiSuccess("Coupon deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedCoupon = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    validate() {
      this.$refs.form.validate();
    },
    searchCoupon() {
      if (this.searchText) {
        this.filteredCoupons = this.coupons.filter((coupon) => {
          return coupon.code
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredCoupons = this.coupons;
      }
    },
    saveCoupon() {
      this.validate();
      if (this.createCouponValidation.valid) {
        const payload = {};
        payload.code = this.editedCoupon.code.trim();
        payload.discountPercentage = this.editedCoupon.discountPercentage;
        payload.maxDiscount = this.editedCoupon.maxDiscount;
        payload.validTill = this.editedCoupon.validTill;
        let url = "";
        if (this.editedIndex > -1) {
          url += EDIT_COUPON;
        } else {
          url += CREATE_COUPON;
        }
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCouponValidation.valid = false;
            this.close();
            if (this.editedIndex > -1) {
              this.setApiSuccess("Coupon updated successfully");
              this.updateCoupon(res.data);
            } else {
              this.setApiSuccess("Coupon created successfully");
              this.coupons.unshift(res.data);
            }
            this.filteredCoupons = this.coupons;
            this.editedIndex = -1;
            // this.getAllCoupons();
            this.setOverlayValue(false);
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
    updateCoupon(updatedCoupon) {
      var foundIndex = this.coupons.findIndex(
        (cp) => cp.code == updatedCoupon.code
      );
      Object.assign(this.coupons[foundIndex], updatedCoupon);
    },
  },
  components: { ContextMenu }
};
</script>
