var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._b({},'div',_vm.$props,false),[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"ma-1 pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500","font-size":"20px"}},[_vm._v("Question: ")])]),_c('v-col',{staticClass:"ma-1 pa-0"},[_c('span',{staticStyle:{"font-size":"20px"},domProps:{"innerHTML":_vm._s(_vm.assignmentProblem.question)}})])],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"ma-1 pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500","font-size":"20px"}},[_vm._v("Answer: ")])]),(
          _vm.assignmentProblem.submissions &&
          _vm.assignmentProblem.submissions.length == 0
        )?_c('v-col',{staticClass:"ma-1 pa-0"},[_c('span',[_c('quill-editor',{staticStyle:{"height":"140px","margin-bottom":"40px"},attrs:{"options":_vm.editorOptions},on:{"input":function($event){return _vm.validateTextAssignemnt()}},model:{value:(_vm.textAssignmentSubmission),callback:function ($$v) {_vm.textAssignmentSubmission=$$v},expression:"textAssignmentSubmission"}})],1),_c('span',{staticClass:"red--text"},[_vm._v(_vm._s(_vm.textAssignmentError))])]):_c('v-col',{staticClass:"ma-1 pa-0"},[_c('span',{staticStyle:{"font-size":"20px"},domProps:{"innerHTML":_vm._s(_vm.assignmentProblem.answer)}})])],1)],1),(
      _vm.assignmentProblem.submissions &&
      _vm.assignmentProblem.submissions.length != 0
    )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"ma-1 pa-0",attrs:{"cols":"2"}},[_c('span',{staticClass:"error--text",staticStyle:{"font-weight":"500","font-size":"20px"}},[_vm._v("Submission: ")])]),_c('v-col',{staticClass:"ma-1 pa-0"},[_c('span',{staticStyle:{"font-size":"20px"},domProps:{"innerHTML":_vm._s(_vm.assignmentProblem.submissions[0].submission)}})])],1)],1):_vm._e(),_c('v-col',[(
        _vm.assignmentProblem.submissions && _vm.assignmentProblem.submissions == 0
      )?_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.submitTextAssignment()}}},[_vm._v("Submit")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }