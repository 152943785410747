<template>
  <v-navigation-drawer app clipped permanent>
    <v-card class="ma-2 pb-1" style="border-radius: 10px">
      <v-img heigth="10" src="../assets/APT_LogoR.png"></v-img>
      <v-list-item class="ml-n4 mb-3">
        <v-list-item-avatar v-if="myProfile.profilePhoto">
          <img :src="myProfile.profilePhoto" />
        </v-list-item-avatar>
        <v-list-item-avatar v-else-if="myProfile.userType == USER_TYPE_STUDENT && !myProfile.profilePhoto
          ">
          <img v-if="myProfile.student && myProfile.student.gender == 'MALE'" src="../assets/Male Avatar.png"
            alt="John" />
          <img v-else src="../assets/Female Avatar.png" alt="Jill" />
        </v-list-item-avatar>
        <v-list-item-avatar v-else-if="myProfile.userType == USER_TYPE_TEACHER && !myProfile.profilePhoto
          ">
          <img v-if="myProfile.teacher && myProfile.teacher.gender == 'MALE'" src="../assets/Male Avatar.png"
            alt="John" />
          <img v-else src="../assets/Female Avatar.png" alt="Jill" />
          <!-- <img v-if="myProfile.student && myProfile.student.gender == 'MALE'" src="https://www.w3schools.com/howto/img_avatar.png" alt="John">
                <img v-else src="https://www.w3schools.com/howto/img_avatar2.png" alt="Jill">
            </v-list-item-avatar>
            <v-list-item-avatar v-else-if="myProfile.userType == USER_TYPE_TEACHER && !myProfile.profilePhoto">
                <img v-if="myProfile.teacher && myProfile.teacher.gender == 'MALE'" src="https://www.w3schools.com/howto/img_avatar.png" alt="John">
                <img v-else src="https://www.w3schools.com/howto/img_avatar2.png" alt="Jill"> -->
        </v-list-item-avatar>
        <v-list-item-avatar v-else>
          <img src="../assets/Male Avatar.png" alt="John" />
        </v-list-item-avatar>
        <!-- <v-list-item-avatar
          v-else-if="
            myProfile.userType == USER_TYPE_CONTENT_MANAGER &&
            !myProfile.profilePhoto
          "
        >
          <img src="../assets/Male Avatar.png" alt="John" />
        </v-list-item-avatar>
        <v-list-item-avatar
          v-else-if="
            myProfile.userType == USER_TYPE_RESOURCE_MANAGER &&
            !myProfile.profilePhoto
          "
        >
          <img src="../assets/Male Avatar.png" alt="John" />
        </v-list-item-avatar>
        <v-list-item-avatar
          v-else-if="
            myProfile.userType == USER_TYPE_INSTITUTE && !myProfile.profilePhoto
          "
        >
          <img src="../assets/Male Avatar.png" alt="John" />
        </v-list-item-avatar> -->
        <v-chip v-if="myProfile.firstName" class="ml-n2 pt-2 pb-2 text-wrap" font-weight="bold" label
          :disabled="!disableSideBar" style="
            background-color: #fff1d6;
            font-size: 16px;
            height: max-content;
            width: 80%;
          " router to="/home/profile">
          {{
            myProfile.userType == USER_TYPE_STUDENT && !disableSideBar
            ? "--"
            : myProfile.firstName + " " + myProfile.lastName
          }}
        </v-chip>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="myProfile.userType" style="
          background: #f5fcff;
          border-radius: 20px;
          border-top: 7px solid #8ac7e5;
          border-bottom: 7px solid #8ac7e5;
          margin: 5%;
        " class="overflow-y-auto">
        <v-list-item-group v-if="myProfile.userType == USER_TYPE_ADMIN">
          <v-list-item color="primary" v-for="item in adminOptions" :key="item.title" link router :to="item.path">
            <v-list-item-avatar tile>
              <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 18px">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_CONTENT_MANAGER">
          <v-list-item color="primary" v-for="item in cmOptions" :key="item.title" link router :to="item.path">
            <v-list-item-avatar tile>
              <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 18px">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_RESOURCE_MANAGER">
          <v-list-item color="primary" v-for="item in rmOptions" :key="item.title" link router :to="item.path">
            <v-list-item-avatar tile>
              <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 18px">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_SALES_MANAGER">
          <v-list-item color="primary" v-for="item in smOptions" :key="item.title" link router :to="item.path">
            <v-list-item-avatar tile>
              <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 18px">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
        <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_STUDENT">
          <v-list-item color="primary" v-for="item in stOptions" :key="item.title" link router :to="item.path"
            style="padding: 0 10px" :disabled="!disableSideBar">
            <v-list-item-avatar tile>
              <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 18px">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group color="primary">
            <template v-slot:activator>
              <v-list-item-avatar tile>
                <v-img max-height="30" max-width="30" src="../assets/Icons/online-course (1) 1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-title style="font-size: 18px">Practice</v-list-item-title>
            </template>
            <v-list-item color="primary" @click="openBlocklyCoding" :disabled="!disableSideBar">
              <v-list-item-avatar tile>
                <v-img max-height="30" max-width="30" src="../assets/Icons/blocks 1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px">Blockly Coding</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item
              color="primary"
              to="notice-board"
              link
              router
              :disabled="!disableSideBar"
            >
              <v-list-item-avatar tile>
                <v-img
                  max-height="30"
                  max-width="30"
                  src="../assets/Icons/blocks 1.svg"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px"
                  >Notice Board</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item color="primary" v-for="item in pcOptions" :key="item.title" link router :to="item.path"
              :disabled="!disableSideBar" @click="resetParams()">
              <v-list-item-avatar tile>
                <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
        <v-list-item-group v-else-if="myProfile.userType == USER_TYPE_TEACHER">
          <v-list-item color="primary" v-for="item in tcOptions" :key="item.title" link router :to="item.path"
            :disabled="!disableSideBar">
            <v-list-item-avatar tile>
              <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title style="font-size: 18px">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group color="primary">
            <template v-slot:activator>
              <v-list-item-avatar tile>
                <v-img max-height="30" max-width="30" src="../assets/Icons/online-course (1) 1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-title style="font-size: 18px">Practice</v-list-item-title>
            </template>
            <v-list-item color="primary" @click="openBlocklyCoding" :disabled="!disableSideBar">
              <v-list-item-avatar tile>
                <v-img max-height="30" max-width="30" src="../assets/Icons/blocks 1.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px">Blockly Coding</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item color="primary" v-for="item in pcOptions" :key="item.title" link router :to="item.path"
              :disabled="!disableSideBar" @click="resetParams()">
              <v-list-item-avatar tile>
                <v-img max-height="30" max-width="30" :src="getIcons(item.title)"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title style="font-size: 18px">{{
                  item.title
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { GET_USER_PROFILE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { SET_PARAMS_VALUE } from "@/store/mutations";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_INSTITUTE,
  USER_TYPE_SALES_MANAGER,
} from "@/common/constants";
import { BLOCKLY_URL } from "@/common/apiEndpoints";

export default {
  data: () => ({
    drawer: true,
    myProfile: {},
    disableSideBar: false,
    adminOptions: [
      { title: "Dashboard", path: "/home/dashboard" },
      { title: "Users", path: "/home/users" },
      { title: "Institutes", path: "/home/institutes" },
      { title: "Courses", path: "/home/courses" },
      { title: "Quiz", path: "/home/quiz" },
      { title: "Competitions", path: "/home/competitions" },
      { title: "Coupons", path: "/home/coupons" },
      // { title: "Organization", path: "/home/organization" },
      // { title: "Plans", path: "/home/plans" },
      // { title: "Assignments", path: "/home/assignments" },

      // { title: "Blockly", path: "/home/blockly" },
    ],
    cmOptions: [
      { title: "Dashboard", path: "/home/dashboard" },
      {
        title: "Courses",
        path: "/home/courses",
      },
      { title: "Quiz", path: "/home/quiz" },
      {
        title: "Competitions",
        path: "/home/competitions",
      },
    ],
    rmOptions: [
      { title: "Dashboard", icon: "mdi-account", path: "/home/dashboard" },
      {
        title: "Courses",
        path: "/home/courses",
      },
      { title: "Quiz", path: "/home/quiz" },
      {
        title: "Competitions",
        path: "/home/competitions",
      },
      {
        title: "Resources",
        path: "/home/resources",
      },
    ],
    stOptions: [
      { title: "Dashboard", icon: "mdi-account", path: "/home/dashboard" },
      { title: "My Courses", path: "/home/student-courses" },
      { title: "My Classes", path: "/home/student-classes" },
      { title: "My Orders", path: "/home/student-orders" },
      { title: "Competitions", path: "/home/student-competition" },
    ],
    pcOptions: [
      // { title: 'Blockly Coding', icon: 'mdi-code-array', path: '/home/blockly-coding' },
      { title: "Syntax Coding", path: "/home/practice-coding" },
    ],
    tcOptions: [
      { title: "Dashboard", path: "/home/dashboard" },
      {
        title: "My Courses",
        path: "/home/teacher-courses",
      },
      {
        title: "My Classes",
        path: "/home/teacher-classes",
      },
      {
        title: "My Calendar",
        path: "/home/teacher-calendar",
      },
      { title: "Quiz", path: "/home/quiz" },
    ],
    smOptions: [{ title: "Registrations", path: "/home/uk/registrations" }],
    mini: true,
    USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
    USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
    USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    USER_TYPE_TEACHER: USER_TYPE_TEACHER,
    USER_TYPE_SALES_MANAGER: USER_TYPE_SALES_MANAGER,
    USER_TYPE_INSTITUTE: USER_TYPE_INSTITUTE,
  }),
  computed: {
    ...mapGetters({
      profile: `schoolModule/${GET_USER_PROFILE}`,
    }),
  },
  watch: {
    profile: {
      handler(profile) {
        if (profile) {
          this.myProfile = Object.assign({}, this.profile);
          this.disableSideBar = this.myProfile.registrationCompleted;
        }
      },
    },
    immediate: true,
  },
  mounted: function () {
    if (this.profile) {
      this.myProfile = Object.assign({}, this.profile);
      this.disableSideBar = this.myProfile.registrationCompleted;
    }
    // this.$forceUpdate();
  },
  methods: {
    ...mapMutations({
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    openBlocklyCoding() {
      window.open(BLOCKLY_URL, "_self");
    },
    getIcons(title) {
      if (title == "Dashboard")
        return require("../assets/Icons/business-report 1.svg");
      else if (title == "Users")
        return require("../assets/Icons/teamwork 1.svg");
      else if (title == "Institutes")
        return require("../assets/Icons/smart 1.svg");
      else if (title == "Courses")
        return require("../assets/Icons/online-education 1.svg");
      else if (title == "Quiz") return require("../assets/Icons/quiz 1.svg");
      else if (title == "Competitions")
        return require("../assets/Icons/trophy 1.svg");
      else if (title == "Coupons") return require("../assets/Icons/coupon.png");
      else if (title == "Blockly")
        return require("../assets/Icons/blocks 1.svg");
      else if (title == "Resources")
        return require("../assets/Icons/resources_01.png");
      else if (title == "My Courses")
        return require("../assets/Icons/online-course 1.svg");
      else if (title == "My Classes")
        return require("../assets/Icons/webinar 1.svg");
      else if (title == "My Orders")
        return require("../assets/Icons/Orders.png");
      else if (title == "My Calendar")
        return require("../assets/Icons/calendar.png");
      else if (title == "Practice Coding")
        return require("../assets/Icons/blocks 1.svg");
      else if (title == "Blockly Coding")
        return require("../assets/Icons/blocks 1.svg");
      else if (title == "Syntax Coding")
        return require("../assets/Icons/code.png");
      else if (title == "Organization")
        return require("../assets/Icons/organisation 1.svg");
      else if (title == "Plans") return require("../assets/Icons/plans 1.svg");
      else if (title == "Assignments")
        return require("../assets/Icons/plans 1.svg");
      else return require("../assets/Icons/teamwork 1.svg");
    },
    resetParams() {
      var codeParams = {};
      this.setParams(codeParams);
    },
  },
};
</script>
