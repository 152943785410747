<template>
  <vue-friendly-iframe class="iframe" :src="src"></vue-friendly-iframe>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>