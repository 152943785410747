<template>
  <v-card>
    <vue-friendly-iframe
      class="iframe"
      :src="src"
      allow="clipboard-read; clipboard-write"
    ></vue-friendly-iframe>
  </v-card>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>