<template>
  <v-card class="ma-2" min-height="650px" style="border-radius: 10px">
    <v-toolbar dark color="primary" enlevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Dashboard</v-toolbar-title
      >
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-container class="ma-0" fluid v-if="userType == USER_TYPE_STUDENT">
      <v-row class="ma-1">
        <v-col cols="12">
          <v-card class="mt-3" outlined>
            <v-card height="60" color="#525866">
              <v-card-text
                style="font-size: 20px; font-weight: 500; color: white"
                ><v-row justify="center">
                  <v-col cols="2">Recent Classes</v-col>
                </v-row></v-card-text
              >
            </v-card>
            <v-tabs
              v-if="classes.length > 0"
              centered
              grow
              v-model="tab"
              class="mt-2"
              center-active
              show-arrows
              background-color="#e7fce9"
              color="success"
            >
              <v-tab v-for="(clss, index) in newClasses" :key="index">{{
                clss.date
              }}</v-tab>
            </v-tabs>
            <v-card v-else
              ><v-card-text style="font-size: 18px; color: black"
                ><v-row justify="center">
                  <v-col cols="3">You have no classes yet!</v-col>
                </v-row></v-card-text
              ></v-card
            >
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(clss, index) in newClasses" :key="index">
                <v-data-table
                  :headers="classesHeaders"
                  :items="clss.classes"
                  class="mt-2"
                  hide-default-footer
                  item-key="classOpId"
                  item-value="classOpId"
                >
                  <template v-slot:[`item.courseId`]="{ item }"
                    >{{ getCourseNameById(item.courseId) }}
                  </template>
                  <template v-slot:[`item.time`]="{ item }">
                    {{ formattedTime(item.openTime) }} to
                    {{ formattedTime(item.closeTime) }}
                  </template>
                  <template v-slot:[`item.status`]="{ item }">
                    <v-row justify="center">
                      <v-chip
                        small
                        :color="getClassStatusColor(item)"
                        text-color="white"
                        label
                        background
                      >
                        {{ item.status }}
                      </v-chip>
                      <v-icon class="ml-1" v-if="item.isExtra == true" medium
                        >mdi-alpha-e-circle-outline</v-icon
                      >
                    </v-row>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip
                      bottom
                      v-if="item.status == CLASS_STATUS_STARTED"
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          class="mr-2"
                          color="primary"
                          size="20"
                          @click="joinClass(item)"
                        >
                          mdi-account-plus-outline
                        </v-icon>
                      </template>
                      <span>Join Class</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="ma-0" fluid v-else>
      <v-row class="ma-1">
        <v-col cols="4" v-for="card in cards" :key="card.title">
          <v-card
            min-height="230px"
            width="100%"
            class="ma-1 mt-3"
            v-if="card.entries !== null"
            outlined
          >
            <v-card height="60" :color="getColor(card.title)" class="mb-3">
              <v-row>
                <v-col cols="8">
                  <v-btn
                    dark
                    text
                    @click="goTo(card)"
                    style="font-weight: 600; font-size: 18px"
                    class="ma-1"
                    >{{ card.title }}</v-btn
                  >
                </v-col>
                <v-col cols="4">
                  <v-btn dark text style="font-size: 18px" class="ma-1 pa-0">{{
                    card.total
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-card>
            <div v-for="entry in card.entries" :key="entry.title">
              <v-container>
                <v-row>
                  <v-col cols="9" class="pa-0 ma-1">
                    <v-icon class="ma-1" size="6" style="color: black">
                      mdi-circle
                    </v-icon>
                    {{ entry.title }}
                  </v-col>
                  <v-col
                    cols="2"
                    class="pa-0 ma-1 ml-2"
                    style="font-weight: 600"
                  >
                    <span v-bind:style="{ color: getColor(card.title) }">
                      {{ entry.count }}
                    </span>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {
  BASE_URL,
  GET_USER_DASHBOARD,
  GET_STUDENT_DASHBOARD,
  GET_STUDENT_COURSES,
} from "@/common/apiEndpoints";
import {
  getAxiosOptions,
  formattedTime,
  formatDate,
} from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
  CLASS_STATUS_COMPLETED,
  CLASS_STATUS_CANCELED,
  CLASS_STATUS_STARTED,
} from "@/common/constants";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import { SET_OVERLAY_VALUE, SET_API_ERROR } from "@/store/mutations";
export default {
  name: "Dashboard",
  data: () => ({
    cards: [],
    classes: [],
    newClasses: [],
    todayClass: false,
    todayDate: "",
    courses: [],
    tab: null,
    classesHeaders: [
      {
        text: "Classroom Name",
        align: "center",
        value: "name",
      },
      {
        text: "Course",
        value: "courseId",
        align: "center",
      },
      {
        text: "Time",
        value: "time",
        align: "center",
      },
      {
        text: "Teacher",
        value: "teacherName",
        align: "center",
      },
      {
        text: "Status",
        value: "status",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ],
    USER_TYPE_TEACHER: USER_TYPE_TEACHER,
    USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    CLASS_STATUS_CANCELED: CLASS_STATUS_CANCELED,
    CLASS_STATUS_COMPLETED: CLASS_STATUS_COMPLETED,
    CLASS_STATUS_STARTED: CLASS_STATUS_STARTED,
  }),
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  
  mounted: function () {
    if (this.userType == this.USER_TYPE_STUDENT) {
      this.fetchCourses();
      this.fetchStudentDashboard();
    } else {
      this.fetchUserDashboard();
    }
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    formattedTime(date) {
      return formattedTime(date);
    },
    formatDate(date) {
      return formatDate(date);
    },
    fetchCourses() {
      let url = GET_STUDENT_COURSES;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    fetchUserDashboard() {
      this.setOverlayValue(true);
      axios
        .get(GET_USER_DASHBOARD, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.cards = res.data.dashboard;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    fetchStudentDashboard() {
      this.setOverlayValue(true);
      axios
        .get(GET_STUDENT_DASHBOARD, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.classes = res.data.studentClasses;
          this.getTodayClass();
          this.setLocaldateTime();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
      if (c.length>0 && c[0]) return c[0].courseName;
      return "No Course";
    },
    getTodayClass() {
      this.newClasses = this.classes.map((clss) =>
        new Date(clss.date).toLocaleDateString() ===
        new Date().toLocaleDateString()
          ? {
              ...clss,
              date: "Today" + " " + this.formatDate(new Date()),
            }
          : { ...clss, date: this.formatDate(clss.date)}
      );
      for (let i = 0; i < this.classes.length; i++) {
        if (
          new Date(this.classes[i].date).toLocaleDateString() ===
          new Date().toLocaleDateString()
        ) {
          this.tab = i;
          break;
        } else {
          if (new Date(this.classes[i].date) > new Date()) {
            this.tab = i;
            this.newClasses.splice(i, 0, {
              classes: [],
              date: "Today" + " " + this.formatDate(new Date()),
            });
            break;
          } else {
            if (i === this.classes.length - 1) {
              if (new Date(this.classes[i].date) < new Date()) {
                this.tab = this.classes.length;
                this.newClasses.push({
                  classes: [],
                  date: "Today" + " " + this.formatDate(new Date()),
                });
                break;
              }
            }
          }
        }
      }
    },
    goTo(card) {
      if (card.title == "Users") {
        this.$router.push({ name: "Users" });
      } else if (card.title == "Courses") {
        this.$router.push({ name: "Courses" });
      } else if (card.title == "Coupons") {
        this.$router.push({ name: "Coupons" });
      } else if (card.title == "Institutes") {
        this.$router.push({ name: "Institutes" });
      } else if (card.title == "Quizes") {
        this.$router.push({ name: "Quiz" });
      } else if (card.title == "Resources") {
        this.$router.push({ name: "Resources" });
      } else if (card.title == "Competitions") {
        this.$router.push({ name: "Competitions" });
      } else if (
        (card.title == "Classes" || card.title == "Class operations") &&
        this.userType == USER_TYPE_TEACHER
      ) {
        this.$router.push({ name: "TeacherClasses" });
      }
    },
    getColor(title) {
      if (title == "Coupons" || title == "Classes") return "#F55B49";
      else if (title == "Class operations" || title == "Institutes")
        return "#26BD67";
      else if (title == "Competitions" || title == "Courses") return "#195D80";
      else if (title == "Users" || title == "Quizes") return "#FAAF19";
      else return "primary";
    },

    getTitleColor(title) {
      if (title == "Coupons" || title == "Classes") return "#FFB9B1";
      else if (title == "Class operations" || title == "Institutes")
        return "#B2FFD4";
      else if (title == "Competitions" || title == "Courses") return "#A8D8F0";
      else if (title == "Users" || title == "Quizes") return "#FFE6B2";
      else return "primary";
    },
    getCountColor(title) {
      if (title == "Coupons" || title == "Classes") return "#FF8C7F";
      else if (title == "Class operations" || title == "Institutes")
        return "#5CE597";
      else if (title == "Competitions" || title == "Courses") return "#478FB2";
      else if (title == "Users" || title == "Quizes") return "#FFCC66";
      else return "primary";
    },
    getClassStatusColor(item) {
      if (item.status == this.CLASS_STATUS_CREATED) {
        return "blue";
      } else if (item.status == this.CLASS_STATUS_COMPLETED) {
        return "success";
      } else if (item.status == this.CLASS_STATUS_STARTED) {
        return "primary";
      } else if (item.status == this.CLASS_STATUS_CANCELED) {
        return "error";
      } else {
        return "purple";
      }
    },
    joinClass(item) {
      if (item.status == CLASS_STATUS_STARTED) {
        if (
          item.classLink.startsWith("http://") ||
          item.classLink.startsWith("https://")
        ) {
          window.open(item.classLink, "_blank");
        } else {
          let x = "https://";
          item.classLink = x.concat(item.classLink);
          window.open(item.classLink, "_blank");
        }
      } else {
        this.setApiError("Class Not Started Yet !");
      }
    },
    setLocaldateTime(){
      for(let i=0;i<this.newClasses.length;i++){
        for (let j = 0; j < this.newClasses[i].classes.length; j++) {
          let date = new Date(this.newClasses[i].date);
          date.setHours(0, 0, 0, 0);
          if(this.newClasses[i].classes[j].timezoneOffset == null){
            this.newClasses[i].classes[j].timezoneOffset = "-330";
          }
          let offset = ((Number(this.newClasses[i].classes[j].timezoneOffset)) - date.getTimezoneOffset()) * 60 * 1000;
              let openHours =
              this.newClasses[i].classes[j].openTime.split(":");
              let openMinutes = (Number(openHours[0]) * 60 + Number(openHours[1])) * 60 * 1000;
              let openTime = new Date(date.getTime() + openMinutes + offset);
              let hours = openTime.getHours()<=9 ? "0"+openTime.getHours():openTime.getHours();
              let minutes = openTime.getMinutes()==0 ? "0"+openTime.getMinutes():openTime.getMinutes();
              this.newClasses[i].classes[j].openTime = `${hours}:${minutes}`;
              let closeHours = this.newClasses[i].classes[j].closeTime.split(":");
              let closeMinutes = (Number(closeHours[0]) * 60 + Number(closeHours[1])) * 60 * 1000;
              let closeTime = new Date(date.getTime() + closeMinutes + offset);
              hours = closeTime.getHours()<=9 ? "0"+closeTime.getHours():closeTime.getHours();
              minutes = closeTime.getMinutes()==0 ? "0"+closeTime.getMinutes():closeTime.getMinutes();
              this.newClasses[i].classes[j].closeTime = `${hours}:${minutes}`;
        }
      }
    }
  },
};
</script>