<template>
  <v-container fluid>
    <v-dialog persistent v-model="deleteConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title
          >Are you sure you want to delete {{ competition.name }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDeleteDailog()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="deleteCompetition()">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="editCompDialog" max-width="1000">
      <v-card>
        <v-card>
          <v-card-title class="primary--text">Edit Competition</v-card-title>
          <v-form ref="form" v-model="createCompValidation.valid">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedComp.name"
                      label="Competition name"
                      :rules="createCompValidation.nameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="6">
                    <v-text-field v-model="editedComp.totalCoins" label="Total Coins"
                      :rules="createCompValidation.coinRules" type="number" min="0" required></v-text-field>
                  </v-col> -->
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedQuizComp"
                      :items="quizes"
                      label="Quizes"
                      return-object
                      :rules="createCompValidation.quizRules"
                      item-text="quizName"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="6">
                    <v-combobox
                      v-model="editedComp.grades"
                      :items="gradesArray"
                      label="Grades"
                      multiple
                      :rules="createCompValidation.multiValueRules"
                      deletable-chips
                      small-chips
                      item-text="desc"
                      item-value="id"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="selectAll">
                          <v-list-item-action>
                            <v-icon color="primary">{{ icon() }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-combobox>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedComp.duration"
                      label="Duration (in minutes)"
                      :rules="createCompValidation.durationRules"
                      type="number"
                      min="0"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.startDate"
                          label="Start Date"
                          prepend-icon="mdi-calendar"
                          :rules="createCompValidation.nameRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedComp.startDate"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="startDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="startTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.startTime"
                          label="Start Time"
                          :rules="createCompValidation.nameRules"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="editedComp.startTime"
                        :allowed-minutes="[
                          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                        ]"
                        @input="startTimeMenu = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.endDate"
                          label="End Date"
                          prepend-icon="mdi-calendar"
                          :rules="createCompValidation.nameRules"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedComp.endDate"
                        :min="new Date().toISOString().substr(0, 10)"
                        @input="endDateMenu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="3">
                    <v-menu
                      v-model="endTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedComp.endTime"
                          label="End Time"
                          :rules="createCompValidation.nameRules"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="editedComp.endTime"
                        :allowed-minutes="[
                          0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55,
                        ]"
                        @input="endTimeMenu = false"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close()"> Cancel </v-btn>
              <v-btn color="primary" text @click="saveCompetition()">
                Save
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="leaderboard" max-width="500px">
      <v-card elevation="0" class="mt-0">
        <v-simple-table class="mt-0">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  <v-icon color="yellow">mdi-trophy</v-icon>
                </th>
                <th class="text-left">Leaderboard</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in compRanks" :key="item.name">
                <td>
                  <v-avatar size="30" class="mr-2" v-if="item.profile">
                    <img :src="item.profile" alt="Profile Picture" />
                  </v-avatar>
                  <v-avatar v-else size="30" class="mr-2">
                    <img
                      src="../assets/Male Avatar.png"
                      alt="Profile Picture"
                    />
                  </v-avatar>
                  {{ item.name }}
                </td>
                <td>{{ item.rank }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions class="mt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="leaderboard = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card flat>
      <v-card
        elevation="0"
        v-if="competition.status != 'DRAFT' && statusText == 'Completed'"
      >
        <v-row>
          <v-col>
            <span style="font-size: 20px; font-weight: 500">Analytics</span>
          </v-col>
          <v-col align="end" class="mr-4">
            <span>
              <v-chip class="ml-2" label v-if="competition.grades.length < 2">
                Grade: {{ competition.grades[0] }}
              </v-chip>
              <v-chip class="ml-2" label v-else>
                Grades: {{ competition.grades.join(", ") }}
              </v-chip>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5">
            <v-card elevation="0">
              <div v-if="comp2Analytics && Object.keys(comp2Analytics).length">
                <chart-component
                  :data="chartData"
                  :options="chartOptions"
                ></chart-component>
              </div>
              <div v-else>chart not available</div>
              <v-row class="ma-1 ml-3 pt-5">
                <v-card
                  height="70px"
                  width="600px"
                  class="d-flex align-center ma-1 justify-space-between"
                  elevation="0"
                >
                  <div class="d-flex align-center flex-column">
                    <v-progress-circular
                      :rotate="-90"
                      :size="70"
                      :width="9"
                      :value="getPercenTageCompletion"
                      color="teal"
                      class="mb-2"
                    >
                      {{ getPercenTageCompletion + "%" }}
                    </v-progress-circular>
                    <div class="text-center">
                      <span style="font-size: 14px; color: grey"
                        >Completion percent</span
                      >
                    </div>
                  </div>

                  <div class="d-flex flex-column pt-1">
                    <div>
                      <span style="font-size: 14px; color: grey"
                        >Average Score:</span
                      >
                      <span class="font-weight-medium" style="font-size: 14px">
                        {{ formattedAverageScore }}
                      </span>
                    </div>
                    <div class="text-right pr-2">
                      <span style="font-size: 14px; color: grey"
                        >Average Time:</span
                      >
                      <span
                        class="font-weight-medium ml-1"
                        style="font-size: 14px"
                      >
                        {{ formattedAverageTime }}
                      </span>
                    </div>
                  </div>
                </v-card>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="7">
            <v-card elevation="0">
              <v-simple-table class="ma-1 mytable">
                <template v-slot:default>
                  <tbody>
                    <tr style="background-color: #edf5fd">
                      <th style="font-size: 16px; font-weight: 400">
                        <v-icon class="ml-1" small style="color: #447ef1">
                          mdi-circle
                        </v-icon>
                        Total Participants:

                        <span style="color: #447ef1">
                          {{ comp2Analytics.totalStudents ?? "-" }}</span
                        >
                      </th>
                      <td class="pl-4">
                        <v-btn
                          small
                          style="color: white; background-color: #447ef1"
                          @click="openCompLeaderboard()"
                        >
                          Leaderboard
                        </v-btn>
                      </td>
                    </tr>

                    <tr style="background-color: #e7fce9">
                      <th style="font-size: 16px; font-weight: 400">
                        <v-icon class="ml-1" small style="color: #44be75">
                          mdi-circle
                        </v-icon>
                        Highest Score:
                        <span style="color: #44be75">
                          {{ comp2Analytics.highestScore ?? "-" }}</span
                        >
                      </th>
                      <td style="font-weight: 400; font-size: 16px">
                        <v-icon class="ml-1" small style="color: #44be75">
                          mdi-circle
                        </v-icon>
                        Lowest Score:
                        <span style="color: #44be75">{{
                          comp2Analytics.lowestScore ?? "-"
                        }}</span>
                      </td>
                    </tr>

                    <tr style="background-color: #fff0e1">
                      <th style="font-size: 16px; font-weight: 400">
                        <v-icon class="ml-1" small style="color: #df9756">
                          mdi-circle
                        </v-icon>
                        Max Time:
                        <span style="color: #df9756">{{
                          formattedMaximumTime
                        }}</span>
                      </th>
                      <td style="font-weight: 500; font-size: 16px">
                        <v-icon class="ml-1" small style="color: #df9756">
                          mdi-circle
                        </v-icon>
                        Min Time:
                        <span style="color: #df9756">{{
                          formattedMinimumTime
                        }}</span>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-row
        v-else-if="competition.status != 'DRAFT' && statusText != 'Completed'"
      >
        <v-col>
          <span style="font-size: 20px; font-weight: 500">
            Analytics will be available once competition is over
          </span>
        </v-col>
        <v-col align="end" class="mr-4">
          <span>
            <v-chip label v-if="competition.grades.length < 2">
              Grade: {{ competition.grades[0] }}
            </v-chip>
            <v-chip label v-else>
              Grades: {{ competition.grades.join(", ") }}
            </v-chip>
          </span>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-row v-if="competition.status != STATUS_PUBLISHED">
          <v-row class="mt-1 ml-n3">
            <span>
              <v-chip
                label
                v-if="competition.grades.length < 2"
              >
                Grade: {{ competition.grades[0] }}
              </v-chip>
              <v-chip
                label
                v-else
              >
                Grades: {{ competition.grades.join(", ") }}
              </v-chip>
            </span>
          </v-row>
          <v-btn
            color="primary"
            small
            icon
            @click="openEditCompDialog(competition)"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-pencil</v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
          </v-btn>
          <v-btn color="primary" small icon @click="deleteCompetitionConfirm()">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="error">mdi-delete</v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ChartComponent from "./ChartComponent.vue";
import {
  BASE_URL,
  // CREATE_COMPETETION,
  DELETE_COMPETETION,
  SAVE_COMPETETION_DETAILS,
  GET_ALL_QUIZ,
  QUIZ_COMPETITION_COMP_LEADERBOARD,
  QUIZ_COMPETITION_ADMIN_ANALYTICS,
} from "@/common/apiEndpoints";
import { getAxiosOptions, formatDateTime, formatTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import axios from "axios";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  grades,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      publishConfirmDialog: false,
      deleteConfirmDialog: false,
      editCompDialog: false,
      startDateMenu: false,
      endDateMenu: false,
      startTimeMenu: false,
      endTimeMenu: false,
      selectedQuizComp: {},
      editedIndex: -1,
      editedComp: {
        name: "",
        grades: [],
        quizes: [],
        startDate: "",
        endDate: "",
        totalCoins: 0,
      },
      defaultItem: {
        name: "",
        grades: [],
        quizCompId: "",
        startDate: "",
        endDate: "",
        totalCoins: 0,
      },
      createCompValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        durationRules: [
          (v) => !!v || "This is required",
          (v) => Number(0) < v || "Duration cannot be 0",
          (v) => Number(181) > v || "Duration cannot be greater than 3 hours",
        ],
        coinRules: [(v) => !!v || v > 0 || "Coins should be greater than 0"],
        multiValueRules: [(v) => v.length > 0 || "Select Grade"],
        quizRules: [(v) => !!v || "Select Quiz"],
      },
      quizes: [],
      gradesArray: grades,
      compAnalytics: {},
      comp2Analytics: {},
      compRanks: [],
      leaderboard: false,

      statusText: this.getStatusText(
        this.competition.startDate,
        this.competition.endDate
      ),
    };
  },
  props: ["competition"],
  components: {
    ChartComponent,
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      userType: `schoolModule/${GET_USER_TYPE}`,
    }),
    formattedAverageTime() {
      if (this.comp2Analytics.averageTime) {
        const totalSeconds = this.comp2Analytics.averageTime ?? 300;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes} mins ${seconds} secs`;
      }
      return "-";
    },
    formattedAverageScore() {
      if (this.comp2Analytics.averageScore) {
        const score = Math.floor(this.comp2Analytics.averageScore);

        return score ?? "-";
      }
      return "-";
    },
    formattedMaximumTime() {
      if (this.comp2Analytics.maximumTime) {
        const totalSeconds = this.comp2Analytics.maximumTime;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes} mins ${seconds} secs`;
      }
      return "-";
    },
    formattedMinimumTime() {
      if (this.comp2Analytics.minimumTime) {
        const totalSeconds = this.comp2Analytics.minimumTime ?? 300;
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${minutes} mins ${seconds} secs`;
      }
      return "-";
    },

    chartData() {
      const timePercentages = new Array(11).fill(0);
      const correctPercentages = new Array(11).fill(0);
      const incorrectPercentages = new Array(11).fill(0);

      const totalDurationSeconds = this.competition.duration * 60;

      this.compAnalytics.students.forEach((student) => {
        const totalQuestions = student.totalQuestions;

        const startTime = new Date(student.startDateTime).getTime();
        const endTime = new Date(student.endDateTime).getTime();
        const timeSpentSeconds = Math.floor((endTime - startTime) / 1000);

        const timePercentage = (timeSpentSeconds / totalDurationSeconds) * 100;
        const correctPercentage =
          totalQuestions === 0
            ? 0
            : (student.correctQuestions / totalQuestions) * 100;
        const incorrectPercentage =
          totalQuestions === 0
            ? 0
            : (student.incorrectQuestions / totalQuestions) * 100;

        const timeIndex = Math.min(Math.floor(timePercentage / 10), 10);
        const correctIndex = Math.min(Math.floor(correctPercentage / 10), 10);
        const incorrectIndex = Math.min(
          Math.floor(incorrectPercentage / 10),
          10
        );

        timePercentages[timeIndex]++;
        correctPercentages[correctIndex]++;
        incorrectPercentages[incorrectIndex]++;
      });

      return {
        labels: [
          "0%",
          "10%",
          "20%",
          "30%",
          "40%",
          "50%",
          "60%",
          "70%",
          "80%",
          "90%",
          "100%",
        ],
        datasets: [
          {
            label: "Time",
            backgroundColor: "#ffcd56",
            borderColor: "#ffcd56",
            fill: false,
            data: timePercentages,
          },
          {
            label: "Correct Questions",
            backgroundColor: "#4bc0c0",
            borderColor: "#4bc0c0",
            fill: false,
            data: correctPercentages,
          },
          {
            label: "Incorrect Questions",
            backgroundColor: "#ff6384",
            borderColor: "#ff6384",
            fill: false,
            data: incorrectPercentages,
          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              callback: function (value) {
                return value;
              },
            },
            title: {
              display: true,
              text: "Number of Students",
            },
          },
          x: {
            title: {
              display: true,
              text: "Percentage Intervals",
            },
          },
        },
      };
    },

    getPercenTageCompletion() {
      if (this.comp2Analytics.completion) {
        return Math.floor(
          (this.comp2Analytics.completion / this.comp2Analytics.totalStudents) *
            100
        );
      }
      return 0;
    },
  },
  mounted: function () {
    this.getCompAnalytics();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    getStatusText(startDate, endDate) {
      const currentDate = new Date();
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (currentDate < start) {
        return "Upcoming";
      } else if (currentDate >= start && currentDate <= end) {
        return "Live";
      } else {
        return "Completed";
      }
    },

    openCompLeaderboard() {
      const url = QUIZ_COMPETITION_COMP_LEADERBOARD;
      const payload = { compId: this.competition.quizCompId };

      return axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          const leaderboard = res.data.leaderboard;
          this.compRanks = [];

          leaderboard.forEach((item, index) => {
            if (index < 10) {
              this.compRanks.push({
                name: `${item.firstName} ${item.lastName}`,
                profile: item.profilePic,
                rank: item.rank,
                userId: item.userId,
              });
            }
            this.leaderboard = true;
          });
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.errorMessage || error.message;
          this.setOverlayValue(false);
          throw new Error(errorMessage);
        });
    },

    getCompAnalytics() {
      const url = QUIZ_COMPETITION_ADMIN_ANALYTICS;
      const payload = { compId: this.competition.quizCompId };

      if (this.competition.status != "DRAFT") {
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((response) => {
            const data = response.data;
            this.compAnalytics = data;

            if (data.students.length === 0) {
              this.compAnalytics = {
                totalStudents: 0,
                highestScore: "-",
                lowestScore: "-",
                maximumTime: "-",
                minimumTime: "-",
                averageScore: "-",
                averageTime: "-",
                completion: 0,
              };
              return;
            }

            const scores = data.students.map(
              (student) => student.correctQuestions
            );
            const times = data.students.map(
              (student) =>
                (new Date(student.endDateTime) -
                  new Date(student.startDateTime)) /
                1000
            );

            const getValidNumber = (value) => {
              if (Array.isArray(value) && value.length === 0) {
                return "-";
              } else if (isFinite(value) && !isNaN(value)) {
                return Math.floor(value);
              } else {
                return "-";
              }
            };

            const highestScore = getValidNumber(Math.max(...scores));
            const lowestScore = getValidNumber(Math.min(...scores));

            const maxTime = Math.max(...times);
            const minTime = Math.min(...times);

            const maximumTime = getValidNumber(maxTime);
            const minimumTime = getValidNumber(minTime);

            const avgScore =
              scores.reduce((acc, score) => acc + score, 0) /
              data.students.length;
            const avgTime =
              times.reduce((acc, time) => acc + time, 0) / data.students.length;

            const averageScore = getValidNumber(avgScore);
            const averageTime = getValidNumber(avgTime);

            const completion = data.students.filter(
              (student) => student.attemptedQuestions === student.totalQuestions
            ).length;

            const comp2Analytics = {
              totalStudents: data.students.length,
              highestScore,
              lowestScore,
              maximumTime,
              minimumTime,
              averageScore,
              averageTime,
              completion,
            };

            this.comp2Analytics = comp2Analytics;
          })
          .catch((error) => {
            const errorMessage =
              error.response?.data?.errorMessage || error.message;
            this.setOverlayValue(false);
            throw new Error(errorMessage);
          });
      }
    },

    getAllQuiz(competition) {
      let url = GET_ALL_QUIZ + "?status=PUBLISHED";
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quizes = res.data.quizes;
          this.selectedQuizComp = this.quizes.find(
            (quiz) => quiz.quizId == competition.quizes[0].quizId
          );
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    validate() {
      this.$refs.form.validate();
    },

    selectAllGrades() {
      return this.editedComp.grades.length === this.gradesArray.length;
    },
    selectSomeGrades() {
      return this.editedComp.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedComp.grades = [];
        } else {
          this.editedComp.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },

    close() {
      this.dialog = false;
      this.editCompDialog = false;
      this.$nextTick(() => {
        this.editedComp = Object.assign({}, this.defaultItem);
        this.selectedQuizComp = {};
        this.editedIndex = -1;
      });
    },
    saveCompetition() {
      this.validate();
      if (this.createCompValidation.valid) {
        if (!this.editedComp.quizes) {
          this.editedComp.quizes = [];
        }
        this.editedComp.quizes[0] = this.selectedQuizComp;
        // this.editedComp.quizComp = this.selectedQuizComp;
        let payload = Object.assign({}, this.editedComp);
        let startDate = payload.startDate + " " + payload.startTime;
        startDate = new Date(startDate);
        let endDate = payload.endDate + " " + payload.endTime;
        endDate = new Date(endDate);
        payload.startDate = startDate.toISOString();
        payload.endDate = endDate.toISOString();
        delete payload.startTime;
        delete payload.endTime;
        let url = SAVE_COMPETETION_DETAILS;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCompValidation.valid = false;
            this.$emit("updateComp");
            this.close();
            this.setOverlayValue(false);
            this.setApiSuccess("Competition updated successfully");
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
          });
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    closeDeleteDailog() {
      this.deleteConfirmDialog = false;
    },
    deleteCompetitionConfirm() {
      this.deleteConfirmDialog = true;
    },
    deleteCompetition() {
      const url = DELETE_COMPETETION;
      let payload = {};
      payload.quizCompId = this.competition.quizCompId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeDeleteDailog();
          this.$emit("updateComp");
          this.setOverlayValue(false);
          this.setApiSuccess("Competition deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.closeDeleteDailog();
        });
    },
    openEditCompDialog(competition) {
      this.editedComp = Object.assign({}, competition);
      this.getAllQuiz(competition);

      // this.selectedQuizComp = this.quizes.find(quiz => quiz.quizId == competition.quizes[0].quizId);

      // this.editedComp.startTime = formatDateTime(this.editedComp.startDate);
      // this.editedComp.endTime = formatDateTime(this.editedComp.endDate);
      this.editedComp.startTime = formatTime(this.editedComp.startDate);
      this.editedComp.endTime = formatTime(this.editedComp.endDate);
      this.editedComp.startDate = this.editedComp.startDate.substring(0, 10);
      this.editedComp.endDate = this.editedComp.endDate.substring(0, 10);
      this.editCompDialog = true;
    },
  },
};
</script>
