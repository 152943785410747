<template>
  <v-card style="height: 500px; width: 400px; overflow: hidden">
    <v-container class="pa-0">
      <v-toolbar color="primary" dark class="fixed-bar">
        <v-img
          max-height="40"
          max-width="40"
          class="mr-4"
          src="../assets/Icons/Group_227.svg"
        ></v-img>
        <v-toolbar-title style="color: white; font-weight: 500"
          >Ask Me Your Question</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
       <button class="material-icons " @click="getAllPreviousMessages" > refresh</button>
       
      </v-toolbar>
      <v-card
        flat
        style="height: 380px; overflow-y: scroll"
        class="flex-container"
      >
        <v-list class="mt-2">
          <v-list-item
            v-for="(pm, index) in previousMessages"
            ref="scrollList"
            :key="pm.assistId"
            :index="index"
            :class="{ 'last-item': index === previousMessages.length - 1 }"
          >
            <v-list-item-content style="display: inline">
              <v-row>
                <v-col cols="2" class="pb-0"></v-col>
                <v-col class="pb-0 mb-0">
                  <v-row v-if="showDate == true" class="ma-1" justify="end">
                    <span style="font-size: 12px;">{{
                      formatDateTime(pm.createdOn)
                    }}</span>
                  </v-row>
                  <v-textarea
                    v-model="pm.question"
                    class="quesText"
                    auto-grow
                    rows="1"
                    outlined
                    readonly
                    style="font-size: 12px"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 mt-n8">
                  <v-row v-if="showDate == true" class="ma-1">
                    <span style="font-size: 12px;">{{
                      formatDateTime(pm.createdOn)
                    }}</span>
                  </v-row>
                  <v-textarea
                    v-model="pm.answer"
                    class="ansText"
                    auto-grow
                    rows="1"
                    outlined
                    solo
                    readonly
                    background-color="#FFE6B4"
                    style="width: 80%; font-size: 12px"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card flat>
        <v-row class="ml-1">
          <v-col class="pa-0 ml-1">
            <v-textarea
              v-model="question"
              label="Type your question"
              auto-grow
              rows="1"
              v-on:keyup.enter="sendQuestion()"
            ></v-textarea>
          </v-col>
          <v-col cols="2">
            <v-btn
              fab
              small
              color="primary"
              :disabled="question.length < 1 || disableSendBtn"
              @click="sendQuestion()"
            >
              <v-icon> mdi-send </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-card>
</template> 
<script>
import axios from "axios";
import {
  AI_ASSISTANT_ASSIST,
  AI_ASSISTANT_GET,
  BASE_URL,
} from "@/common/apiEndpoints.js";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { USER_TYPE_STUDENT } from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { formatDateTime, getAxiosOptions } from "@/common/utility";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
export default {
  data() {
    return {
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      previousMessages: [],
      question: "",
      disableSendBtn: false,
      showDate: true,
    };
  },

  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    this.getAllPreviousMessages();
  },
  methods: {
    ...mapMutations({
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getAllPreviousMessages() {
      let url = AI_ASSISTANT_GET;
      axios.get(url, getAxiosOptions(BASE_URL, this.token)).then((res) => {
        this.previousMessages = res.data.list;
        this.disableSendBtn = false;
      });
    },
    sendQuestion() {
      if (this.question) {
        const payload = {};
        payload.question = this.question;
        let url = AI_ASSISTANT_ASSIST;
        let newEntry = {};
        newEntry.question = this.question;
        newEntry.answer = "Typing...";
        this.previousMessages.push(newEntry);
        this.question = "";
        this.disableSendBtn = true;
        this.showDate = false;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            let index = this.previousMessages.length;
            this.previousMessages.splice(index - 1, 1);
            this.previousMessages.push(res.data);
            this.disableSendBtn = false;
            this.showDate = true;
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
  },
};
</script>
<style>
.quesText.v-text-field--outlined > .v-input__control > .v-input__slot {
  align-items: stretch;
  min-height: 0px;
}
.quesText.v-text-field--outlined fieldset {
  border-color: #ffcc66;
  border-width: 2px;
}
.quesText.v-textarea textarea {
  line-height: 1.2rem;
}
.ansText.v-text-field--outlined fieldset {
  border-color: transparent;
}
.ansText.v-textarea textarea {
  line-height: 1.2rem;
}
.fixed-bar {
  position: sticky;
  top: 0px;
  z-index: 2;
}
.v-list-item__content {
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.flex-container {
  display: flex;
  flex-direction: column-reverse; /* Items will be laid out as a row in reverse order */
  justify-content: space-between;
  width: 100%;
}
</style>
