<template>     <v-menu
v-model="showMenuState.showMenu"
    :position-x="showMenuState.xPosProp"
    :position-y="showMenuState.yPosProp"
    absolute
    offset-y
  >
    <v-list>
      <v-list-item
        @click="copyTextFunction()"
      >
        <v-list-item-title >Copy</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu></template>
  
<script>
  export default {
    data() {
    return {
      // x: 0,
      // y: 0,
      showMenuState:{},
    }
  },
  watch: {
    contextMenu: {
  // the callback will be called immediately after the start of the observation
  immediate: true, 
  handler (val, oldVal) {
    // do your stuff
   this.showMenuState=this.contextMenu
  },   
}
},
// props:['showMenuProp','xPosProp','yPosProp','copyText'],
props:['contextMenu'],
methods:{
    copyTextFunction(){ navigator.clipboard.writeText(this.showMenuState.copyText);
    }
}
}

    </script>