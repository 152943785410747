<template>
  <v-card flat>
    <v-row>
      <v-card-text>
        <v-row>
          <v-col cols="4" class="ma-1 pa-0">
            <v-row class="ma-1">
              <v-col>
                <p class="pa-0 ma-1" style="font-size: 20px">
                  Price :
                  <span style="color: #ff6666; font-weight: 700"
                    >₹ {{ course.price }}</span
                  >
                </p>
              </v-col>
              <v-col>
                <p class="pa-0 ma-1" style="font-size: 20px">
                  Classes :
                  <span style="color: #ff6666; font-weight: 700">{{
                    course.noOfClasses
                  }}</span>
                </p>
              </v-col>
            </v-row>
            <v-col class="pb-0">
              <v-select
                v-model="selectedTeacherId"
                :items="filteredTeachers"
                label="Select Teacher"
                outlined
                dense
                @change="ShowAvailablity()"
                item-value="teacherId"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchTerm"
                        placeholder="Search"
                        @input="searchTeacher"
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.firstName }} {{ data.item.lastName }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list>
                    <v-list-item class="pa-0 ma-0">
                      <v-list-item-avatar>
                        <v-img
                          v-if="data.item.profilePhoto"
                          :src="data.item.profilePhoto"
                        ></v-img>
                        <img
                          v-else-if="data.item.gender == 'MALE'"
                          src="../assets/Male Avatar.png"
                        />
                        <img
                          v-else
                          src="../assets/Female Avatar.png"
                          alt="Jill"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content style="display: block !important">
                        <v-list-item-title style="font-size: 16px"
                          >{{ data.item.firstName }} {{ data.item.lastName }}
                          {{
                            "(" + data.item.highestQualification.qName + ")"
                          }}</v-list-item-title
                        >
                        <span style="font-size: 14px">{{
                          data.item.gender +
                          " (" +
                          data.item.experience +
                          " year)"
                        }}</span>
                        <v-rating
                          v-model="data.item.ratings"
                          color="yellow darken-3"
                          background-color="grey darken-1"
                          empty-icon="$ratingFull"
                          half-increments
                          readonly
                          x-small
                        ></v-rating>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </template>
              </v-select>
              <v-list class="pa-0" v-if="selectedTeacherId">
                <v-list-item class="pa-0">
                  <v-list-item-content>
                    <v-list-item-title style="font-size: 20px"
                      >About</v-list-item-title
                    >
                    <v-list-item-subtitle class="mt-1" style="font-size: 18px"
                      ><span style="color: #ff6666; font-weight: 600"
                        >Gender:</span
                      >
                      {{ selectedTeacher.gender }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="mt-1" style="font-size: 18px"
                      ><span style="color: #ff6666; font-weight: 600"
                        >Experience:</span
                      >
                      {{ selectedTeacher.experience }}
                      years</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="mt-1" style="font-size: 18px"
                      ><span style="color: #ff6666; font-weight: 600"
                        >Qualification:</span
                      >
                      {{ selectedTeacher.highestQualification.qName }},
                      {{
                        selectedTeacher.highestQualification.passingYear
                      }}</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="mt-1" style="font-size: 18px"
                      ><span style="color: #ff6666; font-weight: 600"
                        >Languages:</span
                      >
                      <span
                        v-for="(
                          language, index
                        ) in selectedTeacher.speakingLanguages"
                        :key="index"
                      >
                        {{ language
                        }}{{
                          index != selectedTeacher.speakingLanguages.length - 1
                            ? ","
                            : ""
                        }}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item class="pa-0" v-if="selectedSlots.length != 0">
                  <v-list-item-title style="font-size: 20px"
                    >Selected Slots</v-list-item-title
                  >
                </v-list-item>
                <ul class="ml-3 pa-1">
                  <v-list-item
                    class="pa-0 mt-n6"
                    v-for="(slot, index) in selectedSlots"
                    :key="index"
                  >
                    <li>
                      <v-list-item-subtitle style="font-size: 18px"
                        ><span style="color: #ff6666; font-weight: 600"
                          >{{ slot.day.day }} -</span
                        >
                        {{ slot.openTime }} to
                        {{ slot.closeTime }}</v-list-item-subtitle
                      >
                    </li>
                    <v-list-item-icon>
                      <v-icon
                        color="error"
                        @click="removeSelectedSlot(slot.eventId, index)"
                        >mdi-delete</v-icon
                      >
                    </v-list-item-icon>
                  </v-list-item>
                </ul>
              </v-list>
            </v-col>
            <v-col class="ma-1 pa-0">
              <v-card-actions class="pt-0">
                <v-btn
                  color="primary"
                  style="font-weight: 600; font-size: 16px"
                  @click="processToPay()"
                  :disabled="selectedSlots.length < 2 || selectedSlots.length > 4"
                  >Proceed To Pay</v-btn
                >
              </v-card-actions>
            </v-col>
          </v-col>
          <v-divider vertical v-if="selectedTeacherId"></v-divider>
          <v-col class="ma-1 pa-0" v-if="selectedTeacherId">
            <v-sheet height="500">
              <v-calendar
                ref="calendar"
                class="mycalendar"
                type="week"
                :events="events"
                :weekdays="weekDaysFormat"
                :first-interval="intervals.first"
                :interval-minutes="intervals.minutes"
                :interval-count="intervals.count"
                :interval-height="intervals.height"
                :event-height="intervals.height"
                :interval-style="intervalStyle"
                :interval-format="intervalFormat"
                @mousedown:time="bookSlot"
              >
                <template v-slot:event="{ eventParsed }">
                  {{ eventParsed.start.time }} - {{ eventParsed.end.time }}
                </template>
              </v-calendar>
              <!-- @mousedown:event="startDrag" -->
              <!-- @mouseup:time="endDrag" -->
              <!-- @mouseleave.native="cancelDrag" -->
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>
    </v-row>
  </v-card>
</template>
<script>
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { mapGetters, mapMutations } from "vuex";
import { BASE_URL, GET_ALL_TEACHER_AVAILABILITY } from "@/common/apiEndpoints";
import axios from "axios";
import {
  getAxiosOptions,
  formatDateTime,
  formatTime,
  formatDateNumeric,
} from "@/common/utility";
// import SampleCalendar from "@/components/SampleCalendar.vue";

const intervalsDefault = {
  first: 0,
  minutes: 30,
  count: 48,
  height: 20,
};
const stylings = {
  workday(interval) {
    const inactive = this.isScheduleAvailable(interval);
    // interval.weekday === 0 ||
    //   interval.weekday === 6 ||
    //   interval.hour < 9 ||
    //   interval.hour >= 17
    return {
      backgroundColor: !inactive ? "rgba(0,0,0,0.05)" : undefined,
    };
  },
};

export default {
  data() {
    return {
      teachers: [],
      selectedTeacherId: "",
      teacherAvailability: [],
      teacherSchedule: [],
      selectedTeacher: {},
      filteredTeachers: [],
      selectedSlots: [],
      availableHours: [],
      availableMinutes: [],
      selectedAvailableHour: 0,
      selectedAvailableMinutes: 0,
      intervals: intervalsDefault,
      events: [],
      eventId: 0,
      weekDaysFormat: [],
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      createEnd: null,
      extendOriginal: null,
      searchTerm: "",
      today: new Date(),
      weekDays: [
        { id: 0, day: "SUN" },
        { id: 1, day: "MON" },
        { id: 2, day: "TUE" },
        { id: 3, day: "WED" },
        { id: 4, day: "THU" },
        { id: 5, day: "FRI" },
        { id: 6, day: "SAT" },
      ],
    };
  },
  // components: { SampleCalendar },
  props: ["course"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),

    intervalStyle() {
      return stylings["workday"].bind(this);
    },
  },
  mounted: function () {
    this.getTeacherAvailability();
    this.getWeekDaysFormat();
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),

    intervalFormat(interval) {
      if (interval.time.split(":")[1] != "30") return interval.time;
    },

    formatDateTime(date) {
      return formatDateTime(date);
    },
    getTeacherAvailability() {
      let url = GET_ALL_TEACHER_AVAILABILITY;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.teachers = res.data.teachers;
          this.filteredTeachers = this.teachers;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    ShowAvailablity() {
      this.selectedTeacher = this.teachers.find(
        (teacher) => teacher.teacherId == this.selectedTeacherId
      );
      this.teacherAvailability = this.selectedTeacher.availability;
      this.teacherSchedule = this.selectedTeacher.schedule;
      const allDay = false;
      this.selectedSlots = [];
      this.events = [];
      this.eventId = 0;
      for (let i = 0; i < this.teacherAvailability.length; i++) {
        let date = new Date(this.teacherAvailability[i].date);
        for (let k = 0; k < this.teacherAvailability[i].slots.length; k++) {
          let openHours =
            this.teacherAvailability[i].slots[k].openTime.split(":");
          date.setHours(openHours[0], openHours[1]);
          let openTime = new Date(date);
          let closeHours =
            this.teacherAvailability[i].slots[k].closeTime.split(":");
          date.setHours(closeHours[0], closeHours[1]);
          let closeTime = new Date(date);
          let isAvailable = this.teacherAvailability[i].slots[k].isAvailable;
          let eventColor = isAvailable ? "success" : "error";
          if (!isAvailable) {
            this.events.push({
              id: this.eventId,
              start: openTime,
              end: closeTime,
              timed: !allDay,
              color: eventColor,
            });
            this.eventId++;
          }
        }
      }

      //   this.$refs.calendar.scrollToTime("08:00");
    },
    searchTeacher() {
      if (!this.searchTerm) {
        this.filteredTeachers = this.teachers;
      } else {
        this.filteredTeachers = this.teachers.filter((teacher) => {
          return teacher.firstName.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1;
        });
      }
    },
    formatDateNumeric(date) {
      return formatDateNumeric(date);
    },
    formatTime(date) {
      return formatTime(date);
    },
    isSlotAvailable(interval) {
      let isAvailableSlot = false;
      for (let i = 0; i < this.teacherAvailability.length; i++) {
        let date = new Date(this.teacherAvailability[i].date);
        if (this.formatDateNumeric(date) == interval.date) {
          for (let k = 0; k < this.teacherAvailability[i].slots.length; k++) {
            let openHours =
              this.teacherAvailability[i].slots[k].openTime.split(":");
            // date.setHours(openHours[0], openHours[1]);
            // let openTime = new Date(date);
            let closeHours =
              this.teacherAvailability[i].slots[k].closeTime.split(":");
            // date.setHours(closeHours[0], closeHours[1]);
            // let closeTime = new Date(date);
            let isAvailable = this.teacherAvailability[i].slots[k].isAvailable;
            if (isAvailable) {
              if (
                interval.hour > Number(openHours[0]) || (interval.hour == Number(openHours[0]) && interval.minute > Number(openHours[1])) &&
                interval.hour < Number(closeHours[0])
              ) {
                if (
                  (Number(closeHours[1]) == 0 &&
                    (interval.hour < Number(closeHours[0] - 1) ||
                      interval.minute <= 30)) ||
                  (Number(closeHours[1]) == 30 &&
                    interval.hour <= Number(closeHours[0] - 1))
                ) {
                  for (let k = 0; k < this.events.length; k++) {
                    let startTime = this.formatTime(this.events[k].start);
                    let endTime = this.formatTime(this.events[k].end);
                    if (
                      this.formatDateNumeric(this.events[k].start) ==
                      interval.date
                    ) {
                      if (
                        interval.hour == Number(startTime.split(":")[0]) ||
                        (Number(startTime.split(":")[1]) == 30 &&
                          interval.hour == Number(endTime.split(":")[0]) &&
                          interval.minute <= 30) ||
                        (Number(startTime.split(":")[1]) == 0 &&
                          interval.hour ==
                            Number(startTime.split(":")[0]) - 1 &&
                          interval.minute >= 30)
                      ) {
                        return false;
                      }
                    }
                  }
                  isAvailableSlot = true;
                  break;
                }
              }
            }
          }
        }
      }
      return isAvailableSlot;
    },
    processToPay() {
      this.$router.push({
        name: "CoursePayment",
        params: {
          schedule: this.selectedSlots,
          teacherId: this.selectedTeacher.teacherId,
          course: this.course,
          redirectedFromOrder: false,
        },
      });
    },
    // startDrag({ event, timed }) {
    //   if (event && timed) {
    //     this.dragEvent = event;
    //     this.dragTime = null;
    //     this.extendOriginal = null;
    //   }
    // },
    bookSlot(tms) {
      const mouse = this.toTime(tms);
      if (
        this.isSlotAvailable(tms) &&
        !this.isSlotAlreadyBookedOnThisDay(tms)
      ) {
        if (this.selectedSlots.length < 4) {
          this.createStart = this.roundTime(mouse);
          this.createEnd = this.createStart + 60 * 60 * 1000;
          this.createEvent = {
            id: this.eventId,
            color: "success",
            start: this.createStart,
            end: this.createEnd,
            timed: true,
          };
          let selectedSlot = {};
          selectedSlot.day = this.weekDays.find((wd) => wd.id == tms.weekday);
          selectedSlot.openTime =
            (tms.hour <= 9 ? "0" + tms.hour : tms.hour) +
            ":" +
            (tms.minute < 30 ? "00" : "30");
          selectedSlot.closeTime =
            (tms.hour + 1 <= 9
              ? "0" + Number(tms.hour + 1)
              : Number(tms.hour + 1)) +
            ":" +
            (tms.minute < 30 ? "00" : "30");
          selectedSlot.eventId = this.eventId;
          this.selectedSlots.push(selectedSlot);
          this.selectedSlots.sort(function (a, b) {
            return a.day.id - b.day.id;
          });
          this.events.push(this.createEvent);
          this.eventId++;
        } else {
          this.setApiError("Minimum 2 and Maximum 4 slots can be booked");
        }
      }
    },
    removeSelectedSlot(eventId, slotIndex) {
      let eventIndex = this.events.findIndex((event) => event.id == eventId);
      this.events.splice(eventIndex, 1);
      this.selectedSlots.splice(slotIndex, 1);
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },

    // endDrag() {
    //   this.dragTime = null;
    //   this.dragEvent = null;
    //   this.createEvent = null;
    //   this.createStart = null;
    //   this.extendOriginal = null;
    // },
    // cancelDrag() {
    //   if (this.createEvent) {
    //     if (this.extendOriginal) {
    //       this.createEvent.end = this.extendOriginal;
    //     } else {
    //       const i = this.events.indexOf(this.createEvent);
    //       if (i !== -1) {
    //         this.events.splice(i, 1);
    //       }
    //     }
    //   }

    //   this.createEvent = null;
    //   this.createStart = null;
    //   this.dragTime = null;
    //   this.dragEvent = null;
    // },
    roundTime(time, down = true) {
      const roundTo = 30; // minutes
      const roundDownTime = roundTo * 60 * 1000;

      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    isScheduleAvailable(interval) {
      for (let i = 0; i < this.teacherSchedule.length; i++) {
        if (
          this.weekDays.find((wd) => wd.id == interval.weekday).day ==
          this.teacherSchedule[i].day
        ) {
          for (let k = 0; k < this.teacherSchedule[i].timings.length; k++) {
            let openHours =
              this.teacherSchedule[i].timings[k].openTime.split(":");
            let closeHours =
              this.teacherSchedule[i].timings[k].closeTime.split(":");
            if (
              (interval.hour > Number(openHours[0]) &&
                interval.hour < Number(closeHours[0])) ||
              (interval.hour == Number(openHours[0]) &&
                interval.minute >= Number(openHours[1])) ||
              (interval.hour == Number(closeHours[0]) &&
                interval.minute < Number(closeHours[1]))
            ) {
              //  if (interval.hour < Number(closeHours[0] - 1) || interval.minute <= 30) {
              return true;
              // }
            }
          }
        }
      }
      return false;
    },
    isSlotAlreadyBookedOnThisDay(interval) {
      let day = this.weekDays.find((wd) => wd.id == interval.weekday).day;
      let index = this.selectedSlots.findIndex((ss) => ss.day.day == day);
      return index != -1;
    },
    getWeekDaysFormat() {
      const dayNames = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
      let dateObj = new Date();
      let dayName = dayNames[dateObj.getDay()];

      switch (dayName) {
        case "Sun":
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
          break;
        case "Mon":
          this.weekDaysFormat = [1, 2, 3, 4, 5, 6, 0];
          break;
        case "Tues":
          this.weekDaysFormat = [2, 3, 4, 5, 6, 0, 1];
          break;
        case "Wed":
          this.weekDaysFormat = [3, 4, 5, 6, 0, 1, 2];
          break;
        case "Thu":
          this.weekDaysFormat = [4, 5, 6, 0, 1, 2, 3];
          break;
        case "Fri":
          this.weekDaysFormat = [5, 6, 0, 1, 2, 3, 4];
          break;
        case "Sat":
          this.weekDaysFormat = [6, 0, 1, 2, 3, 4, 5];
          break;
        default:
          this.weekDaysFormat = [0, 1, 2, 3, 4, 5, 6];
      }
    },
  },
};
</script>
