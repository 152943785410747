<template>
  <v-card class="ma-4" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="secondary">
      <v-btn icon class="hidden-xs-only" @click="$router.go(-1)">
        <v-icon >mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title style="font-weight: 500; font-size: 24px">{{
        quiz.quizName
      }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchQuizQuestion()"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon
              v-on="on"
              @click="showSearchField = !showSearchField"
              color=""
              >mdi-magnify</v-icon
            >
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-dialog persistent v-model="addDialog" max-width="900" :retain-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="quiz.status != STATUS_PUBLISHED"
            @click="addQuestionDialog()"
            dark
            text
            v-bind="attrs"
            v-on="on"
            >Add Question</v-btn
          >
        </template>
        <v-card :key="componentKey">
          <v-card-title class="primary--text">New Question</v-card-title>
          <v-form ref="form" v-model="createQuestionValidation.valid">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col >
                    <span>Question title</span>
                          <quill-editor style="height: 140px; margin-bottom:30px;"
                          :options="editorOptions" v-model="editedQuestion.title"
                          ></quill-editor>

                  </v-col>
                </v-row>
                  <v-row>
                  <v-col cols="4" class="mt-4">
                    <v-select
                      v-model="editedQuestion.selectedNoOfOptions"
                      :items="noOfOptions"
                      label="No of options"
                      @input="getOptions"
                      :rules="createQuestionValidation.optionRules"
                      required
                      dense
                    ></v-select
                  ></v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedQuestion.resourceName"
                      label="Question Media"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col >
                    <v-menu
                      v-model="resourceMenu"
                      max-height="30rem"
                      offset-y
                      bottom
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="primary mt-2"
                          >Search source
                        </v-btn>
                      </template>
                      <ResourceSearch
                        @selectResource="OnSelected"
                      ></ResourceSearch>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length >= 2
                    "
                    @click="uniqueCheck(editedQuestion.options[0])"
                    v-model="editedQuestion.options[0].isAnswer"
                  ></v-checkbox>
                  <v-text-field
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length >= 2
                    "
                    label="Option 1"
                    v-model="editedQuestion.options[0].value"
                    outlined
                    required
                    dense
                    :rules="createQuestionValidation.nameRules"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length >= 2
                    "
                    @click="uniqueCheck(editedQuestion.options[1])"
                    v-model="editedQuestion.options[1].isAnswer"
                  ></v-checkbox>
                  <v-text-field
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length >= 2
                    "
                    label="Option 2"
                    v-model="editedQuestion.options[1].value"
                    outlined
                    required
                    dense
                    :rules="createQuestionValidation.nameRules"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length > 2
                    "
                    @click="uniqueCheck(editedQuestion.options[2])"
                    v-model="editedQuestion.options[2].isAnswer"
                  ></v-checkbox>
                  <v-text-field
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length > 2
                    "
                    label="Option 3"
                    v-model="editedQuestion.options[2].value"
                    outlined
                    required
                    dense
                    :rules="createQuestionValidation.nameRules"
                  ></v-text-field>
                </v-row>
                <v-row>
                  <v-checkbox
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length > 3
                    "
                    @click="uniqueCheck(editedQuestion.options[3])"
                    v-model="editedQuestion.options[3].isAnswer"
                  ></v-checkbox>
                  <v-text-field
                    v-if="
                      editedQuestion.options == undefined
                        ? false
                        : editedQuestion.options.length > 3
                    "
                    label="Option 4"
                    v-model="editedQuestion.options[3].value"
                    outlined
                    required
                    dense
                    :rules="createQuestionValidation.nameRules"
                  ></v-text-field>
                </v-row>
                <!-- <v-row>
                  <v-col>
                    <v-text-field
                      v-model="editedQuestion.resourceName"
                      label="Question Media"
                      clearable
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-menu
                      v-model="resourceMenu"
                      max-height="350px"
                      offset-y
                      top
                      offset-x
                      :close-on-content-click="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" color="primary"
                          >Search source
                        </v-btn>
                      </template>
                      <ResourceSearch
                        @selectResource="OnSelected"
                      ></ResourceSearch>
                    </v-menu>
                  </v-col>
                </v-row> -->
                <v-row>
                  <v-col>
                    <span   >Detailed answer</span>
                          <quill-editor style="height: 140px; margin-bottom:30px;"
                          :options="editorOptions" v-model="editedQuestion.explaination"
                          ></quill-editor>
                    <!-- <v-textarea
                      v-model="editedQuestion.explaination"
                      label="Detailed answer"
                      required
                      auto-grow
                      outlined
                      dense
                      :rules="createQuestionValidation.explanationRules"
                    ></v-textarea> -->
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-alert
                v-if="alert && addAnother"
                :type="alertType"
                dense
                dismissible
                outlined
                width="350"
              >
                {{ addQuestionResponse }}
              </v-alert>
              <v-checkbox
                v-model="addAnother"
                label="Create another question"
              ></v-checkbox>
              <v-btn color="primary" text @click="close()"> Cancel </v-btn>
              <v-btn color="primary" text @click="saveQuestion()"> Save </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="deleteDialog" max-width="500px">
        <v-card>
          <v-card-title
            >Are you sure you want to delete this Question
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDeleteDailog()"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="deleteQuestion()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="imgDialog">
      <v-card>
        <v-img :src="questionMedia"></v-img>
      </v-card>
    </v-dialog>
    <v-list>
      <v-list-group
        v-for="question in filteredQuizQuestions"
        :key="question.questionNo"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content >
            <v-list-item-title style="font-size: 18px;" 
            v-html="formatQuestionTitle(question.questionNo,question.title)" >
            </v-list-item-title>
          </v-list-item-content>
        </template>

        <v-card flat>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                Options
              </v-list-item-title>

              <v-row>
                <div
                  class="ma-2 pa-2"
                  v-for="option in question.options"
                  :key="option.number"
                >
                  <span class="primary--text"> {{ option.number + ". " }}</span>
                  <span
                    style="font-size: 16px"
                    :class="`${
                      option.isAnswer ? 'success--text' : 'error--text'
                    }`"
                  >
                    {{ option.value }}</span
                  >
                </div>
              </v-row>
              <span style="font-size: 18px; font-weight: 500;" class="primary--text">Explanation: </span>
              <span class="mt-1" style="font-size: 18px;" v-html="question.explaination">
              </span>
            </v-list-item-content>
            <!-- <v-list-item-avatar v-if="question.media" size="200" tile>
              <v-img :src="question.media"></v-img>
            </v-list-item-avatar> -->
          </v-list-item>
          <v-card-actions>
            <v-tooltip bottom v-if="question.media">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="ml-2 mr-4"
                  @click="viewImage(question)"
                  color="success"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>View Media</span>
            </v-tooltip>
            <v-tooltip bottom v-if="quiz.status != STATUS_PUBLISHED">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="ml-2 mr-4"
                  @click="editQuestionDialog(question)"
                  color="primary"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom v-if="quiz.status != STATUS_PUBLISHED">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="error"
                  type="submit"
                  @click="deleteConfirmDialog(question)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
import {
  ADD_QUESTION,
  BASE_URL,
  DELETE_QUESTION,
  GET_QUIZ_BY_ID,
  UPDATE_QUESTION,
} from "@/common/apiEndpoints";
import { GET_TOKEN, GET_USER_TYPE,GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { SET_OVERLAY_VALUE,SET_PARAMS_VALUE } from "@/store/mutations";
import { getAxiosOptions,formatQuestionTitle } from "@/common/utility.js";
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import { SET_API_SUCCESS } from "@/store/mutations";
import ResourceSearch from "./ResourceSearch.vue";

export default {
  data() {
    return {
      editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
      addAnother: false,
      alertType: "success",
      componentKey: 0,
      imgDialog: false,
      questionMedia: "",
      addQuestionResponse: "saved successfully",
      alert: false,
      resourceMenu: false,
      quiz: {},
      noOfOptions: [2, 3, 4],
      showSearchField: false,
      // selectedNoOfOptions:2,
      searchText: "",
      filteredQuizQuestions: [],
      options: [],
      questionDialogTitle: "Add New Question",
      addDialog: false,
      deleteDialog: false,
      createQuestionValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        titleRules: [(v) => !!v || "Question Title is required"],
        optionRules: [(v) => !!v || "No Of Option is required"],
        explanationRules: [(v) => !!v || "Detailed Answer is required"],
      },
      editedQuestion: { options: [] },
      defaultItem: {},
      editedIndex: -1,
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
    };
  },
  components: { ResourceSearch },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      userType: `schoolModule/${GET_USER_TYPE}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    if(this.$route &&this.$route.params&& this.$route.params.quizId){
      this.getQuizBYQuizId();
      let quizParamsValue={}
      quizParamsValue.quizId=this.$route.params.quizId;
      this.setParams(quizParamsValue)
    }
    else{
      this.getQuizBYQuizId();
    }
    // this.filteredQuizQuestions = this.quiz.questions;
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    viewImage(question) {
      this.imgDialog = true;
      this.questionMedia = question.media;
    },
    getQuizBYQuizId() {
      const url = GET_QUIZ_BY_ID;
      const payload = {};
      payload.quizId = this.$route.params.quizId||this.paramsValue.quizId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.quiz = res.data;
          this.filteredQuizQuestions = this.quiz.questions;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    addQuestionDialog() {
      this.editedQuestion = Object.assign({}, this.defaultItem);
      this.addDialog = true;
    },
    deleteConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedQuestion = Object.assign({}, item);
      this.deleteDialog = true;
    },
    deleteQuestion() {
      let url = DELETE_QUESTION;
      this.setOverlayValue(true);
      if (this.editedIndex > -1) {
        let payload = {};
        payload.quizId = this.quiz.quizId;
        payload.questionNo = this.editedQuestion.questionNo;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.quiz = res.data;
            this.filteredQuizQuestions = this.quiz.questions;
            this.editedIndex = -1;
            this.closeDeleteDailog();
            this.setOverlayValue(false);
            this.setApiSuccess("Question deleted successfully");
          })
          .catch((error) => {
            this.closeDeleteDailog();
            this.setOverlayValue(false);
          });
      }
    },
    closeDeleteDailog() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.editedQuestion = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editQuestionDialog(item) {
      this.editedIndex = 0;
      this.editedQuestion = Object.assign({}, item);
      this.editedQuestion.selectedNoOfOptions =
        this.editedQuestion.options.length;
      if(item.resource){
      this.editedQuestion.resourceName = item.resource.resourceName;
      this.editedQuestion.resourceId = item.resource.resourceId;
      }
      this.addDialog = true;
    },
    
    saveQuestion() {
      this.validate();
      if (this.createQuestionValidation.valid && this.editedQuestion.explaination && this.editedQuestion.title) {
        let payload = {};
        // this.getOptions();
        if (this.editedIndex > -1) {
          let url = UPDATE_QUESTION;
          this.setOverlayValue(true);
          payload.quizId = this.quiz.quizId;
          payload.title = this.editedQuestion.title;
          payload.questionNo = this.editedQuestion.questionNo;
          payload.options = this.editedQuestion.options;
          payload.explaination = this.editedQuestion.explaination;
          if(this.editedQuestion.resourceName){
          payload.resourceId = this.editedQuestion.resourceId;
          payload.resourceName = this.editedQuestion.resourceName;
          }
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.quiz = res.data;
              this.setOverlayValue(false);
              if (!this.addAnother) {
                this.setApiSuccess("Question updated successfully");
              }
              this.filteredQuizQuestions = this.quiz.questions;
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 1500);
              this.createQuestionValidation.valid = false;
              this.editedIndex = -1;
              if (this.addAnother == true) {
                this.$nextTick(() => {
                  this.editedQuestion = Object.assign({}, this.defaultItem);
                });
              } else {
                this.close();
              }
            })
            .catch((error) => {
              // this.alert = true;
              // this.alertType = 'error';
              // this.addQuestionResponse = error.response.data.errorMessage;
              //   setTimeout(() => {
              //   this.alert = false;
              // }, 1500);
              this.setOverlayValue(false);
            });
        } else {
          let url = ADD_QUESTION;
          this.setOverlayValue(true);
          payload.quizId = this.quiz.quizId;
          payload.title = this.editedQuestion.title;
          payload.options = this.editedQuestion.options;
          payload.explaination = this.editedQuestion.explaination;
          if(this.editedQuestion.resourceName){
          payload.resourceName = this.editedQuestion.resourceName;
          payload.source = this.editedQuestion.source;
          payload.resourceId = this.editedQuestion.resourceId;
          }

          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.quiz = res.data;
              let questionNo =
              this.quiz.questions[this.quiz.questions.length - 1].questionNo;
              this.setOverlayValue(false);
              if (!this.addAnother) {
                this.setApiSuccess("Question created successfully");
              }
              this.filteredQuizQuestions = this.quiz.questions;
              this.alert = true;
              setTimeout(() => {
                this.alert = false;
              }, 2500);
              this.createQuestionValidation.valid = false;
              this.editedIndex = -1;
              if (this.addAnother == true) {
                this.$nextTick(() => {
                  this.editedQuestion = Object.assign({}, this.defaultItem);
                });
              } else {
                this.close();
              }
            })
            .catch((error) => {
              console.log(error);
              this.setOverlayValue(false);
            });
        }
      }
    },
    getOptions() {
      if (this.editedQuestion.options == undefined) {
        this.editedQuestion.options = [];
      }
      if (
        this.editedQuestion.options.length <
        this.editedQuestion.selectedNoOfOptions
      ) {
        for (
          let i = 0;
          i <
          this.editedQuestion.selectedNoOfOptions -
            this.editedQuestion.options.length;

        ) {
          let number = this.editedQuestion.options.length + 1;
          let option = { number: number, value: "", isAnswer: false };
          this.editedQuestion.options.push(option);
        }
      } else {
        for (
          let i = 0;
          i <
          this.editedQuestion.options.length -
            this.editedQuestion.selectedNoOfOptions;

        ) {
          this.editedQuestion.options.pop();
        }
      }
    },
    uniqueCheck(option) {
      for (let i = 0; i < this.editedQuestion.options.length; i++) {
        if (option.number != this.editedQuestion.options[i].number) {
          this.editedQuestion.options[i].isAnswer = false;
        }
      }
      this.componentKey += 1;
    },
    close() {
      this.addDialog = false;
      this.$nextTick(() => {
        // if(this.alertType != 'error'){
        this.editedQuestion = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        // }
      });
    },
    searchQuizQuestion() {
      if (this.searchText && this.searchText != "") {
        this.filteredQuizQuestions = this.quiz.questions.filter((question) => {
          return question.title
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredQuizQuestions = this.quiz.questions;
      }
    },
    OnSelected(resource) {
      this.editedQuestion.resourceName = resource.name;
      this.editedQuestion.resourceId = resource.resourceId;
      this.editedQuestion.source = resource.source;
      this.resourceMenu = false;
    },
    formatQuestionTitle(questionNo,questionTitle){
      return formatQuestionTitle(questionNo,questionTitle);
    },
  },
};
</script>
