<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Courses</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-if="showSearchField"
          v-model="searchText"
          @input="searchCourse"
          clearable
        ></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="showSearchField = !showSearchField"
              >mdi-magnify</v-icon
            >
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" title="Filter">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox
              v-model="courseFilter"
              label="All"
              color="primary"
              value="ALL"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseStatusFilter"
              label="Draft"
              color="primary"
              value="DRAFT"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseStatusFilter"
              label="Published"
              color="primary"
              value="PUBLISHED"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseTypeFilter"
              label="Public"
              color="primary"
              value="PUBLIC"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              v-model="courseTypeFilter"
              label="Private"
              color="primary"
              value="PRIVATE"
              @click="getAllCourses()"
              hide-details
            ></v-checkbox>
          </v-list-item>
          <!-- <v-list-item>
            <v-btn color="primary" text @click="getAllCourses()"> Apply </v-btn>
          </v-list-item> -->
        </v-list>
      </v-menu>

      <v-dialog persistent v-model="dialog" width="1000" :retain-focus="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 16px">
            New Course
          </v-btn>
        </template>
        <v-card>
          <!-- <v-tabs center-active>
            <v-tab>{{ courseDialogTitle }}</v-tab>
            <v-tab-item key="1">
              <v-card> -->
          <v-card-title class="primary--text">New Course</v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="createCourseValidation.valid">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedCourse.name"
                      label="Course Name"
                      :rules="createCourseValidation.coursenameRules"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <span>Description</span>
                    <quill-editor
                    v-model="editedCourse.description"
                    @input="validateCourseDesc()"
                   style="height: 70%; margin-bottom:40px;"
                    :options="editorOptions"
                  ></quill-editor>
                    <span class="red--text ">{{ courseDescError }}</span>
                  </v-col>
                  <v-col cols="2">
                    <span class="ma-2 primary--text text-h6 ">Chapters:</span>
                  </v-col>
                  <v-col cols="1">
                    <v-text-field
                      v-model="chapterCount"
                      dense
                      @input="addChapter"
                      :rules="createCourseValidation.chaptersRules"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="3"
                    v-for="chapter in editedCourse.chapters"
                    :key="chapter.chapterNo"
                  >
                    <span>{{ chapter.name }} </span>
                    <v-text-field
                      v-model="chapter.lessonCount"
                      dense
                      label="Lessons"
                      @input="addLesson(chapter)"
                      :rules="createCourseValidation.lessonsRules"
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- <span class="ml-2 mt-4 pa-0  primary--text text-h6">Select Course Type:</span> -->

                <v-row>
                  <v-col cols="3">
                    <span class="ml-2 ma-0 pa-0 primary--text text-h6"
                      >Select Course Type:</span
                    >
                  </v-col>
                  <v-col cols="4">
                    <v-radio-group
                      row
                      :disabled="editedCourse.status == STATUS_PUBLISHED"
                      class="ma-1 pa-1 ml-n6"
                      v-model="editedCourse.courseType"
                    >
                      <v-radio
                        label="Public"
                        color="primary"
                        value="PUBLIC"
                      ></v-radio>
                      <v-radio
                        label="Private"
                        color="primary"
                        value="PRIVATE"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  <v-col cols="5">
                    <v-combobox
                      v-model="editedCourse.grades"
                      :items="gradesArray"
                      label="Grades"
                      multiple
                      outlined
                      dense
                      :rules="createCourseValidation.multiValueRules"
                      class="ml-n4"
                      deletable-chips
                      chips
                      item-text="desc"
                      item-value="id"
                    >
                      <template v-slot:prepend-item>
                        <v-list-item @click="selectAll()">
                          <v-list-item-action>
                            <v-icon>{{ icon() }}</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider />
                      </template>
                    </v-combobox>
                  </v-col>
                </v-row>
                <v-row
                  class="ma-0 pa-0"
                  v-if="editedCourse.courseType == 'PRIVATE'"
                >
                  <v-col cols="2" class="pt-0 ma-0">
                    <span class="primary--text text-h6">For UK:</span>
                  </v-col>
                  <v-col cols="1" class="ma-0 pt-1 ml-n16">
                    <v-switch
                      v-model="editedCourse.isForUK"
                      class="ma-0 pa-0"
                      @click="buyableFalse()"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="6" v-if="editedCourse.courseType == 'PRIVATE'">
                    <v-checkbox
                      v-model="editedCourse.buyable"
                      :disabled="editedCourse.isForUK == true"
                      class="ml-n3 ma-1 pa-2"
                      label="Do you want to make the course buyable?"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="3" v-if="editedCourse.courseType == 'PRIVATE'">
                    <v-text-field
                      v-model="editedCourse.noOfClasses"
                      :rules="createCourseValidation.noOfClassesRules"
                      dense
                      class="ma-1 pa-1"
                      type="number"
                      label="No of Classes"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="2"
                    v-if="
                      editedCourse.courseType == 'PRIVATE' &&
                      editedCourse.buyable
                    "
                  >
                    <v-text-field
                      v-model="editedCourse.price"
                      :rules="createCourseValidation.priceRules"
                      dense
                      class="ma-1 pa-1"
                      v-if="editedCourse.isForUK == false"
                      type="number"
                      label="Price"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close()">Cancel </v-btn>
                  <v-btn color="primary" text @click="saveCourse()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card-text>
          <!-- </v-card>
            </v-tab-item>
          </v-tabs> -->
        </v-card>
      </v-dialog>

      <v-dialog persistent v-model="publishConfirmDialog" max-width="500px">
        <v-card>
          <v-card-title
            >Are you sure you want to publish
            {{ editedCourse.name }}</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closePublishDailog"
              >Cancel</v-btn
            >
            <v-btn color="primary" text @click="publishCourse">Yes</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-expansion-panels multiple class="courseExp" v-model="panel">
      <v-expansion-panel
        class="mt-2"
        v-for="course in filteredCourses"
        :key="course.courseId"
        :ref="panelRef(course.courseId)"
      >
        <v-expansion-panel-header>
          <template v-slot:actions>
            <v-icon large color="#F55B49"> $expand </v-icon>
          </template>
          <CourseHeader
            v-bind:course="course"
            @onPublishCourse="onChange($event)"
          />
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <Chapters
            v-bind:courseId="course.courseId"
            @updateCourse="onChange($event)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import {
  GET_ALL_COURSES,
  BASE_URL,
  CREATE_COURSE,
  PUBLISH_COURSE,
  ADD_COURSE_THUMBNAIL,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import Chapters from "@/components/Chapters.vue";
import {
  GET_TOKEN,
  GET_USER_TYPE,
  GET_COURSE_EXPANSION,
} from "@/store/getters";
import {
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
  grades,
} from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { formatDate, getAxiosOptions } from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_COURSE_EXPANSION_PANEL,
} from "@/store/mutations";
import CourseHeader from "./CourseHeader.vue";

export default {
  data() {
    return {
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      courses: [],
      courseStatusFilter: [],
      courseTypeFilter: [],
      courseFilter: [],
      menu: false,
      showSearchField: false,
      searchText: "",
      filteredCourses: [],
      dialog: false,
      publishConfirmDialog: false,
      editedCourse: {
        chapters: [],
        grades: [],
        buyable: false,
        isForUK: false,
      },
      gradesArray: grades,

      defaultItem: {
        name: "",
        courseType: "",
        grades: [],
        buyable: false,
        isForUK: false,
      },
      courseDescError: "",
      createCourseValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        multiValueRules: [
          (v) => !!v.length || "Please select at least one grade",
        ],
        coursenameRules: [(v) => !!v || "Course Name is required"],
        numberRules: [(v) => !!v || v > 0 || "This is required"],
        descriptionRules: [
          (v) => !!v || "Description is required",
          (v) => /.{50,}/.test(v) || "Description must be minimum 50 character",
          (v) =>
            (v && v.length <= 500) ||
            "Description must not be above 500 character",
        ],
        priceRules:[
            (v) => !!v || "This is required",
            (v) => Number(1) <= v || "Price must be greater than 0",
        ],
        noOfClassesRules:[
            (v) => !!v || "This is required",
            (v) => Number(1) <= v || "No of classes must be greater than 0",
        ],
        chaptersRules: [
            (v) => !!v || "This is required",
            (v) => Number(1) <= v || "Chapters must be greater than 0",
        ],
        lessonsRules: [
            (v) => !!v || "This is required",
            (v) => Number(1) <= v || "Lessons must be greater than 0",
        ],
      },
      file: "",
      preview: "",
      showUploadBtns: false,
      chapterCount: 0,
      panel: [0],
      editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
    };
  },
  components: { Chapters, CourseHeader},
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      courseExpansion: `schoolModule/${GET_COURSE_EXPANSION}`,
    }),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    publishConfirmDialog(val) {
      val || this.closePublishDailog();
    },
    panel(newValue, oldValue) {
      this.setCourseExpansion(newValue);
    },
  },
  mounted: function () {
  this.getAllCourses();
  this.filteredCourses = this.courses;
  this.panel = this.courseExpansion.coursePanel;
  setTimeout(() => {
    // Scroll the last active panel into view
    const lastActiveCourse = this.panel[this.panel.length - 1];
    if (lastActiveCourse !== undefined) {
      const courseId = this.courses[lastActiveCourse]?.courseId;
      if (courseId !== undefined) {
        const panelRef = this.$refs[this.panelRef(courseId)];
        if (panelRef !== undefined && panelRef[0] !== undefined) {
          panelRef[0].$el.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }
  }, 1000);
},


  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setCourseExpansion: `schoolModule/${SET_COURSE_EXPANSION_PANEL}`,
    }),
    panelRef(courseId) {
      return `panel_${courseId}`;
    },
    onChange() {
      console.log("INside courses for emit");
      this.getAllCourses();
    },
    buyableFalse() {
      this.editedCourse.buyable = false;
    },
    validateCourseDesc() {
      if (this.editedCourse.description.length == 0) {
        this.courseDescError = "Description is required";
      } else if (this.editedCourse.description.length < 50) {
        this.courseDescError = "Description must be minimum 50 character";
      } else if (this.editedCourse.description.length > 500) {
        this.courseDescError = "Description must not be above 500 character";
      } else {
        this.courseDescError = "";
      }
    },
    selectAllGrades() {
      return this.editedCourse.grades.length === this.gradesArray.length;
    },
    selectSomeGrades() {
      return this.editedCourse.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },

    getAllCourses() {
      this.menu = false;
      let url = GET_ALL_COURSES;
      this.setOverlayValue(true);
      if (!this.courseFilter.includes("ALL")) {
        if (
          !(
            this.courseStatusFilter.length == 0 ||
            this.courseStatusFilter.length == 2
          )
        ) {
          url = url + "?status=" + this.courseStatusFilter[0];
        }
        if (
          !(
            this.courseTypeFilter.length == 0 ||
            this.courseTypeFilter.length == 2
          )
        ) {
          if (url.indexOf("?") > 0) {
            url = url + "&courseType=" + this.courseTypeFilter[0];
          } else {
            url = url + "?courseType=" + this.courseTypeFilter[0];
          }
        }
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
          for (let i = 0; i < this.courses.length; i++) {
            const grade = this.courses[i].grades;
            grade.sort((a, b) => {
              if (Number(a) < Number(b)) return -1;
              if (Number(a) > Number(b)) return 1;
              return 0;
            });
          }
          this.filteredCourses = res.data.courses;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    close() {
      this.dialog = false;
      this.chapterCount = 0;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    publishCourseConfirm(course) {
      this.editedIndex = this.courses.indexOf(course);
      this.editedCourse = Object.assign({}, course);
      this.publishConfirmDialog = true;
    },
    closePublishDailog() {
      this.publishConfirmDialog = false;
      this.$nextTick(() => {
        this.editedCourse = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    trimEditedCourses() {
      this.editedCourse.name = this.editedCourse.name.trim();
    },
    saveCourse() {
      if (
        this.createCourseValidation.valid &&
        this.courseDescError.length == 0
      ) {
        this.trimEditedCourses();
        let payload = {};
        payload = this.editedCourse;
        if (this.editedCourse.courseType == "PUBLIC") {
          payload.buyable = false;
          payload.isForUK = false;
        } else if (
          this.editedCourse.courseType == "PRIVATE" &&
          this.editedCourse.isForUK
        ) {
          payload.buyable = false;
        }
        let url = CREATE_COURSE;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createCourseValidation.valid = false;
            this.close();
            this.getAllCourses();
            this.setOverlayValue(false);
            this.setApiSuccess("Course created successfully");
            // this.initialize();
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
            this.setOverlayValue(false);
          });
      }
    },
    formatDate(date) {
      return formatDate(date);
    },
    publishCourse(Course) {
      const url = PUBLISH_COURSE;
      let payload = {};
      payload.courseId = this.editedCourse.courseId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDailog();
          this.getAllCourses();
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
          error = error.errorMessage;
          this.closePublishDailog();
        });
    },

    searchCourse() {
      if (this.searchText) {
        this.filteredCourses = this.courses.filter((course) => {
          return course.name
            .toLowerCase()
            .includes(this.searchText.toLowerCase());
        });
      } else {
        this.filteredCourses = this.courses;
      }
    },
    chapterCountChange() {
      let chapters = [];
      for (var i = 0; i < this.editedCourse.chapterCount; i++) {
        let data = {};
        data.name = `Chapter ${i + 1}`;
        data.chapterNo = i + 1;
        chapters.push(data);
      }
      this.editedCourse.chapters = chapters;
    },
    lessonCountChange(chapter) {
      let lessons = [];
      for (var i = 0; i < chapter.lessonCount; i++) {
        let data = {};
        data.name = `Lesson ${i + 1}`;
        data.lessonNo = i + 1;
        lessons.push(data);
      }
      chapter.lessons = lessons;
    },
    addChapter() {
      var chapters = [];
      for (var i = 0; i < this.chapterCount; i++) {
        let newChapter = {
          lessons: [],
        };
        newChapter.name = `Chapter ${i + 1}`;
        newChapter.chapterNo = i + 1;
        chapters.push(newChapter);
      }

      this.editedCourse.chapters = chapters;
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.editedCourse.grades = [];
        } else {
          this.editedCourse.grades = this.gradesArray.slice().map((grd) => {
            return grd;
          });
        }
      });
    },

    removeChapter() {
      this.editedCourse.chapters.pop();
    },
    addLesson(chapter) {
      var lessons = [];
      for (var i = 0; i < chapter.lessonCount; i++) {
        let newLesson = {};
        newLesson.name = `Lesson ${i + 1}`;
        newLesson.lessonNo = i + 1;
        lessons.push(newLesson);
      }
      chapter.lessons = lessons;
    },

    addThumbnail() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      if (this.file) {
        this.preview = URL.createObjectURL(e.target.files[0]);
      } else {
        this.preview = "";
      }
      this.showUploadBtns = true;
    },
    uploadThumbnail(course) {
      let payload = {};
      payload.file = this.file;
      payload.courseId = course.courseId;
      let url = ADD_COURSE_THUMBNAIL;
      this.setOverlayValue(true);
      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      axios
        .post(url, payload, options)
        .then((res) => {
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
          this.setOverlayValue(false);
          this.setApiSuccess("Thumbnail updated successfully");
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    cancelUpload() {
      this.showUploadBtns = false;
      this.file = "";
      this.preview = "";
    },
  },
};
</script>
