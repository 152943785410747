<template>
  <div class="mt-4">
    <!-- <v-card class="pa-2 ma-4" height="580">
    <v-row>
        <v-toolbar dense flat dark color="primary">
            <v-toolbar-title style="font-weight: 500; font-size: 16px; font-size: 24px;">Profile</v-toolbar-title>
        </v-toolbar>
    </v-row> -->

    <v-card class="ma-4" style="border-radius: 10px">
      <v-toolbar dark color="primary" elevation="2">
        <v-toolbar-title
          style="font-weight: 500; font-size: 16px; font-size: 24px"
          >Profile</v-toolbar-title
        >
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid>
        <v-row class="ma-1 pa-1">
          <!--<v-card class="pa-2 ma-2" width="1200">-->
          <v-card-text>
            <v-row>
              <v-col cols="4" class="ma-1">
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-if="myProfile.profilePhoto"
                >
                  <img :src="myProfile.profilePhoto" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_STUDENT &&
                    !myProfile.profilePhoto
                  "
                >
                  <img
                    v-if="myProfile.student.gender == 'MALE'"
                    src="../assets/Male Avatar.png"
                    alt="John"
                  />
                  <img v-else src="../assets/Female Avatar.png" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_TEACHER &&
                    !myProfile.profilePhoto
                  "
                >
                  <img
                    v-if="myProfile.teacher.gender == 'MALE'"
                    src="../assets/Male Avatar.png"
                  />
                  <img v-else src="../assets/Female Avatar.png" />
                </v-list-item-avatar>
                <v-list-item-avatar size="250" class="ma-8" v-else>
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar>
                <!-- <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_CONTENT_MANAGER &&
                    !myProfile.profilePhoto
                  "
                >
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_RESOURCE_MANAGER &&
                    !myProfile.profilePhoto
                  "
                >
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar>
                <v-list-item-avatar
                  size="250"
                  class="ma-8"
                  v-else-if="
                    myProfile.userType == USER_TYPE_INSTITUTE &&
                    !myProfile.profilePhoto
                  "
                >
                  <img src="../assets/Male Avatar.png" />
                </v-list-item-avatar> -->
                <v-card-actions class="mx-8" width="216%">
                  <v-btn
                    v-if="myProfile.profilePhoto"
                    style="font-weight: 600; font-size: 16px"
                    color="primary"
                    @click="changeProfile()"
                    >Change Profile Photo</v-btn
                  >
                  <v-btn
                    v-else
                    color="primary"
                    style="font-weight: 600; font-size: 16px"
                    @click="changeProfile()"
                    >Upload Profile Photo</v-btn
                  >
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    @change="onFileChanged"
                  />
                </v-card-actions>
                <v-card-actions
                  class="mx-12"
                  width="216%"
                  v-if="showUploadBtns"
                >
                  <v-row justify="center">
                    <v-btn color="primary" icon>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" @click="uploadProfilePhoto()"
                            >mdi-upload</v-icon
                          >
                        </template>
                        <span>Upload</span>
                      </v-tooltip>
                    </v-btn>
                    <v-btn color="primary" icon>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" @click="cancelUpload()"
                            >mdi-cancel</v-icon
                          >
                        </template>
                        <span>Cancel</span>
                      </v-tooltip>
                    </v-btn>
                  </v-row>
                </v-card-actions>
                <!-- <v-card-actions class="ma-2" v-if="myProfile.userType == USER_TYPE_TEACHER">
              <v-row class="ma-4">
              <v-btn v-if="!openScheduleTable" color="primary" @click="viewSchedule()">View Schedule</v-btn>
              <v-btn v-else color="primary" @click="viewProfile()">View Profile</v-btn>
              </v-row>
            </v-card-actions> -->
              </v-col>
              <v-col cols="7" class="ma-1">
                <v-tabs
                  v-model="tab"
                  v-if="myProfile.userType == USER_TYPE_TEACHER"
                >
                  <v-tab>Profile</v-tab>
                  <v-tab>Schedule</v-tab>
                </v-tabs>
                <v-tabs-items
                  v-model="tab"
                  v-if="myProfile.userType == USER_TYPE_TEACHER"
                >
                  <v-tab-item v-if="myProfile.userType == USER_TYPE_TEACHER">
                    <v-simple-table class="mt-4 mytable">
                      <template v-slot:default>
                        <tbody>
                          <tr style="background-color: #edf5fd">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #447ef1">
                                mdi-circle
                              </v-icon>
                              Name
                            </th>
                            <td
                              style="
                                color: #447ef1;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{
                                myProfile.firstName + " " + myProfile.lastName
                              }}
                            </td>
                          </tr>
                          <tr style="background-color: #e7fce9">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #44be75">
                                mdi-circle
                              </v-icon>
                              Mobile No.
                            </th>
                            <td
                              style="
                                color: #44be75;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{ myProfile.mobileNo }}
                            </td>
                          </tr>
                          <tr style="background-color: #fff0e1">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #df9756">
                                mdi-circle
                              </v-icon>
                              Email
                            </th>
                            <td
                              style="
                                color: #df9756;
                                font-weight: 500;
                                font-size: 16px;
                              "
                              v-if="myProfile.email == null"
                            >
                              --
                            </td>
                            <td
                              v-else
                              style="
                                color: #df9756;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{ myProfile.email }}
                            </td>
                          </tr>
                          <tr style="background-color: #edf5fd">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #447ef1">
                                mdi-circle
                              </v-icon>
                              Gender
                            </th>
                            <td
                              style="
                                color: #447ef1;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{
                                myProfile.teacher.gender[0] +
                                myProfile.teacher.gender.slice(1).toLowerCase()
                              }}
                            </td>
                          </tr>
                          <tr style="background-color: #e7fce9">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #44be75">
                                mdi-circle
                              </v-icon>
                              Class Link
                            </th>
                            <td
                              style="
                                color: #44be75;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{ myProfile.teacher.classLink }}
                            </td>
                          </tr>
                          <tr style="background-color: #fff0e1">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #df9756">
                                mdi-circle
                              </v-icon>
                              Date of Birth
                            </th>
                            <td v-if="myProfile.email == null"></td>
                            <td
                              v-else
                              style="
                                color: #df9756;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{ formatDate(myProfile.teacher.dob) }}
                            </td>
                          </tr>
                          <tr style="background-color: #edf5fd">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #447ef1">
                                mdi-circle
                              </v-icon>
                              Experience
                            </th>
                            <td
                              style="
                                color: #447ef1;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{ myProfile.teacher.experience }} years
                            </td>
                          </tr>
                          <tr style="background-color: #e7fce9">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #44be75">
                                mdi-circle
                              </v-icon>
                              Institue Name
                            </th>
                            <td
                              style="
                                color: #44be75;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{
                                myProfile.teacher.highestQualification.institute
                              }}
                            </td>
                          </tr>
                          <tr style="background-color: #fff0e1">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #df9756">
                                mdi-circle
                              </v-icon>
                              Passing Year
                            </th>
                            <td
                              style="
                                color: #df9756;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{
                                myProfile.teacher.highestQualification
                                  .passingYear
                              }}
                            </td>
                          </tr>
                          <tr style="background-color: #edf5fd">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #447ef1">
                                mdi-circle
                              </v-icon>
                              Qualification Name
                            </th>
                            <td
                              style="
                                color: #447ef1;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              {{ myProfile.teacher.highestQualification.qName }}
                            </td>
                          </tr>
                          <tr style="background-color: #e7fce9">
                            <th style="font-size: 16px; font-weight: 400">
                              <v-icon class="ml-1" small style="color: #44be75">
                                mdi-circle
                              </v-icon>
                              Preferred Grades
                            </th>
                            <td
                              style="
                                color: #44be75;
                                font-weight: 500;
                                font-size: 16px;
                              "
                            >
                              <span
                                class="mr-1"
                                v-for="(preferredGrade, index) in myProfile
                                  .teacher.preferredGrades"
                                :key="index"
                                >{{ preferredGrade }}
                                {{
                                  index !=
                                  myProfile.teacher.preferredGrades.length - 1
                                    ? ","
                                    : ""
                                }}</span
                              >
                            </td>
                          </tr>
                          <!-- <tr style="background-color: #fff0e1">
                            <th style="font-size:16px; font-weight: 400;">
                              <v-icon
                                class="ml-1"
                                x-small
                                style="color: #df9756"
                              >
                                mdi-circle
                              </v-icon>
                              Ratings
                            </th>
                            <td style="color: #df9756">
                              {{ myProfile.teacher.ratings }}
                            </td>
                          </tr> -->
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-col v-if="myProfile.userType == USER_TYPE_TEACHER">
                      <v-row justify="end" class="mr-1">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon
                              v-on="on"
                              color="primary"
                              @click="openUpdateClassLinkDialog()"
                              >mdi-pencil</v-icon
                            >
                          </template>
                          <span>Edit Profile</span>
                        </v-tooltip>
                      </v-row>
                    </v-col>
                  </v-tab-item>
                  <v-tab-item v-if="myProfile.userType == USER_TYPE_TEACHER">
                    <v-simple-table class="scheduleTable">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th
                              class="text-left"
                              style="font-weight: 500; font-size: 16px"
                            >
                              Days
                            </th>
                            <th
                              class="text-center"
                              style="font-weight: 500; font-size: 16px"
                            >
                              Timings
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="schedule in myProfile.teacher.schedule"
                            :key="schedule.day"
                          >
                            <td
                              class="text-left"
                              style="
                                font-weight: 500;
                                font-size: 14px;
                                color: black;
                              "
                            >
                              {{ schedule.day }}
                            </td>
                            <td class="text-center">
                              <v-chip
                                v-for="timing in schedule.timings"
                                :key="timing.openTime"
                                color="primary"
                                label
                                outlined
                                class="ma-1"
                                >{{ timing.openTime }} to
                                {{ timing.closeTime }}</v-chip
                              >
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-tab-item>
                </v-tabs-items>
                <v-simple-table v-else class="ma-1 mytable">
                  <template v-slot:default>
                    <tbody>
                      <tr style="background-color: #edf5fd">
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #447ef1">
                            mdi-circle
                          </v-icon>
                          Name
                        </th>

                        <td
                          style="
                            color: #447ef1;
                            font-weight: 500;
                            font-size: 16px;
                          "
                        >
                          {{ myProfile.firstName + " " + myProfile.lastName }}
                        </td>
                      </tr>

                      <tr
                        style="background-color: #ffedcc"
                        v-if="myProfile.userType == USER_TYPE_STUDENT"
                      >
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #df9756">
                            mdi-circle
                          </v-icon>
                          Parent/Guardian Name
                        </th>
                        <td
                          style="
                            color: #df9756;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          v-if="myProfile.student.parentName == null"
                        >
                          --
                        </td>
                        <td
                          style="
                            color: #df9756;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          v-else
                        >
                          {{ myProfile.student.parentName }}
                        </td>
                      </tr>

                      <tr style="background-color: #e7fce9">
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #44be75">
                            mdi-circle
                          </v-icon>
                          Mobile No.
                        </th>
                        <td
                          style="
                            color: #44be75;
                            font-weight: 500;
                            font-size: 16px;
                          "
                        >
                          {{ myProfile.mobileNo }}
                        </td>
                      </tr>
                      <tr style="background-color: #fff0e1">
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #df9756">
                            mdi-circle
                          </v-icon>
                          Email
                        </th>
                        <td
                          style="
                            color: #df9756;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          v-if="myProfile.email == null"
                        >
                          --
                        </td>
                        <td
                          style="
                            color: #df9756;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          v-else
                        >
                          {{ myProfile.email }}
                        </td>
                      </tr>
                      <tr
                        style="background-color: #edf5fd"
                        v-if="myProfile.userType == USER_TYPE_STUDENT"
                      >
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #447ef1">
                            mdi-circle
                          </v-icon>
                          Gender
                        </th>
                        <td
                          style="
                            color: #447ef1;
                            font-weight: 500;
                            font-size: 16px;
                          "
                          v-if="myProfile.userType == USER_TYPE_STUDENT"
                        >
                          {{
                            myProfile.student.gender[0] +
                            myProfile.student.gender.slice(1).toLowerCase()
                          }}
                        </td>
                      </tr>
                      <tr
                        style="background-color: #e7fce9"
                        v-if="myProfile.userType == USER_TYPE_STUDENT"
                      >
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #44be75">
                            mdi-circle
                          </v-icon>
                          Age
                        </th>
                        <td
                          style="
                            color: #44be75;
                            font-weight: 500;
                            font-size: 16px;
                          "
                        >
                          {{ myProfile.student.age }}
                        </td>
                      </tr>
                      <tr
                        style="background-color: #ffedcc"
                        v-if="myProfile.userType == USER_TYPE_STUDENT"
                      >
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #df9756">
                            mdi-circle
                          </v-icon>
                          School Name
                        </th>
                        <td
                          style="
                            color: #df9756;
                            font-weight: 500;
                            font-size: 16px;
                          "
                        >
                          {{ myProfile.student.schoolName }}
                        </td>
                      </tr>
                      <tr
                        style="background-color: #e6e6ff"
                        v-if="myProfile.userType == USER_TYPE_STUDENT"
                      >
                        <th style="font-size: 16px; font-weight: 400">
                          <v-icon class="ml-1" small style="color: #447ef1">
                            mdi-circle
                          </v-icon>
                          Grade
                        </th>
                        <td
                          style="
                            color: #447ef1;
                            font-weight: 500;
                            font-size: 16px;
                          "
                        >
                          {{ myProfile.student.grade }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <v-col v-if="myProfile.userType == USER_TYPE_STUDENT">
                  <v-row justify="end" class="mr-1">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" color="primary" @click="editProfile()"
                          >mdi-pencil</v-icon
                        >
                      </template>
                      <span>Edit Profile</span>
                    </v-tooltip>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-card-text>
        </v-row>
      </v-container>
    </v-card>
    <v-dialog v-model="editProfileDialog" max-width="1000">
      <v-card v-if="editedProfile.student != null">
        <v-card-title class="primary--text">Edit Profile</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="profileValidation.valid">
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.student.age"
                    :rules="profileValidation.ageRules"
                    type="number"
                    required
                    label="Age"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <span>Select Your Gender</span>
                  <v-row>
                    <v-col class="pt-0">
                      <v-radio-group
                        row
                        v-model="editedProfile.student.gender"
                        column
                      >
                        <v-radio
                          label="Male"
                          color="primary"
                          value="MALE"
                        ></v-radio>
                        <v-radio
                          label="Female"
                          color="primary"
                          value="FEMALE"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.student.schoolName"
                    label="School Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.student.parentName"
                    :rules="profileValidation.nameRules"
                    label="Parent/Guardian Name"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    @input="getAddressDetails()"
                    v-model="editedProfile.address.pincode"
                    :rules="profileValidation.pinRules"
                    type="number"
                    label="Pin Number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    v-model="editedProfile.address.state"
                    label="State"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close()"> Cancel </v-btn>
          <v-btn color="primary" text @click="updateProfile()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="classLinkDialog" width="40%">
      <v-card>
      <v-toolbar dark dense color="primary">
        <v-toolbar-title style="font-weight: 500; font-size: 24px"
          >Edit Class Link</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeUpdateClassLinkDialog()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
          <v-form ref="form" v-model="profileValidation.valid">
        <v-text-field
          type="string"
          v-model="classLink"
          label="Enter class Link"
          :rules="profileValidation.classLinkRule"
          hint="https://meet.google.com/zgk-xmue-vdr"
        ></v-text-field>
          </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2" color="primary" text @click="updateClassLink()"
          >save
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  BASE_URL,
  UPLOAD_FILE,
  UPDATE_PROFILE,
  GET_ADDRESS_DETAILS,
  UPDATE_CLASSLINK,
} from "@/common/apiEndpoints";
import { formatDate, getAxiosOptions } from "@/common/utility";
import { FETCH_USER_PROFILE } from "@/store/actions";
import { GET_TOKEN, GET_USER_PROFILE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_INSTITUTE,
} from "@/common/constants";
import axios from "axios";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "UserProfile",
  data: () => ({
    myProfile: {},
    tab: null,
    showUploadBtns: false,
    file: "",
    openScheduleTable: false,
    editProfileDialog: false,
    classLink: " ",
    classLinkDialog: false,
    editedProfile: {},
    profileValidation: {
      valid: false,
      nameRules: [
        (v) =>
          /^[A-Za-z\s]+$/.test(v) || "Please enter alphabetic characters only",
      ],
      ageRules: [
        (v) => /^\d+$/.test(v) || "Age must be a number",
        (v) => Number(2) <= v || "Age must be greater than 2",
        (v) => Number(99) >= v || "Age must be less than 99",
      ],
      pinRules: [
        (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
      ],
      classLinkRule: [
        (v) => !!v || "This is required",
      ]
    },
    USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
    USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
    USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    USER_TYPE_TEACHER: USER_TYPE_TEACHER,
    USER_TYPE_INSTITUTE: USER_TYPE_INSTITUTE,
  }),
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      profile: `schoolModule/${GET_USER_PROFILE}`,
    }),
  },
  watch: {
    profile: {
      handler(profile) {
        if (profile) {
          this.myProfile = Object.assign({}, this.profile);
          for (let i = 0; i < this.myProfile.length; i++) {
            const teacher = this.myProfile.teacher[i];
            for (let j = 0; j < teacher.length; j++) {
              const preferredGrade = teacher.preferredGrades[j];
              preferredGrade.sort();
            }
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    ...mapActions({
      fetchProfile: `schoolModule/${FETCH_USER_PROFILE}`,
    }),
    formatDate(date) {
      return formatDate(date);
    },
    // viewSchedule() {
    //   this.openScheduleTable = true;
    // },
    // viewProfile() {
    //   this.openScheduleTable = false;
    // },
    changeProfile() {
      // After obtaining the focus when closing the FilePicker, return the button state to normal
      window.addEventListener("focus", () => {}, {
        once: true,
      });

      // Trigger click on the FileInput
      this.$refs.uploader.click();
    },
    validate() {
      this.$refs.form.validate();
    },
    getAddressDetails() {
      let url = GET_ADDRESS_DETAILS;
      if (this.editedProfile.address.pincode.length == 6) {
        url += this.editedProfile.address.pincode;
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            if (res.data.state == null) {
              this.editedProfile.address.state = "";
            } else {
              this.editedProfile.address.state = res.data.state;
            }
          })
          .catch((error) => {
            error = error.errorMessage;
          });
      }
    },
    editProfile() {
      this.editedProfile = Object.assign({}, this.myProfile);
      this.editedProfile.student = Object.assign({}, this.myProfile.student);
      this.editedProfile.address = Object.assign({}, this.myProfile.address);
      this.editProfileDialog = true;
    },
    close() {
      this.editProfileDialog = false;
      // this.editedProfile = Object.assign({}, this.myProfile);
      this.editedProfile = {};
    },
    openUpdateClassLinkDialog() {
      this.classLinkDialog = true;
      this.classLink = this.myProfile.teacherclassLink;
    },
    closeUpdateClassLinkDialog() {
      this.classLinkDialog = false;
      this.classLink = " ";
    },
    updateProfile() {
      this.validate();
      if (this.profileValidation.valid) {
        let payload = {};
        payload.gender = this.editedProfile.student.gender;
        payload.age = this.editedProfile.student.age;
        payload.schoolName = this.editedProfile.student.schoolName.trim();
        payload.parentName = this.editedProfile.student.parentName.trim();
        payload.pincode = this.editedProfile.address.pincode;
        payload.state = this.editedProfile.address.state.trim();
        let url = UPDATE_PROFILE;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.profileValidation.valid = false;
            this.setApiSuccess("Profile updated successfully");
            this.fetchProfile().then(() => {
              this.close();
              this.setOverlayValue(false);
            });
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
    updateClassLink() {
      this.validate();
      if (this.profileValidation.valid) {
      let url = UPDATE_CLASSLINK;
      let payload = {};
      this.setOverlayValue(true);
      payload.classLink = this.classLink.trim();
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.setApiSuccess("Class Link updated successfully");
          this.fetchProfile().then(() => {
            this.closeUpdateClassLinkDialog();
            this.setOverlayValue(false);
            });
        })
        .catch((error) => {
          this.closeUpdateClassLinkDialog();
          this.setOverlayValue(false);
        });
    }
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      this.myProfile.profilePhoto = URL.createObjectURL(e.target.files[0]);
      this.showUploadBtns = true;
    },
    uploadProfilePhoto() {
      let payload = {};
      payload.file = this.file;
      payload.type = "PROFILE_PHOTO";
      payload.userId = this.myProfile.userId;
      let url = UPLOAD_FILE;

      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.fetchProfile().then(() => {
            this.setApiSuccess("Profile photo updated successfully");
            this.myProfile = Object.assign({}, this.profile);
            this.showUploadBtns = false;
            this.file = "";
          });
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    cancelUpload() {
      this.myProfile = Object.assign({}, this.profile);
      this.showUploadBtns = false;
      this.file = "";
    },
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 0.5rem;
}
</style>
