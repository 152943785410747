<template v-bind="$props">
  <v-sheet>
    <v-data-table :headers="leaderBoardHeaders" :items="leaderBoardData" hide-default-footer :items-per-page=11>
      <template v-slot:[`item.name`]="{ item }">
        <td :class="item.userId == userId ? 'leaderBoardTable' : ''">
          <div class="ml-2">
            {{ item.firstName + " " + item.lastName }}
          </div>
        </td>
      </template>
      <template v-slot:[`item.grade`]="{ item }">
        <td :class="item.userId == userId ? 'leaderBoardTable' : ''">
          <div class="ml-2">
            {{ item.grade }}
          </div>
        </td>
      </template>
      <template v-slot:[`item.rank`]="{ item }">
        <td :class="item.userId == userId ? 'leaderBoardTable' : ''">
          <v-icon :color="item.userId == userId ? 'success' : 'primary'" class="ma-1">mdi-account-circle</v-icon
          >{{ item.rank }}
        </td>
      </template>
    </v-data-table>
  </v-sheet>
</template>
<script>
import axios from "axios";
import { BASE_URL, STUDENT_LEADERBOARD } from "@/common/apiEndpoints";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      leaderBoardData : [],
      leaderBoardHeaders: [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Grade",
          align: "start",
          value: "grade",
        },
        {
          text: "Rank",
          value: "rank",
          align: "start",
        },
      ],
    };
  },
  props: ["userId" , "leaderBoard"],
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  mounted: function () {
    if(this.leaderBoard.length == 0){
      this.getLeaderBoard();
    }else{
      this.leaderBoardData = this.leaderBoard;
    }
  },
  methods: {
    formatDateTime(date) {
      return formatDateTime(date);
    },
    getLeaderBoard() {
      let url = STUDENT_LEADERBOARD;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.leaderBoardData = res.data.leaderBoard;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    
  },
};
</script>
<style>
.leaderBoardTable {
  background-color: rgb(175, 208, 175) !important;
  font-size: 16px !important;
  font-weight: 500;
}
</style>
  