<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar text dark color="primary" elevation="2">
      <v-toolbar-title
        style="font-weight: 500; font-size: 24px; min-width: 200px"
      >
        My Competitions
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchQuery"
        append-icon="mdi-magnify"
        label="Search"
        style="max-width: 250px"
        left
        hide-details
        class="ml-3 mt-2"
      ></v-text-field>
      <v-select
        :items="options"
        
        v-model="selectedOption"
        class="ml-4 mt-7"
        style="max-width: 200px; cursor: pointer"
      ></v-select>
      <!-- <v-tabs  right class="ml-0">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item" @click="tab = item">
          {{ item }}
        </v-tab>
      </v-tabs> -->
    </v-toolbar>

    <v-container
      fluid
      id="live-competitions"
      v-if=" !loadingLive && selectedOption == 'Live'"
    >
      <v-row class="ma-1" v-if="filteredLiveCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredLiveCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pt-4">
            <v-card justify-center elevation="0" class="ml-4 mr-4">
              <v-img
                justify-center
                width="100%"
                height="100"
                :src="comp.thumbnail"
              ></v-img>
              <v-btn
                class="fixedbtn"
                label
                :color="getChipColor(comp.state)"
                text-color="white"
                style="
                  font-weight: 500;
                  width: 60px;
                  height: 30px;
                  color: white;
                  justify-content: center;
                  border-radius: 10px;
                "
                >{{ comp.state }}
              </v-btn>
            </v-card>
            <v-row class="ma-1 pa-0">
              <v-col cols="12">
                <v-row>
                  <v-card-title
                    class="pa-0 pt-2 ml-4"
                    style="font-size: 22px; font-weight: 500"
                  >
                    <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                    <v-tooltip bottom v-if="comp.name.length > 20">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{
                          comp.name.substring(0, 20) + "..."
                        }}</span>
                      </template>
                      <span>{{ comp.name }}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-card-title
                    class="pa-0 mt-3 ml-4"
                    style="font-size: 18px; font-weight: 400"
                  >
                    <span style="color: grey; font-size: 16px"
                      >Prize Pool
                      <span
                        style="
                          color: #faaf19;
                          font-size: 16px;
                          font-weight: 400;
                        "
                        >{{
                          comp.coinsPerQuestion *
                          comp.quizes[0].quizQuestions.length
                        }}
                        <img style="height: 15px" src="../assets/Coin.svg"
                      /></span>
                    </span>
                  </v-card-title>
                </v-row>
                <v-col class="ma-1 pa-0">
                  <span style="font-weight: 400; color: grey; font-size: 12px"
                    >Ends in:
                  </span>
                  <span style="font-weight: 500; color: grey; font-size: 12px">
                    {{ comp.timeEndDifference }}
                  </span>
                </v-col>
              </v-col>
            </v-row>
            <v-card-actions class="pt-0 mr-1 ml-1">
              <v-row
                class="ma-1 mb-0"
                v-if="
                  comp.state == COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == false
                "
              >
                <v-btn
                  width="100%"
                  style="font-weight: 600; font-size: 14px"
                  color="primary"
                  @click="openTestDialog(comp)"
                >
                  Attempt
                </v-btn>
              </v-row>
              <v-row
                class="ma-1 mb-0"
                v-else-if="
                  comp.state == COMP_STATE_LIVE &&
                  comp.isAlreadyAttempted == true
                "
              >
                <!-- <v-btn width="100%" style="font-weight: 600; font-size: 14px; color: white;" color="grey">
                  Attempted
                </v-btn> -->
                <v-col cols="6" class="mb-2 ml-1 pa-0">
                  <v-chip
                    label
                    class="white--text"
                    background
                    color="grey"
                    style="
                      font-weight: 400;
                      width: 100px;
                      justify-content: center;
                      min-width: 130px;
                      display: inline-flex;
                    "
                  >
                    <!-- style="
                      font-weight: 400;
                      width: 100px;
                      justify-content: center;
                      min-width: 150px; 
                      display: inline-flex;
                    " text-color="white" color="grey" -->
                    ATTEMPTED
                  </v-chip>
                </v-col>
                <!-- <v-col class="ml-3 pa-1 mt-1">
                  <div>
                    <span style="font-size: 14px; font-weight: 500; color: grey">{{
                      comp.timeResultDifference }}
                      <span style="font-size: 12px; color: grey">for results</span></span>
                  </div>
                </v-col> -->
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <v-row class="text-center">
            <v-col class="text-center">
              <img height="300" src="../assets/oops.png" alt="img" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <span style="font-size: 30px; font-weight: 500">
                No <span style="color: red">Live</span> Competitions found
              </span> <br>
              <span style="font-size: 18px; color:grey; font-weight: 400">
                Check for <span style="color: green">Upcoming</span> Competitions <span style="color: blue; cursor:pointer;"  @click="selectedOption='Upcoming'" > <u>here</u></span>
              </span>
            </v-col>
          </v-row>
          
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      id="upcoming-competitions"
      v-if="
        selectedOption == 'Upcoming'
      "
    >
      <v-row class="ma-1" v-if="filteredUpcomingCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredUpcomingCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pt-4">
            <v-card justify-center elevation="0" class="ml-4 mr-4">
              <v-img
                justify-center
                width="100%"
                height="100"
                :src="comp.thumbnail"
              ></v-img>
            </v-card>
            <v-row class="ma-1 pa-0">
              <v-col cols="12">
                <v-row>
                  <v-card-title
                    class="pa-0 pt-2 ml-4"
                    style="font-size: 22px; font-weight: 500"
                  >
                    <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                    <v-tooltip bottom v-if="comp.name.length > 20">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{
                          comp.name.substring(0, 20) + "..."
                        }}</span>
                      </template>
                      <span>{{ comp.name }}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-card-title
                    class="pa-0 mt-3 ml-4"
                    style="font-size: 18px; font-weight: 400"
                  >
                    <span style="color: grey; font-size: 16px"
                      >Prize Pool
                      <span
                        style="
                          color: #faaf19;
                          font-size: 16px;
                          font-weight: 400;
                        "
                        >{{
                          comp.coinsPerQuestion *
                          comp.quizes[0].quizQuestions.length
                        }}
                        <img style="height: 15px" src="../assets/Coin.svg"
                      /></span>
                    </span>
                  </v-card-title>
                </v-row>
                <v-col class="ma-1 pa-0">
                  <span style="font-weight: 400; color: grey; font-size: 12px"
                    >Starts in:
                  </span>
                  <span style="font-weight: 500; color: grey; font-size: 12px">
                    {{ comp.timeStartDifference }}
                  </span>
                </v-col>
              </v-col>
            </v-row>
            <v-card-actions class="pt-0 mr-1 ml-1">
              <v-row class="ma-1 mb-0" v-if="tab == 'LIVE'">
                <v-col cols="8" class="mr-1 ml-1 pa-0">
                  <v-chip
                    :color="getChipColor(comp.state)"
                    text-color="white"
                    style="
                      font-weight: 400;
                      width: 100px;
                      justify-content: center;
                      min-width: 150px;
                      display: inline-flex;
                    "
                    >{{ comp.state }}</v-chip
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <v-row class="text-center">
            <v-col class="text-center">
              <img height="300" src="../assets/oops.png" alt="img" />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="text-center">
              <span style="font-size: 30px; font-weight: 500">
                No <span style="color: green">Upcoming</span> Competitions found
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      fluid
      id="completed-competitions"
      v-if="
        selectedOption == 'Completed'
      "
    >
      <v-row class="ma-1" v-if="filteredFinishedCompetitions.length > 0">
        <v-col
          cols="4"
          v-for="(comp, index) in filteredFinishedCompetitions"
          :key="index"
        >
          <v-card width="100%" height="100%" class="ma-1 pt-4">
            <v-card justify-center elevation="0" class="ml-4 mr-4">
              <v-img
                justify-center
                width="100%"
                height="100"
                :src="comp.thumbnail"
              ></v-img>
            </v-card>
            <v-row class="ma-1 ml-0 pa-0">
              <v-col cols="12">
                <v-row>
                  <v-card-title
                    class="pa-0 pt-2 ml-4"
                    style="font-size: 22px; font-weight: 500"
                  >
                    <span v-if="comp.name.length <= 20">{{ comp.name }}</span>
                    <v-tooltip bottom v-if="comp.name.length > 20">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{
                          comp.name.substring(0, 20) + "..."
                        }}</span>
                      </template>
                      <span>{{ comp.name }}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-card-title
                    class="pa-0 mt-3 ml-4"
                    style="font-size: 18px; font-weight: 400"
                  >
                    <span style="color: grey; font-size: 16px"
                      >Prize Pool
                      <span
                        style="
                          color: #faaf19;
                          font-size: 16px;
                          font-weight: 400;
                        "
                        >{{
                          comp.coinsPerQuestion *
                          comp.quizes[0].quizQuestions.length
                        }}
                        <img style="height: 15px" src="../assets/Coin.svg"
                      /></span>
                    </span>
                  </v-card-title>
                </v-row>
                <v-col class="ma-1 pa-0">
                  <span style="font-weight: 400; color: grey; font-size: 12px">
                    Ended on:
                  </span>
                  <span style="font-weight: 500; color: grey; font-size: 12px">
                    {{ formatDateTime(comp.endDate) }}
                  </span>
                </v-col>
              </v-col>
            </v-row>
            <v-card-actions class="pt-0 mr-1 ml-1">
              <v-btn
                v-if="comp.isAlreadyAttempted"
                width="100%"
                style="font-weight: 600; font-size: 14px"
                color="primary"
                @click="analyseComp(comp)"
              >
                <v-icon class="mr-1">mdi-poll</v-icon>
                View Analytics
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="text-center">
          <span style="font-size: 30px; font-weight: 500">
            No completed history found.
          </span>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="competitionCompletedDialog" width="500px">
      <v-card width="500px">
        <v-row class="ma-0 pt-4 pb-4">
          <v-col cols="2" class="ma-0 mt-0 pa-0"
            ><v-avatar color="#EEFFEF" size="48px" class="ml-4">
              <v-icon color="#11D800">mdi-check</v-icon>
            </v-avatar></v-col
          >
          <v-col cols="10" class="mt-1 pa-0">
            <v-col class="pa-0"
              ><span style="color: #5edc11; font-weight: 500; font-size: 20px"
                >Submit Successfully</span
              ></v-col
            ><v-col class="pa-0 mt-2"
              >Congratulations on finishing the test. Your answers have been
              submit successfully. Thanks!</v-col
            >
            <v-col cols="10" class="pa-0 mt-4"
              ><v-btn
                width="100%"
                dark
                color="#5EDC11"
                @click="closeCompetiitonCompletedDialog()"
                >OK</v-btn
              ></v-col
            >
          </v-col>
          <!-- <v-col cols="1" class="pa-0 ma-0"><v-icon @click="closeCompetiitonCompletedDialog()">mdi-close</v-icon></v-col> -->
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openTest" max-width="700px">
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Test Instructions</v-card-title
        >
        <v-card-text>
          <v-subheader>General Instructions</v-subheader>
          <v-list>
            <v-list-item>
              1. The test duration is
              <span
                class="mr-1 ml-1"
                style="font-weight: 700"
                v-if="testComp.duration != null"
                >{{ testComp.duration }}</span
              ><span class="mr-1 ml-1" style="font-weight: 700" v-else>5</span
              >minutes. The test will automatically submit when the time ends.
            </v-list-item>
            <v-list-item>
              2. Do not refresh the page or use the back button. Doing so will
              result in automatic submission of the test.
            </v-list-item>
            <v-list-item>
              3. Ensure you have a stable internet connection throughout the
              test.
            </v-list-item>
            <v-list-item>
              3. Navigation between the questions is possible throughout the
              test.
            </v-list-item>
          </v-list>
          <v-subheader>Rules and Regulations</v-subheader>
          <v-list>
            <v-list-item>
              1. Any form of cheating or violation of rules can result in a
              permanent ban from future tests.
            </v-list-item>
            <v-list-item>
              2. Do not open other tabs or applications during the test.
            </v-list-item>
            <v-list-item>
              3. Your webcam and microphone may be monitored during the test.
            </v-list-item>
          </v-list>

          <v-subheader>Technical Instructions</v-subheader>
          <v-list>
            <v-list-item>
              1. Make sure your device is fully charged or plugged in.
            </v-list-item>
            <v-list-item>
              2. Use an updated browser for the best experience.
            </v-list-item>
            <v-list-item>
              3. If you face any technical issues, contact support immediately.
            </v-list-item>
          </v-list>

          <div class="text-center mt-4">
            <v-alert type="info" dense class="mb-4" border="left">
              Note: The test will automatically submit if the time ends, or if
              you refresh or navigate away from this page.
            </v-alert>
            <v-alert type="error" border="left">
              Warning: Any cheating violation will result in a permanent ban
              from attempting future tests.
            </v-alert>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="attemptCompetiton(testComp)"
            >Proceed</v-btn
          >
          <v-btn color="red darken-1" text @click="openTest = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import confetti from "canvas-confetti";
import {
  GET_ALL_COMPETITIONS,
  QUIZ_COMPETITION_START,
  BASE_URL,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { COMP_STATE_LIVE, COMP_STATE_UPCOMING } from "@/common/constants";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
export default {
  data() {
    return {
      selectedTab: "live-competitions",
      tabs: [
        { id: "live-competitions", label: "Live" },
        { id: "upcoming-competitions", label: "Upcoming" },
        { id: "completed-competitions", label: "Completed" },
      ],
      studentCompetitions: {},
      loadingLive: true,
      compLeaderboard: {},
      options: ["Live", "Upcoming", "Completed"],
      selectedOption: "Live",
      compAnalysis: {},
      coinValue: 70,
      studentPreviousCompetitions: {},
      COMP_STATE_LIVE: COMP_STATE_LIVE,
      COMP_STATE_UPCOMING: COMP_STATE_UPCOMING,
      competitionCompletedDialog: false,
      quizComp: { quizes: [] },
      answered: 0,
      openTest: false,
      testComp: {},
      notAnswered: 0,
      quizContent: { quizQuestions: [] },
      quizID: "",
      quizScore: 0,
      tab: "LIVE",
      items: ["LIVE", "UPCOMING", "COMPLETED"],
      earnedCoins: 2,
      searchQuery: "",
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
    // progress() {
    //   return (this.earnedCoins / this.compAnalysis.totalCoins) * 100;
    // },
    filteredLiveCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      const currentTime = new Date();
      return competitionsArray.filter((comp) => {
        const nameMatchesQuery = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
        if (comp.state === "LIVE") {
          return nameMatchesQuery && currentTime < new Date(comp.endDate);
        } else if (comp.state === "UPCOMING") {
          return nameMatchesQuery && currentTime >= new Date(comp.startDate);
        }
        return false;
      });
    },
    filteredFinishedCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      const currentTime = new Date();

      return competitionsArray.filter((comp) => {
        const isFinished =
          comp.state === "FINISHED" ||
          (comp.state === "LIVE" && currentTime >= new Date(comp.endDate));
        const matchesSearch = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        return isFinished && matchesSearch;
      });
    },

    filteredUpcomingCompetitions() {
      const competitionsArray = Object.values(this.studentCompetitions);
      const currentTime = new Date();
      return competitionsArray.filter((comp) => {
        const isUpcoming =
          comp.state === "UPCOMING" && currentTime < new Date(comp.startDate);
        const matchesSearch = comp.name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());

        return isUpcoming && matchesSearch;
      });
    },
  },
  mounted: function () {
    this.fetchCompetition();
    if (this.$route.params.compFinished) {
      this.openCompetiitonCompletedDialog();
    }
  },
  methods: {
    updateCompetitions() {
      const currentTime = new Date();
      this.studentCompetitions.forEach((comp) => {
        if (
          comp.state === "UPCOMING" &&
          currentTime >= new Date(comp.startDate)
        ) {
          comp.state = "LIVE";
        } else if (
          comp.state === "LIVE" &&
          currentTime >= new Date(comp.endDate)
        ) {
          comp.state = "FINISHED";
        }
      });
    },

    updateTimeDifferences() {
      this.studentCompetitions = this.studentCompetitions.map((comp) => ({
        ...comp,
        timeStartDifference: this.getTimeStartDifference(comp.startDate),
        timeEndDifference: this.getTimeEndDifference(comp.endDate),
        timeResultDifference: this.getTimeResultDifference(comp.endDate),
      }));
    },
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    openTestDialog(comp) {
      this.testComp = comp;
      this.openTest = true;
    },
    analyseComp(comp) {
      var compParams = {};
      compParams.competition = comp;
      this.setParams(compParams);
      this.$router.push({
        name: "CompetitionAnalytics",
        params: { durationTime: comp.duration },
      });

      // this.compAnalysis = comp;
      // await this.getCompProgress2(comp.quizCompId);
      // await this.getCompLeaderboard(comp.quizCompId);

      // for (let i = 0; i < comp.quizes.length; i++) {
      //   this.quizId = comp.quizes[i].quizId;
      //   this.quizContent = comp.quizes[i];
      // }

      // console.log(this.quizContent.quizQuestions);

      // this.answered = 0;
      // this.notAnswered = 0;
      // this.quizScore = 0;

      // for (let i = 0; i < this.quizContent.quizQuestions.length; i++) {
      //   const questionNo = this.quizContent.quizQuestions[i].questionNo;
      //   console.log("exe");
      //   if (this.quizComp && this.quizComp.quizes.length > 0) {
      //     for (let j = 0; j < this.quizComp.quizes.length; j++) {
      //       let question = this.quizComp.quizes[j].questions.find(
      //         (question) => question.questionNo === questionNo
      //       );
      //       if (question) {
      //         this.answered += 1;
      //         if (question.isCorrect) {
      //           this.quizScore = this.quizScore + 1;
      //         }
      //       } else {
      //         this.notAnswered += 1;
      //       }
      //     }
      //   }
      // }

      // this.analyseDialog = true;
    },
    openCompetiitonCompletedDialog() {
      this.competitionCompletedDialog = true;
      this.triggerConfetti();
    },
    triggerConfetti() {
      confetti({
        particleCount: 2500,
        spread: 500,
        origin: { y: 0.35 },
      });
    },
    closeCompetiitonCompletedDialog() {
      this.competitionCompletedDialog = false;
    },
    getTimeEndDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (days > 0) {
        return `${days} days ${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours > 0) {
        return `${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours === 0 && minutes > 0) {
        return `${minutes} mins ${seconds} secs`;
      }
      return `${seconds} secs`;
    },
    getTimeResultDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (days > 0) {
        return `${days} days`;
      }
      if (days === 0 && hours > 0) {
        return `${hours} hours`;
      }
      if (days === 0 && hours === 0 && minutes > 0) {
        return `${minutes} mins`;
      }
      return `${seconds} secs`;
    },
    getTimeStartDifference(date) {
      const now = new Date();
      const targetDate = new Date(date);
      const diff = targetDate - now;
      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);
      if (days > 0) {
        return `${days} days ${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours > 0) {
        return `${hours} hours ${minutes} mins ${seconds} secs`;
      }
      if (days === 0 && hours === 0 && minutes > 0) {
        return `${minutes} mins ${seconds} secs`;
      }
      return `${seconds} secs`;
    },
    fetchCompetition() {
      this.setOverlayValue(true);
      let url = GET_ALL_COMPETITIONS;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCompetitions = res.data.competitions;
          // this.studentCompetitions.forEach((comp) => {
          //   comp.isAlreadyAttempted = false;
          //   // this.getCompProgress(comp.quizCompId);
          // });
          this.updateTimeDifferences();

          setInterval(() => {
            this.updateTimeDifferences();
          }, 1000);

          this.updateCompetitions();

          setInterval(() => {
            this.updateCompetitions();
          }, 1000);

          this.setOverlayValue(false);
          this.loadingLive= false;
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.loadingLive= false;
        });
        this.loadingLive= false;
    },
    getChipColor(state) {
      if (state == COMP_STATE_LIVE) {
        return "red";
      } else if (state == COMP_STATE_UPCOMING) {
        return "green";
      } else {
        return "blue";
      }
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    attemptCompetiton(comp) {
      const url = QUIZ_COMPETITION_START;
      const payload = {};
      payload.competitionId = comp.quizCompId;
      payload.quizId = comp.quizes[0].quizId;
      var compParams = {};
      compParams.competition = comp;
      compParams.isFinished = false;
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.openTest = false;
          this.setParams(compParams);
          this.$router.push({
            name: "QuizCompetition",
            params: { durationTime: comp.duration },
          });
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.openTest = false;
          this.setApiError("Already Attempted , Please refresh");
        });
    },
  },
};
</script>
<style>
.fixedbtn {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  z-index: 1;
}
.fixed-tab {
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 1000;
  background-color: transparent !important;
}

.tabs {
  display: flex;
}

.v-tab {
  min-width: auto;
  padding: 0 6px;
  margin: 0 4px;
  border-radius: 20px;
  background-color: transparent !important;
  color: white;
}
</style>
