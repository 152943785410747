export const GET_ISAUTHENTICATED = "isAuthenticated";
export const GET_TOKEN = "getToken";
export const GET_USER_PROFILE = "getUserProfile";
export const GET_USER_TYPE = "getUserType";
export const GET_API_ERROR_TEXT = "getApiErrorText";
export const GET_API_SUCCESS_TEXT = "getApiSuccessText";
export const GET_OVERLAY_VALUE = "getOverlayValue";
export const GET_TOKEN_EXPIRED = "getTokenExpired";
export const GET_PARAMS_VALUE = "getParamsValue";
export const GET_COURSE_EXPANSION = "getCourseExpansion";
export const GET_INSTITUTE_EXPANSION = "getInstituteExpansion";
export const GET_STUDENT_RANK = "getStudentRank";
