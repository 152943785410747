
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#FAAF19',
        secondary: '#E49104',
        accent: '#195D80',
        error: '#F55B49',
        warning: '#ffeb3b',
        info: '#2196f3',
        success: '#26BD67'
      },
    },
  },
})

export default vuetify