<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="primary" class="mb-1" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >Users</v-toolbar-title
      >
      <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
      <v-spacer></v-spacer>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="getAllInstitutes()"
                  >mdi-filter</v-icon
                >
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-card class="mx-auto px-6 py-8" max-width="450">
          <v-row>
            <v-col cols="12" v-if="searchFilter.userType == USER_TYPE_STUDENT">
              <v-select
                v-model="searchFilter.instituteId"
                :items="institutes"
                label="Institute Name"
                outlined
                dense
                item-text="instituteName"
                item-value="instituteId"
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="searchFilter.mobileNo"
                type="number"
                label="Mobile No"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="searchFilter.email"
                label="Email"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="searchFilter.firstName"
                label="First Name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="searchFilter.lastName"
                label="Last Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span>Created Date Range</span>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchFilter.dateRange.startDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchFilter.dateRange.startDate"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="searchFilter.dateRange.endDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchFilter.dateRange.endDate"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" v-if="searchFilter.userType == USER_TYPE_STUDENT">
              <v-combobox
                v-model="searchFilter.grades"
                :items="gradesArray"
                label="Grades"
                multiple
                outlined
                dense
                deletable-chips
                chips
                item-text="desc"
                item-value="id"
              >
                <template v-slot:prepend-item>
                  <v-list-item @click="selectAll()">
                    <v-list-item-action>
                      <v-icon>{{ icon() }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="searchFilter.onboarded"
                :items="registrationTypes"
                label="Onboarded"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-list-item>
            <v-btn color="primary" text @click="searchUsers()"> Apply </v-btn>
            <v-btn color="primary" text @click="initialize()"> Reset </v-btn>
          </v-list-item>
        </v-card>
      </v-menu>
      <v-col cols="3" class="mt-3">
        <v-select
          @change="searchFilter.userType ? searchUsers() : ''"
          v-model="searchFilter.userType"
          :items="userTypes"
          item-text="key"
          item-value="value"
        ></v-select>
      </v-col>

      <v-dialog persistent v-model="dialog" max-width="1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark text v-bind="attrs" v-on="on" style="font-size: 16px"
            >New User</v-btn
          >
        </template>
        <v-card>
          <v-tabs center-active>
            <v-tab>New User</v-tab>
            <v-tab @click="getAllInstitutes()">Bulk Student upload</v-tab>
            <v-tab-item key="1">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-form ref="form" v-model="createUserValidation.valid">
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.firstName"
                            label="First name"
                            :rules="createUserValidation.firstnameRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.lastName"
                            label="Last name"
                            :rules="createUserValidation.lastnameRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.mobileNo"
                            type="number"
                            :rules="createUserValidation.mobileRules"
                            required
                            :counter="10"
                            label="Mobile No"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.email"
                            label="Email"
                            :rules="createUserValidation.emailRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.password"
                            label="Password"
                            :rules="createUserValidation.passwordRules"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                          <v-select
                            v-model="editedUser.userType"
                            :rules="createUserValidation.nameRules"
                            :items="createUserValidation.items"
                            required
                            item-text="value"
                            item-value="key"
                            label="User Type"
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="editedUser.userType === 'STUDENT'"
                        >
                          <v-text-field
                            v-model="editedUser.age"
                            label="Age"
                            type="number"
                            :rules="createUserValidation.ageRules"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="6"
                          md="4"
                          v-if="editedUser.userType === 'STUDENT'"
                        >
                          <v-text-field
                            v-model="editedUser.parentName"
                            label="Parent/Guardian Name"
                            :rules="createUserValidation.nameRules"
                            required
                          ></v-text-field>
                        </v-col>

                        <v-col v-if="editedUser.userType === 'STUDENT'">
                          <v-text-field
                            v-model="editedUser.schoolName"
                            label="School Name"
                            :rules="createUserValidation.schoolRules"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="editedUser.userType === 'STUDENT'">
                        <v-col cols="12" sm="6" md="4" class="mt-2">
                          <v-combobox
                            v-model="editedUser.grade"
                            :items="gradesArray"
                            label="Grade"
                            dense
                            deletable-chips
                            chips
                            outlined
                            :rules="createUserValidation.gradeRules"
                            item-text="desc"
                            item-value="id"
                          >
                          </v-combobox>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.state"
                            label="State"
                            :rules="createUserValidation.stateRules"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedUser.pincode"
                            type="number"
                            :rules="createUserValidation.pinRules"
                            required
                            label="Pincode"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row v-if="editedUser.userType === 'STUDENT'">
                        <v-col cols="12" sm="6" md="4">
                          <span>Select Your Gender</span>
                          <v-row>
                            <v-col>
                              <v-radio-group v-model="editedUser.gender" row>
                                <v-radio
                                  label="Male"
                                  color="primary"
                                  value="MALE"
                                ></v-radio>
                                <v-radio
                                  label="Female"
                                  color="primary"
                                  value="FEMALE"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="close()"> Cancel </v-btn>
                  <v-btn color="primary" text @click="save()"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item key="2">
              <v-card>
                <v-container>
                  <v-form ref="form" v-model="uploadFileValidation.valid">
                    <v-row>
                      <v-col>
                        <v-file-input
                          :rules="uploadFileValidation.rules"
                          accept=".xls, .xlsx"
                          placeholder="Upload Student excel file"
                          show-size
                          label="Student File"
                          @change="processStudentUpload($event)"
                        ></v-file-input>
                      </v-col>
                      <v-col>
                        <v-select
                          v-model="instituteId"
                          :items="institutes"
                          :rules="uploadFileValidation.rules"
                          label="Institute Name"
                          outlined
                          @change="validateStudentOninstituteChange()"
                          dense
                          item-text="instituteName"
                          item-value="instituteId"
                        >
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-simple-table
                    v-if="uploadFileValidation.valid"
                    height="400"
                    class="pb-2"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">First Name</th>
                          <th class="text-left">Last Name</th>
                          <th class="text-left">Mobile No</th>
                          <th class="text-left">Email</th>
                          <th class="text-left">Grade</th>
                          <th class="text-left">Institute Name</th>
                          <th class="text-left">Pincode</th>
                          <th class="text-left">State</th>
                          <th class="text-left">Age</th>
                          <th class="text-left">Gender</th>
                          <th class="text-left">Paasword</th>
                          <th class="text-left">Parent/Guardian Name</th>
                          <th class="text-left">Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in students" :key="item.mobileNo">
                          <td
                            :class="
                              item['0'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["0"] ? item["0"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['1'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["1"] ? item["1"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['2'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["2"] ? item["2"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['3'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["3"] ? item["3"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['4'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["4"] ? item["4"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['5'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["5"] ? item["5"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['6'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["6"] ? item["6"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['7'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["7"] ? item["7"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['8'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["8"] ? item["8"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['9'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["9"] ? item["9"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['10'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["10"] ? item["10"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['11'] && item['13']
                                ? 'success--text'
                                : 'error--text'
                            "
                          >
                            {{ item["11"] ? item["11"] : "---" }}
                          </td>
                          <td
                            :class="
                              item['13'] ? 'success--text' : 'error--text'
                            "
                          >
                            {{ item["12"] ? item["12"] : "---" }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-row>
                    <v-col>
                      <v-btn
                        :disabled="allValidRecords()"
                        color="primary"
                        @click="uploadStudents()"
                        >Upload</v-btn
                      >
                      <v-btn color="primary" text @click="close()">
                        Cancel
                      </v-btn>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-card-text>
                        <span>{{
                          `Total valid records: ${validRecords}`
                        }}</span>
                      </v-card-text>
                    </v-col>
                    <v-col>
                      <v-btn
                        v-if="!uploadFileValidation.valid"
                        :href="FILE_PATH"
                        color="primary"
                        download
                      >
                        Download Sample File
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="dialogBlock" max-width="500px">
        <v-card>
          <v-card-title>{{ blockUnblockDialogTitle }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeBlockUser()">Cancel</v-btn>
            <v-btn color="primary" text @click="blockUserConfirm()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-container class="pa-0" fluid>
      <v-dialog persistent v-model="updatePasswordDialog" max-width="500px">
        <v-card class="mx-auto px-6 py-8">
          <v-form>
            <v-col>
              <v-text-field
                v-model="password1"
                :type="showPswrd ? 'text' : 'password'"
                placeholder="Enter Password"
                :rules="createUserValidation.passwordRules"
                outlined
                dense
                :append-icon="!showPswrd ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPswrd = !showPswrd"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="password2"
                :type="showPswrd ? 'text' : 'password'"
                placeholder="Re-Enter Password"
                :rules="createUserValidation.passwordRules"
                outlined
                dense
                :append-icon="!showPswrd ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append="showPswrd = !showPswrd"
              ></v-text-field>
            </v-col>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeUpdatePasswordDialog()">
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="updatePassword()"> Save </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="deleteUserConfirmDialog" max-width="500px">
        <v-card>
          <v-card-title>Are you sure you want to delete the user?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeDeleteUserConfirmDialog()"
              >No</v-btn
            >
            <v-btn color="primary" text @click="deleteUser()">Yes</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-container fluid>
        <v-data-table
          :headers="headers"
          :items="users"
          sort-by="firstName"
          @contextmenu:row="rightClickHandler"
          ref="myDataTable"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item :key="1"   @click="blockUnblock(item, item.status == 'ACTIVE' ?'BLOCK':'UNBLOCK')" >
                <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="item.status == 'ACTIVE'"
                  color="primary"
                  class="mr-2"
                >
                  mdi-account-cancel-outline
                </v-icon>
                <v-icon
                  v-on="on"
                  v-else-if="item.status == 'BLOCKED'"
                  color="primary"
                >
                  mdi-account-check-outline
                </v-icon>
              </template>
              <span>{{
                item.status == "ACTIVE" ? "Block user" : "Unblock user"
              }}</span>
            </v-tooltip>
            <v-list-item-title>{{ item.status == "ACTIVE" ? "Block" : "Unblock"}}</v-list-item-title>
              </v-list-item>
            <v-list-item :key="2" @click="openUpdatePasswordDialog(item)">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="mr-2"
                  color="#26BD67"
                  @click="openUpdatePasswordDialog(item)"
                >
                  mdi-key
                </v-icon>
              </template>
              <span>Edit Password</span>
            </v-tooltip>
            <v-list-item-title>{{ ' Edit Password' }}</v-list-item-title>
              </v-list-item>
            <v-list-item :key="3" v-if="USER_TYPE_ADMIN"  @click="openDeleteUserConfirmDialog(item)">
              <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  v-if="USER_TYPE_ADMIN"
                  class="mr-2"
                  color="error"
                  @click="openDeleteUserConfirmDialog(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete User</span>
            </v-tooltip>
            <v-list-item-title>{{ ' Delete User' }}</v-list-item-title>
              </v-list-item>
            <v-list-item  :key="4" v-if="false" @click="editUser(item)">
              <v-tooltip bottom v-if="false">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="mr-2"
                  color="primary"
                  @click="editUser(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-list-item-title>{{ ' Edit' }}</v-list-item-title>
              </v-list-item>
            <v-list-item :key="5" v-if="
                searchFilter.userType == USER_TYPE_TEACHER &&
                item.registrationCompleted == true
              " @click="showCalendar(item)">
              <v-tooltip
              bottom
              v-if="
                searchFilter.userType == USER_TYPE_TEACHER &&
                item.registrationCompleted == true
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="mr-2"
                  color="blue"
                  @click="showCalendar(item)"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>View Calendar</span>
            </v-tooltip>
              <v-list-item-title>{{ ' View Calendar' }}</v-list-item-title>
              </v-list-item>
            <v-list-item :key="6" v-if="
                (searchFilter.userType == USER_TYPE_TEACHER ||
                  searchFilter.userType == USER_TYPE_STUDENT) &&
                item.registrationCompleted == true
              " @click="showClasses(item)">
              <v-tooltip
              bottom
              v-if="
                (searchFilter.userType == USER_TYPE_TEACHER ||
                  searchFilter.userType == USER_TYPE_STUDENT) &&
                item.registrationCompleted == true
              "
            >
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  medium
                  color="primary"
                  @click="showClasses(item)"
                  class="mr-2"
                >
                  mdi-google-classroom
                </v-icon>
              </template>
              <span>View Classes</span>
            </v-tooltip>
              <v-list-item-title>{{ ' View Classes' }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </template>

          <template v-slot:[`item.createdOn`]="{ item }">
            {{ formatDateTime(item.createdOn) }}
          </template>
          <template v-slot:[`item.firstName`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  item.firstName
                    ? item.firstName.length > 15
                      ? item.firstName.substring(0, 15) + "..."
                      : item.firstName
                    : "---"
                }}</span>
              </template>
              <span>{{ item.firstName ? item.firstName : "---" }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.lastName`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  item.lastName
                    ? item.lastName.length > 15
                      ? item.lastName.substring(0, 15) + "..."
                      : item.lastName
                    : "---"
                }}</span>
              </template>
              <span>{{ item.lastName ? item.lastName : "---" }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.mobileNo`]="{ item }">
            {{ item.mobileNo ? item.mobileNo : "---" }}
          </template>
          <template v-slot:[`item.email`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{
                  item.email
                    ? item.email.length > 20
                      ? item.email.substring(0, 20) + "..."
                      : item.email
                    : "---"
                }}</span>
              </template>
              <span>{{ item.email ? item.email : "---" }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-row justify="center">
              <v-chip
                small
                :color="item.status == 'ACTIVE' ? 'success' : 'error'"
                label
                background
              >
                {{ item.status }}
              </v-chip>
              <v-chip
                class="mx-1"
                small
                :color="item.registrationCompleted == true ? 'info' : ''"
                label
                background
              >
                <!-- {{ item.registrationCompleted == true ? "BOARDED" : "NOT-BOARDED" }} -->
                BOARDED
              </v-chip>
            </v-row>
          </template>
          <template v-slot:[`item.userType`]="{ item }">
            <v-chip small color="primary" label background>
              {{ item.userType }}
            </v-chip>
          </template>
          <template v-slot:no-data>
            <span>No Data</span>
          </template>
        </v-data-table>
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import {
  SEARCH_USERS,
  BASE_URL,
  BLOCK_USER,
  DELETE_USER,
  UN_BLOCK_USER,
  CREATE_USER,
  STUDENT_CREATE_BULK,
  UPDATE_PASSWORD,
  GET_ALL_INSTITUTES,
} from "@/common/apiEndpoints.js";
import axios from "axios";
import {
  USER_TYPE_ADMIN,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
  USER_TYPE_STUDENT,
  USER_TYPE_TEACHER,
  USER_TYPE_OPERATIONAL_MANAGER,
  USER_TYPE_SALES_MANAGER,
  grades,
  SMAPLE_FILE_DOWNLOAD_PATH,
} from "@/common/constants";
import readXlsxFile from "read-excel-file";
import { mapGetters, mapMutations } from "vuex";
import { GET_PARAMS_VALUE, GET_TOKEN } from "@/store/getters";
import {
  formatDateTime,
  getAxiosOptions,
  rightClickHandler,
} from "@/common/utility";
import {
  SET_API_SUCCESS,
  SET_API_ERROR,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import "@/assets/Styles/main.css";
import ContextMenu from "./ContextMenu.vue";
import TeacherCalendar from "./TeacherCalendar.vue";
import TeacherClasses from "./TeacherClasses.vue";
import StudentClasses from "./StudentClasses.vue";
export default {
  data() {
    return {
      contextMenuObject: {},
      overlay: false,
      dialog: false,
      dialogBlock: false,
      updatePasswordDialog: false,
      deleteUserConfirmDialog: false,
      showPswrd: false,
      password1: "",
      password2: "",
      instituteId: "",
      institutes: [],
      gradesArray: grades,
      // passwordRegex: "^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{8,}$",
      headers: [
        {
          text: "First Name",
          align: "center",
          sortable: false,
          value: "firstName",
        },
        {
          text: "Last Name",
          align: "center",
          value: "lastName",
        },
        {
          text: "Mobile No",
          align: "center",
          value: "mobileNo",
        },
        { text: "Email ID", align: "center", value: "email" },
        {
          text: "User Type",
          value: "userType",
        },
        { text: "Status", align: "center", value: "status" },
        {
          text: "Created On",
          align: "center",
          value: "createdOn",
        },
        {
          text: "Actions",
          align: "center",
          value: "actions",
          sortable: false,
        },
      ],
      userTypes: [
        { key: "Select", value: "" },
        { key: "Student", value: USER_TYPE_STUDENT },
        { key: "Teacher", value: USER_TYPE_TEACHER },
        { key: "Resource Manager", value: USER_TYPE_RESOURCE_MANAGER },
        { key: "Admin", value: USER_TYPE_ADMIN },
        { key: "Content Manager", value: USER_TYPE_CONTENT_MANAGER },
        { key: "Sales Manager", value: USER_TYPE_SALES_MANAGER },
        { key: "Operational Manager", value: USER_TYPE_OPERATIONAL_MANAGER },
      ],
      registrationTypes: ["Boarded", "Not-Boarded"],
      users: [],
      editedIndex: -1,
      editedUser: {
        firstName: "",
        lastName: "",

        mobileNo: "",
        email: "",
        userType: "",
        userId: "",
      },

      defaultItem: {
        firstName: "",
        lastName: "",
        mobileNo: "",
        email: "",
        userType: "",
      },
      menu: false,
      menu2: false,
      menu3: false,
      searchFilter: {
        active: false,
        firstName: "",
        lastName: "",
        mobileNo: "",
        email: "",
        userType: "",
        onboarded: "",
        dateRange: {},
        grades: [],
        instituteId: "",
      },
      blockUnblockStatus: "",
      blockUnblockDialogTitle: "",
      createUserValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        firstnameRules: [
          (v) => !!v || "First Name is required",
          (v) =>
            /^[A-Za-z\s]+$/.test(v) ||
            "Please enter alphabetic characters only",
        ],
        lastnameRules: [
          (v) => !!v || "Last Name is required",
          (v) =>
            /^[A-Za-z\s]+$/.test(v) ||
            "Please enter alphabetic characters only",
        ],
        passwordRules: [
          (v) => !!v || "Password is required",
          (v) => /[a-z]+/.test(v) || "One lowercase letter required",
          (v) => /[A-Z]+/.test(v) || "One uppercase letter required",
          (v) => /[0-9]+/.test(v) || "One number required",
          (v) => /.{8,}/.test(v) || "8 characters minimum",
        ],

        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        mobileRules: [
          (v) => !!v || "Mobile no is required",
          (v) => (v && v.length == 10) || "Mobile must be 10 characters",
        ],
        ageRules: [
          (v) => !!v || "Age is required",
          (v) => /^\d+$/.test(v) || "Age must be a number",
          (v) => Number(2) <= v || "Age must be greater than 2",
          (v) => Number(99) >= v || "Age must be less than 99",
        ],
        schoolRules: [(v) => !!v || "School name is required"],
        gradeRules: [(v) => !!v || "Grade is required"],
        stateRules: [(v) => !!v || "State is required"],
        pinRules: [
          (v) => !!v || "Pin is required",
          (v) => (v && v.length == 6) || "Pin Number must be 6 characters",
        ],
        items: [
          { value: "Admin", key: "ADMIN" },
          { value: "Content Manager", key: "CONTENT_MANAGER" },
          { value: "Teacher", key: "TEACHER" },
          { value: "Resource Manager", key: "RESOURCE_MANAGER" },
          { value: "Student", key: "STUDENT" },
          { value: "Sales Manager", key: "SALES_MANAGER" },
          { value: "Operational Manager", key: "OPERATIONAL_MANAGER" },

        ],
      },
      uploadFileValidation: {
        valid: false,
        rules: [(value) => !!value || "File is required"],
      },
      students: [],
      validRecords: 0,
      FILE_PATH: SMAPLE_FILE_DOWNLOAD_PATH,
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
      USER_TYPE_TEACHER: USER_TYPE_TEACHER,
      USER_TYPE_ADMIN: USER_TYPE_ADMIN,
    };
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  created() {
    this.searchFilter.userType = USER_TYPE_STUDENT;
    this.initialize();
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogBlock(val) {
      val || this.closeBlockUser();
    },
  },
  methods: {
    rightClickHandler(e, item) {
      e.preventDefault();
      let textInCell = e.target.textContent;
      if (!textInCell) return;
      if (textInCell.slice(-3) === "...") {
        let curText = textInCell.slice(0, -3);
        let itemData = Object.entries(item.item);
        let findSameText = itemData.find((el) => {
          return el[1]?.toString().includes(curText);
        });
        if (findSameText) {
          this.contextMenuObject = rightClickHandler(
            findSameText[1],
            e.clientX,
            e.clientY
          );
          return;
        }
      }
      this.contextMenuObject = rightClickHandler(
        textInCell,
        e.clientX,
        e.clientY
      );
    },
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    openDeleteUserConfirmDialog(item) {
      this.deleteUserConfirmDialog = true;
      if (item.mobileNo) this.editedUser.mobileNo = item.mobileNo;
      if (item.email) this.editedUser.email = item.email;
    },
    closeDeleteUserConfirmDialog() {
      this.deleteUserConfirmDialog = false;
      this.editedUser.email = "";
      this.editedUser.mobileNo = "";
    },
    deleteUser() {
      const payload = {};
      if (this.editedUser.mobileNo) payload.mobileNo = this.editedUser.mobileNo;
      if (this.editedUser.email) payload.emailId = this.editedUser.email;
      let url = DELETE_USER;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeDeleteUserConfirmDialog();
          this.initialize();
          this.setOverlayValue(false);
          this.setApiSuccess("User deleted successfully");
        })
        .catch((error) => {
          this.closeDeleteUserConfirmDialog();
          this.setOverlayValue(false);
        });
    },
    uploadStudents() {
      this.validate();
      if (this.uploadFileValidation.valid) {
        this.overlay = true;
        this.setOverlayValue(true);
        let url = STUDENT_CREATE_BULK;
        const payload = {
          students: [],
        };
        for (var i = 0; i < this.students.length; i++) {
          const std = {};
          std.firstName = this.students[i]["0"];
          std.lastName = this.students[i]["1"];
          std.mobileNo = this.students[i]["2"];
          std.email = this.students[i]["3"];
          std.grade = this.students[i]["4"];
          std.schoolName = this.students[i]["5"];
          std.pincode = this.students[i]["6"];
          std.state = this.students[i]["7"];
          std.age = this.students[i]["8"];
          std.gender = this.students[i]["9"];
          std.password = this.students[i]["10"];
          std.parentName = this.students[i]["11"];
          payload.students.push(std);
        }
        payload.instituteId = this.instituteId;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            // this.students = [];
            this.initialize();
            // this.close();
            let errors = res.data.errors;
            for (var i = 0; i < this.students.length; i++) {
              let mobAlreadyExist = errors.findIndex(
                (err) => err.mobileNo == this.students[i]["2"]
              );
              let emailAlreadyExisit = errors.findIndex(
                (err) => err.email == this.students[i]["3"]
              );
              if (mobAlreadyExist != -1) {
                this.students[i]["12"] = errors[mobAlreadyExist].reason;
                this.students[i]["13"] = false;
              } else if (emailAlreadyExisit != -1) {
                this.students[i]["12"] = errors[emailAlreadyExisit].reason;
                this.students[i]["13"] = false;
              } else {
                this.students[i]["12"] = "Student Created Successfully";
                this.students[i]["13"] = true;
              }
            }
            this.students = Object.assign([], this.students);
            this.setApiSuccess(res.data.msg);
            this.setOverlayValue(false);
            this.overlay = false;
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
            this.overlay = false;
          });
      }
    },
    getAllInstitutes() {
      //  this.menu = false;
      let url = GET_ALL_INSTITUTES;
      if (this.institutes.length == 0) {
        this.setOverlayValue(true);
        axios
          .get(url, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.institutes = res.data.institutes;
            // this.filteredInstitutes = this.institutes;
            this.setOverlayValue(false);
          })
          .catch((error) => {
            error = error.errorMessage;
            this.setOverlayValue(false);
          });
      }
    },

    editUser(item) {
      this.dialog = true;
      this.editedIndex = 0;
      this.editedUser = Object.assign({}, item);
    },
    processStudentUpload($event) {
      if ($event) {
        readXlsxFile($event).then((rows) => {
          rows.splice(0, 1);
          this.validateExcelRows(rows);
        });
      }
    },
    validateExcelRows(rows) {
      let filteredRows = rows.filter(this.validateRow);
      const uniqueRecords = filteredRows.reduce((accumulator, current) => {
        if (
          !accumulator.find((item) => {
            if (item[2]) {
              item["2"] === current["2"] || item["3"] === current["3"];
            }
          })
        ) {
          accumulator.push(current);
        }
        return accumulator;
      }, []);
      this.students = uniqueRecords;
      this.validRecords = this.students.length;
    },
    validateStudentOninstituteChange() {
      this.setOverlayValue(true);
      let filteredRows = this.students.filter(this.validateRow);
      this.students = filteredRows;
      this.setOverlayValue(false);
    },
    validateRow(row) {
      this.setOverlayValue(true);
      const empty =
        row["0"] &&
        row["1"] &&
        //  row["2"] &&
        row["3"] &&
        row["4"] &&
        row["5"] &&
        row["6"] &&
        row["7"] &&
        row["8"] &&
        row["9"] &&
        row["10"] &&
        row["11"];
      if (empty) {
        row["0"] = row["0"].toString();
        row["1"] = row["1"].toString();
        if (row["2"]) {
          row["2"] = row["2"].toString();
        }
        if (row["3"]) {
          row["3"] = row["3"].toString();
        }
        row["4"] = row["4"].toString();
        row["5"] = row["5"].toString();
        row["6"] = Number(row["6"]);
        row["7"] = row["7"].toString();
        row["8"] = row["8"].toString();
        row["9"] = row["9"].toString();
        row["10"] = row["10"].toString();
        row["11"] = row["11"].toString();
      }
      // const validGrades = [
      //   "1",
      //   "2",
      //   "3",
      //   "4",
      //   "5",
      //   "6",
      //   "7",
      //   "8",
      //   "9",
      //   "10",
      //   "11",
      //   "12",
      // ];
      const validEmail = /.+@.+\..+/.test(row["3"]);
      if (!validEmail) {
        row["12"] = "Email is invalid";
      }
      let validMobile = true;
      if (row["2"]) {
        validMobile = /^[6-9]\d{9}$/.test(row["2"]);
      }
      if (!validMobile) {
        row["12"] = "Mobile No is invalid";
      }
      const validGrade = row["4"];
      if (!validGrade) {
        row["12"] = "Grade must not be null";
      }
      const selectedInstitute = this.institutes.find(
        (inst) => inst.instituteId == this.instituteId
      );
      const validInstituteName = selectedInstitute
        ? selectedInstitute.instituteName.toLowerCase() ==
          row["5"].toLowerCase()
        : false;
      if (!validInstituteName) {
        row["12"] = "Please select correct insitute";
      }
      const validGenders = ["MALE", "FEMALE", "OTHER"];
      const validGender = validGenders.includes(row["9"]);
      if (!validGender) {
        row["12"] =
          "Only 'MALE','FEMALE','OTHER' values are allowed in gender column";
      }
      const validPincode = /^[1-9][0-9]{5}$/.test(row["6"]);
      if (!validPincode) {
        row["12"] = "Invalid Pincode";
      }
      const validAge = row["8"] > 2;
      if (!validAge) {
        row["12"] = "Age of Student must be greater than 2 years";
      }
      if (empty == null) {
        row["12"] = "Please Fill all the mandatory fields";
      }
      // const validPassword = row["9"];
      row["13"] =
        empty &&
        validGrade &&
        validGender &&
        validEmail &&
        validMobile &&
        validPincode &&
        validAge &&
        validInstituteName;
      if (row["13"]) {
        row["12"] = "";
      }
      this.setOverlayValue(false);
      return true;
    },
    allValidRecords() {
      let disableUpload = false;
      for (var i = 0; i < this.students.length; i++) {
        if (this.students[i]["13"] == false) {
          disableUpload = true;
          break;
        }
      }
      return disableUpload;
    },
    validate() {
      this.$refs.form.validate();
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    fetchUsers(payload) {
      this.menu = false;
      let url = SEARCH_USERS;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.users = res.data.users;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    initialize() {
      this.searchFilter = {
        active: false,
        firstName: "",
        lastName: "",
        mobileNo: "",
        email: "",

        userType: USER_TYPE_STUDENT,
        onboarded: "",
        dateRange: {},
        grades: [],
        instituteId: "",
      };
      const payload = {};

      // let startDate = new Date();
      // startDate.setHours(0, 0, 0, 0);
      // let endDate = new Date();
      // endDate.setHours(23, 59, 0, 0);
      // payload.dateRange = {};
      // payload.dateRange.startDate = startDate.toISOString();
      // payload.dateRange.endDate = endDate.toISOString();
      // payload.userType = "STUDENT";
      if (this.paramsValue.userType == USER_TYPE_TEACHER) {
        payload.userType = USER_TYPE_TEACHER;
        this.searchFilter.userType = USER_TYPE_TEACHER;
      } else payload.userType = USER_TYPE_STUDENT;
      this.fetchUsers(payload);
    },
    searchUsers() {
      const payload = {
        dateRange: {},
      };

      if (this.searchFilter.mobileNo) {
        payload.mobileNo = this.searchFilter.mobileNo.trim();
      }
      if (this.searchFilter.email) {
        payload.emailId = this.searchFilter.email.trim();
      }
      if (this.searchFilter.firstName) {
        payload.firstName = this.searchFilter.firstName.trim();
      }
      if (this.searchFilter.lastName) {
        payload.lastName = this.searchFilter.lastName.trim();
      }
      if (this.searchFilter.userType) {
        payload.userType = this.searchFilter.userType.toUpperCase();
      }
      if (this.searchFilter.onboarded == "Boarded") {
        payload.onboarded = true;
      } else if (this.searchFilter.onboarded == "Not-Boarded") {
        payload.onboarded = false;
      }
      if (Object.keys(this.searchFilter.dateRange).length !== 0) {
        if (
          this.searchFilter.dateRange.startDate != "" &&
          this.searchFilter.dateRange.endDate != ""
        )
          payload.dateRange.startDate = new Date(
            this.searchFilter.dateRange.startDate
          ).toISOString();
        payload.dateRange.endDate = new Date(
          this.searchFilter.dateRange.endDate
        ).toISOString();
      }
      if (!payload.dateRange.startDate || !payload.dateRange.endDate) {
        delete payload.dateRange;
      }
      if (this.searchFilter.instituteId) {
        payload.instituteId = this.searchFilter.instituteId;
      }
      if (this.searchFilter.grades.length > 0) {
        payload.grades = this.searchFilter.grades;
      }

      this.fetchUsers(payload);
    },
    trimEditedUser(){
      this.editedUser.firstName = this.editedUser.firstName.trim();
      this.editedUser.lastName = this.editedUser.lastName.trim();
      this.editedUser.email = this.editedUser.email.toLowerCase().trim();
      this.editedUser.password =this.editedUser.password.trim();
      if( this.editedUser.userType===USER_TYPE_STUDENT){
      this.editedUser.parentName=this.editedUser.parentName.trim();
      this.editedUser.schoolName=this.editedUser.schoolName.trim();
      }
    },

    blockUnblock(item, status) {
      this.blockUnblockStatus = status;
      this.blockUnblockDialogTitle = `Are you sure to ${
        status == "UNBLOCK" ? "Unblock" : "block"
      } ${item.userType} ${item.firstName} ${item.lastName}?`;
      this.editedIndex = this.users.indexOf(item);
      this.editedUser = Object.assign({}, item);
      this.dialogBlock = true;
    },

    blockUserConfirm() {
      const payload = {};
      payload.userId = this.editedUser.userId;

      let url = "";
      if (this.blockUnblockStatus == "BLOCK") {
        url += BLOCK_USER;
      } else if (this.blockUnblockStatus == "UNBLOCK") {
        url += UN_BLOCK_USER;
      }
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closeBlockUser();
          this.initialize();
          this.setOverlayValue(false);
          this.setApiSuccess(
            `User ${
              this.blockUnblockStatus == "BLOCK" ? "blocked" : "unblocked"
            } successfully`
          );
        })
        .catch((error) => {
          this.closeBlockUser();
          this.setOverlayValue(false);
        });
    },
    openUpdatePasswordDialog(item) {
      this.updatePasswordDialog = true;
      this.editedUser.userId = item.userId;
    },
    closeUpdatePasswordDialog() {
      this.updatePasswordDialog = false;
      this.password1= "";
      this.password2= "";
    },
    close() {
      this.dialog = false;
      this.students = [];
      this.instituteId = "";
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeBlockUser() {
      this.dialogBlock = false;
      this.$nextTick(() => {
        this.editedUser = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    updatePassword() {
      if (this.password1 === this.password2) {
        let payload = {};
        payload.userId = this.editedUser.userId;
        payload.password = this.password1;
        let url = UPDATE_PASSWORD;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.closeUpdatePasswordDialog();
            this.password1 = "";
            this.password2 = "";
            this.initialize();
            this.setOverlayValue(false);
            this.setApiSuccess("User Password updated successfully");
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      } else {
        this.setApiError("Passwords don't match");
      }
    },
    save() {
      this.validate();
      if (this.createUserValidation.valid) {
        this.trimEditedUser();
        let payload = this.editedUser;
        let url = CREATE_USER;
        this.setOverlayValue(true);
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createUserValidation.valid = false;
            this.close();
            this.initialize();
            this.setOverlayValue(false);
            this.setApiSuccess("User created successfully");
          })
          .catch((error) => {
            this.setOverlayValue(false);
          });
      }
    },
    selectAll() {
      this.$nextTick(() => {
        if (this.selectAllGrades()) {
          this.searchFilter.grades = [];
        } else {
          this.searchFilter.grades = this.gradesArray;
        }
      });
    },
    selectAllGrades() {
      return this.searchFilter.grades.length === this.gradesArray.length;

    },
    selectSomeGrades() {
      return this.searchFilter.grades.length > 0 && !this.selectAllGrades();
    },
    icon() {
      if (this.selectAllGrades()) return "mdi-close-box";
      if (this.selectSomeGrades()) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    showCalendar(item) {
      var userParams = {};
      userParams.userType = USER_TYPE_TEACHER;
      userParams.teacherId = item.teacher.teacherId;
      userParams.firstName = item.firstName;
      userParams.lastName = item.lastName;
      this.setParams(userParams);
      this.$router.push({
        name: "TeacherCalendar",
        params: {
          teacherId: item.teacher.teacherId,
        },
      });
    },
    showClasses(item) {
      var userParams = {};
      userParams.firstName = item.firstName;
      userParams.lastName = item.lastName;
      this.setParams(userParams);
      if (this.searchFilter.userType == USER_TYPE_TEACHER) {
        userParams.userType = USER_TYPE_TEACHER;
        userParams.teacherId = item.teacher.teacherId;
        this.$router.push({
          name: "TeacherClasses",
          params: {
            teacherId: item.teacher.teacherId,
          },
        });
      } else if (this.searchFilter.userType == USER_TYPE_STUDENT) {
        userParams.userType = USER_TYPE_STUDENT;
        userParams.studentId = item.student.studentId;
        this.$router.push({
          name: "StudentClasses",
          params: {
            studentId: item.student.studentId,
          },
        });
      }
    },
  },
  components: { ContextMenu, TeacherCalendar, TeacherClasses, StudentClasses },
};
</script>
