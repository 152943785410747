<template>
  <v-container fluid>
    <v-card flat>

      <v-data-table
        :headers="headers"
        :items="textAssignments"
        hide-default-header
        hide-default-footer
        item-key="assignmentNumber"
        :items-per-page="itemsPerPage"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="primary"
                @click="editTextAssignment(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="error"
                @click="deleteAssignmentConfirmDialog(item)"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
                :disabled="item.assignmentNumber == 1"
                @click="openMoveAssignmentDialog(item, 'UP')"
              >
                mdi-arrow-up-bold
              </v-icon>
            </template>
            <span>Move Assignment Up</span>
          </v-tooltip>
          <v-tooltip bottom v-if="course.status != STATUS_PUBLISHED">
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="blue"
               :disabled="item.assignmentNumber == textAssignments.length"
                @click="openMoveAssignmentDialog(item, 'DOWN')"
              >
                mdi-arrow-down-bold
              </v-icon>
            </template>
            <span>Move Assignment Down</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="success"
                @click="previewTextAssignment(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>View</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.type`]="{ item }">
  <v-chip
    small
    :color="item.afterContent == null ? 'green' : 'blue'"
    text-color="white"
  >
    {{ item.afterContent == null ? 'Homework' : 'After Content' }}
    <span v-if="item.afterContent != null">&nbsp;{{ item.afterContent }}</span>
  </v-chip>
</template>
      </v-data-table>

      <v-dialog persistent v-model="moveAssignmentDialog" width="500">
        <v-card>
          <v-card-title class="primary--text">Move Text {{ direction }}</v-card-title>
          <v-form ref="form" v-model="moveAssignmentValidation.valid">
            <v-card-text>
              <v-chip class="primary ml-1">Text {{editedAssignment.assignmentNumber }}</v-chip>
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="editedAssignment.destinationIndex"
                      :rules="moveAssignmentValidation.nameRules"
                      type="number"
                      required
                      label="Move Text to"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="closeMoveAssignmentDialog()"
                    >Cancel
                  </v-btn>
                  <v-btn color="primary" text @click="moveAssignment()">
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-card-actions>
        <v-row align="end" justify="end">
          <v-dialog persistent v-model="assignmentDialog" width="1000">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addAssignmentDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="success"> mdi-plus</v-icon>
                  </template>
                  <span>Add Text Assignment</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text"
                >New Text Assignment</v-card-title
              >
              <v-card-text>
                <v-chip-group column>
                  <v-chip class="primary">Chapter {{ chapterNo }}</v-chip>
                  <v-chip class="primary">Lesson {{ lessonNo }}</v-chip>
                </v-chip-group>
                <v-form ref="form" v-model="createAssignmentValidation.valid">
                  <v-container>
                    <v-row>
                      <v-col cols="5">
                        <v-text-field
                          v-model="editedAssignment.title"
                          outlined
                          label="Title"
                          :rules="createAssignmentValidation.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          type="number"
                          required
                          label="Coins"
                          v-model="editedAssignment.coins"
                          :rules="createAssignmentValidation.coinRules"
                          :disabled="editedIndex != -1"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col col="2"
                      v-if="editedAssignment.isHomework!='true'"
                        :disabled="true"
                        >
                          <v-text-field
                          type="number"
                          required 
                          label="After content"
                          v-model="editedAssignment.afterContent"
                          :rules="createAssignmentValidation.contentRules"
                          
                          >
                          </v-text-field>
                        </v-col>
                          <v-col
                        >
                          <v-checkbox
                          v-model="editedAssignment.isHomework"
                          label="Home Work"
                          value="true"
                          required
                          :disabled="editedIndex != -1"
                          >
                          </v-checkbox>
                        </v-col>

                    </v-row>
                    <v-row>
                      <v-col>
                        <span>Question</span>
                        <quill-editor
                        style="height: 140px; margin-bottom:40px;"
                    :options="editorOptions"
                          v-model="editedAssignment.question"
                          @input="validateQuestion()"
                        ></quill-editor>
                        <span class="red--text text-caption">{{
                          questionFieldError
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <span>Answer</span>
                        <quill-editor
                        style="height: 140px; margin-bottom:40px;"
                    :options="editorOptions"
                          v-model="editedAssignment.answer"
                          @input="validateAnswer()"
                        >
                        </quill-editor>
                        <span class="red--text text-caption">{{
                          answerFieldError
                        }}</span>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveTextAssignment()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-dialog
            persistent
            v-model="previewTextAssignmentDialog"
            width="60%"
            class="pa-0"
          >
            <v-toolbar dark color="primary">
              <v-toolbar-title style="font-weight: 500; font-size: 24px"
                >Text Assignment</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn icon dark @click="closepreviewTextAssignmentDialog()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card v-model="editedAssignment">
              <v-col>
                <v-row class="mt-1 pl-3">
                  <span
                    style="font-weight: 500; font-size: 20px"
                    class="error--text"
                    >Title:
                  </span>
                  <span
                    class="ml-1"
                    style="font-size: 20px"
                    v-html="editedAssignment.title"
                  ></span>
                </v-row>
              </v-col>
              <v-col>
                <span
                  class="mt-4 ml-0 error--text"
                  style="font-weight: 450; font-size: 20px"
                  >Question:</span
                >
                <div
                  class="overflow-auto my-2"
                  style="border: 2px solid #ccc; padding: 5px; height: 200px"
                  v-html="editedAssignment.question"
                ></div>
              </v-col>
              <v-col>
                <span
                  class="mt-4 ml-0 error--text"
                  style="font-weight: 450; font-size: 20px"
                  >Answer:</span
                >
                <div
                  class="overflow-auto my-2"
                  style="border: 2px solid #ccc; padding: 5px; height: 200px"
                  v-html="editedAssignment.answer"
                ></div>
              </v-col>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="editLessonDialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="editLesson()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">mdi-pencil</v-icon>
                  </template>
                  <span>Edit Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary--text">Edit Lesson</v-card-title>
              <v-form ref="form" v-model="createLessonValidation.valid">
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="editedLesson.name"
                          label="Lesson Name"
                          :rules="createLessonValidation.nameRules"
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close()"
                        >Cancel
                      </v-btn>
                      <v-btn color="primary" text @click="saveLesson()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-container>
                </v-card-text>
              </v-form>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="deleteLessonDialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="course.status != STATUS_PUBLISHED"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteLessonConfirmDialog()"
                icon
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="error">mdi-delete</v-icon>
                  </template>
                  <span>Delete Lesson</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card>
              <v-card-title
                >Are you sure you want to delete this Lesson?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDialog()"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteLesson()">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            persistent
            v-model="deleteAssignmentDialog"
            max-width="500px"
          >
            <v-card>
              <v-card-title
                >Are you sure you want to delete this ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDeleteDialog"
                  >Cancel</v-btn
                >
                <v-btn color="primary" text @click="deleteAssignment">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template> 
<script>
import {
  STATUS_PUBLISHED,
  USER_TYPE_CONTENT_MANAGER,
  USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  DELETE_ASSIGNMENT,
  UPDATE_TEXTASSIGNMENT,
  ADD_TEXTASSIGNMENT,
  BASE_URL,
  DELETE_LESSON,
  GET_COURSE_BY_ID,
  MOVE_ASSIGNMENT,
  UPDATE_LESSON,
} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
import ResourceSearch from "./ResourceSearch.vue";
import { SET_OVERLAY_VALUE } from "@/store/mutations";
import { SET_API_SUCCESS } from "@/store/mutations";
export default {
  data() {
    return {
      editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
      editorConfig: {
        height: 90,
      },
      headers: [
        {
          text: "Assignment No",
          align: "start",
          value: "assignmentNumber",
        },

        { text: "Title", value: "title" },

        { text: "Type", value: "type" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      assignmentDialog: false,
      moveAssignmentDialog: false,
      deleteAssignmentDialog: false,
      editLessonDialog: false,
      deleteLessonDialog: false,
      previewTextAssignmentDialog: false,
      previewData: {},
      editedIndex: -1,
      itemsPerPage: -1,
      editedAssignment: {},
      editedLesson: {},
      defaultItem: {},
      course: {},
      direction: "",
      overlay: false,
      quizes: [],
      questionFieldError: "",
      answerFieldError: "",
      createLessonValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      moveAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
      },
      createAssignmentValidation: {
        valid: false,
        nameRules: [(v) => !!v || "This is required"],
        coinRules: [
          (v) => !!v || "This is required",
          (v) => Number(1) <= v || "Coins must be greater than 0",
          (v) => Number(1000) >= v || "Coin must be less than 1000",
        ],
        contentRules:[
        (v) => !!v || "This is required",
          (v) => (Number(0) < v && Number(this.noOfContents) >= v) || "content must be greater than 0 and less than "+(this.noOfContents+1),
        ],
      },
      USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      menu: false,
    };
  },
  components: { ResourceSearch },
  props: ["textAssignments", "courseId", "chapterNo", "lessonNo","noOfContents"],
  mounted: function () {
    // this.getAllQuiz();
    this.getCourseById();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    // getAllQuiz() {
    //     let url = GET_ALL_QUIZ + "?status=PUBLISHED";
    //     this.setOverlayValue(true);
    //     axios
    //         .get(url, getAxiosOptions(BASE_URL, this.token))
    //         .then((res) => {
    //             this.quizes = res.data.quizes;
    //             this.setOverlayValue(false);
    //         })
    //         .catch((error) => {
    //             error = error.errorMessage;
    //             this.setOverlayValue(false);
    //         });
    // },

    getCourseById() {
      const url = GET_COURSE_BY_ID;
      const payload = {};
      payload.courseId = this.courseId;

      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.course = res.data;
          // this.assignLessons();
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    // getColor(type) {
    //     if (type == "MEDIA")
    //         return "success";
    //     else if (type == "TEXT")
    //         return "red";
    //     else if (type == "QUIZ")
    //         return "purple";
    //     else
    //         return "primary";
    // },

    validate() {
      this.$refs.form.validate();
    },
    addAssignmentDialog() {
      this.assignmentDialog = true;
      this.editedAssignment = Object.assign({}, this.defaultItem);
    },
    close() {
      this.deleteLessonDialog = false;
      this.assignmentDialog = false;
      this.editLessonDialog = false;

      // this.assignmentDialog = false;
      this.deleteAssignmentDialog = false;
      this.$nextTick(() => {
        this.editedLesson = Object.assign({}, this.defaultItem);
        this.editedAssignment = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteLessonConfirmDialog(item) {
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
      this.deleteLessonDialog = true;
    },
    closeDeleteDialog() {
      this.deleteLessonDialog = false;
      this.deleteAssignmentDialog = false;
      this.$nextTick(() => {
        this.editedChapter = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validateQuestion() {
      if (this.editedAssignment.question.length == 0) {
        this.questionFieldError = "This is required";
      } else {
        this.questionFieldError = "";
      }
    },
    validateAnswer() {
      if (this.editedAssignment.answer.length == 0) {
        this.answerFieldError = "This is required";
      } else {
        this.answerFieldError = "";
      }
    },
    deleteLesson() {
      const url = DELETE_LESSON;
      let payload = {};
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.deleteType = "CHAPTER";
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.$emit("update");
          this.closeDeleteDialog();
          this.setOverlayValue(false);
          this.setApiSuccess("Chapter Lesson deleted successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    editLesson(item) {
      this.editLessonDialog = true;
      this.editedIndex = 0;
      this.editedLesson = Object.assign({}, item);
    },
    previewTextAssignment(item) {
      this.editedAssignment = item;
      this.previewTextAssignmentDialog = true;
    },
    closepreviewTextAssignmentDialog() {
      this.previewTextAssignmentDialog = false;
    },

    editTextAssignment(item) {
      this.assignmentDialog = true;
      this.editedIndex = 0;
      this.editedAssignment = Object.assign({}, item);
    },
    openMoveAssignmentDialog(item, direction) {
      this.moveAssignmentDialog = true;
      this.editedAssignment.assignmentNumber = item.assignmentNumber;
      this.direction = direction;
      if (direction == "UP") {
        this.editedAssignment.direction = "UP";
      } else {
        this.editedAssignment.direction = "DOWN";
      }
    },
    closeMoveAssignmentDialog() {
      this.moveAssignmentDialog = false;
      this.$nextTick(() => {
        this.editedAssignment = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    moveAssignment() {
      if (this.moveAssignmentValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
        payload.assignmentNumber = this.editedAssignment.assignmentNumber;
        payload.direction = this.editedAssignment.direction;
        payload.destinationIndex = this.editedAssignment.destinationIndex;
        payload.assignmentType= "TEXT";
        let url = MOVE_ASSIGNMENT;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.moveAssignmentValidation.valid = false;
            this.editedIndex = -1;
            this.$emit("update");
            this.close();
            this.setOverlayValue(false);
            this.closeMoveAssignmentDialog();
            this.setApiSuccess("Text Assignment moved successfully");
            // this.getCourseById();
            // this.initialize();
          })
          .catch((error) => {
            console.log(error);
            this.setOverlayValue(false);
            this.closeMoveAssignmentDialog();
          });
      }
    },
    saveLesson() {
      if (this.createLessonValidation.valid) {
        let payload = {};
        this.setOverlayValue(true);
        if (this.editedIndex > -1) {
          payload.courseId = this.courseId;
          payload.lessonName = this.editedLesson.name;
          payload.lessonNo = this.lessonNo;
          payload.chapterNo = this.chapterNo;
          let url = UPDATE_LESSON;
          axios
            .post(url, payload, getAxiosOptions(BASE_URL, this.token))
            .then((res) => {
              this.createLessonValidation.valid = false;
              this.editedIndex = -1;
              this.$emit("update");
              this.close();
              this.setOverlayValue(false);
              this.setApiSuccess("Chapter Lesson updated successfully");
              // this.getCourseById();
              // this.initialize();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    saveTextAssignment() {
      this.validate();
      if (this.createAssignmentValidation.valid) {
        this.overlay = true;
        const payload = {};
        let url = ADD_TEXTASSIGNMENT;
        if (this.editedIndex > -1) {
          url = UPDATE_TEXTASSIGNMENT;
          payload.assignmentNumber = this.editedAssignment.assignmentNumber;
        }
        payload.title = this.editedAssignment.title.trim();
        payload.question = this.editedAssignment.question;
        payload.answer = this.editedAssignment.answer;
        payload.coins = this.editedAssignment.coins;
        payload.courseId = this.courseId;
        payload.chapterNo = this.chapterNo;
        payload.lessonNo = this.lessonNo;
        if(this.editedAssignment.isHomework){
          this.editedAssignment.afterContent=null;
        }
        payload.afterContent =this.editedAssignment.afterContent;
        payload.isHomework=this.editedAssignment.isHomework;
        axios
          .post(url, payload, getAxiosOptions(BASE_URL, this.token))
          .then((res) => {
            this.createAssignmentValidation.valid = false;
            this.overlay = false;
            this.editedIndex = -1;
            this.$emit("update");
            this.close();
            this.setOverlayValue(false);
            this.setApiSuccess("Assignment saved successfully");
          })
          .catch((error) => {
            console.log(error);
            this.overlay = false;
          });
      }
    },
    deleteAssignmentConfirmDialog(item) {
      this.editedAssignment = Object.assign({}, item);
      this.deleteAssignmentDialog = true;
    },
    deleteAssignment() {
      let url = DELETE_ASSIGNMENT;
      let payload = {};
      this.setOverlayValue(true);
      payload.courseId = this.courseId;
      payload.chapterNo = this.chapterNo;
      payload.lessonNo = this.lessonNo;
      payload.assignmentNumber = this.editedAssignment.assignmentNumber;
      payload.assingmentType = "TEXT";

      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.createAssignmentValidation.valid = false;
          this.editedIndex = -1;
          this.$emit("update");
          this.close();
          this.setOverlayValue(false);
          this.setApiSuccess("Assignment deleted successfully");
        })
        .catch((error) => {
          console.log(error);
          this.setOverlayValue(false);
        });
    },

    //     previewDialogOpen(item) {
    //         this.previewData = Object.assign({}, item);
    //         this.previewDialog = true;

    //     },
    //     closePreviewDialog() {
    //         this.previewData = {};
    //         this.previewDialog = false;
    //     }
  },
};
</script>

<style scoped>
.after-content-value {
  margin-left: 4px; /* Adjust the value as needed for the desired gap */
}
</style>
