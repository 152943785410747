<template>
  <v-row align="center">
    <v-col cols="1" class="pa-0 ma-1">
      <v-card flat>
        <v-badge icon bottom avatar overlap @click.native.stop>
          <template v-slot:badge>
            <v-icon @click="addThumbnail()">mdi-camera</v-icon>
            <input
              ref="uploader"
              class="d-none"
              accept="image/png, image/gif, image/jpeg"
              type="file"
              @change="onFileChanged"
            />
          </template>
          <v-avatar
            v-if="!preview && !competition.thumbnail"
            style="border: 2px solid #195d80"
            size="70"
            @click="imgDialog = !imgDialog"
          >
            <v-img
              class="align-start text-white"
              height="50"
              src="@/assets/Avatar_sales.png"
              contain
            >
            </v-img>
          </v-avatar>
          <v-avatar
            v-else-if="!preview && competition.thumbnail"
            style="border: 2px solid #195d80"
            size="70"
            @click="imgDialog = !imgDialog"
          >
            <v-img :src="competition.thumbnail"> </v-img>
          </v-avatar>
          <v-avatar
            style="border: 2px solid #195d80"
            v-else
            size="70"
            @click="imgDialog = !imgDialog"
          >
            <v-img :src="preview"> </v-img>
          </v-avatar>
        </v-badge>
        <v-card-actions v-if="showUploadBtns">
          <v-btn color="primary" x-small icon @click.native.stop>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="uploadThumbnail()">mdi-upload</v-icon>
              </template>
              <span>Upload</span>
            </v-tooltip>
          </v-btn>
          <v-btn color="primary" x-small icon @click.native.stop>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="error" @click="cancelUpload()"
                  >mdi-cancel</v-icon
                >
              </template>
              <span>Cancel</span>
            </v-tooltip>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

    <v-dialog v-model="imgDialog" width="300">
      <v-card>
        <v-img
          v-if="!preview && !competition.thumbnail"
          class="align-start text-white"
          height="50"
          src="@/assets/Avatar_sales.png"
          contain
        ></v-img>
        <v-img
          v-if="!preview && competition.thumbnail"
          :src="competition.thumbnail"
        ></v-img>
        <v-img v-if="preview" :src="preview"></v-img>
      </v-card>
    </v-dialog>

    <v-col cols="3">
      <span style="font-size: 22px; font-weight: 500; color: #195d80">{{
        competition.name
      }}</span>
    </v-col>
    <v-col cols="2" class="ml-n2 ma-1 pa-0">
      <v-row justify="center">
        <v-chip small label :color="getStatusColor(competition)"
          >{{ competition.status }}
        </v-chip>
      </v-row>
    </v-col>
    <v-col cols="1" class="ma-2 pa-0">
      <v-row justify="center">
        <v-chip
          small
          label
          :color="statusColor"
          style="color:white;"
        >
          {{ statusText }}
        </v-chip>
      </v-row>
    </v-col>
    <v-col cols="1" class="ml-n2 pa-0 ma-1 mr-4">
      <v-row justify="center">
        <v-btn
          color="primary"
          v-if="competition.status != STATUS_PUBLISHED"
          icon
          @click="publishCompetitionConfirm()"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-img
                v-on="on"
                src="../assets/Publish.svg"
                max-height="25"
                max-width="25"
              ></v-img>
            </template>
            <span>Publish Competition</span>
          </v-tooltip>
        </v-btn>
      </v-row>
    </v-col>

    <v-row class="ml-n6">
      <v-col cols="5" class="mr-n2 ma-1 pa-0">
        <v-col class="ma-1 pa-0">
          <span style="font-weight: 500; font-size: 18px" class="primary--text"
            >Start Date:</span
          >
        </v-col>
        <v-col class="ma-1 pa-0">
          <span style="font-weight: 400; font-size: 12px">
            {{ formatDateTime(competition.startDate) }}</span
          >
        </v-col>
      </v-col>
      <v-col cols="1" class="mr-n2 pa-0 ma-1">
        <v-row justify="start" class="pa-0 ma-0">
          <div class="vertical pa-0"></div>
        </v-row>
      </v-col>
      <v-col cols="5" class="ml-n2 ma-1 pa-0">
        <v-col class="ma-1 pa-0">
          <span style="font-weight: 500; font-size: 18px" class="primary--text"
            >End Date:</span
          >
        </v-col>
        <v-col class="ma-1 pa-0">
          <span style="font-weight: 400; font-size: 12px">{{
            formatDateTime(competition.endDate)
          }}</span>
        </v-col>
      </v-col>
    </v-row>
    
    <v-dialog persistent v-model="publishConfirmDialog" max-width="500px">
      <v-card>
        <v-card-title
          >Are you sure you want to publish {{ competition.name }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePublishDailog()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="publishCompetition()">Yes</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {
  BASE_URL,
  PUBLISH_COMPETETION,
  GET_ALL_QUIZ_COMPETITIONS,
  GET_ALL_QUIZ,
  CREATE_COMPETETION,
  ADD_COMPETITION_THUMBNAIL,
} from "@/common/apiEndpoints";
import { getAxiosOptions, formatDateTime } from "@/common/utility";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { SET_API_SUCCESS, SET_OVERLAY_VALUE } from "@/store/mutations";
import {
  USER_TYPE_RESOURCE_MANAGER,
  STATUS_PUBLISHED,
} from "@/common/constants";
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      currentDate: new Date(),
      file: "",
      preview: "",
      showUploadBtns: false,
      imgDialog: false,
      USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      publishConfirmDialog: false,
    };
  },
  props: ["competition"],
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      userType: `schoolModule/${GET_USER_TYPE}`,
    }),
    statusText() {
      const start = new Date(this.competition.startDate);
      const end = new Date(this.competition.endDate);

      if (this.currentDate < start) {
        return 'Upcoming';
      } else if (this.currentDate >= start && this.currentDate <= end) {
        return 'Live';
      } else {
        return 'Completed';
      }
    },
    statusColor() {
      const start = new Date(this.competition.startDate);
      const end = new Date(this.competition.endDate);

      if (this.currentDate < start) {
        return 'green';
      } else if (this.currentDate >= start && this.currentDate <= end) {
        return 'red';
      } else {
        return 'blue';
      }
    }
  },
  mounted() {
    this.interval = setInterval(() => {
      this.currentDate = new Date();
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    // getStatusText(startDate, endDate) {
    //   const currentDate = new Date();
    //   const start = new Date(startDate);
    //   const end = new Date(endDate);

    //   if (currentDate < start) {
    //     return 'Upcoming';
    //   } else if (currentDate >= start && currentDate <= end) {
    //     return 'Live';
    //   } else {
    //     return 'Completed';
    //   }
    // },
    // getStatus1Color(startDate, endDate) {
    //   const currentDate = new Date();
    //   const start = new Date(startDate);
    //   const end = new Date(endDate);

    //   if (currentDate < start) {
    //     return 'green';
    //   } else if (currentDate >= start && currentDate <= end) {
    //     return 'red';
    //   } else {
    //     return 'blue';
    //   }
    // },
    getStatusColor(quizCompetition) {
      if (quizCompetition.status == "PUBLISHED") return "success";
      else return "red white--text";
    },
    formatDateTime(date) {
      return formatDateTime(date);
    },
    addThumbnail() {
      window.addEventListener("focus", () => {}, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.file = e.target.files[0];
      if (this.file) {
        this.preview = URL.createObjectURL(e.target.files[0]);
      } else {
        this.preview = "";
      }
      this.showUploadBtns = true;
    },
    uploadThumbnail() {
      let payload = {};
      payload.file = this.file;
      payload.compId = this.competition.quizCompId;
      let url = ADD_COMPETITION_THUMBNAIL;

      const options = getAxiosOptions(BASE_URL, this.token);
      options.headers["Content-Type"] = "multipart/form-data";
      this.setOverlayValue(true);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.setOverlayValue(false);
          this.setApiSuccess("Thumbnail updated successfully");
          this.$emit("updateCompetetion");
          this.file = "";
          this.preview = "";
          this.showUploadBtns = false;
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    closePublishDailog() {
      this.publishConfirmDialog = false;
    },
    publishCompetitionConfirm() {
      this.publishConfirmDialog = true;
    },
    publishCompetition() {
      const url = PUBLISH_COMPETETION;
      let payload = {};
      payload.quizCompId = this.competition.quizCompId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.closePublishDailog();
          this.$emit("updateCompetetion");
          this.setOverlayValue(false);
          this.setApiSuccess("Competition published successfully");
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          this.closePublishDailog();
        });
    },
    cancelUpload() {
      this.showUploadBtns = false;
      this.file = "";
      this.preview = "";
    },
  },
};
</script>