<template>
<v-container v-if="!sendForVerification">
  <v-card class="mx-auto px-6 py-8" max-width="450">
    <v-img max-height="150" src="../assets/APT_LogoR.png"></v-img>

    <v-card-title class="font-weight-black">Please Contact Your Admin</v-card-title>
    <!-- <v-col>
    <v-text-field v-model="username" :readonly="loading" :rules="[required]" placeholder="Enter Email" outlined dense
      density="compact"></v-text-field>
      </v-col>
    <v-card-actions>
      <v-btn color="primary"  block size="large" type="submit" @click="sendForVerificationCode()">
          Send Verification Code
        </v-btn> 
    </v-card-actions>-->
    <a><span class="text-subtitle-2 ma-3" v-if="!loginWithOtp" color="primary" @click="backToLogin()" >Back to login</span></a>
  </v-card>
  </v-container>
  <v-container v-else>
  <v-card class="mx-auto px-6 py-8" max-width="450">
    <v-btn  @click="backToLogin()" icon><v-icon large>mdi-arrow-left</v-icon></v-btn>
    <v-img max-height="150" src="../assets/APT_LogoR.png"></v-img>
    <v-col>
    <v-text-field v-model="password" :readonly="loading" :rules="[required]" placeholder="Enter Paasword" outlined dense
      density="compact"></v-text-field>
      </v-col>
      <v-col>
    <v-text-field v-model="password" :readonly="loading" :rules="[required]" placeholder="Enter Paasword" outlined dense
      density="compact"></v-text-field>
      </v-col>
      <v-col>
    <v-text-field v-model="password" :readonly="loading" :rules="[required]" placeholder="Enter Paasword" outlined dense
      density="compact"></v-text-field>
      </v-col>
    <v-card-actions>
      <v-btn color="primary"  block size="large" type="submit" @click="sendForVerificationCode()">
          Send Verification Code
        </v-btn>
    </v-card-actions>
    <a><span class="text-subtitle-2 ma-3" v-if="!loginWithOtp" color="primary" @click="backToLogin()" >Back to login</span></a>
  </v-card>
  </v-container>
</template>

<script>
import { BASE_URL, LOGIN_URL, SEND_VERIFICATION_CODE } from '@/common/apiEndpoints.js';
import axios from "axios";

export default {
  name: "ResetPassword",
  data(){
    return{
      username:'',
      sendForVerification: false,
    }
  },
  methods: {
    sendForVerificationCode() {
      const url = BASE_URL + SEND_VERIFICATION_CODE;
      // const url = BASE_URL + LOGIN_URL;
      const payload = {};
      payload.email = this.username;
      this.sendForVerification= true;
      // axios
      //   .post(url, payload)
      //   .then((res) => {
      //     console.log(res.data);
      //     this.sendForVerification= true;
      //   })
      //   .catch((error) => {
      //     error = error.data.message;
      //   });
    },
    backToLogin(){
        this.$router.push({ path: "/login"});
    }
  },

}

</script>