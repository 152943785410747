<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar flat dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px"
        >My Orders</v-toolbar-title
      >
    </v-toolbar>
    <v-container fluid>
      <ContextMenu   :contextMenu="contextMenuObject" ></ContextMenu>
      <v-data-table
        :headers="OrderHeaders"
        :items="orders"
        :hide-default-footer="orders.length <= 10"
        item-key="orderId"
        item-value="orderId"
        :single-expand="true"
        show-expand
        @item-expanded="expandStudentOrders"
        @contextmenu:row="rightClickHandler" 
      >
        <template v-slot:[`item.courseId`]="{ item }">
          <router-link
            style="text-decoration: none"
            class="text-h6"
            to="/home/student-courses"
            >{{ getCourseNameById(item.courseId) }}</router-link
          >
        </template>
        <template v-slot:[`item.createdOn`]="{ item }">
          {{ formatDateTime(item.createdOn) }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <!-- <v-row justify="center">
          <v-col class="pa-0 ma-0"> -->
          <v-chip
            small
            :color="getOrderStatusColor(item)"
            text-color="white"
            label
            background
          >
            {{ item.status }}
          </v-chip>
          <!-- </v-col>
        </v-row> -->
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            bottom
            v-if="!item.isDuplicate && item.status != ORDER_STATUS_SUCCESS"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="primary"
                @click="checkPayment(item)"
              >
                mdi-cash-sync
              </v-icon>
            </template>
            <span>Check Payment</span>
          </v-tooltip>
          <v-tooltip
            bottom
            v-if="!item.isDuplicate && item.status != ORDER_STATUS_SUCCESS"
          >
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="primary"
                @click="retryPayment(item)"
              >
                mdi-credit-card-settings-outline
              </v-icon>
            </template>
            <span>Retry Payment</span>
          </v-tooltip>
          <v-chip
            v-if="item.isDuplicate"
            small
            color="error"
            text-color="white"
            label
            background
            >Duplicate Order</v-chip
          >
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Days</th>
                    <th class="text-center">Timings</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="schedule in item.schedule" :key="schedule.day">
                    <td
                      style="color: #ff6666; font-size: 14px; font-weight: 600"
                    >
                      {{ schedule.day }}
                    </td>
                    <td
                      style="font-size: 16px; font-weight: 500"
                      class="text-center"
                    >
                      {{ formattedTime(schedule.openTime) }} to
                      {{ formattedTime(schedule.closeTime) }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>
<script>
import {
  BASE_URL,
  GET_ORDERS,
  GET_STUDENT_COURSES,
  CHECK_PAYMENT,
  ORDER_VALIDATE,
} from "@/common/apiEndpoints";
import {
  ORDER_STATUS_CANCELLED,
  ORDER_STATUS_SUCCESS,
  ORDER_STATUS_PAYMENT_DONE,
  ORDER_STATUS_PAYMENT_PENDING,
  ORDER_STATUS_PAYMENT_FAILED,
} from "@/common/constants";
import {
  getAxiosOptions,
  formatDateTime,
  formattedTime,
  rightClickHandler
} from "@/common/utility";
import { GET_TOKEN, GET_USER_PROFILE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import ContextMenu from "./ContextMenu.vue";
export default {
  data() {
    return {
      contextMenuObject:{},
      courses: [],
      orders: [],
      OrderHeaders: [
        { text: "", value: "data-table-expand" },
        {
          text: "Course Name",
          value: "courseId",
          width: "25%",
        },
        {
          text: "Price",
          value: "orderAmount",
        },
        {
          text: "Teacher Name",
          value: "teacherName",
          align: "center"
        },
        { text: "Created On", align: "center", value: "createdOn" },
        { text: "Classes", align: "center", value: "classes" },
        { text: "Status", align: "center", value: "status" },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      ORDER_STATUS_PAYMENT_PENDING: ORDER_STATUS_PAYMENT_PENDING,
      ORDER_STATUS_SUCCESS: ORDER_STATUS_SUCCESS,
      ORDER_STATUS_PAYMENT_DONE: ORDER_STATUS_PAYMENT_DONE,
      ORDER_STATUS_CANCELLED: ORDER_STATUS_CANCELLED,
      ORDER_STATUS_PAYMENT_FAILED: ORDER_STATUS_PAYMENT_FAILED,
    };
  },
  mounted: function () {
    this.fetchCourses();
    this.getOrders();
  },
  computed: {
    ...mapGetters({
      token: `schoolModule/${GET_TOKEN}`,
      profile: `schoolModule/${GET_USER_PROFILE}`,
    }),
  },
  components:{ContextMenu},
  methods: { 
    rightClickHandler(e, item) {
      e.preventDefault();
            // do something with e and/or item
           if(!e.target.textContent) return;
           this.contextMenuObject= rightClickHandler(e.target.textContent,e.clientX,e.clientY)
        },
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    formattedTime(date) {
      return formattedTime(date);
    },
    checkPayment(item) {
      let payload = {};
      payload.orderId = item.gatewayOrderId;
      let url = CHECK_PAYMENT;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          let index = this.orders.findIndex(
            (order) => order.orderId == item.orderId
          );
          Object.assign(this.orders[index], res.data);
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },
    fetchCourses() {
      let url = GET_STUDENT_COURSES;
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    getOrderStatusColor(item) {
      if (item.status == this.ORDER_STATUS_PAYMENT_PENDING) {
        return "primary";
      } else if (
        item.status == this.ORDER_STATUS_PAYMENT_DONE ||
        item.status == this.ORDER_STATUS_SUCCESS
      ) {
        return "success";
      } else if (
        item.status == this.ORDER_STATUS_CANCELLED ||
        item.status == this.ORDER_STATUS_PAYMENT_FAILED
      ) {
        return "error";
      }
    },
    getOrders() {
      let url = GET_ORDERS;
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.orders = res.data.orders;
          this.orders.map((order) => (order.isDuplicate = true));
          var uniqueCourseIds = new Set();
          this.orders.forEach((order) => uniqueCourseIds.add(order.courseId));
          const myIterator = uniqueCourseIds.values();
          for (const entry of myIterator) {
            for (let i = 0; i < this.orders.length; i++) {
              if (this.orders[i].courseId == entry) {
                this.orders[i].isDuplicate = false;
                break;
              }
            }
          }
          if (
            this.$route.params.isRedirectedFromPayment &&
            this.orders[0].status != this.ORDER_STATUS_SUCCESS
          ) {
            this.checkPayment(this.orders[0]);
          }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
       if (c.length>0 && c[0]) return c[0].courseName;
      return "No Course";
    },
    retryPayment(order) {
      let payload = {};
      let url = ORDER_VALIDATE;
      payload.orderId = order.orderId;
      this.setOverlayValue(true);
      axios
        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          if (res.data.status == true) {
            let course = this.courses.find(
              (course) => course.courseId == order.courseId
            );
            this.$router.push({
              name: "RazorPayPayment",
              params: {
                orderId: order.gatewayOrderId,
                studentDetails: this.profile,
                price: course.price,
              },
            });
          } else {
            this.setApiError("Teacher Slot is already booked");
          }
          this.setOverlayValue(false);
        })
        .catch((error) => {
          this.setOverlayValue(false);
        });
    },

    expandStudentOrders({ item }) {
      item.schedule.sort(function (a, b) {
        return a.day.id - b.day.id;
      });
    },
  },
};
</script>
