<template v-bind="$props">
  <v-card
    :class="isCompleteAssignment ? 'ma-0' : 'ma-2'"
    :style="
      isCompleteAssignment ? 'border-radius: 0px;' : 'border-radius: 10px;'
    "
    :min-height="isCompleteAssignment ? '' : '650px'"
  >
    <v-toolbar
      dark
      :color="isCompleteAssignment ? 'white' : 'primary'"
      :elevation="isCompleteAssignment ? '0' : '2'"
    >
      <v-col class="pl-0">
        <v-toolbar-title
          v-if="paramsValue.isCompleteAssignment"
          style="font-weight: 500; font-size: 24px"
          ><v-icon class="mr-2 mb-1" @click="$router.go(-1)"
            >mdi-arrow-left</v-icon
          >Code Assignment</v-toolbar-title
        >
        <v-toolbar-title
          v-else
          :class="isCompleteAssignment ? 'error--text' : 'white--text'"
          style="font-weight: 500; font-size: 24px"
          >Practice</v-toolbar-title
        >
      </v-col>
      <v-col class="pa-0 ma-1" cols="2">
        <v-select
          v-model="codeEditor.language"
          :items="allowedLanguages"
          class="mt-7"
          dense
          solo
          @change="changeLanguage()"
          item-text="name"
        ></v-select>
      </v-col>
      <v-col class="ma-1 pa-0" cols="1">
        <v-row class="ma-1 pa-0" justify="end">
          <v-btn color="info" class="ma-1" @click="executeCode()">Run</v-btn>
        </v-row>
      </v-col>
      <v-col
        class="ma-1 pa-0"
        cols="1"
        v-if="(paramsValue.isCompleteAssignment || isCompleteAssignment) && userType == USER_TYPE_STUDENT"
      >
        <v-btn
          class="ma-1"
          color="success"
          @click="openSubmitAssignmentConfirmDialog()"
        >
          Submit
        </v-btn>
      </v-col>
    </v-toolbar>
    <div>
      <v-row class="ma-1" v-if="paramsValue.isCompleteAssignment || isCompleteAssignment">
        <div class="ma-1 ml-2">
          <v-col class="pa-0 ma-1">
            <v-row class="mt-1 pl-3">
              <span
                style="font-weight: 500; font-size: 20px"
                class="error--text"
                >Question:
              </span>
              <span
                class="ml-1"
                style="font-size: 20px"
                v-html="assignmentProblem.question"
              ></span>
            </v-row>
          </v-col>
          <v-col class="pa-0 ma-1">
            <span style="font-weight: 500; font-size: 20px" class="error--text"
              >Expected Output:
            </span>
            <span style="font-size: 20px">{{
              assignmentProblem.expectedOutput
            }}</span>
          </v-col>
        </div>
      </v-row>
      <v-row class="ma-1">
        <v-col cols="8">
          <AceEditor
            v-model="codeEditor.content"
            @init="editorInit"
            :lang="codeEditor.lang"
            theme="monokai"
            width="100%"
            height="450px"
            :options="{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              fontSize: 14,
              highlightActiveLine: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 2,
              showPrintMargin: false,
              showGutter: true,
            }"
            :commands="[
              {
                name: 'save',
                bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                exec: dataSumit,
                readOnly: true,
              },
            ]"
          />
        </v-col>

        <v-col cols="4" class="pt-0">
          <v-card-text>
            <v-textarea
              outlined
              rows="2"
              v-model="codeEditor.input"
              label="Input"
            ></v-textarea>
            <!-- color="white" -->
            <v-textarea
              outlined
              rows="12"
              disabled
              v-model="codeEditor.output"
              label="Output"
            ></v-textarea>
          </v-card-text>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      persistent
      v-model="submitAssignmentConfirmDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          >Assignment can only be submitted Once. Are you sure you want to
          submit ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="closeSubmitAssignmentConfirmDialog()"
            >No</v-btn
          >
          <v-btn color="primary" text @click="submitCode()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  BASE_URL,
  EXECUTE_CODE,
  GET_CODE,
  SUBMIT_CODE,
} from "@/common/apiEndpoints";
import AceEditor from "vuejs-ace-editor";
import { getAxiosOptions } from "@/common/utility";
import { mapGetters, mapMutations } from "vuex";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import axios from "axios";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
} from "@/store/mutations";
import { USER_TYPE_STUDENT } from "@/common/constants";
export default {
  data() {
    return {
      timeout: 20,
      codeEditor: { language: "c", output: "" },
      assignmentProblem: {},
      allowedLanguages: [
        {
          code: 50,
          name: "C",
          value: "c",
          initialCodeSnippet:
            '#include <stdio.h>\n\nint main(void) {\n  \n  printf("hello World");\n  return 0;\n}',
        },
        {
          code: 54,
          name: "C++",
          value: "cpp",
          initialCodeSnippet:
            '#include <iostream>\n\nint main() {\n    std::cout << "Hello World!";\n    return 0;\n}',
        },
        {
          code: 62,
          name: "Java",
          value: "java",
          initialCodeSnippet:
            'class Main\n{\n    public static void main(String []args)\n    {\n        System.out.println("Hello World");\n    }\n};',
        },
        {
          code: 71,
          name: "Python",
          value: "python",
          initialCodeSnippet: "print('Hello, world')",
        },
        {
          code: 63,
          name: "Javascript",
          value: "javascript",
          initialCodeSnippet: "console.log('Hello World');",
        },
      ],
      submitAssignmentConfirmDialog: false,
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    };
  },
  props: ["classOpId", "assignment", "isCompleteAssignment"],
  components: {
    AceEditor,
  },
  watch: {
    assignment: {
      handler(assignment) {
        if (assignment) {
          this.assignmentProblem = assignment;
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  mounted: function () {
    this.changeLanguage();
    this.assignmentProblem = this.assignment || this.paramsValue;
  },
  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
    }),
    dataSumit() {
      //code here
    },
    editorInit: function () {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/mode/html");
      require("brace/mode/javascript"); //language
      require("brace/mode/java");
      require("brace/mode/c_cpp");
      require("brace/mode/python");
      require("brace/theme/monokai");
      //   require("brace/theme/chrome");
      require("brace/snippets/javascript");
      require("brace/snippets/c_cpp");
      require("brace/snippets/java");
      require("brace/snippets/python");
      require("brace/snippets/html");
    },
    changeLanguage() {
      let selectedLangObj = this.allowedLanguages.find(
        (lang) => lang.value.toLowerCase() == this.codeEditor.language
      );
      if (selectedLangObj.name == "C" || selectedLangObj.name == "C++") {
        this.codeEditor.lang = "c_cpp";
      }
      this.codeEditor.content = selectedLangObj.initialCodeSnippet;
      this.codeEditor.langCode = selectedLangObj.code;
    },
    executeCode() {
      if (this.codeEditor.content) {
        let payload = {};
        payload.sourceCode = this.codeEditor.content;
        payload.lang = this.codeEditor.langCode;
        payload.input = this.codeEditor.input;
        this.setOverlayValue(true);
        let url = EXECUTE_CODE;
        let oThis = this;
        const options = getAxiosOptions(BASE_URL, this.token);
        axios
          .post(url, payload, options)
          .then((res) => {
            setTimeout(() => {
              oThis.getcode(res.data.codeId);
            }, 2000);
          })
          .catch((error) => {
            error = error.errorMessage;
            console.log(error);
          });
      }
    },
    getcode(codeId) {
      let payload = {};
      payload.codeId = codeId;
      this.setOverlayValue(true);
      let url = GET_CODE;
      const options = getAxiosOptions(BASE_URL, this.token);
      axios
        .post(url, payload, options)
        .then((res) => {
          if (res.data.output) {
            this.codeEditor.output = res.data.output;
            this.setOverlayValue(false);
          } else {
            if (this.timeout > 0) {
              let oThis = this;
              this.timeout = this.timeout - 5;
              setTimeout(() => {
                oThis.getcode(codeId);
              }, 5000);
            } else {
              this.setApiError("code is Taking more time , please optimize");
              this.timeout = 20;
              this.setOverlayValue(false);
            }
          }
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
          console.log(error);
        });
    },
    openSubmitAssignmentConfirmDialog(item) {
      this.submitAssignmentConfirmDialog = true;
    },
    closeSubmitAssignmentConfirmDialog() {
      this.submitAssignmentConfirmDialog = false;
    },
    submitCode() {
      let payload = {};
      this.setOverlayValue(true);
      payload.classOpId = this.paramsValue.classOpId || this.classOpId;
      payload.assignmentNumber = this.paramsValue.assignmentNumber || this.assignment.assignmentNumber;
      payload.language = this.codeEditor.langCode;
      payload.submission = this.codeEditor.content;
      payload.stdin = this.codeEditor.input;
      let url = SUBMIT_CODE;
      const options = getAxiosOptions(BASE_URL, this.token);
      axios
        .post(url, payload, options)
        .then((res) => {
          this.setOverlayValue(false);
          this.closeSubmitAssignmentConfirmDialog();
          this.setApiSuccess("Assignment Submitted Successfully");
          this.$emit("assignmentCompleted",this.classOpId,'codeAssignment');
          if(this.paramsValue.classOpId){
            this.$router.go(-1);
          }
        })
        .catch((error) => {
          error = error.errorMessage;
          console.log(error);
          this.closeSubmitAssignmentConfirmDialog();
          this.setOverlayValue(false);
          if(this.paramsValue.classOpId){
            this.$router.go(-1);
          }
        });
    },
  },
};
</script>