<template>
    <v-container fluid>
        <v-card flat>
            <v-data-table :headers="headers" :items="codeAssignments" hide-default-header hide-default-footer
                item-key="assignmentNumber" :items-per-page="itemsPerPage">
                <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="primary" @click="editAssignment(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="error" @click="deleteAssignmentConfirmDialog(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Delete</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" class="mr-2" color="success" @click="previewCodeAssignment(item)">
                                mdi-eye
                            </v-icon>
                        </template>
                        <span>View</span>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-card-actions>

                <v-row align="end" justify="end">
                    <v-dialog persistent v-model="assignmentDialog" width="600">
                         
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primary" v-bind="attrs" v-on="on" @click="addAssignmentDialog()" icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="success"> mdi-plus</v-icon>
                                    </template>
                                    <span>Add Code Assignment</span>
                                </v-tooltip>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title class="primary--text">New Code Assignment</v-card-title>
                            <v-card-text>
                                <v-chip-group column>
                                    <v-chip class="primary">Chapter {{ chapterNo }}</v-chip>
                                    <v-chip class="primary">Lesson {{ lessonNo }}</v-chip>
                                </v-chip-group>
                                <v-form ref="form" v-model="createAssignmentValidation.valid">
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="editedAssignment.title" label="Title"
                                                    :rules="createLessonValidation.nameRules" required></v-text-field>
                                                <span>Question</span>
                                                <quill-editor v-model="editedAssignment.question"
                                                style="height: 140px; margin-bottom:30px;"
                    :options="editorOptions"
                                                    @input="validateCKeditorValues()"></quill-editor>
                                                <span class="red--text text-caption">{{
                                                    questionFieldError
                                                }}</span>
                                                <v-textarea outlined auto-grow class="pt-8" rows="1" row-height="15"
                                                    v-model="editedAssignment.expectedOutput" label="Expected Output">
                                                </v-textarea>
                                                <span>Solution</span>
                                                <quill-editor style="height: 140px; margin-bottom:30px;"
                                                :options="editorOptions" v-model="editedAssignment.solution"
                                                    @input="validateCKeditorValues()"></quill-editor>
                                                <span class="red--text text-caption">{{
                                                    solutionFieldError
                                                }}</span>
                                            </v-col>
                                        </v-row>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="close()">Cancel </v-btn>
                                            <v-btn color="primary" text @click="saveCodeAssignment()">
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-container>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="previewCodeAssignmentDialog" width="75%" class="pa-0">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title style="font-weight: 500; font-size: 24px">Code Assignment</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="closepreviewCodeAssignmentDialog()">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <PracticeCoding :key="editedAssignment.assignmentNumber" v-bind:assignment="editedAssignment"
                            v-bind:isCompleteAssignment="true" />
                    </v-dialog>

                    <v-dialog persistent v-model="editLessonDialog" width="500">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="course.status != STATUS_PUBLISHED
                                " color="primary" v-bind="attrs" v-on="on" @click="editLesson()" icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-pencil</v-icon>
                                    </template>
                                    <span>Edit Lesson</span>
                                </v-tooltip>
                            </v-btn>
                        </template>

                        <v-card>
                            <v-card-title class="primary--text">Edit Lesson</v-card-title>
                            <v-form ref="form" v-model="createLessonValidation.valid">
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col>
                                                <v-text-field v-model="editedLesson.name" label="Lesson Name"
                                                    :rules="createLessonValidation.nameRules" required></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="primary" text @click="close()">Cancel </v-btn>
                                            <v-btn color="primary" text @click="saveLesson()">
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-container>
                                </v-card-text>
                            </v-form>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="deleteLessonDialog" max-width="500px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn v-if="course.status != STATUS_PUBLISHED
                                " color="primary" dark v-bind="attrs" v-on="on" @click="deleteLessonConfirmDialog()"
                                icon>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="error">mdi-delete</v-icon>
                                    </template>
                                    <span>Delete Lesson</span>
                                </v-tooltip>
                            </v-btn>

                        </template>
                        <v-card>
                            <v-card-title>Are you sure you want to delete this Lesson?
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDeleteDialog()">Cancel</v-btn>
                                <v-btn color="primary" text @click="deleteLesson()">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog persistent v-model="deleteAssignmentDialog" max-width="500px">
                        <v-card>
                            <v-card-title>Are you sure you want to delete this ? </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="closeDeleteDialog">Cancel</v-btn>
                                <v-btn color="primary" text @click="deleteAssignment">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-container>
</template>
  
<script>
import {
    STATUS_PUBLISHED,
    USER_TYPE_CONTENT_MANAGER,
    USER_TYPE_RESOURCE_MANAGER,
} from "@/common/constants";
import PracticeCoding from "@/components/PracticeCoding.vue"
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {

    DELETE_ASSIGNMENT,
    UPDATE_CODEASSIGNMENT,
    ADD_CODEASSIGNMENT,
    BASE_URL,
    CREATE_QUIZ,
    DELETE_LESSON,
    GET_ALL_QUIZ,
    GET_COURSE_BY_ID,
    UPDATE_LESSON,
    UPLOAD_FILE,
} from "@/common/apiEndpoints";
import axios from "axios";
import { getAxiosOptions } from "@/common/utility";
import ResourceSearch from "./ResourceSearch.vue";
import { SET_OVERLAY_VALUE } from '@/store/mutations';
import { SET_API_SUCCESS } from '@/store/mutations';
export default {
    data() {
        return {
            editorOptions: {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],      
            [{ 'align': [] }],
            [{ 'color': [] }, { 'background': [] }],       
          
            ['link'],  
        ]
      }
    },
            editorConfig: {},
            headers: [
                {
                    text: "Assignment No",
                    align: "start",
                    value: "assignmentNumber"
                },
                { text: "Quiz Name", value: "quizName" },


                { text: "Title", value: "title" },

                { text: "Type", value: "type" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            assignmentDialog: false,
            deleteAssignmentDialog: false,
            editLessonDialog: false,
            deleteLessonDialog: false,
            previewCodeAssignmentDialog: false,
            previewData: {},
            editedIndex: -1,
            itemsPerPage: -1,
            editedAssignment: {},
            editedLesson: {},
            defaultItem: {},
            course: {},
            overlay: false,
            quizes: [],
            questionFieldError: '',
            solutionFieldError: '',
            createLessonValidation: {
                valid: false,
                nameRules: [(v) => !!v || "This is required"],
            },
            createAssignmentValidation: {
                valid: false,
                nameRules: [(v) => !!v || "This is required"],
            },
            USER_TYPE_CONTENT_MANAGER: USER_TYPE_CONTENT_MANAGER,
            USER_TYPE_RESOURCE_MANAGER: USER_TYPE_RESOURCE_MANAGER,
            STATUS_PUBLISHED: STATUS_PUBLISHED,
            menu: false
        };
    },
    components: { ResourceSearch, PracticeCoding },
    props: ["codeAssignments", "courseId", "chapterNo", "lessonNo"],
    mounted: function () {
        this.getAllQuiz();
        this.getCourseById();
    },
    computed: {
        ...mapGetters({
            userType: `schoolModule/${GET_USER_TYPE}`,
            token: `schoolModule/${GET_TOKEN}`,
        }),
    },
    methods: {
        ...mapMutations({
            setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
            setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`

        }),
        getAllQuiz() {
            let url = GET_ALL_QUIZ + "?status=PUBLISHED";
            this.setOverlayValue(true);
            axios
                .get(url, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.quizes = res.data.quizes;
                    this.setOverlayValue(false);
                })
                .catch((error) => {
                    error = error.errorMessage;
                    this.setOverlayValue(false);
                });
        },
        previewCodeAssignment(item) {
            this.editedAssignment = Object.assign({}, item);
            this.previewCodeAssignmentDialog = true;
        },

        getCourseById() {
            const url = GET_COURSE_BY_ID;
            const payload = {};
            payload.courseId = this.courseId;

            axios
                .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.course = res.data;
                    // this.assignLessons();

                })
                .catch((error) => {
                    error = error.errorMessage;
                });
        },
        getColor(type) {
            if (type == "MEDIA")
                return "success";
            else if (type == "TEXT")
                return "red";
            else if (type == "QUIZ")
                return "purple";
            else
                return "primary";
        },

        validate() {
            this.$refs.form.validate();
        },
        addAssignmentDialog() {
            this.assignmentDialog = true;
            this.editedAssignment = Object.assign({}, this.defaultItem);
        },
        close() {
            this.deleteLessonDialog = false;
            this.assignmentDialog = false;
            this.editLessonDialog = false;

            // this.assignmentDialog = false;
            this.deleteAssignmentDialog = false;
            this.$nextTick(() => {
                this.editedLesson = Object.assign({}, this.defaultItem);
                this.editedAssignment = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        deleteLessonConfirmDialog(item) {
            this.editedIndex = 0;
            this.editedLesson = Object.assign({}, item);
            this.deleteLessonDialog = true;
        },
        closeDeleteDialog() {
            this.deleteLessonDialog = false;
            this.deleteAssignmentDialog = false;
            this.$nextTick(() => {
                this.editedChapter = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        deleteLesson() {
            const url = DELETE_LESSON;
            let payload = {};
            payload.courseId = this.courseId;
            payload.chapterNo = this.chapterNo;
            payload.lessonNo = this.lessonNo;
            payload.deleteType = "CHAPTER";
            this.setOverlayValue(true);
            axios
                .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.$emit("update");
                    this.closeDeleteDialog();
                    this.setOverlayValue(false);
                    this.setApiSuccess("Chapter Lesson deleted successfully");
                })
                .catch((error) => {
                    error = error.errorMessage;
                    this.setOverlayValue(false);
                });
        },
        editLesson(item) {
            this.editLessonDialog = true;
            this.editedIndex = 0;
            this.editedLesson = Object.assign({}, item);
        },

        closepreviewCodeAssignmentDialog() {
            this.previewCodeAssignmentDialog = false;
        },

        editAssignment(item) {
            this.assignmentDialog = true;
            this.editedIndex = 0;
            this.editedAssignment = Object.assign({}, item);
        },
        saveLesson() {
            if (this.createLessonValidation.valid) {
                let payload = {};
                this.setOverlayValue(true);
                if (this.editedIndex > -1) {
                    payload.courseId = this.courseId;
                    payload.lessonName = this.editedLesson.name;
                    payload.lessonNo = this.lessonNo;
                    payload.chapterNo = this.chapterNo;
                    let url = UPDATE_LESSON;
                    axios
                        .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                        .then((res) => {
                            this.createLessonValidation.valid = false;
                            this.editedIndex = -1;
                            this.$emit("update");
                            this.close();
                            this.setOverlayValue(false);
                            this.setApiSuccess("Chapter Lesson updated successfully")
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            }
        },
        saveCodeAssignment() {
            this.validate();
            this.setOverlayValue(true);
            if (this.createAssignmentValidation.valid) {
                this.overlay = true;
                const payload = {};
                let url = ADD_CODEASSIGNMENT;
                if (this.editedIndex > -1) {
                    url = UPDATE_CODEASSIGNMENT;
                    payload.assignmentNumber = this.editedAssignment.assignmentNumber;
                }
                payload.title = this.editedAssignment.title;
                payload.question = this.editedAssignment.question;
                payload.solution = this.editedAssignment.solution;
                payload.expectedOutput = this.editedAssignment.expectedOutput;
                payload.courseId = this.courseId;
                payload.chapterNo = this.chapterNo;
                payload.lessonNo = this.lessonNo;
                axios
                    .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                    .then((res) => {
                        this.createAssignmentValidation.valid = false;
                        this.overlay = false;
                        this.editedIndex = -1;
                        this.$emit("update");
                        this.close();
                        this.setOverlayValue(false);
                        this.setApiSuccess("Assignment saved successfully");
                    })
                    .catch((error) => {
                        console.log(error);
                        this.overlay = false;
                    });


            }
        },
        validateCKeditorValues() {
            if (this.editedAssignment.question.length == 0) {
                this.questionFieldError = "This is required";
            } else {
                this.questionFieldError = '';
            }
            if (this.editedAssignment.solution.length == 0) {
                this.solutionFieldError = "This is required";
            } else {
                this.solutionFieldError = '';
            }

        },


        deleteAssignmentConfirmDialog(item) {
            this.editedAssignment = Object.assign({}, item);
            this.deleteAssignmentDialog = true;
        },
        deleteAssignment() {
            let url = DELETE_ASSIGNMENT;
            let payload = {};
            this.setOverlayValue(true);
            payload.courseId = this.courseId;
            payload.chapterNo = this.chapterNo;
            payload.lessonNo = this.lessonNo;
            payload.assignmentNumber = this.editedAssignment.assignmentNumber;
            payload.assingmentType = "CODE";

            payload.deleteType = "CHAPTER";
            axios
                .post(url, payload, getAxiosOptions(BASE_URL, this.token))
                .then((res) => {
                    this.createAssignmentValidation.valid = false;
                    this.editedIndex = -1;
                    this.$emit("update");
                    this.close();
                    this.setOverlayValue(false);
                    this.setApiSuccess("Assignment deleted successfully");
                })
                .catch((error) => {
                    console.log(error);
                    this.setOverlayValue(false);
                });
        },
    },



};
</script>
