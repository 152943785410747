<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="primary" elevation="2"
      ><v-toolbar-title
        style="font-weight: 500; font-size: 24px"
        v-if="$route.params.studentId || paramsValue.studentId"
      >
        <v-icon class="mr-2 mb-1" @click="$router.go(-1)">mdi-arrow-left</v-icon
        >{{
          paramsValue.firstName + " " + paramsValue.lastName
        }}</v-toolbar-title
      ><v-toolbar-title v-else style="font-weight: 500; font-size: 24px"
        >My Classes</v-toolbar-title
      >
    </v-toolbar>
    <ContextMenu :contextMenu="contextMenuObject"></ContextMenu>
    <v-container fluid>
      <!-- <h2  class="ma-4">My Classes</h2>  -->
      <!-- v-if="classRooms.length > 0" -->
      <v-data-table
        :headers="ClassRoomheaders"
        :items="classRooms"
        :hide-default-footer="classRooms.length <= 10"
        item-key="classRoomId"
        item-value="classRoomId"
        @contextmenu:row="rightClickHandler"
      >
        <template v-slot:[`item.startDate`]="{ item }">
          {{ formatDateTime(item.startDate) }}
        </template>
        <template v-slot:[`item.courseId`]="{ item }">
          <a
            v-if="userType == USER_TYPE_STUDENT"
            style="font-size: 18px; font-weight: 500"
            @click="courseDialogOpen(item.courseId)"
            >{{ getCourseNameById(item.courseId) }}</a
          >
          <a
            v-else
            style="font-size: 18px; font-weight: 500"
            @click="courseDialogOpen(item.courseId)"
            >{{ getCourseNameById(item.courseId) }}</a
          >
        </template>
        <template v-slot:[`item.classes`]="{ item }">
          <v-row
            justify="center"
            v-if="item.classes != null && item.classes != 0"
          >
            {{ item.classes }}
            {{
              item.extraClasses != null && item.extraClasses != 0
                ? " + " + item.extraClasses
                : ""
            }}
          </v-row>
          <v-row justify="center" v-else> -- </v-row>
        </template>

        <template v-slot:[`item.progress`]="{ item }">
          <v-chip
            v-if="item.progress != null"
            small
            color="success"
            label
            outlined
            >{{ item.progress.completed }} %</v-chip
          >
          <v-chip
            v-if="item.progress == null"
            small
            color="success"
            label
            outlined
            >0</v-chip
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                class="mr-2"
                color="success"
                @click="viewSchedule(item)"
              >
                mdi-eye
              </v-icon>
            </template>
            <span>View Schedule</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </v-card>
</template>

<script>
import {
  BASE_URL,
  GET_STUDENT_CLASSES,
  GET_STUDENT_COURSES,
} from "@/common/apiEndpoints";
import {
  getAxiosOptions,
  formatDateTime,
  rightClickHandler,
} from "@/common/utility";
import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE, GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import {
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
import ContextMenu from "./ContextMenu.vue";
import {
  STATUS_PUBLISHED,
  COURSE_TYPE_PRIVATE,
  USER_TYPE_STUDENT,
} from "@/common/constants";
export default {
  data() {
    return {
      contextMenuObject: {},
      classRooms: [],
      courses: [],
      ClassRoomheaders: [
        {
          text: "Classroom Name",
          align: "start",
          width: "20%",
          value: "name",
        },
        {
          text: "Start Date",
          value: "startDate",
          align: "center",
        },
        {
          text: "Course Name",
          value: "courseId",
          width: "25%",
          align: "center",
        },
        {
          text: "Classes",
          value: "classes",
          align: "center",
        },
        {
          text: "Progress",
          value: "progress",
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      STATUS_PUBLISHED: STATUS_PUBLISHED,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      USER_TYPE_STUDENT: USER_TYPE_STUDENT,
    };
  },
  mounted: function () {
    this.setParams(this.paramsValue);
    this.getStudentClassess();
    this.fetchCourses();
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  methods: {
    ...mapMutations({
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    formatDateTime(date) {
      return formatDateTime(date);
    },
    rightClickHandler(e, item) {
      e.preventDefault();
      // do something with e and/or item
      if (!e.target.textContent) return;
      this.contextMenuObject = rightClickHandler(
        e.target.textContent,
        e.clientX,
        e.clientY
      );
    },

    fetchCourses() {
      let url = GET_STUDENT_COURSES;
      if (this.$route.params.studentId) {
        url = url + "?studentId=" + this.$route.params.studentId;
      } else if (this.paramsValue.studentId) {
        url = url + "?studentId=" + this.paramsValue.studentId;
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.courses = res.data.courses;
        })
        .catch((error) => {
          error = error.errorMessage;
        });
    },
    // getAllCourses() {
    //   let url = GET_ALL_COURSES;
    //   this.setOverlayValue(true);
    //   url = url + "?status=" + this.STATUS_PUBLISHED;
    //   url = url + "&courseType=" + this.COURSE_TYPE_PRIVATE;
    //   axios
    //     .get(url, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.courses = res.data.courses;
    //       this.getStudentClassess();
    //       this.setOverlayValue(false);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    getStudentClassess() {
      let url = GET_STUDENT_CLASSES;
      if (this.$route.params.studentId) {
        url = url + "?studentId=" + this.$route.params.studentId;
      } else if (this.paramsValue.studentId) {
        url = url + "?studentId=" + this.paramsValue.studentId;
      }
      this.setOverlayValue(true);
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.classRooms = res.data.classes;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },
    getCourseNameById(courseId) {
      let c = this.courses.filter((course) => {
        return course.courseId == courseId;
      });
       if (c.length>0 && c[0]) return c[0].courseName;
      return "No Course";
    },
    courseDialogOpen(courseId) {
      let c = this.courses.filter((course) => {
        if (course.courseId == courseId) {
          return course;
        }
      });
      if (c) {
        var courseParams = {};
        courseParams.courseData = c[0];
        this.setParams(courseParams);
        this.$router.push({
          name: "CourseView",
        });
      }
    },
    viewSchedule(item) {
      this.$router.push({
        name: "ClassSchedule",
        params: {
          classRoomId: item.classRoomId,
          className: item.name,
          isActiveSession: true,
          studentId: this.paramsValue.studentId,
          userType: USER_TYPE_STUDENT,
          firstName: this.paramsValue.firstName,
          lastName: this.paramsValue.lastName,
        },
      });
    },
  },
  components: { ContextMenu },
};
</script>
