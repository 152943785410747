<template>
  <v-card class="ma-2" style="border-radius: 10px" min-height="650px">
    <v-toolbar dark color="primary" elevation="2">
      <v-toolbar-title style="font-weight: 500; font-size: 24px">My Courses</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-col cols="10" sm="6" md="4">
        <v-text-field v-if="showSearchField" v-model="searchText" @input="searchCourse" clearable></v-text-field>
      </v-col>
      <v-btn icon>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" @click="showSearchField = !showSearchField">mdi-magnify</v-icon>
          </template>
          <span>Search</span>
        </v-tooltip>
      </v-btn>
      <v-menu v-model="menu" offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" title="Filter">mdi-filter</v-icon>
              </template>
              <span>Filter</span>
            </v-tooltip>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-checkbox v-model="courseTypeFilter" label="Public" color="primary" value="PUBLIC" @click="fetchCourses()"
              hide-details></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox v-model="courseTypeFilter" label="Private" color="primary" value="PRIVATE" @click="fetchCourses()"
              hide-details></v-checkbox>
          </v-list-item>

        </v-list>
      </v-menu>
    </v-toolbar>
    <v-container fluid>
      <v-row class="ma-1">
        <v-col cols="4" v-for="(course, index) in studentCourses" :key="index">
          <v-card width="100%" min-height="350" class="ma-1">
            <v-img width="100%" max-height="170" :src="course.thumbnail"></v-img>
            <v-row class="ma-1 pa-0" v-if="course.courseType == COURSE_TYPE_PRIVATE">
              <v-col cols="10">
                <v-row>
                  <v-card-title class="pa-0 ml-1" style="font-size: 22px; font-weight: 400">
                    <span v-if="course.courseName.length <= 20">{{
                      course.courseName
                    }}</span>
                    <v-tooltip bottom v-if="course.courseName.length > 20">
                      <template v-slot:activator="{ on }">
                        <span v-on="on">{{
                          course.courseName.substring(0, 20) + "..."
                        }}</span>
                      </template>
                      <span>{{ course.courseName }}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-row>
              </v-col>
              <v-col cols="2" class="pa-0 ma-0">
                <!-- <v-img src="../assets/premium-quality 1.svg" class="ml-2 pa-0" max-height="30" max-width="30"></v-img>
                <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE && !course.buyable && course.isForUK == true"
                  class="mt-1 ml-1" src="../assets/alpha-u-circle-outline.svg" max-height="30" max-width="30"></v-img> -->

                <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE && course.buyable
                  " src="../assets/premium-quality 1.svg" max-height="30" max-width="30"></v-img>
                <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE &&
                  !course.buyable &&
                  !course.isForUK
                  " src="../assets/school.png" max-height="30" max-width="30"></v-img>
                <v-img v-if="course.courseType == COURSE_TYPE_PRIVATE &&
                  !course.buyable &&
                  course.isForUK == true
                  " class="mt-1 ml-1" src="../assets/alpha-u-circle-outline.svg" max-height="30"
                  max-width="30"></v-img>
              </v-col>
            </v-row>
            <v-row class="ma-1 pa-0" v-else>
              <v-card-title class="pa-0 ml-1" style="font-size: 22px; font-weight: 400">
                <span v-if="course.courseName.length <= 28">{{
                  course.courseName
                }}</span>
                <v-tooltip bottom v-if="course.courseName.length > 28">
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                      course.courseName.substring(0, 28) + "..."
                    }}</span>
                  </template>
                  <span>{{ course.courseName }}</span>
                </v-tooltip>
              </v-card-title>
            </v-row>
            <v-container class="pa-0 ma-1">
              <v-row class="ma-1 pa-0" v-if="course.buyable && !course.classRoomId">
                <p class="pa-0 ma-0" style="font-size: 20px; font-weight: 400">
                  Price :
                  <span style="color: #faaf19; font-size: 20px; font-weight: 500">₹ {{ course.price }}</span>
                </p>
              </v-row>
              <v-row class="ma-1 mt-2 mb-2 pa-0" v-if="course.classRoomId">
                <v-col>
                  <v-row v-if="course.progress != null">
                    <v-col cols="9" class="pa-0">
                      <v-progress-linear rounded style="border-radius: 25px" height="20"
                        :value="course.progress.completed" color="success"></v-progress-linear>
                    </v-col>
                    <v-col cols="2" class="pa-0 mt-2">
                      <v-row justify="end">
                        <span style="font-size: 18px; font-weight: 500">{{ course.progress.completed }}%</span>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row v-if="course.progress == null && course.classRoomId" align="center">
                    <v-chip label small outlined color="error" style="font-size: 14px">Yet to start!</v-chip>
                  </v-row>
                </v-col>
              </v-row>

              <v-card-text class="pa-1">
                <div style="font-size: 14px; font-weight: 400" class="pa-0"
                  v-if="course.description && course.description.length < 80">
                  <span v-html="course.description"> </span>
                </div>
                <div style="font-size: 14px; font-weight: 400" class="pa-0" v-if="course.description &&
                  course.description.length >= 80 &&
                  showDescArr.length > 0 &&
                  !showDescArr[index].showFullDesc
                  ">
                  <span v-html="course.description.substring(0, 80) + '...'"></span>
                  <v-row justify="center">
                    <v-btn color="primary" small text @click="showDescArr[index].showFullDesc = true">
                      Read more
                    </v-btn>
                  </v-row>
                </div>
                <div class="pa-0 ma-1" v-if="course.description &&
                  course.description.length >= 80 &&
                  showDescArr.length > 0 &&
                  showDescArr[index].showFullDesc
                  ">
                  <span v-html="course.description"> </span>
                  <v-row justify="center">
                    <v-btn color="primary" small text @click="showDescArr[index].showFullDesc = false">
                      Read less
                    </v-btn>
                  </v-row>
                </div>
              </v-card-text>
            </v-container>
            <v-card-actions>
              <v-row class="ma-1" justify="center">
                <v-btn width="100" style="font-weight: 600; font-size: 16px" color="primary" v-if="course.buyable && course.chapters[0].lessons.length == 0
                  " @click="buyCourse(course)">
                  Buy Now
                </v-btn>
                <v-btn width="100" style="font-weight: 600; font-size: 16px" color="primary"
                  v-if="course.chapters[0].lessons.length != 0" @click="courseDialogOpen(course)">
                  Open
                </v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import {
  GET_STUDENT_COURSES,
  BASE_URL,
} from "@/common/apiEndpoints.js";
import {
  COURSE_TYPE_PRIVATE,
  COURSE_TYPE_PUBLIC,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
} from "@/common/constants";

import axios from "axios";
import { GET_TOKEN, GET_USER_TYPE } from "@/store/getters";
import { mapGetters, mapMutations } from "vuex";
import { getAxiosOptions } from "@/common/utility";
import {
  SET_API_ERROR,
  SET_API_SUCCESS,
  SET_OVERLAY_VALUE,
  SET_PARAMS_VALUE,
} from "@/store/mutations";
export default {
  data() {
    return {
      showSearchField: false,
      searchText: "",
      courseName: "",
      courses: [],
      courseTypeFilter: [],
      menu: false,
      studentCourses: [],
      showDescArr: [],
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      COURSE_TYPE_PUBLIC: COURSE_TYPE_PUBLIC,
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
    };
  },
  computed: {
    ...mapGetters({
      userType: `schoolModule/${GET_USER_TYPE}`,
      token: `schoolModule/${GET_TOKEN}`,
    }),
  },

  mounted: function () {
    this.fetchCourses();
    this.studentCourses = this.courses;
  },

  methods: {
    ...mapMutations({
      setApiError: `schoolModule/${SET_API_ERROR}`,
      setApiSuccess: `schoolModule/${SET_API_SUCCESS}`,
      setOverlayValue: `schoolModule/${SET_OVERLAY_VALUE}`,
      setParams: `schoolModule/${SET_PARAMS_VALUE}`,
    }),
    fetchCourses() {
      this.setOverlayValue(true);
      this.menu = false;
      let url = GET_STUDENT_COURSES;
      if (
        !(
          this.courseTypeFilter.length == 0 ||
          this.courseTypeFilter.length == 2
        )
      ) {
        if (url.indexOf("?") > 0) {
          url = url + "&courseType=" + this.courseTypeFilter[0];
        } else {
          url = url + "?courseType=" + this.courseTypeFilter[0];
        }
      }
      axios
        .get(url, getAxiosOptions(BASE_URL, this.token))
        .then((res) => {
          this.studentCourses = res.data.courses;
          this.showDescArr = [];
          for (let i = 0; i < this.studentCourses.length; i++) {
            let showDescProp = {};
            showDescProp.courseId = this.studentCourses[i].courseId;
            showDescProp.showFullDesc = false;
            this.showDescArr.push(showDescProp);
          }
          this.courses = res.data.courses;
          this.studentCourses = this.courses;
          this.setOverlayValue(false);
        })
        .catch((error) => {
          error = error.errorMessage;
          this.setOverlayValue(false);
        });
    },

    searchCourse() {
      if (this.searchText) {
        this.studentCourses = this.courses.filter((course) => {
          return course.courseName.toLowerCase().includes(this.searchText.toLowerCase());
        });
      } else {
        this.studentCourses = this.courses;
      }
    },
    courseDialogOpen(course) {
      var courseParams = {};
      courseParams.courseData = course;
      this.setParams(courseParams);
      if(course.courseType == 'PRIVATE'){
      this.$router.push({
        name: "PrivateCourseView",
      });
      }else{
         this.$router.push({
        name: "PublicCourseView",
      });
      }
    },
    buyCourse(course) {
      this.$router.push({
        name: "StudentCourseDetails",
        params: { course: course },
      });
    },
  },
};
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 80px;
  right: 20px;
}
</style>
